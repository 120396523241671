import {
  createJoboffer,
  deleteJoboffer,
  getJoboffer,
  saveJoboffer,
  validateJoboffer,
} from "services/cms/cmsJobofferService";
import {
  isSaveReportModalPresent,
  showSaveReportModal,
} from "services/cms/cmsService";
import * as actionTypes from "store/actionTypes/cmsJobofferActionTypes";
import { MODE_CREATE, MODE_EDIT } from "utils/constants";
import { translate, triggerGetServerSideProps } from "utils/util";
import { closeCreateEditModal, setSaveReportModal } from "./cmsActionCreator";
import {
  disableBeforeunload,
  hideLoadingOverlay,
  redirect,
  showLoadingOverlay,
} from "./generalActionCreator";

export const initJobofferCreate = () => {
  return {
    type: actionTypes.CMS_INIT_JOBOFFER_CREATE,
  };
};

export const initJobofferEditor = (editJoboffer) => {
  return {
    payload: editJoboffer,
    type: actionTypes.CMS_INIT_JOBOFFER_EDIT,
  };
};

export const resetJobofferEdit = () => {
  return {
    type: actionTypes.CMS_RESET_JOBOFFER_EDIT,
  };
};

export const getJobofferAndInitEdit = (jobofferUrl) => {
  return async (dispatch) => {
    const result = await getJoboffer(jobofferUrl);
    if (result.success) {
      console.log("fetched joboffer", result.response.data);
      dispatch(initJobofferEditor(result.response.data));
    }
  };
};

export const saveJobofferCreate = (router) => {
  return async (dispatch, getState) => {
    if (validateJoboffer(getState().cmsJoboffer.editJoboffer, MODE_CREATE)) {
      dispatch(showLoadingOverlay(translate("cms:creatingJoboffer")));

      const editJoboffer = getState().cmsJoboffer.editJoboffer;

      const result = await createJoboffer(editJoboffer);

      if (result.success) {
        console.log("created joboffer", result.response.data);
        dispatch(createJobofferSuccess());
        router.push(`/joboffers/${result.response.data.url}?edit=true`, null, {
          locale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
        });
        dispatch(initJobofferEditor(result.response.data));
        setTimeout(() => {
          dispatch(hideLoadingOverlay());
        }, 1000);
      } else {
        dispatch(createJobofferFailed());
        console.log("failed to create joboffer", result.error);
        dispatch(hideLoadingOverlay());
      }
    } else {
      dispatch(jobofferValidationFailed());
      dispatch(hideLoadingOverlay());
    }
  };
};

export const editJobofferField = (field, value) => {
  return {
    payload: { value, field },
    type: actionTypes.CMS_EDIT_JOBOFFER_FIELD,
  };
};

export const addJobofferJobCard = (type) => {
  return {
    payload: { type },
    type: actionTypes.CMS_ADD_JOBOFFER_JOBCARD,
  };
};

export const editJobofferJobCard = (field, value, position) => {
  return {
    payload: { value, field, position },
    type: actionTypes.CMS_EDIT_JOBOFFER_JOBCARD,
  };
};

export const deleteJobofferJobCard = (position) => {
  return {
    payload: { position },
    type: actionTypes.CMS_DELETE_JOBOFFER_JOBCARD,
  };
};

export const moveJobofferContentListItemUp = (position) => {
  return {
    payload: { position, direction: "up" },
    type: actionTypes.CMS_MOVE_JOBOFFER_CONTENT_LIST_ITEM_UP,
  };
};

export const moveJobofferContentListItemDown = (position) => {
  return {
    payload: { position, direction: "down" },
    type: actionTypes.CMS_MOVE_JOBOFFER_CONTENT_LIST_ITEM_DOWN,
  };
};

/**
 * deletes the current form from the joboffer
 * @param {*} formId
 * @returns
 */
export const deleteJobofferForm = (formId) => {
  return {
    payload: { formId },
    type: actionTypes.CMS_DELETE_JOBOFFER_FORM,
  };
};

/**
 * updates an joboffer
 *
 * @param {*} router
 * @param {boolean} publish optional only present if you click
 * the publish=true/unpublish=false button defaults to null if its not present
 * @returns
 */
export const saveJobofferUpdate = (
  router,
  publish = null,
  callbackFunction
) => {
  return async (dispatch, getState) => {
    if (validateJoboffer(getState().cmsJoboffer.editJoboffer, MODE_EDIT)) {
      dispatch(showLoadingOverlay(translate("cms:updatingJoboffer")));
      const oldUrl = getState().cmsJoboffer.originalJoboffer.url;

      const result = await saveJoboffer(
        getState().cmsJoboffer.editJoboffer,
        dispatch,
        publish
      );
      if (result.success) {
        console.log("updated joboffer", result.response.data);
        dispatch(getJobofferAndInitEdit(result.response.data.url));

        let confirmModal = null;
        if (isSaveReportModalPresent(getState().cms.saveReportModal)) {
          confirmModal = {
            title: translate("cms:hint"),
            content: (
              <>
                {translate("cms:jobofferSaved")}
                <br /> {translate("cms:filesThatCouldNotBeSaved")}:{" "}
              </>
            ),
            acceptBtnText: translate("cms:ok"),
            xIsCancel: true,
            imageType: "warning",
            hideCancelBtn: true,
          };
        }

        // hide create/edit modal
        dispatch(closeCreateEditModal());

        if (oldUrl !== result.response.data.url) {
          if (isSaveReportModalPresent(getState().cms.saveReportModal)) {
            dispatch(
              setSaveReportModal({
                redirectUrl: `/joboffers/${result.response.data.url}`,
                hideAction: "redirect",
              })
            );
            showSaveReportModal(
              dispatch,
              getState().cms.saveReportModal,
              confirmModal
            );
            dispatch(hideLoadingOverlay());
          } else {
            if (callbackFunction) {
              callbackFunction({
                status: "success",
                nextRoute: `/joboffers/${result.response.data.url}`,
              });
            } else {
              dispatch(disableBeforeunload(router));
              dispatch(
                redirect(
                  () => router.push(`/joboffers/${result.response.data.url}`),
                  `/joboffers/${result.response.data.url}`,
                  true
                )
              );
            }
          }
        } else {
          if (isSaveReportModalPresent(getState().cms.saveReportModal)) {
            showSaveReportModal(
              dispatch,
              getState().cms.saveReportModal,
              confirmModal
            );
            dispatch(hideLoadingOverlay());
          } else {
            // if no save report must be shown and the action was publishing
            // reload the joboffer to leave the edit mode
            if (publish) {
              // publish
              dispatch(reloadJoboffer(router));
            } else {
              // save
              dispatch(hideLoadingOverlay());
              if (callbackFunction) {
                callbackFunction({ status: "success" });
              }
            }
          }
        }
      } else {
        dispatch(hideLoadingOverlay());
        dispatch(updateJobofferFailed());
        console.log("failed to update joboffer", result.error);
        if (callbackFunction) {
          callbackFunction({ status: "error" });
        }
      }
    } else {
      dispatch(jobofferValidationFailed());
      dispatch(hideLoadingOverlay());
      if (callbackFunction) {
        callbackFunction({ status: "error" });
      }
    }
  };
};

export const reloadJoboffer = (router) => {
  return async (dispatch) => {
    dispatch(disableBeforeunload(router));
    dispatch(showLoadingOverlay(translate("cms:stopingEditMode")));
    dispatch(resetJobofferEdit());
    dispatch(redirect(() => triggerGetServerSideProps(router), `reload`));
  };
};

export const sendDeleteJoboffer = (id, router) => {
  return async (dispatch) => {
    dispatch(showLoadingOverlay(translate("cms:deletingJoboffer")));
    const result = await deleteJoboffer(id);
    if (result.success) {
      dispatch(disableBeforeunload(router));
      dispatch(redirect(() => router.push("/"), "/"));
      console.log("deleted joboffer", result.response.data);
    } else {
      console.log("failed to delete joboffer", result.error);
      dispatch(hideLoadingOverlay());
    }
  };
};

const updateJobofferSuccess = (updatedJoboffer) => {
  return {
    payload: updatedJoboffer,
    type: actionTypes.CMS_UPDATE_JOBOFFER_SUCCESS,
  };
};

const updateJobofferFailed = () => {
  return {
    type: actionTypes.CMS_UPDATE_JOBOFFER_FAILED,
  };
};

const createJobofferSuccess = () => {
  return {
    type: actionTypes.CMS_CREATE_JOBOFFER_SUCCESS,
  };
};
const createJobofferFailed = () => {
  return {
    type: actionTypes.CMS_CREATE_JOBOFFER_FAILED,
  };
};

const jobofferValidationFailed = () => {
  return {
    type: actionTypes.CMS_JOBOFFER_VALIDATION_FAILED,
  };
};

export const discardJobofferEditModalChanges = () => {
  return {
    type: actionTypes.CMS_DISCARD_JOBOFFER_EDIT_MODAL_CHANGES,
  };
};
