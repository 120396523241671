import * as actionTypes from "store/actionTypes/cmsCollectionTypeActionTypes";

export const openCollectionTypeNestedContentModal = (position, name) => {
  return {
    payload: { position, name },
    type: actionTypes.CMS_OPEN_NESTED_CONTENT_MODAL,
  };
};
export const openCollectionTypeConfigModal = (name, position) => {
  return {
    payload: { position, name },
    type: actionTypes.CMS_OPEN_CONFIG_CONTENT_MODAL,
  };
};

export const closeCollectionTypeModals = () => {
  return {
    type: actionTypes.CMS_CLOSE_COLLECTION_TYPE_MODALS,
  };
};
