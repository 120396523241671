import { createTheme } from "@mui/material/styles";
// DataGrid Locales
// import { deDE } from "@mui/x-data-grid";
// General Locales
// import { deDE } from "@mui/material/locale";


export const muiTheme = createTheme(
  // base theme configuratrion here
  {
    // this wasnt working
    // overrides: {
    //   MuiCssBaseline: {
    //     "@global": {
    //       html: {
    //         fontSize: "62.5%",
    //       },
    //     },
    //   },
    // },

    // set the base font size to 10 because of html font-size 62.5%
    typography: {
      // Tell Material-UI what's the font-size on the html element is.
      htmlFontSize: 10,
    },
    palette: {
      primary: {
        main: "#1976d2", //this overide blue color
        light: "#42a5f5", //overides light blue
        dark: "#1565c0", //overides dark blue color
      },
    },
    // If enUS is not wanted as NEXT_PUBLIC_CMS_USER_LANGUAGE this
    // is required for datagrid-translations:
    // ...deDE
  }
);
