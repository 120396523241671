import { getStrapiURL } from "utils/api";
import {
  axiosServerPostRequest,
  invalidateCmsAuthenticationCookie,
} from "utils/serverUtil";

export const checkCmsLogin = async (cookies, res) => {
  if (cookies && cookies.cmsToken) {
    // console.log("checking cms accesstoken against strapi...");
    const result = await axiosServerPostRequest(
      getStrapiURL("/custom/auth"),
      {},
      { cmsaccesstoken: cookies.cmsToken }
    );
    if (result.success) {
      if (result.response.data.invalidateCmsToken) {
        invalidateCmsAuthenticationCookie(res);
        return {
          isCmsUserAuthenticated: false,
          cmsUser: null,
          notifyMsg: result.response.data.notifyMsg,
        };
      } else {
        return {
          isCmsUserAuthenticated: true,
          cmsUser: result.response.data.cmsUserData,
          notifyMsg: result.response.data.notifyMsg,
        };
      }
    } else {
      return { isCmsUserAuthenticated: false, cmsUser: null, notifyMsg: null };
    }
  }
  return { isCmsUserAuthenticated: false, cmsUser: null, notifyMsg: null };
};
