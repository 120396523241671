// page states
export const SAVE_PAGE_STATE = 1;
export const REVIEW_PAGE_STATE = 2;
export const PUBLISH_PAGE_STATE = 3;

// root page url moved to env vars (NEXT_PUBLIC_ROOT_PAGE_URL)
// use process.env.NEXT_PUBLIC_ROOT_PAGE_URL insted
// export const ROOT_PAGE_URL = "startseite";

// placeholder image path
export const IMAGE_PLACEHOLDER = "/image-placeholder.png";

// CMS roles
export const CMS_ROLE_ADMIN = "admin";
export const CMS_ROLE_EDITOR = "editor";
export const CMS_ROLE_AUTHOR = "author";

// CMS feature names
export const CMS_FEATURE_NEWS = "news";
export const CMS_FEATURE_EVENTS = "events";
export const CMS_FEATURE_JOBOFFERS = "joboffers";
export const CMS_FEATURE_USERAPI = "userapi";
export const CMS_FEATURE_PWA = "pwa";

// CMS Page types used to determine what type of
// page is currently opened as content manager
export const PAGE_TYPE_HARDCODED = "hardcoded";
export const PAGE_TYPE_NOT_EDITABLE = "not_editable";
export const PAGE_TYPE_CONTENTPAGE = "contentpage";
export const PAGE_TYPE_NEWS = "news";
export const PAGE_TYPE_EVENT = "event";
export const PAGE_TYPE_JOBOFFER = "joboffer";

// translation namespaces
export const TRANSLATION_NAMESPACE_CONTENTTYPE = "contenttype";
export const TRANSLATION_NAMESPACE_USERAPI = "userapi";
export const TRANSLATION_NAMESPACE_PUBLIC = "public";
export const TRANSLATION_NAMESPACE_CMS = "cms";
export const TRANSLATION_NAMESPACE_DATAGRID = "datagrid";

// Content type general constants for different content types
// instead of using "news"/"form"/"event"...
// also used to determine which action
// is needed in CmsContent... components and which edit modal opens
export const CONTENT_TYPE_PAGE = "page"; // page is the edit modal of content page
export const CONTENT_TYPE_DYNAMICLIST = "dynamiclist";
export const CONTENT_TYPE_CONTENTPAGE = "contentpage";
export const CONTENT_TYPE_NEWS = "news";
export const CONTENT_TYPE_EVENT = "event";
export const CONTENT_TYPE_FORM = "form";
export const CONTENT_TYPE_NAVIGATION = "navigation";
export const CONTENT_TYPE_TAG = "tag";
export const CONTENT_TYPE_CATEGORY = "category";
export const CONTENT_TYPE_JOBOFFER = "joboffer";
export const CONTENT_TYPE_USER = "user";

export const CMS_PAGES_PATH = "/cms/pages";
export const CMS_NEWS_PATH = "/cms/news";
export const CMS_EVENTS_PATH = "/cms/events";
export const CMS_JOBOFFER_PATH = "/cms/joboffers";
export const CMS_FORMS_PATH = "/cms/forms";
export const CMS_CATEGORIES_PATH = "/cms/categories";
export const CMS_TAGS_PATH = "/cms/tags";
export const CMS_NAVIGATIONS_PATH = "/cms/navigations";
export const CMS_USER_MANAGEMENT_PATH = "/cms/user-management";

export const SEARCH_URL = "/suche";
export const NEWS_URL = "/news";
export const EVENTS_URL = "/events";
export const JOBOFFER_URL = "/joboffers";
export const USER_LOGIN_URL = "/user/login";

// create / edit collection item modal constants for isEdit
export const MODE_CREATE = false;
export const MODE_EDIT = true;

// flag that can be set on update news/event for publish/unpublish
export const MODE_PUBLISH = true;
export const MODE_UNPUBLISH = false;

// tags
export const MAX_TAGS = 25;

// file sizes
export const MAX_FORM_FIELD_FILE_SIZE_IN_MB = 4;
export const MAX_VIDEO_SIZE = 200 * 1024 * 1024;
export const MAX_AUDIO_SIZE = 20 * 1024 * 1024;

// media types
export const MEDIA_TYPE_IMG = "img";
export const MEDIA_TYPE_YOUTUBE = "youtube";
export const MEDIA_TYPE_VIDEO = "video";
export const MEDIA_TYPE_AUDIO = "audio";

// media library constants:
// managed file types
export const MANAGED_FILE_TYPE_ALL = "all";
export const MANAGED_FILE_TYPE_JOBOFFER = "joboffer";
// managed file mime types
export const MANAGED_FILE_MIMETYPE_ALL = "all";
export const MANAGED_FILE_MIMETYPE_IMAGE = "image";
export const MANAGED_FILE_MIMETYPE_APPLICATION = "application"; // documents (pdf...)

export const UPLOAD_TYPE_CONTENT_MANAGER = "UPLOAD_TYPE_CONTENT_MANAGER";
export const UPLOAD_TYPE_USER = "UPLOAD_TYPE_USER";
export const UPLOAD_TYPE_GUEST = "UPLOAD_TYPE_GUEST";

export const UPLOAD_STATE = {
  started: "started",
  progress: "progress",
  finished: "finished",
  failed: "failed",
};

export const TARGET_ATTRIBUTES = ["_self", "_parent", "_top", "_blank"];

/**
 * the max fileSize that will be sent as multipart formdata, everything
 * bigger than that is uploaded chunk by chunk
 */
export const MAX_MB_FORM_DATA_FILE_SIZE = 3;
/**
 * the chunk fileSize this is not 1MB its a bit above 1MB
 * because the chunks are base64 encoded
 */
export const CHUNK_UPLOAD_DEFAULT_SLICE_SIZE = 1 * (1024 * 1024);

// scaling factor for elements where spaceX/Y scaling is too much
export const MOBILE_SCALING_FACTOR = 0.5;
export const TABLET_SCALING_FACTOR = 0.75;

export const HARD_CODED_PAGES = [
  { url: "news" },
  { url: "events" },
  { url: "joboffers" },
  { url: "user/login" },
];

export const USER_REFRESH_TOKEN_INTERVAL = 30000; // 30 seconds
export const USER_REFRESH_TOKEN_WHEN_BELOW_REMAINING = 60000; // 1 minute

export const NAVIGATION_MAIN_NAME = "main";
export const NAVIGATION_MAIN_PRIVATE_NAME = "main_private";

export const MANAGED_FILECATEGORIES = {
  IMAGE: "image",
  VIDEO: "video",
  DOCUMENT: "document",
  AUDIO: "audio",
  SVG: "svg",
};
