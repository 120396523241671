import { userForgotPassword } from "services/authentication/userClientAuthenticationService";
import { checkCaptcha } from "services/captchaService/captchaService";
import {
  createUser,
  deleteUser,
  updateUser,
} from "services/cms/cmsUserManagementService";
import * as actionTypes from "store/actionTypes/cmsUserManagementActionTypes";
import { translate } from "utils/util";
import {
  disableBeforeunload,
  hideLoadingOverlay,
  redirect,
  showLoadingOverlay,
} from "./generalActionCreator";

const userDoesNotExist = () => {
  return {
    type: actionTypes.CMS_USER_DOES_NOT_EXIST,
  };
};

export const resetUserEditor = () => {
  return {
    type: actionTypes.CMS_RESET_USER_EDITOR,
  };
};

export const initUserEditorCreate = () => {
  return {
    type: actionTypes.CMS_INIT_USER_EDITOR_CREATE,
  };
};

export const editUserField = (field, value) => {
  return {
    payload: { field, value },
    type: actionTypes.CMS_EDIT_USER_FIELD,
  };
};

export const editUserImageField = (field, value) => {
  return {
    payload: { field, value },
    type: actionTypes.CMS_EDIT_USER_IMAGE_FIELD,
  };
};

export const editUserDataField = (field, value) => {
  return {
    payload: { field, value },
    type: actionTypes.CMS_EDIT_USER_DATA_FIELD,
  };
};

export const sendDeleteUser = (userId, refreshDataFromServer) => {
  return async (dispatch) => {
    dispatch(showLoadingOverlay());
    const result = await deleteUser(userId);
    if (result.success) {
      if (refreshDataFromServer) {
        refreshDataFromServer();
      }
      dispatch(deleteUserSuccess());
    } else {
      dispatch(deleteUserFailed());
    }
    dispatch(hideLoadingOverlay());
  };
};

export const initUserEditorEdit = (user) => {
  return async (dispatch) => {
    if (user && user.id) {
      dispatch(initUserEditor(user));
    } else {
      dispatch(userDoesNotExist());
    }
  };
};

const initUserEditor = (user) => {
  return {
    payload: {
      user: user,
    },
    type: actionTypes.CMS_INIT_USER_EDITOR,
  };
};

export const sendCreateUser = (router) => {
  return async (dispatch, getState) => {
    dispatch(showLoadingOverlay(translate("cms:creatingUser")));
    const result = await createUser(getState().cmsUserManagement.editUser);
    if (result.success) {
      userForgotPassword(result.response.data.email, checkCaptcha(null), "cms");
      dispatch(createUserSuccess());
      if (router) {
        dispatch(disableBeforeunload());
        dispatch(
          redirect(
            () => router.push("/cms/user-management"),
            "/cms/user-management"
          )
        );
      }
    } else {
      dispatch(hideLoadingOverlay());
      dispatch(createUserFailed());
      console.log("could not create user!");
      console.log(result.error);
    }
  };
};

export const sendUpdateUser = (router, callbackFunction) => {
  return async (dispatch, getState) => {
    dispatch(saveUser());
    dispatch(showLoadingOverlay(translate("cms:updatingUser")));

    const result = await updateUser(getState().cmsUserManagement.editUser);
    if (result.success) {
      dispatch(updateUserSuccess());
      if (callbackFunction) {
        callbackFunction({ status: "success" });
      } else if (router) {
        dispatch(disableBeforeunload());
        dispatch(
          redirect(
            () => router.push("/cms/user-management"),
            "/cms/user-management"
          )
        );
      } else {
        dispatch(hideLoadingOverlay());
        if (callbackFunction) {
          callbackFunction({ status: "success" });
        }
      }
    } else {
      dispatch(hideLoadingOverlay());
      dispatch(updateUserFailed());
      console.log("could not update user!");
      console.log(result.error);
      if (callbackFunction) {
        callbackFunction({ status: "error" });
      }
    }
  };
};

const saveUser = () => {
  return {
    type: actionTypes.CMS_UPDATE_USER,
  };
};

const createUserFailed = () => {
  return {
    type: actionTypes.CMS_CREATE_USER_FAILED,
  };
};

const createUserSuccess = () => {
  return {
    type: actionTypes.CMS_CREATE_USER_SUCCESS,
  };
};
const updateUserFailed = () => {
  return {
    type: actionTypes.CMS_UPDATE_USER_FAILED,
  };
};

const updateUserSuccess = () => {
  return {
    type: actionTypes.CMS_UPDATE_USER_SUCCESS,
  };
};
const deleteUserFailed = () => {
  return {
    type: actionTypes.CMS_DELETE_USER_FAILED,
  };
};

const deleteUserSuccess = () => {
  return {
    type: actionTypes.CMS_DELETE_USER_SUCCESS,
  };
};
