import { useTranslation } from "next-i18next";
import { cmsHasFeature } from "utils/clientUtil";

/**
 * feature check for the CMS
 *
 * renders a string if the app is running in local environment (NEXT_PUBLIC_CURRENT_ENVIRONMENT)
 * Its not defined if we show the missing feature notification/text
 *
 * @param {props.feature} the feature that needs to be checked
 * @param {props.children} the JSX code that is only shown if the user does have the role
 * @param {props.hidePlaceholderText} hide the info which feature is missing
 * @returns
 */
export default function CmsHasFeature(props) {
  const { feature, children, hidePlaceholderText } = props;
  const { t: tCms } = useTranslation("cms");
  return (
    <>
      {cmsHasFeature(feature) ? (
        <> {children} </>
      ) : (
        <>
          {!hidePlaceholderText &&
          process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT === "local" ? (
            <>
              <div style={{ backgroundColor: "white" }}>
                {tCms("feature-functionNeeded", { feature })}
              </div>
            </>
          ) : null}
        </>
      )}
    </>
  );
}
