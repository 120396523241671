import { getNextJsApiURL } from "utils/api";
import { axiosPostRequest } from "utils/clientUtil";
import { handleResponseToastNotifications } from "utils/util";

/**
 * Update user service
 * @param {*} data user object
 * @returns
 */
export const updateUser = async (data) => {
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/user/update`),
    data
  );
  handleResponseToastNotifications(
    result,
    "userUpdateSuccess",
    "userUpdateFail"
  );
  return result;
};

/**
 * User reset 2FA service
 * @param {*} id
 * @returns
 */
export const userResetTwoFactor = async (id) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/cms/manage/user/reset-two-factor"),
    {
      id,
    }
  );
  handleResponseToastNotifications(
    result,
    "userResetTwoFactorSuccess",
    "userResetTwoFactorFail"
  );
  return result;
};

/**
 * User reset policy status service
 * @returns
 */
export const userResetPolicyStatus = async () => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/cms/manage/user/reset-policy-status"),
    {}
  );
  handleResponseToastNotifications(
    result,
    "userResetPolicyStatusSuccess",
    "userResetPolicyStatusFail"
  );
  return result;
};

/**
 * Create user
 * @param {*} data
 * @returns
 */
export const createUser = async (data) => {
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/user/create`),
    data
  );
  handleResponseToastNotifications(
    result,
    "userCreateSuccess",
    "userCreateFailed"
  );
  return result;
};

/**
 * delete user by id
 * @param {*} id
 * @returns
 */
export const deleteUser = async (id) => {
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/user/delete`),
    { id }
  );
  handleResponseToastNotifications(
    result,
    "userDeleteSuccess",
    "userDeleteFailed"
  );
  return result;
};
