import * as actionTypes from "store/actionTypes/cmsOpenAIActionTypes";

export const resetOpenAIEditor = () => {
  return {
    type: actionTypes.CMS_RESET_OPENAI_EDITOR,
  };
};
export const openOpenAIEditor = ({
  contentPos,
  fieldNames,
  nestedContentArrayFieldName,
  nestedContentPos,
}) => {
  return {
    payload: {
      contentPos,
      fieldNames,
      nestedContentArrayFieldName,
      nestedContentPos,
    },
    type: actionTypes.CMS_OPEN_OPENAI_EDITOR,
  };
};

export const closeOpenAIEditor = () => {
  return {
    type: actionTypes.CMS_CLOSE_OPENAI_EDITOR,
  };
};
export const editOpenAIEditorValue = (fieldName, fieldValue) => {
  return {
    payload: { fieldName, fieldValue },
    type: actionTypes.CMS_EDIT_OPENAI_VALUE,
  };
};

export const editChipsHistory = (historyElement) => {
  return {
    payload: historyElement,
    type: actionTypes.CMS_UPDATE_CHIPS_HISTORY,
  };
};
export const deleteChipsHistoryItem = (contentPos, nestedContentPos) => {
  return (dispatch, getState) => {
    const contentElement =
      getState().cms.editInfo?.editDraftPage?.content[contentPos];
    const nestedContentElement =
      nestedContentPos >= 0 ? contentElement.content[nestedContentPos] : null;
    const ceId = contentElement.id || contentElement.__new_id;
    const nestedId =
      nestedContentPos >= 0
        ? nestedContentElement.id || nestedContentElement.__new_id
        : null;

    const index = getState().cmsOpenAI.chipsHistory.findIndex(
      (element) =>
        element.component === contentElement.__component &&
        element.id === ceId &&
        element.nestedId === nestedId
    );
    dispatch({
      payload: index,
      type: actionTypes.CMS_DELETE_CHIPS_HISTORY_ITEM,
    });
  };
};
export const openOpenAIModalAndLoadChipsHistory = ({
  contentPos,
  fieldNames,
  nestedContentArrayFieldName,
  nestedContentPos,
}) => {
  return (dispatch, getState) => {
    dispatch(
      openOpenAIEditor({
        contentPos,
        fieldNames,
        nestedContentArrayFieldName,
        nestedContentPos,
      })
    );
    const contentElement =
      getState().cms.editInfo?.editDraftPage?.content[contentPos];
    const nestedContentElement =
      nestedContentPos >= 0 ? contentElement.content[nestedContentPos] : null;
    const ceId = contentElement.id || contentElement.__new_id;
    const nestedId =
      nestedContentPos >= 0
        ? nestedContentElement.id || nestedContentElement.__new_id
        : null;
    if (!contentElement || ceId === 0) {
      return -1;
    }
    const openAIEditorState = getState().cmsOpenAI;
    const index = getState().cmsOpenAI.chipsHistory.findIndex(
      (element) =>
        element.component === contentElement.__component &&
        element.id === ceId &&
        element.nestedId === nestedId
    );
    if (index !== -1) {
      dispatch(
        editOpenAIEditorValue("keywords", [
          ...new Set([...openAIEditorState.chipsHistory[index].keywords]),
        ])
      );
      dispatch(
        editOpenAIEditorValue(
          "maxWords",
          openAIEditorState.chipsHistory[index].textLength
            ? openAIEditorState.chipsHistory[index].textLength
            : 0
        )
      );
      dispatch(
        editOpenAIEditorValue(
          "withContext",
          openAIEditorState.chipsHistory[index].withContext
        )
      );
    }
  };
};
