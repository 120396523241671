export const SHOW_CONFIRM_MODAL = "SHOW_CONFIRM_MODAL";
export const RESET_CONFIRM_MODAL = "RESET_CONFIRM_MODAL";
export const HIDE_CONFIRM_MODAL = "HIDE_CONFIRM_MODAL";
export const ACCEPT_CONFIRM_MODAL = "ACCEPT_CONFIRM_MODAL";
export const CANCEL_CONFIRM_MODAL = "CANCEL_CONFIRM_MODAL";
export const UPDATE_DLIST = "UPDATE_DLIST";
export const ADD_CREATED_DLIST = "ADD_CREATED_DLIST";
export const SHOW_LOADING_OVERLAY = "SHOW_LOADING_OVERLAY";
export const HIDE_LOADING_OVERLAY = "HIDE_LOADING_OVERLAY";
export const SET_LOADING_OVERLAY_MESSAGE = "SET_LOADING_OVERLAY_MESSAGE";
export const ENABLE_BEFOREUNLOAD_EVENT = "ENABLE_BEFOREUNLOAD_EVENT";
export const DISABLE_BEFOREUNLOAD_EVENT = "DISABLE_BEFOREUNLOAD_EVENT";
export const REDIRECT = "REDIRECT";
export const SET_PAGE_VISIBILITY = "SET_PAGE_VISIBILITY";
export const RESET_CLIPBOARD = "RESET_CLIPBOARD";
export const SET_CLIPBOARD_DATA = "SET_CLIPBOARD_DATA";
export const SET_CLIPBOARD_CANCELED = "SET_CLIPBOARD_CANCELED";
export const SET_SHOW_PWA_PROMPT = "SET_SHOW_PWA_PROMPT";
