export const CMS_RESET_USER_EDITOR = "CMS_RESET_USER_EDITOR";
export const CMS_INIT_USER_EDITOR_CREATE = "CMS_INIT_USER_EDITOR_CREATE";
export const CMS_EDIT_USER_FIELD = "CMS_EDIT_USER_FIELD";
export const CMS_INIT_USER_EDITOR = "CMS_INIT_USER_EDITOR";
export const CMS_INIT_USER_EDITOR_EDIT = "CMS_INIT_USER_EDITOR_EDIT";
export const CMS_EDIT_USER_IMAGE_FIELD = "CMS_EDIT_USER_IMAGE_FIELD";
export const CMS_EDIT_USER_DATA_FIELD = "CMS_EDIT_USER_DATA_FIELD";
export const CMS_DELETE_USER = "CMS_DELETE_USER";
export const CMS_UPDATE_USER = "CMS_UPDATE_USER";
export const CMS_CREATE_USER_FAILED = "CMS_CREATE_USER_FAILED";
export const CMS_CREATE_USER_SUCCESS = "CMS_CREATE_USER_SUCCESS";
export const CMS_DELETE_USER_FAILED = "CMS_DELETE_USER_FAILED";
export const CMS_DELETE_USER_SUCCESS = "CMS_DELETE_USER_SUCCESS";
export const CMS_UPDATE_USER_FAILED = "CMS_UPDATE_USER_FAILED";
export const CMS_UPDATE_USER_SUCCESS = "CMS_UPDATE_USER_SUCCESS";
export const CMS_USER_DOES_NOT_EXIST = "CMS_USER_DOES_NOT_EXIST";
