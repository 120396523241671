export const CMS_RESET_DLIST_EDITOR = "CMS_RESET_DLIST_EDITOR";
export const CMS_OPEN_DLIST_EDITOR = "CMS_OPEN_DLIST_EDITOR";
export const CMS_INIT_DLIST_EDITOR = "CMS_INIT_DLIST_EDITOR";
export const CMS_DLIST_DOES_NOT_EXIST = "CMS_DLIST_DOES_NOT_EXIST";
export const CMS_ADD_DLIST_ITEM = "CMS_ADD_DLIST_ITEM";
export const CMS_EDIT_DLIST_ITEM = "CMS_EDIT_DLIST_ITEM";
export const CMS_DELETE_DLIST_ITEM = "CMS_DELETE_DLIST_ITEM";
export const CMS_MOVE_DLIST_ITEM_UP = "CMS_MOVE_DLIST_ITEM_UP";
export const CMS_MOVE_DLIST_ITEM_DOWN = "CMS_MOVE_DLIST_ITEM_DOWN";
export const CMS_UPDATE_DLIST = "CMS_UPDATE_DLIST";
export const CMS_UPDATE_DLIST_FAILED = "CMS_UPDATE_DLIST_FAILED";
export const CMS_UPDATE_DLIST_SUCCESS = "CMS_UPDATE_DLIST_SUCCESS";
export const CMS_DLIST_EDITOR_RESET_CHANGES = "CMS_DLIST_EDITOR_RESET_CHANGES";
export const CMS_UPDATE_DLIST_TITLE = "CMS_UPDATE_DLIST_TITLE";