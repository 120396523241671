import * as actionTypes from "../actionTypes/cmsTagActionTypes";

export const initCmsTagState = {
  tagEditorOpen: false,
  editTagId: null,
  editTagName: "",
};

const cmsTagReducer = (state = initCmsTagState, action) => {
  switch (action.type) {
    case actionTypes.CMS_RESET_TAG_EDITOR:
    case actionTypes.CMS_CREATE_TAG_SUCCESS:
    case actionTypes.CMS_UPDATE_TAG_SUCCESS: {
      return {
        tagEditorOpen: false,
        editTagId: null,
        editTagName: "",
      };
    }
    case actionTypes.CMS_OPEN_TAG_EDITOR:
      return {
        tagEditorOpen: true,
        editTagId: action.payload.tagId,
        editTagName: action.payload.tagName,
      };
    case actionTypes.CMS_EDIT_TAG_FIELD: {
      return {
        ...state,
        [action.payload.fieldName]: action.payload.fieldValue,
      };
    }
    default:
      return state;
  }
};

export default cmsTagReducer;
