export const USER_AUTHENTICATE_SUCCESS = "USER_AUTHENTICATE_SUCCESS";
export const USER_AUTHENTICATE_FAILED = "USER_AUTHENTICATE_FAILED";
export const USER_REFRESH_TOKEN = "USER_REFRESH_TOKEN";
export const USER_REFRESH_TOKEN_SUCCESS = "USER_REFRESH_TOKEN_SUCCESS";
export const USER_REFRESH_TOKEN_FAILED = "USER_REFRESH_TOKEN_FAILED";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const USER_FORGOT_PASSWORD_FAILED = "USER_FORGOT_PASSWORD_FAILED";
export const USER_SET_PASSWORD_SUCCESS = "USER_SET_PASSWORD_SUCCESS";
export const USER_SET_PASSWORD_FAILED = "USER_SET_PASSWORD_FAILED";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILED = "USER_LOGOUT_FAILED";
export const USER_AUTHENTICATE_WAITING_FOR_2FA =
  "USER_AUTHENTICATE_WAITING_FOR_2FA";