export const CMS_INIT_NEWS_CREATE = "CMS_INIT_NEWS_CREATE";
export const CMS_INIT_NEWS_EDIT = "CMS_INIT_NEWS_EDIT";
export const CMS_RESET_NEWS_EDIT = "CMS_RESET_NEWS_EDIT";
export const CMS_DISCARD_NEWS_EDIT_MODAL_CHANGES =
  "CMS_DISCARD_NEWS_EDIT_MODAL_CHANGES";
export const CMS_CREATE_NEWS_SUCCESS = "CMS_CREATE_NEWS_SUCCESS";
export const CMS_CREATE_NEWS_FAILED = "CMS_CREATE_NEWS_FAILED";
export const CMS_EDIT_NEWS_FIELD = "CMS_EDIT_NEWS_FIELD";
export const CMS_UPDATE_NEWS_SUCCESS = "CMS_UPDATE_NEWS_SUCCESS";
export const CMS_UPDATE_NEWS_FAILED = "CMS_UPDATE_NEWS_FAILED";
export const CMS_ADD_NEWS_CONTENT_LIST_ITEM = "CMS_ADD_NEWS_CONTENT_LIST_ITEM";
export const CMS_MOVE_NEWS_CONTENT_LIST_ITEM_UP =
  "CMS_MOVE_NEWS_CONTENT_LIST_ITEM_UP";
export const CMS_MOVE_NEWS_CONTENT_LIST_ITEM_DOWN =
  "CMS_MOVE_NEWS_CONTENT_LIST_ITEM_DOWN";
export const CMS_DELETE_NEWS_CONTENT_LIST_ITEM =
  "CMS_DELETE_NEWS_CONTENT_LIST_ITEM";
export const CMS_RELOAD_NEWS_PAGE = "CMS_RELOAD_NEWS_PAGE";
export const CMS_NEWS_VALIDATION_FAILED = "CMS_NEWS_VALIDATION_FAILED";
export const CMS_ADD_NESTED_NEWS_CONTENT_LIST_ITEM =
  "CMS_ADD_NESTED_NEWS_CONTENT_LIST_ITEM";
export const CMS_EDIT_NESTED_NEWS_FIELD = "CMS_EDIT_NESTED_NEWS_FIELD";
export const CMS_DELETE_NESTED_NEWS_FIELD = "CMS_DELETE_NESTED_NEWS_FIELD";
export const CMS_MOVE_NEWS_CONTENT_NESTED_LIST_ITEM_UP =
  "CMS_MOVE_NEWS_CONTENT_NESTED_LIST_ITEM_UP";
export const CMS_MOVE_NEWS_CONTENT_NESTED_LIST_ITEM_DOWN =
  "CMS_MOVE_NEWS_CONTENT_NESTED_LIST_ITEM_DOWN";
export const CMS_EDIT_CONFIG_NEWS_FIELD = "CMS_EDIT_CONFIG_NEWS_FIELD";
export const CMS_CLONE_NEWS_CONTENT_LIST_ITEM =
  "CMS_CLONE_NEWS_CONTENT_LIST_ITEM";
