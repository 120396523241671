import {
  createManagedFile,
  deleteManagedFile,
  updateManagedFile,
  validateManagedFileInputs,
} from "services/cms/cmsManagedFileService";
import * as actionTypes from "store/actionTypes/cmsManagedFileActionTypes";

export const resetManagedFileEditor = () => {
  return {
    type: actionTypes.CMS_RESET_MANAGEDFILE_EDITOR,
  };
};

export const initManagedFileCreate = () => {
  return {
    type: actionTypes.CMS_INIT_MANAGEDFILE_CREATE,
  };
};

export const editManagedFileField = (fieldName, fieldValue) => {
  return {
    payload: {
      fieldName,
      fieldValue,
    },
    type: actionTypes.CMS_EDIT_MANAGEDFILE_FIELD,
  };
};

export const editManagedFileValues = (managedFileValues) => {
  return {
    payload: {
      managedFileValues,
    },
    type: actionTypes.CMS_EDIT_MANAGEDFILE_VALUES,
  };
};

export const editSelectedManagedFile = (selectedFileValues) => {
  return {
    payload: {
      selectedFileValues,
    },
    type: actionTypes.CMS_EDIT_SELECTED_MANAGEDFILE,
  };
};

export const openManagedFileEditor = (managedFileCategories, noSelectionConfirmButton = false) => {
  return {
    payload: { managedFileCategories, noSelectionConfirmButton },
    type: actionTypes.CMS_OPEN_MANAGEDFILE_EDITOR,
  };
};

export const saveManagedFile = (callbackFunction, isEdit) => {
  return async (dispatch, getState) => {
    if (!validateManagedFileInputs(getState().cmsManagedFile.editManagedFile)) {
      return dispatch(createManagedFileFailed());
    }
    let result = null;

    if (isEdit) {
      dispatch(updateManagedFileStart);
      result = await updateManagedFile(
        getState().cmsManagedFile.editManagedFile,
        dispatch
      );
    } else {
      dispatch(createManagedFileStart);
      result = await createManagedFile(
        getState().cmsManagedFile.editManagedFile,
        dispatch
      );
    }

    if (result.success) {
      if (callbackFunction) {
        callbackFunction(result.response.data);
      }
    }
  };
};

export const removeManagedFile = (callbackFunction) => {
  return async (dispatch, getState) => {
    // if (!validateManagedFileInputs(getState().cmsManagedFile.editManagedFile)) {
    //   return dispatch(deleteManagedFileFailed());
    // }
    dispatch(deleteManagedFileStart);
    const result = await deleteManagedFile(
      getState().cmsManagedFile.editManagedFile.id
    );

    if (result.success) {
      dispatch(triggerReloadOnManagedFileEditorClose());
      if (callbackFunction) {
        callbackFunction(result.response.data);
      }
    }
  };
};

export const createManagedFileStart = () => {
  return {
    type: actionTypes.CMS_CREATE_MANAGEDFILE_START,
  };
};

export const createManagedFileSuccess = () => {
  return {
    type: actionTypes.CMS_CREATE_MANAGEDFILE_SUCCESS,
  };
};

export const createManagedFileFailed = () => {
  return {
    type: actionTypes.CMS_CREATE_MANAGEDFILE_FAILED,
  };
};

export const updateManagedFileStart = () => {
  return {
    type: actionTypes.CMS_UPDATE_MANAGEDFILE_START,
  };
};

export const updateManagedFileSuccess = () => {
  return {
    type: actionTypes.CMS_UPDATE_MANAGEDFILE_SUCCESS,
  };
};

export const updateManagedFileFailed = () => {
  return {
    type: actionTypes.CMS_UPDATE_MANAGEDFILE_FAILED,
  };
};

export const sendDeleteManagedFile = (managedFileId) => {
  return async (dispatch) => {
    // currently not implemented this will be implemented later so keep this
    console.log("delete managed file API call...");
  };
};

export const deleteManagedFileStart = () => {
  return {
    type: actionTypes.CMS_DELETE_MANAGEDFILE_START,
  };
};

export const deleteManagedFileSuccess = () => {
  return {
    type: actionTypes.CMS_DELETE_MANAGEDFILE_SUCCESS,
  };
};

export const deleteManagedFileFailed = () => {
  return {
    type: actionTypes.CMS_DELETE_MANAGEDFILE_FAILED,
  };
};

export const triggerReloadOnManagedFileEditorClose = () => {
  return {
    type: actionTypes.CMS_TRIGGER_RELOAD_ON_CLOSE_MANAGEDFILE_EDITOR,
  };
};