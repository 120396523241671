// import bootstrap first to override bootstrap classes
import { ThemeProvider } from "@mui/material/styles";
import dynamic from "next/dynamic";
import Layout from "components/layout/layout";
import GlobalStyle from "components/theme/globalStyle";
import useRegisterServiceWorker from "hooks/useRegisterServiceWorker";
import { appWithTranslation } from "next-i18next";
import App from "next/app";
import Head from "next/head";
import { Provider } from "react-redux";
import { getAllCategories } from "services/category/categoryServerService";
import { checkCmsLogin } from "services/cms/cmsServerService";
import { getCmsSettings } from "services/cmsSettings/cmsSettingsServerService";
import {
  cmsSettings,
  setCmsSettings,
} from "services/cmsSettings/cmsSettingsService";
import { getContentElementSettings } from "services/contentElementSettings/contentElementSettingsServerService";
import {
  contentElementSettings,
  setContentElementSettings,
} from "services/contentElementSettings/contentElementSettingsService";
import { getGlobalCss } from "services/cssService/cssServerService";
import { globalCss, setGlobalCss } from "services/cssService/cssService";
import { getDynamicLists } from "services/dynamicList/dynamicListServerService";
import { getAllEvents } from "services/event/eventServerService";
import { getGlobalSettings } from "services/globalSettings/globalSettingsServerService";
import {
  globalSettings,
  setGlobalSettings,
} from "services/globalSettings/globalSettingsService";
import { getAllJoboffers } from "services/joboffer/jobofferServerService";
import {
  getNavigations,
  getPrivateNavigations,
} from "services/navigation/navigationServerService";
import { getAllNews } from "services/news/newsServerService";
import { getPages } from "services/page/pageServerService";
import { getAllTags } from "services/tag/tagServerService";
import { getUserapiSettings } from "services/userapiSettings/userapiSettingsServerService";
import {
  setUserapiSettings,
  userapiSettings,
} from "services/userapiSettings/userapiSettingsService";
import { initCmsCategoryState } from "store/reducers/cmsCategoryReducer";
import { initCmsCollectionTypeState } from "store/reducers/cmsCollectionTypeReducer";
import { initCmsEventState } from "store/reducers/cmsEventReducer";
import { initCmsFormState } from "store/reducers/cmsFormReducer";
import { initCmsJobofferState } from "store/reducers/cmsJobofferReducer";
import { initCmsManagedFileState } from "store/reducers/cmsManagedFileReducer";
import { initCmsNavigationState } from "store/reducers/cmsNavigationReducer";
import { initCmsNewsState } from "store/reducers/cmsNewsReducer";
import { initCmsState } from "store/reducers/cmsReducer";
import { initCmsTagState } from "store/reducers/cmsTagReducer";
import { initCmsUserManagementState } from "store/reducers/cmsUserManagementReducer";
import { initGeneralState } from "store/reducers/generalReducer";
import { initTwoFactorState } from "store/reducers/twoFactorReducer";
import { initUserState } from "store/reducers/userReducer";
import { useStore } from "store/store";
import { CMS_FEATURE_USERAPI } from "utils/constants";
import { muiTheme } from "utils/muiTheme";
import { setCustomLang } from "services/customLang/customLangService";
import {
  cmsHasFeatureServerSide,
  isUserAccessTokenValid,
} from "utils/serverUtil";
import {
  combineAllPageTypesContents,
  matchWithNexti18NextLocale,
} from "utils/util";
const ErrorComponent = dynamic(() =>
  import("components/util/errorComponent/errorComponent")
);
import nextI18nConfig from "../next-i18next.config";
import "../styles/App.scss";
import "../styles/Bootstrap.scss";

const MyApp = ({ Component, pageProps }) => {
  const store = useStore(pageProps.initialReduxState);

  const pageLocale = matchWithNexti18NextLocale(pageProps?.page?.locale);
  if (pageProps.requestLocale !== pageLocale) {
    setCustomLang(pageLocale);
  } else {
    setCustomLang(pageProps.requestLocale);
  }

  setGlobalSettings(pageProps.globalSettings);

  if (
    pageProps.initialReduxState &&
    pageProps.initialReduxState.cms.isCmsUserAuthenticated
  ) {
    setCmsSettings(pageProps.cmsSettings);
  }
  if (
    pageProps.initialReduxState &&
    (pageProps.initialReduxState.cms.isCmsUserAuthenticated ||
      pageProps.initialReduxState.user.isAuthenticated)
  ) {
    setUserapiSettings(pageProps.userapiSettings);
  }
  setContentElementSettings(pageProps.contentElementSettings);
  setGlobalCss(pageProps.globalCss);
  useRegisterServiceWorker();

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      {/* {pageProps.globalCss ? (
        <style dangerouslySetInnerHTML={{ __html: pageProps.globalCss }} />
      ) : null} */}
      <Provider store={store}>
        <ThemeProvider theme={muiTheme}>
          {pageProps.settingsMissing ? (
            <ErrorComponent
              withStyle={true}
              optionalErrorMessage={"errorComponent-missingGlobalsettings"}
            />
          ) : (
            <Layout
              globalSettings={pageProps.globalSettings}
              contentElementSettings={pageProps.contentElementSettings}
              notifyMsg={pageProps.notifyMsg}
            >
              <Component {...pageProps} />
            </Layout>
          )}
        </ThemeProvider>

        {pageProps.settingsMissing ? null : (
          <>
            <GlobalStyle
              globalSettings={pageProps.globalSettings}
              globalCssString={pageProps.globalCss}
            />
            {/* <CsrStyleFix globalCssString={pageProps.globalCss} /> */}
          </>
        )}
      </Provider>
    </>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
MyApp.getInitialProps = async (appContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  // console.log("current locale: " + appContext.router.locale);
  const requestLocale = appContext.router.locale;

  // getInitialProps can run on server and client side!
  // if there is no request in ctx this function is running on the client side
  if (appContext.ctx.req) {
    // console.log("getInitialProps _app.js");

    const [
      contentElementSettings,
      globalCss,
      globalSettings,
      navigations,
      dynamicLists,
      cmsLogin,
    ] = await Promise.all([
      getContentElementSettings(),
      getGlobalCss(),
      getGlobalSettings(),
      getNavigations(requestLocale),
      getDynamicLists(requestLocale),
      checkCmsLogin(appContext.ctx.req.cookies, appContext.ctx.res),
    ]);

    // Add the currently authenticated user to the redux state so the user
    // is still available in the frontend
    let authenticatedUserState = initUserState;
    let userIsAuthenticated = false;
    if (appContext.ctx.req.cookies.userAccessToken) {
      // You could verify the token here but it does not matter because
      // the  token verification is done on pages/actions where it is needed anyway

      // decode the user access token to get the user information out of it
      const userAccessTokenVerifyResult = isUserAccessTokenValid(
        appContext.ctx.req,
        true
      );

      if (userAccessTokenVerifyResult.verified) {
        userIsAuthenticated = true;
        authenticatedUserState = {
          ...initUserState,
          isAuthenticated: true,
          expirationDate: userAccessTokenVerifyResult.payload.exp * 1000,
          user: userAccessTokenVerifyResult.payload.user,
        };
      }
    }

    let settingsMissing = false;
    if (
      Object.keys(globalSettings).length === 0 ||
      Object.keys(contentElementSettings).length === 0
    ) {
      settingsMissing = true;
    }

    const { isCmsUserAuthenticated, cmsUser, notifyMsg } = cmsLogin;
    // get all available pages if the content manager is authenticated
    let pages = [];
    let allNews = [];
    let allEvents = [];
    let allJoboffers = [];
    let allPageTypesContent = [];
    let categories = [];
    let tags = [];
    let cmsSettings = null;
    let userapiSettings = null;
    if (isCmsUserAuthenticated) {
      [pages, allNews, allEvents, allJoboffers, categories, tags, cmsSettings] =
        await Promise.all([
          getPages(appContext.ctx.req),
          getAllNews(appContext.ctx.req),
          getAllEvents(appContext.ctx.req),
          getAllJoboffers(appContext.ctx.req),
          getAllCategories(appContext.ctx.req),
          getAllTags(appContext.ctx.req),
          getCmsSettings(appContext.ctx.req),
        ]);

      allPageTypesContent = combineAllPageTypesContents(
        pages,
        allNews,
        allEvents,
        allJoboffers
      );
    }

    // all requests that should be done if you are authenticated
    // as a user OR as a cmsUser
    if (userIsAuthenticated || isCmsUserAuthenticated) {
      if (cmsHasFeatureServerSide(CMS_FEATURE_USERAPI)) {
        // authenticated user data that is refreshed on each reload
        // add other requests here to Promise.all
        const [privateNavigations, localizedUserapiSettings] =
          await Promise.all([
            getPrivateNavigations(appContext.ctx.req, requestLocale),
            getUserapiSettings(false, requestLocale),
          ]);
        navigations.push(...privateNavigations);
        userapiSettings = localizedUserapiSettings;
      }
    }

    // SET THE INITIAL REDUX STATE
    const initialReduxState = {
      cms: {
        ...initCmsState,
        isCmsUserAuthenticated: isCmsUserAuthenticated,
        cmsUser: {
          userId: cmsUser ? cmsUser.id : 0,
          username: cmsUser ? cmsUser.username : null,
          email: cmsUser ? cmsUser.email : null,
          roles: cmsUser ? cmsUser.roles : [],
          userSettings: {
            richTextHoverMenu: cmsUser ? cmsUser.richTextHoverMenu : true,
            cfgModalAsSidebar: cmsUser ? cmsUser.cfgModalAsSidebar : false,
          },
        },
        pages: pages,
        categories: categories,
        tags: tags,
        news: allNews,
        events: allEvents,
        joboffers: allJoboffers,
        allPageTypesContent: allPageTypesContent,
        popoverOpenerId: null,
      },
      navigation: {
        ...initCmsNavigationState,
        navigations: navigations,
      },
      general: {
        ...initGeneralState,
        dynamicLists: dynamicLists,
      },
      user: {
        ...authenticatedUserState,
      },
      twoFactor: {
        ...initTwoFactorState,
      },
      cmsNews: {
        ...initCmsNewsState,
      },
      cmsEvent: {
        ...initCmsEventState,
      },
      cmsJoboffer: {
        ...initCmsJobofferState,
      },
      cmsForm: {
        ...initCmsFormState,
      },
      cmsCategory: {
        ...initCmsCategoryState,
      },
      cmsTag: {
        ...initCmsTagState,
      },
      cmsNavigation: {
        ...initCmsNavigationState,
      },
      cmsCollectionType: {
        ...initCmsCollectionTypeState,
      },
      cmsManagedFile: {
        ...initCmsManagedFileState,
      },
      cmsUserManagement: {
        ...initCmsUserManagementState,
      },
    };
    return {
      ...appProps,
      // if you want to add more props put them into pageProps
      pageProps: {
        globalSettings: globalSettings,
        contentElementSettings: contentElementSettings,
        pages: pages,
        initialReduxState: initialReduxState,
        globalCss: globalCss,
        settingsMissing: settingsMissing,
        notifyMsg: notifyMsg,
        cmsSettings: cmsSettings,
        userapiSettings: userapiSettings,
        requestLocale: requestLocale,
      },
    };
  } else {
    // clientside initial props
    return {
      ...appProps,
      pageProps: {
        globalSettings: globalSettings,
        contentElementSettings: contentElementSettings,
        globalCss: globalCss,
        cmsSettings: cmsSettings,
        userapiSettings: userapiSettings,
        requestLocale: requestLocale,
      },
    };
  }
};

export default appWithTranslation(MyApp, nextI18nConfig);
