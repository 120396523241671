export let contentElementSettings = null;

export const setContentElementSettings = (settingsParameter) => {
  if (settingsParameter) {
    contentElementSettings = settingsParameter;
  } else {
    console.log(
      "using settings default values! - fetched settings is null or empty."
    );
  }
};
