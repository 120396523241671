import { getStrapiURL } from "utils/api";
import {
  axiosServerGetRequest,
  handleServerRequestError,
} from "utils/serverUtil";

export const getContentElementSettings = async () => {
  /* These settings get merged into content[x]-Element when settings are available. */
  /* Should be props.content.settings */
  const result = await axiosServerGetRequest(
    getStrapiURL("/assets/config-contentelementssettings.json")
  );
  if (result.success) {
    return result.response.data;
  } else {
    handleServerRequestError(
      result.error,
      "could not fetch contentelementssettings config-contentelementssettings.json [getContentElementSettings]"
    );
    return {}; // TODO return some defaul content element settings
  }
};
