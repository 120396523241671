import {
  createCategoryByNameAndType,
  deleteCategory,
  updateCategory,
} from "services/cms/cmsCategoryService";
import * as actionTypes from "store/actionTypes/cmsCategoryActionTypes";

export const resetCategoryEditor = () => {
  return {
    type: actionTypes.CMS_RESET_CATEGORY_EDITOR,
  };
};

export const editCategoryField = (fieldName, fieldValue) => {
  return {
    payload: {
      fieldName,
      fieldValue,
    },
    type: actionTypes.CMS_EDIT_CATEGORY_FIELD,
  };
};

export const openCategoryEditor = (
  categoryId = null,
  categoryName = "",
  categoryType = "",
  categoryLocalizationJson = ""
) => {
  return {
    payload: {
      categoryId,
      categoryName,
      categoryType,
      categoryLocalizationJson,
    },
    type: actionTypes.CMS_OPEN_CATEGORY_EDITOR,
  };
};

export const saveCategory = (refreshDataFromServer = null) => {
  return async (dispatch, getState) => {
    if (getState().cmsCategory.editCategoryId === null) {
      dispatch(createCategoryStart);
      const result = await createCategoryByNameAndType(
        getState().cmsCategory.editCategoryName,
        getState().cmsCategory.editCategoryType,
        getState().cmsCategory.editCategoryLocalizationJson
      );

      if (result.success) {
        console.log("create category", result.response.data);
        dispatch(createCategorySuccess(refreshDataFromServer));
      } else {
        dispatch(createCategoryFailed());
        console.log("failed to create category", result.error);
      }
    } else {
      dispatch(updateCategoryStart);
      const result = await updateCategory(
        getState().cmsCategory.editCategoryId,
        getState().cmsCategory.editCategoryName,
        getState().cmsCategory.editCategoryType,
        getState().cmsCategory.editCategoryLocalizationJson
      );

      if (result.success) {
        console.log("update category", result.response.data);
        dispatch(updateCategorySuccess(refreshDataFromServer));
      } else {
        dispatch(createCategoryFailed());
        console.log("failed to update category", result.error);
      }
    }
  };
};

export const createCategoryStart = () => {
  return {
    type: actionTypes.CMS_CREATE_CATEGORY_START,
  };
};

export const createCategorySuccess = (refreshDataFromServer) => {
  if (refreshDataFromServer !== null) {
    refreshDataFromServer();
  }
  return {
    type: actionTypes.CMS_CREATE_CATEGORY_SUCCESS,
  };
};

export const createCategoryFailed = () => {
  return {
    type: actionTypes.CMS_CREATE_CATEGORY_FAILED,
  };
};

export const updateCategoryStart = () => {
  return {
    type: actionTypes.CMS_UPDATE_CATEGORY_START,
  };
};

export const updateCategorySuccess = (refreshDataFromServer) => {
  if (refreshDataFromServer !== null) {
    refreshDataFromServer();
  }
  return {
    type: actionTypes.CMS_UPDATE_CATEGORY_SUCCESS,
  };
};

export const updateCategoryFailed = () => {
  return {
    type: actionTypes.CMS_UPDATE_CATEGORY_FAILED,
  };
};

export const sendDeleteCategory = (
  categoryId,
  refreshDataFromServer = null
) => {
  return async (dispatch) => {
    dispatch(deleteCategoryStart());
    const result = await deleteCategory(categoryId);
    if (result.success) {
      dispatch(resetCategoryEditor());
      dispatch(deleteCategorySuccess(refreshDataFromServer));
    } else {
      dispatch(deleteCategoryFailed());
    }
  };
};

export const deleteCategoryStart = () => {
  return {
    type: actionTypes.CMS_DELETE_CATEGORY_START,
  };
};

export const deleteCategorySuccess = (refreshDataFromServer) => {
  if (refreshDataFromServer !== null) {
    refreshDataFromServer();
  }
  return {
    type: actionTypes.CMS_DELETE_CATEGORY_SUCCESS,
  };
};

export const deleteCategoryFailed = () => {
  return {
    type: actionTypes.CMS_DELETE_CATEGORY_FAILED,
  };
};
