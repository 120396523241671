import * as actionTypes from "store/actionTypes/cmsEventActionTypes";
import { getRandomSlug } from "utils/util";

export const initCmsEventState = {
  editEvent: null,
  editEventId: null,
  originalEvent: null,
  eventUrl: null,
};
const cmsEventReducer = (state = initCmsEventState, action) => {
  switch (action.type) {
    case actionTypes.CMS_INIT_EVENT_CREATE: {
      return {
        ...state,
        editEvent: {
          ...state.editEvent,
          title: "",
          dateTimeStart: new Date().toISOString(),
          dateTimeEnd: new Date().toISOString(),
          dateTimeRepeatUntil: null,
          fullDay: false,
          location: "",
          repetition: "noRepetition",
          seoSettings: null,
          isPrivate: false,
          repetitionValues: action.payload.repetitionValues,
          categories: [],
          tags: [],
          // creator: creator is added programatically user based
        },
      };
    }
    case actionTypes.CMS_INIT_EVENT_EDIT: {
      return {
        ...state,
        editEvent: action.payload,
        editEventId: action.payload.id,
        originalEvent: action.payload,
        eventUrl: action.payload.url,
      };
    }
    case actionTypes.CMS_RESET_EVENT_EDIT: {
      return {
        ...state,
        editEvent: null,
        editEventId: null,
        originalEvent: null,
        eventUrl: null,
      };
    }
    case actionTypes.CMS_DISCARD_EVENT_EDIT_MODAL_CHANGES: {
      return {
        ...state,
        editEvent: {
          ...state.editEvent,
          dateTimeStart: state.originalEvent.dateTimeStart,
          dateTimeEnd: state.originalEvent.dateTimeEnd,
          dateTimeRepeatUntil: state.originalEvent.dateTimeRepeatUntil,
          fullDay: state.originalEvent.fullDay,
          location: state.originalEvent.location,
          repetition: state.originalEvent.repetition,
          seoSettings: state.originalEvent.seoSettings,
          categories: [...state.originalEvent.categories],
        },
      };
    }
    case actionTypes.CMS_EDIT_EVENT_FIELD: {
      const { value, field, position, specialType } = action.payload;

      if (specialType === "time" || specialType === "date") {
        let newDateTime = null;
        if (specialType === "date") {
          let timeDate = state.editEvent[field]
            ? new Date(state.editEvent[field])
            : new Date();
          if (value) {
            newDateTime = new Date(value);
          } else {
            newDateTime = new Date();
          }
          newDateTime.setHours(timeDate.getHours());
          newDateTime.setMinutes(timeDate.getMinutes());
        } else {
          newDateTime = state.editEvent[field]
            ? new Date(state.editEvent[field])
            : new Date();
          let hours = value.substring(0, 2);
          let minutes = value.substring(3, 5);
          newDateTime.setHours(hours);
          newDateTime.setMinutes(minutes);
        }
        return {
          ...state,
          editEvent: {
            ...state.editEvent,
            [field]: newDateTime.toISOString(),
          },
        };
      } else {
        // if position is undefined the field is direct property
        // and not a nested array
        if (typeof position !== "undefined" && position !== null) {
          return {
            ...state,
            editEvent: {
              ...state.editEvent,
              content: state.editEvent.content.map((element, index) =>
                position === index ? { ...element, [field]: value } : element
              ),
            },
          };
        } else {
          return {
            ...state,
            editEvent: {
              ...state.editEvent,
              [field]: value,
            },
          };
        }
      }
    }

    case actionTypes.CMS_EDIT_NESTED_EVENT_FIELD: {
      const { value, field, position, nestedPosition } = action.payload;

      return {
        ...state,
        editEvent: {
          ...state.editEvent,
          content: state.editEvent.content.map((element, index) =>
            position === index
              ? {
                  ...element,
                  nestedlistitems: state.editEvent.content[
                    index
                  ].nestedlistitems.map((nestedElement, nestedIndex) =>
                    nestedPosition === nestedIndex
                      ? { ...nestedElement, [field]: value }
                      : nestedElement
                  ),
                }
              : element
          ),
        },
      };
    }

    case actionTypes.CMS_EDIT_CONFIG_EVENT_FIELD: {
      const { value, field, position } = action.payload;
      return {
        ...state,
        editEvent: {
          ...state.editEvent,
          content: state.editEvent.content.map((element, index) =>
            position === index
              ? {
                  ...element,
                  cfgSettings: {
                    ...element.cfgSettings,
                    [field]: {
                      ...element.cfgSettings[field],
                      [`${field}Value`]: value,
                    },
                  },
                }
              : element
          ),
        },
      };
    }

    case actionTypes.CMS_DELETE_NESTED_EVENT_FIELD: {
      const { position, nestedPosition } = action.payload;

      return {
        ...state,
        editEvent: {
          ...state.editEvent,
          content: state.editEvent.content.map((element, index) =>
            position === index
              ? {
                  ...element,
                  nestedlistitems: state.editEvent.content[
                    index
                  ].nestedlistitems.filter(
                    (nestedElement, nestedIndex) =>
                      nestedPosition !== nestedIndex
                  ),
                }
              : element
          ),
        },
      };
    }

    case actionTypes.CMS_ADD_NESTED_EVENT_CONTENT_LIST_ITEM: {
      const { position, type } = action.payload;

      let newNestedListItem = {
        __component: "dynamiclistelements.nestedlistitem",
        __new_id: getRandomSlug(),
        cfgLinkCanonical: false,
        img: null,
        imgAlt: null,
        imgCaption: null,
        linkCanonical: null,
        linkText: null,
        linkUrl: null,
        richTextContent: null,
        title: null,
        videoUrl: null,
        video: null,
        videoThumbnail: null,
        videoFileName: null,
        audio: null,
        audioFileName: null,
        file: null,
        itemType: null,
      };

      switch (type) {
        case "gallery":
          newNestedListItem = {
            ...newNestedListItem,
            img: null,
            imgAlt: "",
            imgCaption: "",
            itemType: "image",
          };
          break;
        default:
          break;
      }

      return {
        ...state,
        editEvent: {
          ...state.editEvent,
          content: state.editEvent.content.map((element, index) =>
            position === index
              ? {
                  ...element,
                  nestedlistitems: [
                    ...state.editEvent.content[index].nestedlistitems,
                    newNestedListItem,
                  ],
                }
              : element
          ),
        },
      };
    }

    case actionTypes.CMS_ADD_EVENT_CONTENT_LIST_ITEM: {
      const { type, position } = action.payload;

      let newListItem = {
        __component: "dynamiclistelements.listitem",
        __new_id: getRandomSlug(),
        cfgLinkCanonical: false,
        img: null,
        imgAlt: null,
        imgCaption: null,
        linkCanonical: null,
        linkText: null,
        linkUrl: null,
        richTextContent: null,
        title: null,
        videoUrl: null,
        video: null,
        videoThumbnail: null,
        videoFileName: null,
        audio: null,
        audioFileName: null,
        file: null,
        nestedlistitems: [],
        itemType: null,
        cfgSettings: {
          backgroundColor: {
            backgroundColorValue: "default",
            backgroundColorOptions: ["default", "primary", "secondary"],
          },
        },
      };

      switch (type) {
        case "image":
          newListItem = {
            ...newListItem,
            img: null,
            imgAlt: "",
            imgCaption: "",
            itemType: "image",
          };
          break;
        case "link":
          newListItem = {
            ...newListItem,
            linkText: "",
            linkUrl: "",
            itemType: "link",
          };
          break;
        // case "canonicalLink":
        //   newListItem = {
        //     ...newListItem,
        //     linkUrl: "",
        //     cfgLinkCanonical: true,
        //   };
        //   break;
        case "multimedia":
          newListItem = {
            ...newListItem,
            itemType: "multimedia",
          };
          break;
        case "richtext":
          newListItem = {
            ...newListItem,
            richTextContent: "",
            itemType: "richtext",
          };
          break;
        case "gallery":
          newListItem = {
            ...newListItem,
            itemType: "gallery",
            cfgSettings: {
              ...newListItem.cfgSettings,
              galleryMode: {
                galleryModeValue: "default",
                galleryModeOptions: ["default", "masonry", "grid"],
              },
            },
          };
          break;

        default:
          break;
      }

      let updatedContentArray = [...state.editEvent.content];

      // If position is set, insert the element in the given position
      if (typeof position !== "undefined" && position !== null) {
        const currentCePos = position;
        const newCePos =
          currentCePos === state.editEvent.content.length
            ? currentCePos
            : currentCePos + 1;

        updatedContentArray.splice(newCePos, 0, newListItem);
        // Position is not set, add the element to the end of the content array
      } else {
        updatedContentArray.push(newListItem);
      }

      return {
        ...state,
        editEvent: {
          ...state.editEvent,
          content: updatedContentArray,
        },
      };
    }

    case actionTypes.CMS_MOVE_EVENT_CONTENT_LIST_ITEM_UP:
    case actionTypes.CMS_MOVE_EVENT_CONTENT_LIST_ITEM_DOWN: {
      const { direction, position } = action.payload;

      let updatedContentArray = null;
      let newPos = null;
      let movedElement = null;

      newPos = direction === "up" ? position - 1 : position + 1;

      // check if the new position is in array boundaries
      if (newPos < 0 || newPos > state.editEvent.content.length - 1) {
        return state;
      }

      updatedContentArray = [...state.editEvent.content];
      movedElement = updatedContentArray[position];
      updatedContentArray[position] = updatedContentArray[newPos];
      updatedContentArray[newPos] = movedElement;
      return {
        ...state,
        editEvent: {
          ...state.editEvent,
          content: updatedContentArray,
        },
      };
    }

    case actionTypes.CMS_MOVE_EVENT_CONTENT_NESTED_LIST_ITEM_UP:
    case actionTypes.CMS_MOVE_EVENT_CONTENT_NESTED_LIST_ITEM_DOWN: {
      const { direction, position, nestedPosition } = action.payload;

      let updatedContentArray = null;
      let updatedNestedContentArray = null;
      let newPos = null;
      let movedElement = null;

      newPos = direction === "up" ? nestedPosition - 1 : nestedPosition + 1;

      // check if the new nestedPosition is in array boundaries
      if (
        newPos < 0 ||
        newPos > state.editEvent.content[position].nestedlistitems.length - 1
      ) {
        return state;
      }

      updatedNestedContentArray = [
        ...state.editEvent.content[position].nestedlistitems,
      ];
      movedElement = updatedNestedContentArray[nestedPosition];
      updatedNestedContentArray[nestedPosition] =
        updatedNestedContentArray[newPos];
      updatedNestedContentArray[newPos] = movedElement;

      updatedContentArray = [...state.editEvent.content];
      updatedContentArray[position].nestedlistitems = updatedNestedContentArray;

      return {
        ...state,
        editEvent: {
          ...state.editEvent,
          content: updatedContentArray,
        },
      };
    }

    case actionTypes.CMS_DELETE_EVENT_CONTENT_LIST_ITEM: {
      const { position } = action.payload;

      return {
        ...state,
        editEvent: {
          ...state.editEvent,
          content: state.editEvent.content.filter(
            (element, index) => position !== index
          ),
        },
      };
    }

    case actionTypes.CMS_CREATE_EVENT_SUCCESS: {
      return state;
    }
    case actionTypes.CMS_CREATE_EVENT_FAILED: {
      return state;
    }
    case actionTypes.CMS_UPDATE_EVENT_SUCCESS: {
      return {
        ...state,
        originalEvent: action.payload,
        editEvent: action.payload,
      };
    }
    case actionTypes.CMS_UPDATE_EVENT_FAILED: {
      return state;
    }
    case actionTypes.CMS_EVENT_VALIDATION_FAILED: {
      return state;
    }
    case actionTypes.CMS_CLONE_EVENT_CONTENT_LIST_ITEM: {
      const { position } = action.payload;

      let updatedContentArray = [...state.editEvent.content];

      const currentCePos = position;
      const newCePos = currentCePos + 1;

      let clonedElement = {
        ...state.editEvent.content[currentCePos],
      };
      delete clonedElement.id;
      clonedElement.__new_id = `${getRandomSlug()}`;
      clonedElement.isCopy = true;

      updatedContentArray.splice(newCePos, 0, clonedElement);

      return {
        ...state,
        editEvent: {
          ...state.editEvent,
          content: updatedContentArray,
        },
      };
    }
    default:
      return state;
  }
};

export default cmsEventReducer;
