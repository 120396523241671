export const CMS_INIT_JOBOFFER_CREATE = "CMS_INIT_JOBOFFER_CREATE";
export const CMS_INIT_JOBOFFER_EDIT = "CMS_INIT_JOBOFFER_EDIT";
export const CMS_RESET_JOBOFFER_EDIT = "CMS_RESET_JOBOFFER_EDIT";
export const CMS_DISCARD_JOBOFFER_EDIT_MODAL_CHANGES =
  "CMS_DISCARD_JOBOFFER_EDIT_MODAL_CHANGES";
export const CMS_CREATE_JOBOFFER_SUCCESS = "CMS_CREATE_JOBOFFER_SUCCESS";
export const CMS_CREATE_JOBOFFER_FAILED = "CMS_CREATE_JOBOFFER_FAILED";
export const CMS_EDIT_JOBOFFER_FIELD = "CMS_EDIT_JOBOFFER_FIELD";
export const CMS_ADD_JOBOFFER_JOBCARD = "CMS_ADD_JOBOFFER_JOBCARD";
export const CMS_DELETE_JOBOFFER_JOBCARD = "CMS_DELETE_JOBOFFER_JOBCARD";
export const CMS_EDIT_JOBOFFER_JOBCARD = "CMS_EDIT_JOBOFFER_JOBCARD";
export const CMS_MOVE_JOBOFFER_CONTENT_LIST_ITEM_UP =
  "CMS_MOVE_JOBOFFER_CONTENT_LIST_ITEM_UP";
export const CMS_MOVE_JOBOFFER_CONTENT_LIST_ITEM_DOWN =
  "CMS_MOVE_JOBOFFER_CONTENT_LIST_ITEM_DOWN";
export const CMS_DELETE_JOBOFFER_FORM = "CMS_DELETE_JOBOFFER_FORM";
export const CMS_UPDATE_JOBOFFER_SUCCESS = "CMS_UPDATE_JOBOFFER_SUCCESS";
export const CMS_UPDATE_JOBOFFER_FAILED = "CMS_UPDATE_JOBOFFER_FAILED";
export const CMS_RELOAD_JOBOFFER_PAGE = "CMS_RELOAD_JOBOFFER_PAGE";
export const CMS_JOBOFFER_VALIDATION_FAILED = "CMS_JOBOFFER_VALIDATION_FAILED";
