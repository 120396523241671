import * as actionTypes from "store/actionTypes/twoFactorActionTypes";

export const showTwoFactorInitModal = () => {
  return {
    type: actionTypes.TWO_FACTOR_INIT_MODAL_OPEN,
  };
};

export const hideTwoFactorInitModal = () => {
  return {
    type: actionTypes.TWO_FACTOR_INIT_MODAL_CLOSE,
  };
};