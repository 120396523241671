export const CMS_RESET_FORM_EDITOR = "CMS_RESET_FORM_EDITOR";
export const CMS_OPEN_FORM_EDITOR = "CMS_OPEN_FORM_EDITOR";
export const CMS_INIT_FORM_EDITOR = "CMS_INIT_FORM_EDITOR";
export const CMS_DELETE_FORM = "CMS_DELETE_FORM";
export const CMS_DELETE_FORM_SUCCESS = "CMS_DELETE_FORM_SUCCESS";
export const CMS_DELETE_FORM_FAILED = "CMS_DELETE_FORM_FAILED";
export const CMS_ADD_FORMFIELD = "CMS_ADD_FORMFIELD";
export const CMS_DELETE_FORMFIELD = "CMS_DELETE_FORMFIELD";
export const CMS_FORMFIELD_MOVE_UP = "CMS_FORMFIELD_MOVE_UP";
export const CMS_FORMFIELD_MOVE_DOWN = "CMS_FORMFIELD_MOVE_DOWN";
export const CMS_SAVE_FORM_SUCCESS = "CMS_SAVE_FORM_SUCCESS";
export const CMS_SAVE_FORM_FAILED = "CMS_SAVE_FORM_FAILED";
export const CMS_EDIT_FORM_FIELD = "CMS_EDIT_FORM_FIELD";
export const CMS_ADD_FORM_FIELD_OPTION = "CMS_ADD_FORM_FIELD_OPTION";
export const CMS_EDIT_FORM_FIELD_OPTION = "CMS_EDIT_FORM_FIELD_OPTION";
export const CMS_DELETE_FORM_FIELD_OPTION = "CMS_DELETE_FORM_FIELD_OPTION";
export const CMS_DELETE_FORM_FIELD_OPTION_MOVE_UP =
  "CMS_DELETE_FORM_FIELD_OPTION_MOVE_UP";
export const CMS_DELETE_FORM_FIELD_OPTION_MOVE_DOWN =
  "CMS_DELETE_FORM_FIELD_OPTION_MOVE_DOWN";
export const CMS_INIT_FORM_EDITOR_CREATE = "CMS_INIT_FORM_EDITOR_CREATE";