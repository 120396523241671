import { getStrapiURL } from "utils/api";
import {
  axiosServerGetRequest,
  getCmsAccessToken,
  handleServerRequestError,
} from "utils/serverUtil";

/**
 * get all saved navigations from static json file
 * All guest users should fetch this via SSR
 * This JSON only contains all public navigations
 * if you are authenticated you should fetch the private
 * navigations separately
 *
 * @returns
 */
export const getNavigations = async (locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/assets/${locale}_content-navigations.json`)
  );
  if (result.success) {
    if (Array.isArray(result.response.data)) {
      return result.response.data;
    }
    console.log(
      `Possible Issue: ${locale}_content-navigations.json is not an Array!`
    );
  } else {
    handleServerRequestError(
      result.error,
      `could not fetch ${locale}_content-navigations.json [getNavigations]`
    );
  }
  return []; // return an empty list
};

/**
 * get all navigations for overview page
 * @returns
 */
export const getCmsNavigations = async (req, locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/navigations/cms-list?_locale=${locale}`),
    {
      cmsaccesstoken: getCmsAccessToken(req),
    }
  );
  if (result.success) {
    return result;
  } else {
    handleServerRequestError(
      result.error,
      "could not fetch all navigations [getCmsNavigations]"
    );
    return result;
  }
};

/**
 * get all private navigations as authenticated user or contentmanager
 * @returns
 */
export const getPrivateNavigations = async (req, locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/navigations/private?_locale=${locale}`)
  );
  if (result.success) {
    return result.response.data;
  } else {
    handleServerRequestError(
      result.error,
      "could not fetch all private navigations [getPrivateNavigations]"
    );
    return [];
  }
};
