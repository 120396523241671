export const CMS_CREATE_CATEGORY = "CMS_CREATE_CATEGORY";
export const CMS_UPDATE_CATEGORY = "CMS_UPDATE_CATEGORY";
export const CMS_CREATE_CATEGORY_START = "CMS_CREATE_CATEGORY_START";
export const CMS_CREATE_CATEGORY_SUCCESS = "CMS_CREATE_CATEGORY_SUCCESS";
export const CMS_CREATE_CATEGORY_FAILED = "CMS_CREATE_CATEGORY_FAILED";
export const CMS_UPDATE_CATEGORY_START = "CMS_CREATE_CATEGORY_START";
export const CMS_UPDATE_CATEGORY_SUCCESS = "CMS_CREATE_CATEGORY_SUCCESS";
export const CMS_UPDATE_CATEGORY_FAILED = "CMS_CREATE_CATEGORY_FAILED";
export const CMS_OPEN_CATEGORY_EDITOR = "CMS_OPEN_CATEGORY_EDITOR";
export const CMS_RESET_CATEGORY_EDITOR = "CMS_RESET_CATEGORY_EDITOR";
export const CMS_EDIT_CATEGORY_FIELD = "CMS_EDIT_CATEGORY_FIELD";
export const CMS_SEND_DELETE_CATEGORY = "CMS_SEND_DELETE_CATEGORY";
export const CMS_DELETE_CATEGORY_START = "CMS_DELETE_CATEGORY_START";
export const CMS_DELETE_CATEGORY_SUCCESS = "CMS_DELETE_CATEGORY_SUCCESS";
export const CMS_DELETE_CATEGORY_FAILED = "CMS_DELETE_CATEGORY_FAILED"; 