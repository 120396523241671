import { getStrapiURL } from "utils/api";
import {
  axiosServerGetRequest,
  handleServerRequestError,
} from "utils/serverUtil";

/**
 * getUserapiSettings
 * @param {boolean} returnAsResult
 * @param {string} locale
 * @returns
 */
export const getUserapiSettings = async (returnAsResult, locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/userapisetting${locale ? `?_locale=${locale}` : ""}`)
  );
  if (result.success) {
    if (returnAsResult) {
      return result;
    }
    return result.response.data;
  } else {
    handleServerRequestError(
      result.error,
      "could not fetch getUserapiSettings"
    );
    if (returnAsResult) {
      return result;
    }
    return null;
  }
};
