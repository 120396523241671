import { getNextJsApiURL } from "utils/api";
import { axiosPostRequest } from "utils/clientUtil";
import { createToast, translate } from "utils/util";

/**
 * action for userLogin
 * needed for userapi
 * @param {*} username
 * @param {*} password
 * @returns
 */
export const userLogin = async (username, password, twoFACode) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/authenticate/login"),
    {
      username,
      password,
      twoFACode,
    }
  );
  if (result.success) {
    createToast({
      type: "success",
      msg: translate("public:loginSuccess"),
    });
  } else {
    createToast({
      type: "error",
      msg: translate("public:loginError"),
    });
  }
  return result;
};

/**
 * action for sending the password forgotten e-mail to the user
 *
 * NOTE: THIS IS ALSO USED FOR WELCOME MAIL
 * USERAPI CHECKS IF user.isFirstLogin == true
 *
 * @param {*} email
 * @param {*} captchaResponse
 * @returns
 */
export const userForgotPassword = async (
  email,
  captchaResponse,
  toastNamespace = "public"
) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/authenticate/forgot-password"),
    {
      email,
      captchaResponse,
    }
  );

  createToast({
    type: "success",
    msg: translate(`${toastNamespace}:emailWasSent`),
  });

  return result;
};

/**
 * action for reseting/setting the password of the user
 * @param {*} password1
 * @param {*} password2
 * @param {*} code
 * @returns
 */
export const userSetPassword = async (password1, password2, code) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/authenticate/set-password"),
    {
      password1,
      password2,
      code,
    }
  );
  if (result.success) {
    createToast({
      type: "success",
      msg: result.response.data.isWelcome
        ? translate("userapi:registrationCompleteSuccess")
        : translate("public:setPasswordSuccess"),
    });
  } else {
    createToast({
      type: "error",
      msg: result.response.data.isWelcome
        ? translate("userapi:registrationCompleteError")
        : translate("public:setPasswordError"),
    });
  }

  return result;
};

/**
 * client side refreshes the token
 */
export const userRefreshToken = async () => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/authenticate/refreshtoken/refresh")
  );
  return result;
};

/**
 * action to logout the user
 */
export const userLogout = async ({ deleteAllTokens }) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/authenticate/refreshtoken/logout"),
    { deleteAllTokens }
  );

  // always show successmessage since the access / refresh token will be deleted
  // and the reponse only reflects if the refreshtoken is deleted in the db
  createToast({
    type: "success",
    msg: translate("public:logoutSuccess"),
  });

  return result;
};
