import * as actionTypes from "store/actionTypes/cmsCollectionTypeActionTypes";

export const initCmsCollectionTypeState = {
  cfgModalOpen: false,
  nestedContentModalOpen: false,
  contentPosition: null,
  itemType: null,
};

const cmsCollectionTypeReducer = (
  state = initCmsCollectionTypeState,
  action
) => {
  switch (action.type) {
    case actionTypes.CMS_OPEN_NESTED_CONTENT_MODAL: {
      const { position, name } = action.payload;
      return {
        ...state,
        cfgModalOpen: false,
        nestedContentModalOpen: true,
        contentPosition: position,
        itemType: name,
      };
    }

    case actionTypes.CMS_OPEN_CONFIG_CONTENT_MODAL: {
      const { position, name } = action.payload;
      return {
        ...state,
        cfgModalOpen: true,
        nestedContentModalOpen: false,
        contentPosition: position,
        itemType: name,
      };
    }

    case actionTypes.CMS_CLOSE_COLLECTION_TYPE_MODALS: {
      return {
        ...state,
        cfgModalOpen: false,
        nestedContentModalOpen: false,
        contentPosition: null,
        itemType: null,
      };
    }

    default:
      return state;
  }
};

export default cmsCollectionTypeReducer;
