import dynamic from "next/dynamic";
const Script = dynamic(() => import("next/script"));
import { globalSettings } from "services/globalSettings/globalSettingsService";

/**
 * Google Analytics scripts
 *
 * Checks if your env is local or not.
 * Checks if cookiebot is enabled or not.
 * Returns the ga script according to these checks.
 * @returns
 */
export default function GoogleAnalytics() {
  const currentEnv = process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT;

  // cookie bot variables
  const cbEnabled = globalSettings.cookiebot?.cookiebotEnabled;
  const cbId = globalSettings.cookiebot?.cookiebotId;

  // google analytics variables
  const gaEnabled = globalSettings.googleanalytics?.googleAnalyticsEnabled;
  const gaId = globalSettings.googleanalytics?.googleAnalyticsId;

  return (
    <>
      {/* local */}
      {/* cookiebot will be ignored */}
      {currentEnv === "local" && gaEnabled && gaId && (
        <>
          {/* we are using the next.js Script component because otherwise we get SSR/Client differences */}
          {/* gtag('js', new Date()) would be on server gtag(&#39;js&#39;, new Date()) */}
          <Script
            strategy="afterInteractive"
            type="text/javascript"
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
          />
          {/* eslint-disable-next-line @next/next/no-sync-scripts */}
          <Script strategy="afterInteractive" type="text/javascript">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gaId}');
            `}
          </Script>
        </>
      )}

      {/* dev or prod */}
      {/* cookiebot enabled */}
      {currentEnv !== "local" && gaEnabled && gaId && cbEnabled && cbId && (
        <>
          <script
            type="text/plain"
            data-cookieconsent="statistics,marketing"
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
          />
          {/* eslint-disable-next-line @next/next/no-sync-scripts */}
          <script type="text/plain" data-cookieconsent="statistics,marketing">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gaId}');
            `}
          </script>
        </>
      )}

      {/* dev or prod */}
      {/* cookiebot disabled */}
      {/* this is the same as local, separated for easier later adjustments */}
      {currentEnv !== "local" && gaEnabled && gaId && !cbEnabled && (
        <>
          <Script
            strategy="afterInteractive"
            type="text/javascript"
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
          />
          {/* eslint-disable-next-line @next/next/no-sync-scripts */}
          <Script strategy="afterInteractive" type="text/javascript">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gaId}');
            `}
          </Script>
        </>
      )}
    </>
  );
}
