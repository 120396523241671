import { getNextJsApiURL } from "utils/api";
import { axiosPostRequest } from "utils/clientUtil";
import { handleResponseToastNotifications } from "utils/util";

export const createTagByName = async (name) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/cms/manage/tags/create"),
    { name }
  );
  handleResponseToastNotifications(
    result,
    "tagCreateSuccess",
    "tagCreateError"
  );
  return result;
};

export const updateTag = async (id, name) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/cms/manage/tags/update"),
    { id, name }
  );
  handleResponseToastNotifications(
    result,
    "tagUpdateSuccess",
    "tagUpdateError"
  );
  return result;
};

export const deleteTag = async (id) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/cms/manage/tags/delete"),
    { id }
  );
  handleResponseToastNotifications(
    result,
    "tagDeleteSuccess",
    "tagDeleteError"
  );
  return result;
};
