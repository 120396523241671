import { getNextJsApiURL } from "utils/api";
import { axiosPostRequest } from "utils/clientUtil";

/**
 * finds the default locale id from a corresponding localized id
 *
 * @param {*} id entityId
 * @param {*} entityType "form"/"navigation"
 * @returns
 */
export const getDefaultLocaleId = async (id, entityType) => {
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/custom/get-default-locale-id`),
    { id, entityType }
  );
  return result;
};
