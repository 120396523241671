import dynamic from "next/dynamic";
const Script = dynamic(() => import("next/script"));
import { globalSettings } from "services/globalSettings/globalSettingsService";

export default function Matomo() {
  const currentEnv = process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT;

  // cookie bot variables
  const cbEnabled = globalSettings.cookiebot?.cookiebotEnabled;
  const cbId = globalSettings.cookiebot?.cookiebotId;

  // matomo variables
  const matomoEnabled = globalSettings.matomo?.enabled;
  const matomoScript = globalSettings.matomo?.script;

  let matomoScriptWithoutScriptTags = matomoScript;
  if (
    matomoScript &&
    typeof matomoScript === "string" &&
    matomoScript.indexOf("<script>") !== -1 &&
    matomoScript.indexOf("</script>") !== -1
  ) {
    matomoScriptWithoutScriptTags = matomoScript
      .replace("<script>", "")
      .replace("</script>", "");
  }

  return (
    <>
      {/* local */}
      {/* cookiebot will be ignored */}
      {currentEnv === "local" &&
        matomoEnabled &&
        matomoScriptWithoutScriptTags && (
          <>
            <Script strategy="afterInteractive" type="text/javascript">
              {`${matomoScriptWithoutScriptTags}`}
            </Script>
          </>
        )}

      {/* dev or prod */}
      {/* cookiebot enabled */}
      {currentEnv !== "local" &&
        matomoEnabled &&
        matomoScriptWithoutScriptTags &&
        cbEnabled &&
        cbId && (
          <>
            {/* eslint-disable-next-line @next/next/no-sync-scripts */}
            <script type="text/plain" data-cookieconsent="statistics,marketing">
              {`${matomoScriptWithoutScriptTags}`}
            </script>
          </>
        )}

      {/* dev or prod */}
      {/* cookiebot disabled */}
      {/* this is the same as local, separated for easier later adjustments */}
      {currentEnv !== "local" &&
        matomoEnabled &&
        matomoScriptWithoutScriptTags &&
        !cbEnabled && (
          <Script strategy="afterInteractive" type="text/javascript">
            {`${matomoScriptWithoutScriptTags}`}
          </Script>
        )}
    </>
  );
}
