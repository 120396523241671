import * as actionTypes from "store/actionTypes/generalActionTypes";
import { translate } from "utils/util";

export const initGeneralState = {
  loadingOverlay: {
    isShown: false,
    overlayMessage: "",
  },
  confirmModal: {
    isOpen: false,
    userActionPerformed: false, // true if the user clicked something (performed any action)
    isConfirmed: false, // the modal was confirmed via the confirm button
    isCanceled: false, // the modal was canceled via the cancel (deny) button
    isHidden: false, // like isConfirmed/isCanceled -> the modal was hidden (not canceled)
    xIsCancel: false, // true = on X close button the cancel function will be triggered,
    // false = on X close button the hide function will be triggered
    title: "",
    content: "",
    contentIsRT: false,
    acceptBtnText: "", // confirm button label
    cancelBtnText: "", // cancel/deny button label
    hideButtonText: "", // hide button text (the button that just hides the modal)
    imageType: "",
    className: "", // className added to the modal-dom-element.
    acceptBtnColor: "",
    hideCancelBtn: false,
    showHideBtn: false,
    canNotCloseHide: false, // true = the user can not close (hide) the modal
    // the user must answer via cancel(deny)/confirm button
  },
  clipboard: {
    // look into the useReduxClipboard hook
    clipboardData: null,
    clipboardSet: false,
    clipboardCanceled: false,
  },
  dynamicLists: [],
  beforeunloadEvent: false,
  redirectUrl: "",
  // page visibility (https://developer.mozilla.org/de/docs/Web/API/Page_Visibility_API)
  // used by usePageVisibility hook
  isPageVisible: true,
  showPwaPrompt: false,
};

const generalReducer = (state = initGeneralState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: {
          isOpen: true,
          userActionPerformed: false,
          isConfirmed: false,
          isCanceled: false,
          isHidden: false,
          xIsCancel: action.payload.xIsCancel,
          title: action.payload.title,
          content: action.payload.content,
          contentIsRT: action.payload.contentIsRT,
          acceptBtnText: action.payload.acceptBtnText
            ? action.payload.acceptBtnText
            : translate("cms:confirm"),
          cancelBtnText: action.payload.cancelBtnText
            ? action.payload.cancelBtnText
            : translate("cms:cancel"),
          hideBtnText: action.payload.hideBtnText
            ? action.payload.hideBtnText
            : "",
          imageType: action.payload.imageType ? action.payload.imageType : "",
          className: action.payload.className ? action.payload.className : "",
          acceptBtnColor: action.payload.acceptBtnColor
            ? action.payload.acceptBtnColor
            : "",
          hideCancelBtn: action.payload.hideCancelBtn ? true : false,
          showHideBtn: action.payload.showHideBtn ? true : false,
          canNotCloseHide: action.payload.canNotCloseHide ? true : false,
        },
      };
    case actionTypes.ACCEPT_CONFIRM_MODAL:
    case actionTypes.CANCEL_CONFIRM_MODAL:
    case actionTypes.HIDE_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: {
          ...state.confirmModal,
          isOpen: false,
          userActionPerformed: action.payload.action.userActionPerformed,
          isConfirmed: action.payload.action.isConfirmed,
          isCanceled: action.payload.action.isCanceled,
          isHidden: action.payload.action.isHidden,
        },
      };
    case actionTypes.RESET_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: {
          ...initGeneralState.confirmModal,
        },
      };
    case actionTypes.UPDATE_DLIST:
      return {
        ...state,
        dynamicLists: state.dynamicLists.map((dynamicList) =>
          dynamicList.name === action.payload.updatedDynamicList.name
            ? action.payload.updatedDynamicList
            : dynamicList
        ),
      };
    case actionTypes.ADD_CREATED_DLIST:
      return {
        ...state,
        dynamicLists: [...state.dynamicLists, action.payload.newDynamicList],
      };
    case actionTypes.SHOW_LOADING_OVERLAY:
      return {
        ...state,
        loadingOverlay: {
          overlayMessage: action.payload.overlayMessage
            ? action.payload.overlayMessage
            : "",
          isShown: true,
        },
      };
    case actionTypes.HIDE_LOADING_OVERLAY:
      return {
        ...state,
        loadingOverlay: {
          overlayMessage: "",
          isShown: false,
        },
      };
    case actionTypes.SET_LOADING_OVERLAY_MESSAGE:
      return {
        ...state,
        loadingOverlay: {
          ...state.loadingOverlay,
          overlayMessage: action.payload.overlayMessage
            ? action.payload.overlayMessage
            : "",
        },
      };
    case actionTypes.ENABLE_BEFOREUNLOAD_EVENT:
      return {
        ...state,
        beforeunloadEvent: true,
      };
    case actionTypes.DISABLE_BEFOREUNLOAD_EVENT:
      return {
        ...state,
        beforeunloadEvent: false,
      };
    case actionTypes.REDIRECT:
      const { redirectUrl } = action.payload;
      return {
        ...state,
        redirectUrl: redirectUrl ? redirectUrl : "",
      };
    case actionTypes.SET_PAGE_VISIBILITY:
      return {
        ...state,
        isPageVisible: action.payload,
      };
    case actionTypes.RESET_CLIPBOARD:
      return {
        ...state,
        clipboard: {
          clipboardData: null,
          clipboardSet: false,
          clipboardCanceled: false,
        },
      };
    case actionTypes.SET_CLIPBOARD_DATA:
      return {
        ...state,
        clipboard: {
          ...state.clipboard,
          clipboardSet: true,
          clipboardData: action.payload,
          clipboardCanceled: false,
        },
      };
    case actionTypes.SET_CLIPBOARD_CANCELED:
      return {
        ...state,
        clipboard: {
          ...state.clipboard,
          clipboardSet: true,
          clipboardData: null,
          clipboardCanceled: true,
        },
      };
    case actionTypes.SET_SHOW_PWA_PROMPT:
      return {
        ...state,
        showPwaPrompt: action.payload,
      };
    default:
      return state;
  }
};

export default generalReducer;
