import Router from "next/router";
import { useEffect } from "react";
import { globalSettings } from "services/globalSettings/globalSettingsService";

/**
 * Scrolls to top of page after routeChangeComplete.
 * @param {number} additionalOffset added to offSet of globalSettings
 */
function useScrollToTop() {
  useEffect(() => {
    const scrollToTop = (url) => {
      // Skip: If # exists. useScrollTo will take care of #.
      if (!window.location.hash) {
        // NextJS (by default, or when using scroll: true on router.push/Link-Component) scrolls to top of page
        // by using window.scrollTo(0, 0). https://nextjs.org/docs/api-reference/next/link
        // However, this does not always work.
        // To scroll to top in these cases, we scroll to an element above main-Area.
        // window.scrollTo(0, 0) works after that.
        document.getElementById("scroll-to-top-anchor").scrollIntoView();
        document
          .getElementById("layout")
          .classList.add("temporarilyDisableAnimation");
        window.scrollTo({
          behavior: globalSettings.scrolling?.scrollEffect || "smooth",
          top: 0,
        });
      }
    };
    Router.events.on("routeChangeComplete", scrollToTop);

    return () => {
      Router.events.off("routeChangeComplete", scrollToTop);
    };
  }, [Router]);
}

export default useScrollToTop;
