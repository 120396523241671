import {
  createNavigation,
  deleteNavigation,
  getNavigation,
  getNavigationById,
  updateNavigation,
  validateNavigation,
} from "services/cms/cmsNavigationService";
import * as actionTypes from "store/actionTypes/cmsNavigationActionTypes";
import { translate } from "utils/util";
import {
  disableBeforeunload,
  hideLoadingOverlay,
  redirect,
  showLoadingOverlay,
} from "./generalActionCreator";
import {
  addCreatedNavigation,
  updateNavigationState,
} from "./navigationActionCreator";

export const resetNavEditor = () => {
  return {
    type: actionTypes.CMS_RESET_NAV_EDITOR,
  };
};

export const initNavEditorCreate = () => {
  return {
    type: actionTypes.CMS_INIT_NAV_EDITOR_CREATE,
  };
};

export const initNavEditorEdit = (navigationId) => {
  return async (dispatch) => {
    const result = await getNavigationById(navigationId);
    if (result.success) {
      dispatch(initNavEditor(result.response.data));
    } else {
      if (result.response?.status && result.response.status === 404) {
        dispatch(navigationDoesNotExist());
      } else {
        console.log("could not initialize navigation editor!");
        console.log(result.error);
      }
    }
  };
};

// unused opens the old nav editor
export const openNavEditor = (navigationName) => {
  return {
    payload: {
      navigationName: navigationName,
    },
    type: actionTypes.CMS_OPEN_NAV_EDITOR,
  };
};

/**
 * currently unused was used in nav editor modal
 */
export const getNavigationAndInitEdit = (navigationName) => {
  return async (dispatch) => {
    const result = await getNavigation(navigationName);
    if (result.success) {
      dispatch(initNavEditor(result.response.data));
    } else {
      if (result.response?.status && result.response.status === 404) {
        dispatch(navigationDoesNotExist());
      } else {
        console.log("could not initialize navigation editor!");
        console.log(result.error);
      }
    }
  };
};

const initNavEditor = (navigation) => {
  return {
    payload: {
      navigation: navigation,
    },
    type: actionTypes.CMS_INIT_NAV_EDITOR,
  };
};

export const resetNavEditorChanges = () => {
  return {
    type: actionTypes.CMS_NAV_EDITOR_RESET_CHANGES,
  };
};

const navigationDoesNotExist = () => {
  return {
    type: actionTypes.CMS_NAV_DOES_NOT_EXIST,
  };
};

/**
 *
 * @param {*} router optional - in the currently not used modal this parameter is not needed
 * @returns
 */
export const sendCreateNavigation = (router) => {
  return async (dispatch, getState) => {
    if (validateNavigation(getState().cmsNavigation.editNavigation)) {
      dispatch(showLoadingOverlay(translate("cms:creatingNavigation")));
      const result = await createNavigation(
        getState().cmsNavigation.editNavigation
      );
      if (result.success) {
        dispatch(createNavigationSuccess());
        dispatch(addCreatedNavigation(result.response.data));
        if (router) {
          dispatch(resetNavEditor());
          dispatch(disableBeforeunload());
          dispatch(showLoadingOverlay(translate("cms:creatingNavigation")));
          dispatch(
            redirect(() => router.push("/cms/navigations"), "/cms/navigations")
          );
        }
      } else {
        dispatch(hideLoadingOverlay());
        dispatch(createNavigationFailed());
        console.log("could not create navigation!");
        console.log(result.error);
      }
    }
  };
};

const createNavigationFailed = () => {
  return {
    type: actionTypes.CMS_CREATE_NAV_FAILED,
  };
};

const createNavigationSuccess = () => {
  return {
    type: actionTypes.CMS_CREATE_NAV_SUCCESS,
  };
};

/**
 *
 * @param {*} router optional - in the currently not used modal this parameter is not needed
 * @returns
 */
export const sendUpdateNavigation = (router, callbackFunction) => {
  return async (dispatch, getState) => {
    dispatch(saveNavigation());
    dispatch(showLoadingOverlay(translate("cms:updatingNavigation")));

    const result = await updateNavigation(
      getState().cmsNavigation.editNavigation
    );

    if (result.success) {
      const newNavigationResult = await getNavigation(
        getState().cmsNavigation.editNavigation.name
      );
      if (newNavigationResult.success) {
        dispatch(saveNavigationSuccess());
        dispatch(updateNavigationState(result.response.data));
        if (callbackFunction) {
          dispatch(showLoadingOverlay(translate("cms:updatingNavigation")));
          callbackFunction({ status: "success" });
        } else if (router) {
          dispatch(disableBeforeunload());
          dispatch(showLoadingOverlay(translate("cms:updatingNavigation")));
          dispatch(
            redirect(() => router.push("/cms/navigations"), "/cms/navigations")
          );
        } else {
          dispatch(hideLoadingOverlay());
          if (callbackFunction) {
            callbackFunction({ status: "success" });
          }
        }
      } else {
        dispatch(hideLoadingOverlay());
        if (callbackFunction) {
          callbackFunction({ status: "error" });
        }
        console.log("could not fetch updated navigation!");
      }
    } else {
      dispatch(hideLoadingOverlay());
      dispatch(saveNavigationFailed());
      console.log("could not update navigation!");
      console.log(result.error);
      if (callbackFunction) {
        callbackFunction({ status: "error" });
      }
    }
  };
};

const saveNavigation = () => {
  return {
    type: actionTypes.CMS_UPDATE_NAV,
  };
};

const saveNavigationFailed = () => {
  return {
    type: actionTypes.CMS_UPDATE_NAV_FAILED,
  };
};

const saveNavigationSuccess = () => {
  return {
    type: actionTypes.CMS_UPDATE_NAV_SUCCESS,
  };
};

export const sendDeleteNavigation = (navigationId, refreshDataFromServer) => {
  return async (dispatch) => {
    dispatch(showLoadingOverlay());
    const result = await deleteNavigation(navigationId);
    if (result.success) {
      dispatch(deleteNavigationSuccess());
      if (refreshDataFromServer) {
        refreshDataFromServer();
      }
    } else {
      dispatch(deleteNavigationFailed());
    }
    dispatch(hideLoadingOverlay());
  };
};

const deleteNavigationFailed = () => {
  return {
    type: actionTypes.CMS_DELETE_NAV_FAILED,
  };
};

const deleteNavigationSuccess = () => {
  return {
    type: actionTypes.CMS_DELETE_NAV_SUCCESS,
  };
};

export const editNavName = (name) => {
  return {
    payload: {
      name,
    },
    type: actionTypes.CMS_EDIT_NAV_NAME,
  };
};

export const editNavLogoItem = (mlogo, url) => {
  return {
    payload: {
      mlogo,
      url,
    },
    type: actionTypes.CMS_EDIT_NAV_LOGO_ITEM,
  };
};

export const editNavField = (fieldName, value) => {
  return {
    payload: {
      fieldName,
      value,
    },
    type: actionTypes.CMS_EDIT_NAV_FIELD,
  };
};

export const createNavItem = (layer, layer1Pos, layer2Pos, layer3Pos) => {
  return {
    payload: {
      layer,
      layer1Pos,
      layer2Pos,
      layer3Pos,
    },
    type: actionTypes.CMS_ADD_NAV_ITEM,
  };
};

export const toggleNavItemEdit = (layer, layer1Pos, layer2Pos, layer3Pos) => {
  return {
    payload: {
      layer,
      layer1Pos,
      layer2Pos,
      layer3Pos,
    },
    type: actionTypes.CMS_TOGGLE_NAV_ITEM_EDIT,
  };
};

export const deleteNavItem = (layer, layer1Pos, layer2Pos, layer3Pos) => {
  return {
    payload: {
      layer,
      layer1Pos,
      layer2Pos,
      layer3Pos,
    },
    type: actionTypes.CMS_DELETE_NAV_ITEM,
  };
};

export const editNavItem = (
  layer,
  layer1Pos,
  layer2Pos,
  layer3Pos,
  editedPageItem
) => {
  return {
    payload: {
      layer,
      layer1Pos,
      layer2Pos,
      layer3Pos,
      editedPageItem,
    },
    type: actionTypes.CMS_EDIT_NAV_ITEM,
  };
};

export const moveNavItemUp = (layer, layer1Pos, layer2Pos, layer3Pos) => {
  return {
    payload: { direction: "up", layer, layer1Pos, layer2Pos, layer3Pos },
    type: actionTypes.CMS_MOVE_NAV_ITEM_UP,
  };
};

export const moveNavItemDown = (layer, layer1Pos, layer2Pos, layer3Pos) => {
  return {
    payload: { direction: "down", layer, layer1Pos, layer2Pos, layer3Pos },
    type: actionTypes.CMS_MOVE_NAV_ITEM_DOWN,
  };
};

export const moveNavItemLayerUp = (layer, layer1Pos, layer2Pos, layer3Pos) => {
  return {
    payload: { direction: "up", layer, layer1Pos, layer2Pos, layer3Pos },
    type: actionTypes.CMS_MOVE_NAV_ITEM_LAYER_UP,
  };
};

export const moveNavItemLayerDown = (
  layer,
  layer1Pos,
  layer2Pos,
  layer3Pos
) => {
  return {
    payload: { direction: "down", layer, layer1Pos, layer2Pos, layer3Pos },
    type: actionTypes.CMS_MOVE_NAV_ITEM_LAYER_DOWN,
  };
};
