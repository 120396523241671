import { getStrapiURL } from "utils/api";
import {
  axiosServerGetRequest,
  handleServerRequestError,
} from "utils/serverUtil";

export const getGlobalCss = async () => {
  const result = await axiosServerGetRequest(
    getStrapiURL("/assets/css-global.min.css")
  );
  if (result.success) {
    return result.response.data;
  } else {
    handleServerRequestError(
      result.error,
      "could not fetch css-global.min.css [getGlobalCss]"
    );
    return ""; // TODO return some default css
  }
};
