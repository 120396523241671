import {
  getPageByUrlForPageEditor,
  sendDeleteRequest,
  sendSavePageRequest,
  sendUpdateRequest,
} from "services/page/pageService";
import * as actionTypes from "store/actionTypes/cmsPageActionTypes";
import {
  createToast,
  getPageUrlOrRootUrl,
  translate,
  triggerGetServerSideProps,
} from "utils/util";
import {
  disableBeforeunload,
  hideLoadingOverlay,
  redirect,
  showLoadingOverlay,
} from "./generalActionCreator";

export const openPageEditor = (isEdit) => {
  return {
    payload: { isEdit },
    type: actionTypes.CMS_OPEN_PAGE_EDITOR,
  };
};

export const resetPageEditor = () => {
  return {
    payload: {},
    type: actionTypes.CMS_RESET_PAGE_EDITOR,
  };
};

export const loadOriginalPage = () => {
  return {
    payload: {},
    type: actionTypes.CMS_LOAD_ORIGINAL_PAGE,
  };
};

export const initializeEdit = (existingPage, isEdit) => {
  return {
    payload: { existingPage, isEdit },
    type: actionTypes.CMS_INIT_PAGE_EDITOR,
  };
};

export const getPageAndInitEdit = (isEdit, url) => {
  return async (dispatch) => {
    // replace all slashes in url with comma so the get Page by url
    // request can place the url into the url path variable for the CMS
    const commaSeparatedUrl =
      url.indexOf("/") !== -1 ? url.replace(/\//g, ",") : url;

    // get data from existing page
    const result = await getPageByUrlForPageEditor(
      `/pages/cms/url/${commaSeparatedUrl}`
    );
    if (result.success) {
      const existingPage = result.response.data;
      console.log(existingPage);
      dispatch(initializeEdit(existingPage, isEdit));
    } else {
      createToast({
        type: "error",
        msg: translate("cms:siteDataCouldNotBeFetched"),
      });
    }
  };
};

export const updatePage = (newPage) => {
  return {
    payload: newPage,
    type: actionTypes.CMS_UPDATE_PAGE,
  };
};

export const redirectPage = (url) => {
  return { payload: { url }, type: actionTypes.CMS_PAGE_REDIRECT };
};

export const savePage = (router) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (state.cmsPage.existingPage && state.cmsPage.isEdit) {
      // Updating a Page:
      // Note: If you want to add properties of cmsPage.page to the payload you need to adjust api/cms/manage/page/updatePage.
      dispatch(showLoadingOverlay(translate("cms:updatingPage")));
      const result = await sendUpdateRequest(state.cmsPage.page);
      if (result.success) {
        const pageUrl =
          typeof router.query.url === "undefined"
            ? process.env.NEXT_PUBLIC_ROOT_PAGE_URL
            : router.query.url;

        console.log({ pageUrl });

        // redirect only on url change
        if (pageUrl !== result.response.data.url) {
          console.log(
            `-- page update -- redirect to: ${getPageUrlOrRootUrl(
              result.response.data.url
            )}`
          );
          dispatch(disableBeforeunload(router));
          dispatch(redirectPage(result.response.data.url));
          dispatch(
            redirect(
              () => router.push(getPageUrlOrRootUrl(result.response.data.url)),
              result.response.data.url
            )
          );
        } else {
          console.log("-- page update -- reload the current page");
          dispatch(disableBeforeunload(router));
          dispatch(resetPageEditor());
          dispatch(redirect(() => triggerGetServerSideProps(router), "reload"));
        }
      } else {
        dispatch(hideLoadingOverlay());
      }
    } else {
      // Creating a Page:
      dispatch(showLoadingOverlay(translate("cms:creatingPage")));
      const result = await sendSavePageRequest(state.cmsPage.page);
      if (result.success) {
        console.log(
          `-- page create -- redirect to: ${getPageUrlOrRootUrl(
            result.response.data.url
          )}`
        );
        dispatch(disableBeforeunload(router));
        dispatch(redirectPage(result.response.data.url));
        dispatch(
          redirect(
            () =>
              router.push(
                `${getPageUrlOrRootUrl(result.response.data.url)}?edit=true`,
                null,
                { locale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE }
              ),
            result.response.data.url
          )
        );
      } else {
        dispatch(hideLoadingOverlay());
      }
    }
  };
};

export const deletePage = (router) => {
  return async (dispatch, getState) => {
    dispatch(showLoadingOverlay(translate("cms:deletingPage")));
    const state = getState();
    const result = await sendDeleteRequest(state.cmsPage.page.id);
    if (result.success) {
      console.log("-- page delete -- redirect to: /");
      dispatch(disableBeforeunload(router));
      dispatch(redirectPage("/"));
      dispatch(redirect(() => router.push("/"), "/"));
    } else {
      dispatch(hideLoadingOverlay());
      createToast({ type: "error", msg: translate("cms:genericError") });
    }
  };
};
