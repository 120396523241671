import * as immutable from "object-path-immutable";
import * as actionTypes from "store/actionTypes/cmsActionTypes";
import {
  PAGE_TYPE_CONTENTPAGE,
  PAGE_TYPE_EVENT,
  PAGE_TYPE_HARDCODED,
  PAGE_TYPE_JOBOFFER,
  PAGE_TYPE_NEWS,
  PAGE_TYPE_NOT_EDITABLE,
} from "utils/constants";
import { convertPropertyPath, getRandomSlug } from "utils/util";

export const initCmsState = {
  editMode: false, // is the user in edit mode?
  editView: false, // boolean if the app should display cms controls
  authenticatedUserPreview: false, // boolean if the app should simulate an authenticated user (showing private navigation etc.)
  currentEditContext: "", // the currently edited contentType (look into constants)
  currentPage: {
    contentPage: null,
    news: null,
    event: null,
    joboffer: null,
    hardcodedPage: null,
    publishedIndicator: false,
  },
  createEditModal: {
    createEditModalOpen: false,
    contentType: "",
    isEdit: false,
  },
  cfgModal: "", // "" if cfgModal is closed, "position of Element that is configured" if open
  nestedCEModal: "", // "" if nestedCEModal is closed, "position of Element that is configured" if open
  showUserCfgModal: false, // false if userCfgModal is closed, true if userCfgModal is open
  editInfo: {
    editPageUrl: "",
    editDraftId: null,
    editDraftPage: null,
    originalDraftPage: null,
    availableElements: [],
    newFiles: [],
    deletedFiles: [],
    renderKey: "",
  },
  saveReportModal: {
    showSaveReportModal: false,
    notUploadedFiles: [],
    redirectUrl: "",
    hideAction: "", // empty (nothing) | reload | redirect (needs redirectUrl too)
  },
  addCEModal: {
    isOpen: false,
    position: null,
  },
  jwt: null,
  isCmsUserAuthenticated: false,
  cmsUser: {
    userId: 0,
    username: null,
    email: null,
    roles: null,
    userSettings: {
      cfgModalAsSidebar: false,
    },
  },
  cmsContentMediaModal: {
    showMediaSelectionModal: false,
    cmsPosition: null,
  },
  pages: [],
  news: [],
  events: [],
  joboffers: [],
  categories: [],
  tags: [],
  // Each instance of the popover generates a unique Id which is stored on open in this state.
  // The popoverOpenerId takes this Id from any popoverButton and shows/opens only the intended popover.
  popoverOpenerId: null,
};

const cmsReducer = (state = initCmsState, action) => {
  switch (action.type) {
    case actionTypes.CMS_SET_EDIT_MODE:
      return {
        ...state,
        editMode: true,
        editView: true,
        // TRM-88:
        // authenticatedUserPreview: false,
        editInfo: {
          editPageUrl: "",
          editDraftId: null,
          editDraftPage: null,
          originalDraftPage: null,
          availableElements: [],
          newFiles: [],
          deletedFiles: [],
          renderKey: "",
        },
      };
    case actionTypes.CMS_RESET_EDIT_MODE:
      return {
        ...state,
        cfgModal: "",
        editMode: false,
        editView: false,
        // TRM-88:
        // authenticatedUserPreview: false,
        editInfo: {
          editPageUrl: "",
          editDraftId: null,
          editDraftPage: null,
          originalDraftPage: null,
          availableElements: [],
          newFiles: [],
          deletedFiles: [],
          renderKey: "",
        },
      };

    case actionTypes.CMS_SET_CURRENT_PAGE: {
      const { pageObject, type } = action.payload;
      switch (type) {
        case PAGE_TYPE_CONTENTPAGE:
          return {
            ...state,
            currentPage: {
              ...state.currentPage,
              contentPage: pageObject,
              news: null,
              event: null,
              joboffer: null,
            },
          };
        case PAGE_TYPE_NEWS:
          return {
            ...state,
            currentPage: {
              ...state.currentPage,
              contentPage: null,
              news: pageObject,
              event: null,
              joboffer: null,
            },
          };
        case PAGE_TYPE_EVENT:
          return {
            ...state,
            currentPage: {
              ...state.currentPage,
              contentPage: null,
              news: null,
              event: pageObject,
              joboffer: null,
            },
          };
        case PAGE_TYPE_JOBOFFER:
          return {
            ...state,
            currentPage: {
              ...state.currentPage,
              contentPage: null,
              news: null,
              event: null,
              joboffer: pageObject,
            },
          };
        case PAGE_TYPE_HARDCODED:
          return {
            ...state,
            currentPage: {
              ...state.currentPage,
              contentPage: null,
              news: null,
              event: null,
              joboffer: null,
              hardcodedPage: pageObject,
            },
          };
        case PAGE_TYPE_NOT_EDITABLE:
        default:
          return {
            ...state,
            currentPage: {
              contentPage: null,
              news: null,
              event: null,
              joboffer: null,
              publishedIndicator: false,
            },
          };
      }
    }
    case actionTypes.CMS_SET_PUBLISHED_INDICATOR: {
      return {
        ...state,
        currentPage: {
          ...state.currentPage,
          publishedIndicator: action.payload,
        },
      };
    }
    case actionTypes.CMS_SET_EDIT_MODE_CONTENTPAGE:
      return {
        ...state,
        editMode: true,
        editView: true,
        // TRM-88:
        // authenticatedUserPreview: false,
        editInfo: {
          ...state.editInfo,
          editPageUrl: action.payload.editPageUrl,
          editDraftId: action.payload.editDraftId,
          editDraftPage: null,
          originalDraftPage: null,
          availableElements: [],
          newFiles: [],
          deletedFiles: [],
        },
      };
    case actionTypes.CMS_RESET_EDIT_MODE_CONTENTPAGE:
      return {
        ...state,
        cfgModal: "",
        editMode: false,
        editView: false,
        // TRM-88:
        // authenticatedUserPreview: false,
        editInfo: {
          editPageUrl: "",
          editDraftId: null,
          editDraftPage: null,
          originalDraftPage: null,
          availableElements: [],
          newFiles: [],
          deletedFiles: [],
          renderKey: "",
        },
      };
    case actionTypes.CMS_INIT_EDITPAGE:
      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          editDraftPage: action.payload.draftPage,
          originalDraftPage: action.payload.draftPage,
          availableElements: action.payload.availableElements,
          newFiles: [],
          deletedFiles: [],
        },
      };
    case actionTypes.CMS_UPDATE_EDITPAGE_FILE_FIELD: {
      // console.log("UPDATE file field:");
      // console.log(action.payload);
      // console.log(action.payload.file);

      // get the attribute that will be updated e.g: content[0].img1:
      const contentArrayElement = [
        ...state.editInfo.editDraftPage.content,
      ].find((element, index) => index === action.payload.position);

      let updatedFile = null;
      let nestedPropertyPath = null;
      if (
        action.payload.field.indexOf("[") > -1 &&
        action.payload.field.indexOf("]") > -1
      ) {
        // if the property is a nested property (array accessor)
        nestedPropertyPath = convertPropertyPath(action.payload.field);
        updatedFile = immutable.get(contentArrayElement, nestedPropertyPath);
      } else {
        // if the property is a direct attribute of content array
        updatedFile = contentArrayElement[action.payload.field];
      }

      // update the deletedFiles Array:
      let updatedDeletedFiles = [...state.editInfo.deletedFiles];
      // if the updatedFile has no id it was uploaded in input type file frontend
      // there is no need to track the deletion of this file in backend
      // because it wasnt in backend before
      if (updatedFile && updatedFile.id) {
        // updatedFile has an id so this must be deleted in backend
        updatedDeletedFiles = [
          ...state.editInfo.deletedFiles,
          {
            [`deleted.content[${action.payload.position}].${action.payload.field}`]:
              updatedFile,
          },
        ];
      }

      // check if this attribute e.g: files.content[0].img1 is
      // already in the newFiles array and get the array pos:
      let existingNewFileIndex = state.editInfo.newFiles.findIndex(
        (element) =>
          element[
            `files.content[${action.payload.position}].${action.payload.field}`
          ]
      );
      // console.log("existingNewFileIndex " + existingNewFileIndex);

      // update the newFilesArray:
      let updatedNewFiles = [...state.editInfo.newFiles];
      // only do this if the selected file exists:
      if (action.payload.file) {
        if (existingNewFileIndex > -1) {
          // if the attribute exists in the newFiles array then just
          // update the array pos with the new file
          updatedNewFiles = [...state.editInfo.newFiles];
          updatedNewFiles[existingNewFileIndex] = {
            [`files.content[${action.payload.position}].${action.payload.field}`]:
              action.payload.file,
          };
        } else {
          // if it was not in the newFiles array before add it
          updatedNewFiles = [
            ...state.editInfo.newFiles,
            {
              [`files.content[${action.payload.position}].${action.payload.field}`]:
                action.payload.file,
            },
          ];
        }
      }

      let updatedContentArray = [...state.editInfo.editDraftPage.content].map(
        (content, pos) => {
          if (pos === action.payload.position) {
            if (nestedPropertyPath) {
              let newContent = immutable.set(
                content,
                nestedPropertyPath,
                action.payload.file // null
              );
              return newContent;
            } else {
              return {
                ...content,
                [action.payload.field]: action.payload.file, // null
              };
            }
          } else {
            return content;
          }
        }
      );

      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          editDraftPage: {
            ...state.editInfo.editDraftPage,
            content: updatedContentArray,
          },
          newFiles: updatedNewFiles,
          deletedFiles: updatedDeletedFiles,
        },
      };
    }
    case actionTypes.CMS_DELETE_EDITPAGE_FILE_FIELD: {
      // console.log("DELETE file field:");
      // console.log(action.payload);

      // get the attribute that will be removed e.g: content[0].img1:
      const contentArrayElement = [
        ...state.editInfo.editDraftPage.content,
      ].find((element, index) => index === action.payload.position);

      let removedFile = null;
      let nestedPropertyPath = null;
      if (
        action.payload.field.indexOf("[") > -1 &&
        action.payload.field.indexOf("]") > -1
      ) {
        // if the property is a nested property (array accessor)
        nestedPropertyPath = convertPropertyPath(action.payload.field);
        removedFile = immutable.get(contentArrayElement, nestedPropertyPath);
      } else {
        // if the property is a direct attribute of content array
        removedFile = contentArrayElement[action.payload.field];
      }

      // check if this attribute e.g: files.content[0].img1 is
      // already in the newFiles array and get the array pos to remove it later:
      let existingNewFileIndex = state.editInfo.newFiles.findIndex(
        (element) =>
          element[
            `files.content[${action.payload.position}].${action.payload.field}`
          ]
      );
      let updatedNewFiles = [...state.editInfo.newFiles];
      if (existingNewFileIndex > -1) {
        // remove the file from newFiles array if it was there
        updatedNewFiles.splice(existingNewFileIndex, 1);
      }

      // update the deletedFiles Array:
      let updatedDeletedFiles = [...state.editInfo.deletedFiles];
      // if the removedFile has no id it was uploaded in input type file frontend
      // there is no need to track the deletion of this file in backend
      // because it wasnt in backend before
      if (removedFile && removedFile.id) {
        // removedFile has an id so this must be deleted in backend
        updatedDeletedFiles = [
          ...state.editInfo.deletedFiles,
          {
            [`deleted.content[${action.payload.position}].${action.payload.field}`]:
              removedFile,
          },
        ];
      }

      if (removedFile === null) {
        // if the file was removed before and it is already in the
        // deletedFiles array or it was null all the time or null was
        // returned from strapi so do nothing!
        return state;
      } else {
        let updatedContentArray = [...state.editInfo.editDraftPage.content].map(
          (content, pos) => {
            if (pos === action.payload.position) {
              if (nestedPropertyPath) {
                let newContent = immutable.set(
                  content,
                  nestedPropertyPath,
                  action.payload.file // null
                );
                return newContent;
              } else {
                return {
                  ...content,
                  [action.payload.field]: action.payload.file, // null
                };
              }
            } else {
              return content;
            }
          }
        );

        return {
          ...state,
          editInfo: {
            ...state.editInfo,
            editDraftPage: {
              ...state.editInfo.editDraftPage,
              content: updatedContentArray,
            },
            deletedFiles: updatedDeletedFiles,
            newFiles: updatedNewFiles,
          },
        };
      }
    }
    case actionTypes.CMS_UPDATE_EDITPAGE_CE_FIELD:
      const { position, field, value } = action.payload;
      let updatedContentArray = [...state.editInfo.editDraftPage.content];
      if (field.indexOf("[") > -1 && field.indexOf("]") > -1) {
        // check if the property is a nested property (if it
        // has array accessor in its property path)
        let nestedPropertyPath = convertPropertyPath(field);
        updatedContentArray = updatedContentArray.map((content, index) => {
          if (index === position) {
            // let newContent = { ...content };
            let newContent = immutable.set(content, nestedPropertyPath, value);
            return newContent;
          } else {
            return content;
          }
        });
      } else {
        // if the property is a flat attribute of the main content array
        // in the contentpage
        updatedContentArray = updatedContentArray.map((content, index) =>
          index === position ? { ...content, [field]: value } : content
        );
      }

      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          editDraftPage: {
            ...state.editInfo.editDraftPage,
            content: updatedContentArray,
          },
        },
      };
    case actionTypes.CMS_UPDATE_EDITPAGE_CE_MOVE_UP:
    case actionTypes.CMS_UPDATE_EDITPAGE_CE_MOVE_DOWN: {
      // console.log("moving CE");
      // console.log(action.payload);
      // console.log(state.editInfo.editDraftPage.content);
      // console.log(state.editInfo.newFiles);
      // console.log(state.editInfo.deletedFiles);
      const originalPos = action.payload.originalPosition;
      const newPos = action.payload.newPosition;

      const newFilesPrefix = "files.";
      let updatedNewFiles = [...state.editInfo.newFiles].map((element) => {
        let keys = Object.keys(element);
        let originalPropertyKey = keys.find((element) =>
          element.startsWith(newFilesPrefix)
        );

        let newPropertyKey = null;
        if (originalPropertyKey.indexOf(`content[${originalPos}]`) !== -1) {
          newPropertyKey = originalPropertyKey.replace(
            `content[${originalPos}]`,
            `content[${newPos}]`
          );
          return { [newPropertyKey]: element[originalPropertyKey] };
        } else if (originalPropertyKey.indexOf(`content[${newPos}]`) !== -1) {
          newPropertyKey = originalPropertyKey.replace(
            `content[${newPos}]`,
            `content[${originalPos}]`
          );
          return { [newPropertyKey]: element[originalPropertyKey] };
        } else {
          return element;
        }
      });

      const deletedFilesPrefix = "deleted.";
      let updatedDeletedFiles = [...state.editInfo.deletedFiles].map(
        (element) => {
          let keys = Object.keys(element);
          let originalPropertyKey = keys.find((element) =>
            element.startsWith(deletedFilesPrefix)
          );

          let newPropertyKey = null;
          if (originalPropertyKey.indexOf(`content[${originalPos}]`) !== -1) {
            newPropertyKey = originalPropertyKey.replace(
              `content[${originalPos}]`,
              `content[${newPos}]`
            );
            return { [newPropertyKey]: element[originalPropertyKey] };
          } else if (originalPropertyKey.indexOf(`content[${newPos}]`) !== -1) {
            newPropertyKey = originalPropertyKey.replace(
              `content[${newPos}]`,
              `content[${originalPos}]`
            );
            return { [newPropertyKey]: element[originalPropertyKey] };
          } else {
            return element;
          }
        }
      );

      let updatedContentArray = [...state.editInfo.editDraftPage.content];
      let movedElement = updatedContentArray[originalPos];
      updatedContentArray[originalPos] = updatedContentArray[newPos];
      updatedContentArray[newPos] = movedElement;
      // console.log(updatedContentArray);
      // console.log(updatedNewFiles);
      // console.log(updatedDeletedFiles);

      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          editDraftPage: {
            ...state.editInfo.editDraftPage,
            content: updatedContentArray,
          },
          deletedFiles: updatedDeletedFiles,
          newFiles: updatedNewFiles,
        },
      };
    }

    case actionTypes.CMS_UPDATE_EDITPAGE_CE:
      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          editDraftPage: {
            ...state.editInfo.editDraftPage,
            content: state.editInfo.editDraftPage.content.map(
              (contentElement, i) =>
                i === action.payload.position
                  ? { ...contentElement, ...action.payload.config }
                  : contentElement
            ),
          },
        },
      };
    case actionTypes.CMS_UPDATE_EDITPAGE_ADD_CE:
      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          editDraftPage: {
            ...state.editInfo.editDraftPage,
            content: [
              ...state.editInfo.editDraftPage.content,
              action.payload.content,
            ],
          },
        },
      };
    case actionTypes.CMS_UPDATE_EDITPAGE_ADD_CE_AT_POS: {
      const currentCePos = action.payload.position;
      const newCePos =
        currentCePos === state.editInfo.editDraftPage.content.length
          ? currentCePos
          : currentCePos + 1;

      let updatedContentArray = [...state.editInfo.editDraftPage.content];
      updatedContentArray.splice(newCePos, 0, action.payload.content);

      // Update keys in newFiles array
      // (new element has been inserted somewhere in the contentpage,
      // so the newFiles-array-indexes have to be updated)
      const newFilesPrefix = "files.";
      let updatedNewFiles = [...state.editInfo.newFiles].map((element) => {
        let keys = Object.keys(element);
        let originalPropertyKey = keys.find((element) =>
          element.startsWith(newFilesPrefix)
        );
        // this is -> files.content[***filesIndex***]...
        const filesIndex = originalPropertyKey.substring(
          originalPropertyKey.indexOf("[") + 1,
          originalPropertyKey.indexOf("]")
        );

        let newPropertyKey = originalPropertyKey;

        if (filesIndex >= newCePos) {
          newPropertyKey =
            newPropertyKey.substring(0, newPropertyKey.indexOf("[") + 1) +
            (parseInt(filesIndex, 10) + 1) +
            newPropertyKey.substring(newPropertyKey.indexOf("]"));
          return { [newPropertyKey]: element[originalPropertyKey] };
        } else {
          return element;
        }
      });

      // Update keys in deletedFiles array
      // (new element has been inserted somewhere in the contentpage,
      // so the deletedFiles-array-indexes have to be updated)
      const newDeletedFilesPrefix = "deleted.";
      let updatedDeletedFiles = [...state.editInfo.deletedFiles].map(
        (element) => {
          let keys = Object.keys(element);
          let originalPropertyKey = keys.find((element) =>
            element.startsWith(newDeletedFilesPrefix)
          );
          // this is -> files.content[***filesIndex***]...
          const filesIndex = originalPropertyKey.substring(
            originalPropertyKey.indexOf("[") + 1,
            originalPropertyKey.indexOf("]")
          );

          let newPropertyKey = originalPropertyKey;

          if (filesIndex >= newCePos) {
            newPropertyKey =
              newPropertyKey.substring(0, newPropertyKey.indexOf("[") + 1) +
              (parseInt(filesIndex, 10) + 1) +
              newPropertyKey.substring(newPropertyKey.indexOf("]"));
            return { [newPropertyKey]: element[originalPropertyKey] };
          } else {
            return element;
          }
        }
      );

      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          editDraftPage: {
            ...state.editInfo.editDraftPage,
            ...{ content: updatedContentArray },
          },
          deletedFiles: updatedDeletedFiles,
          newFiles: updatedNewFiles,
        },
      };
    }
    case actionTypes.CMS_UPDATE_EDITPAGE_CLONE_CE: {
      const currentCePos = action.payload.position;
      const newCePos = currentCePos + 1;

      let clonedElement = {
        ...state.editInfo.editDraftPage.content[currentCePos],
      };
      delete clonedElement.id;
      clonedElement.__new_id = `new_${getRandomSlug()}`;
      clonedElement.isCopy = true;

      if (clonedElement.content) {
        for (let content of clonedElement.content) {
          delete content.id;
        }
      }

      let updatedContentArray = [...state.editInfo.editDraftPage.content];
      updatedContentArray.splice(newCePos, 0, clonedElement);

      // Update keys in newFiles array
      // (new element has been inserted somewhere in the contentpage,
      // so the newFiles-array-indexes have to be updated)
      const newFilesPrefix = "files.";
      let updatedNewFiles = [];
      [...state.editInfo.newFiles].map((element) => {
        let keys = Object.keys(element);
        let originalPropertyKey = keys.find((element) =>
          element.startsWith(newFilesPrefix)
        );

        // this is -> files.content[***filesIndex***]...
        let currentIndex = originalPropertyKey.substring(
          originalPropertyKey.indexOf("[") + 1,
          originalPropertyKey.indexOf("]")
        );

        let newPropertyKey = originalPropertyKey;

        // Cloning newFiles
        if (currentIndex == currentCePos) {
          newPropertyKey =
            newPropertyKey.substring(0, newPropertyKey.indexOf("[") + 1) +
            (parseInt(currentIndex, 10) + 1) +
            newPropertyKey.substring(newPropertyKey.indexOf("]"));

          updatedNewFiles.push({
            [newPropertyKey]: element[originalPropertyKey],
          });
        }

        if (currentIndex >= newCePos) {
          newPropertyKey =
            newPropertyKey.substring(0, newPropertyKey.indexOf("[") + 1) +
            (parseInt(currentIndex, 10) + 1) +
            newPropertyKey.substring(newPropertyKey.indexOf("]"));
          updatedNewFiles.push({
            [newPropertyKey]: element[originalPropertyKey],
          });
        } else {
          return updatedNewFiles.push(element);
        }
      });

      // Update keys in deletedFiles array
      // (new element has been inserted somewhere in the contentpage,
      // so the deletedFiles-array-indexes have to be updated)
      const newDeletedFilesPrefix = "deleted.";
      let updatedDeletedFiles = [...state.editInfo.deletedFiles].map(
        (element) => {
          let keys = Object.keys(element);
          let originalPropertyKey = keys.find((element) =>
            element.startsWith(newDeletedFilesPrefix)
          );
          // this is -> files.content[***filesIndex***]...
          const filesIndex = originalPropertyKey.substring(
            originalPropertyKey.indexOf("[") + 1,
            originalPropertyKey.indexOf("]")
          );

          let newPropertyKey = originalPropertyKey;
          if (filesIndex >= newCePos) {
            newPropertyKey =
              newPropertyKey.substring(0, newPropertyKey.indexOf("[") + 1) +
              (parseInt(filesIndex, 10) + 1) +
              newPropertyKey.substring(newPropertyKey.indexOf("]"));
            return { [newPropertyKey]: element[originalPropertyKey] };
          } else {
            return element;
          }
        }
      );

      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          editDraftPage: {
            ...state.editInfo.editDraftPage,
            ...{ content: updatedContentArray },
          },
          deletedFiles: updatedDeletedFiles,
          newFiles: updatedNewFiles,
        },
      };
    }
    case actionTypes.CMS_UPDATE_EDITPAGE_DELETE_CE:
      const deletedElement =
        state.editInfo.editDraftPage.content[action.payload.position];
      // console.log("deleted Content element:");
      // console.log(deletedElement);
      let newDeletedFiles = [];
      // let newDeletedFileKeys = [];
      Object.keys(deletedElement).forEach((key) => {
        const value = deletedElement[key];
        if (value && Array.isArray(value)) {
          const nestedArray = value;
          nestedArray.forEach((nestedElement, nestedIndex) => {
            Object.keys(nestedElement).forEach((nestedKey) => {
              const nestedValue = nestedElement[nestedKey];
              if (
                nestedValue &&
                nestedValue.id &&
                nestedValue.mime &&
                nestedValue.ext
              ) {
                // console.log("removing nested file... " + nestedKey);
                newDeletedFiles = [
                  ...newDeletedFiles,
                  {
                    [`deleted.content[${action.payload.position}].${key}[${nestedIndex}]${nestedKey}`]:
                      nestedValue,
                  },
                ];
              } else {
                // console.log("do nothing for: " + nestedKey);
              }
            });
          });
        } else if (value && value.id && value.mime && value.ext) {
          // console.log("removing file... " + key);
          newDeletedFiles = [
            ...newDeletedFiles,
            {
              [`deleted.content[${action.payload.position}].${key}`]: value,
            },
          ];
        }
      });

      // console.log(JSON.stringify([...state.editInfo.newFiles]));

      // delete all existing new uploads from the deleted element from newFiles array
      let updatedNewFiles = [...state.editInfo.newFiles].filter(
        (element) =>
          !Object.keys(element).some((key) =>
            key.startsWith(`files.content[${action.payload.position}]`)
          )
      );

      // move all new files one position up because one item got deleted in the array
      updatedNewFiles = updatedNewFiles.map((element) => {
        let keys = Object.keys(element);
        let originalPropertyKey = keys.find((element) =>
          element.startsWith(`files.content[`)
        );
        if (originalPropertyKey) {
          // get the array position
          let currentPos = originalPropertyKey.substring(
            originalPropertyKey.indexOf("[") + 1,
            originalPropertyKey.indexOf("]")
          );
          if (currentPos > action.payload.position) {
            let newPos = currentPos - 1;
            let newPropertyKey = originalPropertyKey.replace(
              `content[${currentPos}]`,
              `content[${newPos}]`
            );
            return { [newPropertyKey]: element[originalPropertyKey] };
          } else {
            return element;
          }
        } else {
          return element;
        }
      });

      // return state;
      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          editDraftPage: {
            ...state.editInfo.editDraftPage,
            content: state.editInfo.editDraftPage.content.filter(
              (contentElement, index) =>
                !(
                  index === action.payload.position &&
                  contentElement.__component === action.payload.name
                )
            ),
          },
          newFiles: updatedNewFiles,
          deletedFiles: [...state.editInfo.deletedFiles, ...newDeletedFiles],
        },
      };
    case actionTypes.CMS_UPDATE_EDITPAGE_ADD_NESTED_CONTENT_ITEM: {
      // console.log("CMS_UPDATE_EDITPAGE_ADD_NESTED_CONTENT_ITEM");
      // console.log(action.payload);
      const { nestedArrayFieldName, contentPosition, nestedPosition, newItem } =
        action.payload;

      let updatedContentArray = [...state.editInfo.editDraftPage.content].map(
        (content, index) => {
          if (index === contentPosition) {
            return {
              ...content,
              [nestedArrayFieldName]: [
                ...content[nestedArrayFieldName],
                newItem,
              ],
            };
          } else {
            return content;
          }
        }
      );
      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          editDraftPage: {
            ...state.editInfo.editDraftPage,
            content: updatedContentArray,
          },
        },
      };
    }
    case actionTypes.CMS_UPDATE_EDITPAGE_MOVE_NESTED_ITEM_UP:
    case actionTypes.CMS_UPDATE_EDITPAGE_MOVE_NESTED_ITEM_DOWN: {
      // console.log("Nested element move!");
      // console.log(action.payload);

      const {
        contentPosition,
        originalNestedPosition,
        newNestedPosition,
        nestedArrayFieldName,
      } = action.payload;

      // console.log("nested Array:");
      // console.log([
      //   ...state.editInfo.editDraftPage.content[contentPosition][
      //     nestedArrayFieldName
      //   ],
      // ]);

      let updatedNestedArray = [
        ...state.editInfo.editDraftPage.content[contentPosition][
          nestedArrayFieldName
        ],
      ];
      let movedElement = updatedNestedArray[originalNestedPosition];
      updatedNestedArray[originalNestedPosition] =
        updatedNestedArray[newNestedPosition];
      updatedNestedArray[newNestedPosition] = movedElement;
      // console.log(updatedNestedArray);

      let updatedContentArray = [...state.editInfo.editDraftPage.content].map(
        (content, index) => {
          if (index === contentPosition) {
            return {
              ...content,
              [nestedArrayFieldName]: updatedNestedArray,
            };
          } else {
            return content;
          }
        }
      );

      const newFilesPrefix = "files.";
      let updatedNewFiles = [...state.editInfo.newFiles].map((element) => {
        let keys = Object.keys(element);
        let originalPropertyKey = keys.find((element) =>
          element.startsWith(newFilesPrefix)
        );
        let newPropertyKey = null;
        if (
          originalPropertyKey.indexOf(
            `content[${contentPosition}].${nestedArrayFieldName}[${originalNestedPosition}]`
          ) !== -1
        ) {
          newPropertyKey = originalPropertyKey.replace(
            `content[${contentPosition}].${nestedArrayFieldName}[${originalNestedPosition}]`,
            `content[${contentPosition}].${nestedArrayFieldName}[${newNestedPosition}]`
          );
          return { [newPropertyKey]: element[originalPropertyKey] };
        } else if (
          originalPropertyKey.indexOf(
            `content[${contentPosition}].${nestedArrayFieldName}[${newNestedPosition}]`
          ) !== -1
        ) {
          newPropertyKey = originalPropertyKey.replace(
            `content[${contentPosition}].${nestedArrayFieldName}[${newNestedPosition}]`,
            `content[${contentPosition}].${nestedArrayFieldName}[${originalNestedPosition}]`
          );
          return { [newPropertyKey]: element[originalPropertyKey] };
        } else {
          return element;
        }
      });

      const deletedFilesPrefix = "deleted.";
      let updatedDeletedFiles = [...state.editInfo.deletedFiles].map(
        (element) => {
          let keys = Object.keys(element);
          let originalPropertyKey = keys.find((element) =>
            element.startsWith(deletedFilesPrefix)
          );

          let newPropertyKey = null;
          if (
            originalPropertyKey.indexOf(
              `content[${contentPosition}].${nestedArrayFieldName}[${originalNestedPosition}]`
            ) !== -1
          ) {
            newPropertyKey = originalPropertyKey.replace(
              `content[${contentPosition}].${nestedArrayFieldName}[${originalNestedPosition}]`,
              `content[${contentPosition}].${nestedArrayFieldName}[${newNestedPosition}]`
            );
            return { [newPropertyKey]: element[originalPropertyKey] };
          } else if (
            originalPropertyKey.indexOf(
              `content[${contentPosition}].${nestedArrayFieldName}[${newNestedPosition}]`
            ) !== -1
          ) {
            newPropertyKey = originalPropertyKey.replace(
              `content[${contentPosition}].${nestedArrayFieldName}[${newNestedPosition}]`,
              `content[${contentPosition}].${nestedArrayFieldName}[${originalNestedPosition}]`
            );
            return { [newPropertyKey]: element[originalPropertyKey] };
          } else {
            return element;
          }
        }
      );

      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          editDraftPage: {
            ...state.editInfo.editDraftPage,
            content: updatedContentArray,
          },
          deletedFiles: updatedDeletedFiles,
          newFiles: updatedNewFiles,
        },
      };
    }
    case actionTypes.CMS_UPDATE_EDITPAGE_DELETE_NESTED_ITEM: {
      // console.log("CMS_UPDATE_EDITPAGE_DELETE_NESTED_ITEM");
      // console.log(action.payload);
      const { contentPosition, nestedPosition, nestedArrayFieldName } =
        action.payload;

      const deletedElement =
        state.editInfo.editDraftPage.content[contentPosition][
          nestedArrayFieldName
        ][nestedPosition];

      // console.log(deletedElement);

      let newDeletedFiles = [];
      // let newDeletedFileKeys = [];
      Object.keys(deletedElement).forEach((key) => {
        const value = deletedElement[key];
        if (value && value.id && value.mime && value.ext) {
          // console.log("removing nested file... " + key);
          newDeletedFiles = [
            ...newDeletedFiles,
            {
              [`deleted.content[${contentPosition}].${nestedArrayFieldName}[${nestedPosition}].${key}`]:
                value,
            },
          ];
        }
      });

      // console.log(JSON.stringify([...state.editInfo.newFiles]));

      // delete all existing new uploads from the deleted element from newFiles array
      let updatedNewFiles = [...state.editInfo.newFiles].filter(
        (element) =>
          !Object.keys(element).some((key) =>
            key.startsWith(
              `files.content[${contentPosition}].${nestedArrayFieldName}[${nestedPosition}]`
            )
          )
      );

      // move all new files one position up because one item got deleted in the array
      updatedNewFiles = updatedNewFiles.map((element) => {
        let keys = Object.keys(element);
        let originalPropertyKey = keys.find((element) =>
          element.startsWith(
            `files.content[${contentPosition}].${nestedArrayFieldName}[`
          )
        );
        if (originalPropertyKey) {
          // using lastindex for the nested array to get the nested position
          let currentPos = originalPropertyKey.substring(
            originalPropertyKey.lastIndexOf("[") + 1,
            originalPropertyKey.lastIndexOf("]")
          );
          if (currentPos > nestedPosition) {
            let newPos = currentPos - 1;
            let newPropertyKey = originalPropertyKey.replace(
              `content[${contentPosition}].${nestedArrayFieldName}[${currentPos}]`,
              `content[${contentPosition}].${nestedArrayFieldName}[${newPos}]`
            );
            return { [newPropertyKey]: element[originalPropertyKey] };
          } else {
            return element;
          }
        } else {
          return element;
        }
      });

      // filter the deleted item from the nested content array
      let updatedContentArray = [...state.editInfo.editDraftPage.content].map(
        (content, index) => {
          if (index === contentPosition) {
            return {
              ...content,
              [nestedArrayFieldName]: content[nestedArrayFieldName].filter(
                (element, index) => !(index === nestedPosition)
              ),
            };
          } else {
            return content;
          }
        }
      );

      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          editDraftPage: {
            ...state.editInfo.editDraftPage,
            content: updatedContentArray,
          },
          newFiles: updatedNewFiles,
          deletedFiles: [...state.editInfo.deletedFiles, ...newDeletedFiles],
        },
      };
    }
    case actionTypes.CMS_UPDATE_EDITPAGE_DELETE_NESTED_SUB_ITEM: {
      const {
        contentPosition,
        nestedPosition,
        nestedArrayFieldName,
        nestedArraySubFieldName,
        nestedArraySubFieldPosition,
      } = action.payload;

      let updatedContentArray = [...state.editInfo.editDraftPage.content];
      // filter the deleted item from the nested content array
      let updatedContentSubArray = [
        ...state.editInfo.editDraftPage.content[contentPosition][
          nestedArrayFieldName
        ],
      ].map((content, index) => {
        if (index === nestedPosition) {
          //console.log(`filtering ${nestedArraySubFieldPosition} in`);
          //console.log(content);
          return {
            ...content,
            [nestedArraySubFieldName]: content[nestedArraySubFieldName].filter(
              (element, subArrayIndex) => {
                // console.log(
                //   `${subArrayIndex} === ${nestedArraySubFieldPosition}`
                // );
                return subArrayIndex !== nestedArraySubFieldPosition;
              }
            ),
          };
        } else {
          return content;
        }
      });

      updatedContentArray[contentPosition][nestedArrayFieldName] =
        updatedContentSubArray;

      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          editDraftPage: {
            ...state.editInfo.editDraftPage,
            content: updatedContentArray,
          },
        },
      };
    }
    case actionTypes.CMS_TOGGLE_EDIT_VIEW:
      return {
        ...state,
        editView: !state.editView,
        // TRM-88:
        // authenticatedUserPreview: false,
      };
    case actionTypes.CMS_TOGGLE_AUTHENTICATED_USER_PREVIEW:
      return {
        ...state,
        // TRM-88:
        // authenticatedUserPreview: !state.authenticatedUserPreview,
      };
    case actionTypes.CMS_TOGGLE_VIEW_CFG_MODAL:
      return {
        ...state,
        cfgModal: action.payload !== null ? "" + action.payload : "",
      };
    case actionTypes.CMS_TOGGLE_VIEW_NESTED_CE_MODAL:
      return {
        ...state,
        nestedCEModal: action.payload !== null ? "" + action.payload : "",
      };
    case actionTypes.CMS_AUTHENTICATE_SUCCESS:
      return {
        ...state,
        isCmsUserAuthenticated: true,
        jwt: action.payload.jwt,
        cmsUser: {
          userId: action.payload.user.id,
          username: action.payload.user.username,
          email: action.payload.user.email,
          roles: action.payload.user.roles,
          userSettings: {
            cfgModalAsSidebar: action.payload.user.cfgModalAsSidebar,
          },
        },
      };
    case actionTypes.CMS_AUTHENTICATE_FAILED:
      return {
        ...state,
        isCmsUserAuthenticated: false,
        jwt: null,
        cmsUser: {
          userId: 0,
          username: null,
          email: null,
          roles: null,
          userSettings: { cfgModalAsSidebar: false },
        },
      };
    case actionTypes.CMS_LOGOUT_SUCCESS:
      return {
        ...state,
        isCmsUserAuthenticated: false,
        jwt: null,
        cmsUser: {
          userId: 0,
          username: null,
          email: null,
          roles: null,
          userSettings: { cfgModalAsSidebar: false },
        },
        pages: [],
      };

    case actionTypes.RESET_SAVE_REPORT_MODAL:
      return {
        ...state,
        saveReportModal: {
          showSaveReportModal: false,
          notUploadedFiles: [],
          redirectUrl: "",
          hideAction: "",
        },
      };
    case actionTypes.ADD_FILE_TO_SAVE_REPORT_MODAL: {
      const { notUploadedFile } = action.payload;
      return {
        ...state,
        saveReportModal: {
          ...state.saveReportModal,
          showSaveReportModal: true,
          notUploadedFiles: [
            ...state.saveReportModal.notUploadedFiles,
            notUploadedFile,
          ],
        },
      };
    }
    case actionTypes.SET_SAVE_REPORT_MODAL: {
      const saveReportModalObject = action.payload;
      return {
        ...state,
        saveReportModal: {
          ...state.saveReportModal,
          ...saveReportModalObject,
        },
      };
    }
    case actionTypes.SHOW_CREATE_EDIT_COLLECTION_ITEM_MODAL: {
      return {
        ...state,
        createEditModal: {
          createEditModalOpen: true,
          contentType: action.payload.contentType,
          isEdit: action.payload.isEdit,
        },
      };
    }
    case actionTypes.HIDE_CREATE_EDIT_COLLECTION_ITEM_MODAL: {
      return {
        ...state,
        createEditModal: {
          createEditModalOpen: false,
          contentType: "",
          isEdit: false,
        },
      };
    }
    case actionTypes.CMS_SHOW_MEDIA_SELECTION_MODAL: {
      return {
        ...state,
        cmsContentMediaModal: {
          showMediaSelectionModal: true,
          cmsPosition: action.payload.cmsPosition,
        },
      };
    }
    case actionTypes.CMS_HIDE_MEDIA_SELECTION_MODAL: {
      return {
        ...state,
        cmsContentMediaModal: {
          showMediaSelectionModal: false,
          cmsPosition: action.payload.cmsPosition,
        },
      };
    }
    case actionTypes.CMS_SET_CURRENT_EDIT_CONTEXT: {
      return {
        ...state,
        currentEditContext: action.payload.currentEditContext,
      };
    }
    case actionTypes.CMS_TOGGLE_USER_CONFIG_MODAL:
      return {
        ...state,
        showUserCfgModal: !action.payload,
      };
    case actionTypes.CMS_UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        cmsUser: {
          ...state.cmsUser,
          userSettings: {
            ...state.cmsUser.userSettings,
            [action.payload.settingName]: action.payload.settingValue,
          },
        },
      };

    case actionTypes.CMS_UPDATE_USER_SETTINGS_FAILED:
      return {
        ...state,
        cmsUser: {
          ...state.cmsUser,
          userSettings: {
            ...state.cmsUser.userSettings,
          },
        },
      };

    case actionTypes.CMS_SHOW_POPOVER:
      return {
        ...state,
        popoverOpenerId: action.payload.openerId,
      };
    case actionTypes.CMS_HIDE_POPOVER:
      return {
        ...state,
        popoverOpenerId: null,
      };
    case actionTypes.CMS_SHOW_ADD_CE_MODAL:
      return {
        ...state,
        addCEModal: {
          isOpen: true,
          position: action.payload.position,
        },
      };
    case actionTypes.CMS_HIDE_ADD_CE_MODAL:
      return {
        ...state,
        addCEModal: {
          ...state.addCEModal,
          isOpen: false,
        },
      };
    case actionTypes.CMS_RESET_ADD_CE_MODAL:
      return {
        ...state,
        addCEModal: {
          ...initCmsState.addCEModal,
        },
      };
    case actionTypes.CMS_REFRESH_RENDER_KEY:
      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          renderKey: "" + new Date().getTime(),
        },
      };
    default:
      return state;
  }
};

export default cmsReducer;
