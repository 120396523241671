import { getNextJsApiURL } from "utils/api";
import {
  axiosGetRequest,
  axiosPostRequest,
  handleClientRequestError,
} from "utils/clientUtil";
import { createToast, translate } from "utils/util";

/**
 * currently unused was used in nav editor modal
 */
export const getNavigation = async (navigationName) => {
  const result = await axiosGetRequest(
    getNextJsApiURL(`/cms/manage/navigation/${navigationName}`)
  );
  if (result.success) {
    console.log(`fetched navigation with name ${navigationName}`);
  } else {
    if (result.response?.status && result.response.status !== 404) {
      createToast({
        type: "warning",
        msg: translate("cms:navigationFetchError"),
      });
    }
  }
  return result;
};

export const getNavigationById = async (navigationId) => {
  const result = await axiosGetRequest(
    getNextJsApiURL(`/cms/manage/navigation/find/${navigationId}`)
  );
  if (result.success) {
    console.log(`fetched navigation with id ${navigationId}`);
  } else {
    if (result.response?.status && result.response.status !== 404) {
      createToast({
        type: "warning",
        msg: translate("cms:navigationFetchError"),
      });
    }
  }
  return result;
};

export const createNavigation = async (navigation) => {
  console.log(navigation);
  // MANAGEDFILE REFACTOR: unused since managedfile refactor
  // const logo = navigation.logo;
  // if (logo instanceof File) {
  //   navigation.logo = null;
  // }

  // Clear __editing state
  navigation.pages.forEach((layer1Element) => {
    delete layer1Element.__editing;
    layer1Element.subPages?.forEach((layer2Element) => {
      delete layer2Element.__editing;
      layer2Element.subPages?.forEach((layer3Element) => {
        delete layer3Element.__editing;
      });
    });
  });

  let formData = new FormData();
  formData.append("data", JSON.stringify(navigation));

  // MANAGEDFILE REFACTOR: unused since managedfile refactor
  // if (logo instanceof File) {
  //   formData.append("files.logo", logo);
  // }

  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/navigation/create`),
    formData,
    { headers: { "Content-Type": "multipart/form-data;" } }
  );
  if (result.success) {
    console.log(`created navigation with name ${navigation.name}`);
    createToast({
      type: "success",
      msg: translate("cms:navigationCreateSuccess"),
    });
  } else {
    handleClientRequestError(
      result.error,
      "could not create navigation [createNavigation]"
    );

    if (result.error.response?.data?.message === "navigationAlreadyExist") {
      createToast({
        type: "warning",
        msg: translate(`cms:${result.error.response?.data?.message}`),
      });
    } else {
      createToast({
        type: "error",
        msg: translate("cms:navigationCreationError"),
      });
    }
  }
  return result;
};

export const updateNavigation = async (editNavigation) => {
  // MANAGEDFILE REFACTOR: unused since managedfile refactor
  // const logo = editNavigation.logo;
  // if (logo instanceof File) {
  //   editNavigation.logo = null;
  // }

  // Clear __editing state
  editNavigation.pages.forEach((layer1Element) => {
    delete layer1Element.__editing;
    layer1Element.subPages?.forEach((layer2Element) => {
      delete layer2Element.__editing;
      layer2Element.subPages?.forEach((layer3Element) => {
        delete layer3Element.__editing;
      });
    });
  });

  let formData = new FormData();
  formData.append("data", JSON.stringify(editNavigation));

  // MANAGEDFILE REFACTOR: unused since managedfile refactor
  // if (logo instanceof File) {
  //   formData.append("files.logo", logo);
  // }

  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/navigation/update/${editNavigation.id}`),
    formData,
    { headers: { "Content-Type": "multipart/form-data;" } }
  );

  if (result.success) {
    createToast({
      type: "success",
      msg: translate("cms:navigationUpdateSuccess"),
    });
  } else {
    handleClientRequestError(
      result.error,
      "could not update [updateNavigation]"
    );
    if (result.error.response?.data?.message === "navigationAlreadyExist") {
      createToast({
        type: "warning",
        msg: translate(`cms:${result.error.response?.data?.message}`),
      });
    } else {
      createToast({
        type: "error",
        msg: translate("cms:navigationUpdateError"),
      });
    }
  }
  return result;
};

export const deleteNavigation = async (id) => {
  console.log("deleteNavigation");
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/navigation/delete`),
    { id }
  );
  if (result.success) {
    createToast({
      type: "success",
      msg: translate("cms:navigationDeleteSuccess"),
    });
    console.log(`deleted navigation with id ${id}`);
  } else {
    createToast({ type: "error", msg: translate("cms:navigationDeleteError") });
    console.log(`failed to delete navigation with id ${id}`);
  }
  return result;
};

export const validateNavigation = (navigationData) => {
  let isNavigationValid = true;

  if (
    !navigationData.name ||
    (navigationData.name && !navigationData.name.trim().length > 0)
  ) {
    isNavigationValid = false;
    createToast({ type: "warning", msg: translate("cms:noNameInput") });
  }

  return isNavigationValid;
};
