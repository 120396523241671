import axios from "axios";
import jwt from "jsonwebtoken";
import getConfig from "next/config";
import {
  CMS_FEATURE_EVENTS,
  CMS_FEATURE_JOBOFFERS,
  CMS_FEATURE_NEWS,
  CMS_FEATURE_USERAPI,
  EVENTS_URL,
  JOBOFFER_URL,
  NEWS_URL,
  TRANSLATION_NAMESPACE_CMS,
  TRANSLATION_NAMESPACE_CONTENTTYPE,
  TRANSLATION_NAMESPACE_DATAGRID,
  TRANSLATION_NAMESPACE_PUBLIC,
  TRANSLATION_NAMESPACE_USERAPI,
} from "./constants";

/**
 * All functions here are for the server
 * side (API routes / getInitial / getServerSideProps /
 * getStaticProps)
 *
 * This functions are not final and can be changed later.
 */

export const handleServerRequestError = (error, customLog) => {
  if (process.env.NEXT_PUBLIC_DEV_MODE === "true") {
    if (customLog) {
      console.log(customLog);
    }
  }
  if (error) {
    console.log(
      error.message +
        `${error.config && error.config.url ? ` ${error.config.url}` : ""} - ${
          error.config && error.config.method ? ` ${error.config.method}` : ""
        }`
    );
  }

  const errorObject = getErrorResponseObject(error);
  if (errorObject.status !== -1) {
    console.log(errorObject);
  }
};

/**
 * server side axios config
 * TODO this is no final version this might change later
 */
export const globalAxiosConfig = {
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
};

/**
 * general axios get request function
 * TODO this is no final version this might change later
 *
 * @param {} url
 * @param {*} accessToken
 * @param {*} customAxiosConfig
 * @returns
 */
export const axiosServerGetRequest = async (
  url,
  accessToken,
  customAxiosConfig
) => {
  try {
    const response = await axios.get(
      url,
      createAxiosConfig(accessToken, customAxiosConfig)
    );

    return {
      success: true,
      error: null,
      response: response,
    };
  } catch (error) {
    handleServerRequestError(error);
    return {
      success: false,
      error,
      response: error.response,
    };
  }
};

/**
 * general axios put request function
 * TODO this is no final version this might change later
 *
 * @param {*} url
 * @param {*} data
 * @param {*} accessToken can be a simple string or an object
 * @param {*} customAxiosConfig
 * @returns
 */
export const axiosServerPutRequest = async (
  url,
  data,
  accessToken,
  customAxiosConfig
) => {
  try {
    const response = await axios.put(
      url,
      data,
      createAxiosConfig(accessToken, customAxiosConfig)
    );
    return {
      success: true,
      error: null,
      response: response,
    };
  } catch (error) {
    handleServerRequestError(error);
    return {
      success: false,
      error,
      response: error.response,
    };
  }
};

/**
 * general axios delete request function
 * TODO this is no final version this might change later
 *
 * @param {} url
 * @param {*} accessToken
 * @param {*} customAxiosConfig
 * @returns
 */
export const axiosServerDeleteRequest = async (
  url,
  accessToken,
  customAxiosConfig
) => {
  try {
    const response = await axios.delete(
      url,
      createAxiosConfig(accessToken, customAxiosConfig)
    );
    return {
      success: true,
      error: null,
      response: response,
    };
  } catch (error) {
    handleServerRequestError(error);
    return {
      success: false,
      error,
      response: error.response,
    };
  }
};

/**
 * general axios post request function
 * TODO this is no final version this might change later
 *
 * @param {*} url
 * @param {*} data
 * @param {*} accessToken can be a simple string or an object
 * @param {*} customAxiosConfig
 * @returns
 */
export const axiosServerPostRequest = async (
  url,
  data,
  accessToken,
  customAxiosConfig
) => {
  try {
    const response = await axios.post(
      url,
      data,
      createAxiosConfig(accessToken, customAxiosConfig)
    );
    return {
      success: true,
      error: null,
      response: response,
    };
  } catch (error) {
    handleServerRequestError(error);
    return {
      success: false,
      error,
      response: error.response,
    };
  }
};

/**
 * helper function for the general axios get/post functions
 *
 * in this function the access token is added to the request
 * header or the entire (global) axios config will be replaced by a
 * custom config
 *
 * @param {*} accessToken
 * @param {*} customAxiosConfig
 * @returns
 */
const createAxiosConfig = (accessToken, customAxiosConfig) => {
  let currentAxiosConfig = globalAxiosConfig;

  if (customAxiosConfig) {
    currentAxiosConfig = customAxiosConfig;
  }

  // add Authorization header if accessToken param is present
  if (accessToken) {
    if (typeof accessToken === "string") {
      // accessToken is just a string value so it will be set
      // into default header 'Authorization: Bearer [JWT accesstoken]'
      currentAxiosConfig = {
        ...currentAxiosConfig,
        headers: {
          ...currentAxiosConfig.headers,
          Authorization: "Bearer " + accessToken,
        },
      };
    } else if (typeof accessToken === "object") {
      // accessToken an object the key of the object will be the header name
      // example { cmsaccesstoken: cookies.cmsToken }
      // the value is the accesstoken
      currentAxiosConfig = {
        ...currentAxiosConfig,
        headers: {
          ...currentAxiosConfig.headers,
          [Object.keys(accessToken)[0]]:
            accessToken[Object.keys(accessToken)[0]],
        },
      };
    }
  }

  return currentAxiosConfig;
};

// /**
//  * extracts the jwt accessToken string from the request object
//  *
//  * @param req
//  * @returns access token jwt string
//  */
// export const getAccessToken = (req) => {
//   return req.cookies.aToken;
// };

// /**
//  * extracts the jwt refreshToken string from the request object
//  *
//  * @param req
//  * @returns refresh token jwt string
//  */
// export const getRefreshToken = (req) => {
//   return req.cookies.rToken;
// };

/**
 * extracts the jwt CmsAccessToken string from the request object
 *
 * @param req
 * @returns token jwt string
 */
export const getCmsAccessToken = (req) => {
  if (req && req.cookies && req.cookies.cmsToken) {
    return req.cookies.cmsToken;
  }
  return null;
};

/**
 * extracts the jwt userAccessToken string from the request object
 *
 * @param req
 * @returns token jwt string
 */
export const getUserAccessToken = (req) => {
  if (req && req.cookies && req.cookies.userAccessToken) {
    return req.cookies.userAccessToken;
  }
  return null;
};

/**
 * checks if the user JWT access token is valid
 * NOTE: this function can only work on the server side
 *
 * @param {*} req
 * @param {boolean} returnPayload?
 * @returns {*} true/false or { verified: true, payload };
 */
export const isUserAccessTokenValid = (req, returnPayload = false) => {
  const { serverRuntimeConfig } = getConfig();
  const jwtAccessToken = getUserAccessToken(req);
  if (jwtAccessToken) {
    try {
      const payload = jwt.verify(
        req.cookies.userAccessToken,
        serverRuntimeConfig.FRONTEND_USERAPI_JWT_SECRET
      );
      if (returnPayload) {
        return { verified: true, payload };
      }
      return true;
    } catch (err) {
      return false;
    }
  }
  return false;
};

/**
 * checks if the cms JWT access token is valid
 * NOTE: this function can only work on the server side
 */
export const isCmsAccessTokenValid = (req) => {
  const { serverRuntimeConfig } = getConfig();
  const jwtAccessToken = getCmsAccessToken(req);
  if (jwtAccessToken) {
    try {
      jwt.verify(
        req.cookies.cmsToken,
        serverRuntimeConfig.FRONTEND_ADMIN_JWT_SECRET
      );
      return true;
    } catch (err) {
      return false;
    }
  }
  return false;
};

/**
 * extracts the current locale from the NEXT_LOCALE cookie
 *
 *  IMPORTANT NOTE: In getServerSideProps / getStaticProps
 *  you should always use the locale that gets injected
 *  via the context object (getServerSideProps parameter)
 *  This is mandatory because the typed URL in the browser will set
 *  the locale cookie after loading the page
 *  (useLanguageCookie in layout.js)
 *
 * only use this function on API routes if needed.
 *
 * @param req
 * @returns the locale from the NEXT_LOCALE cookie
 *    or the default locale if the cookie is not set
 */
export const getLocaleFromCookie = (req) => {
  if (req && req.cookies && req.cookies.NEXT_LOCALE) {
    return req.cookies.NEXT_LOCALE;
  }
  return process.env.NEXT_PUBLIC_DEFAULT_LOCALE;
};

// /**
//  * adds "Set-Cookie" header for access/refresh token cookie to response
//  *
//  * if refreshtoken is undefined/null only the new accesstoken will be set
//  *
//  * @param {*} res
//  * @param {*} accessTokenCookieString
//  * @param {*} refreshTokenCookieString
//  */
// export const setAuthenticationCookiesToResponse = (
//   res,
//   accessTokenCookieString,
//   refreshTokenCookieString
// ) => {
//   let cookieArray = [accessTokenCookieString];
//   if (refreshTokenCookieString) {
//     cookieArray = [...cookieArray, refreshTokenCookieString];
//   }

//   res.setHeader("Set-Cookie", cookieArray);
// };

/**
 * adds expired "Set-Cookie" header for access/refresh
 * token cookie to response
 * this is used to remove cookies from the client
 *
 * this function sets the expires date to a date in the
 * past the browser deletes this cookies automatically
 *
 * @param {*} res
 */
export const invalidateAuthenticationCookies = (res) => {
  res.setHeader("Set-Cookie", [
    "aToken=deleted; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT",
    "rToken=deleted; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT",
  ]);
};

export const setCmsAuthenticationCookieToResponse = (res, cmsAccessToken) => {
  // comment this in if you want to set the correct cookie expiration
  // const decodedCmsAccessToken = jwt.decode(cmsAccessToken);
  // const expiresDateString = buildCookieExpirationDate(decodedCmsAccessToken.exp);
  const expiresDateString = buildCookieExpirationDate(2, 1).toUTCString();

  const cmsTokenString = `cmsToken=${cmsAccessToken}; Path=/; ${
    process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT === "local" ? "" : "Secure; "
  }HttpOnly; SameSite=Strict; Expires=${expiresDateString}; Domain=${
    process.env.NEXT_PUBLIC_NEXTJS_DOMAIN
  }`;

  // console.log(cmsTokenString);

  const cookieArray = [cmsTokenString];

  res.setHeader("Set-Cookie", cookieArray);
};

export const invalidateCmsAuthenticationCookie = (res) => {
  res.setHeader("Set-Cookie", [
    `cmsToken=deleted; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; Domain=${process.env.NEXT_PUBLIC_NEXTJS_DOMAIN}`,
  ]);
};

export const setUserAuthenticationCookiesToResponse = (
  res,
  accessToken,
  refreshToken
) => {
  const decodedAccessToken = jwt.decode(accessToken);
  const expiresDateString = buildCookieExpirationDateFromJwtExpiration(
    decodedAccessToken.exp
  );

  const userTokenString = `userAccessToken=${accessToken}; Path=/; ${
    process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT === "local" ? "" : "Secure; "
  }HttpOnly; SameSite=Strict; Expires=${expiresDateString}; Domain=${
    process.env.NEXT_PUBLIC_NEXTJS_DOMAIN
  }`;

  const decodedRefreshToken = jwt.decode(refreshToken);
  const expiresDateStringRefreshToken =
    buildCookieExpirationDateFromJwtExpiration(decodedRefreshToken.exp);
  const userRefreshTokenString = `userRefreshToken=${refreshToken}; Path=/api/authenticate/refreshtoken/; ${
    process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT === "local" ? "" : "Secure; "
  }HttpOnly; SameSite=Strict; Expires=${expiresDateStringRefreshToken}; Domain=${
    process.env.NEXT_PUBLIC_NEXTJS_DOMAIN
  }`;

  const cookieArray = [userTokenString, userRefreshTokenString];

  res.setHeader("Set-Cookie", cookieArray);
};

export const invalidateUserAccessAndRefreshTokenCookies = (res) => {
  const accessTokenSetCookie = `userAccessToken=deleted; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT; Domain=${process.env.NEXT_PUBLIC_NEXTJS_DOMAIN}`;
  const refreshTokenSetCookie = `userRefreshToken=deleted; Path=/api/authenticate/refreshtoken/; Expires=Thu, 01 Jan 1970 00:00:00 GMT; Domain=${process.env.NEXT_PUBLIC_NEXTJS_DOMAIN}`;

  const cookieArray = [accessTokenSetCookie, refreshTokenSetCookie];

  res.setHeader("Set-Cookie", cookieArray);
};

export const cmsHasFeatureServerSide = (cmsFeature) => {
  const { serverRuntimeConfig } = getConfig();

  const cmsFeaturesArray = serverRuntimeConfig.CMS_FEATURES.split(",");
  // console.log(
  //   `[cmsHasFeatureServerSide] - checking required feature: ${cmsFeature} - available features: ${cmsFeaturesArray}`
  // );
  if (process.env.NEXT_PUBLIC_DEV_MODE === "true") {
    // console.log(
    //   `[cmsHasFeatureServerSide] - checking required feature: ${cmsFeature} - available features: ${cmsFeaturesArray}`
    // );
  }

  if (cmsFeaturesArray.includes(cmsFeature)) {
    return true;
  }
  return false;
};

/**
 *
 * get the strapi error object
 *
 * e.g.:
 *  {
 *   status: 413,
 *   error: 'Request Entity Too Large',
 *   message: 'FileTooBig',
 *  }
 *
 * if the strapi error.response.data is not present this
 * function will create a temporary error object with some
 * request config information
 *
 * @param {*} error
 * @returns
 */
export const getErrorResponseObject = (error) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error
  ) {
    return error.response.data.error;
  }
  const errorMessage = `${error.message} - ${
    error.config && error.config.url ? ` ${error.config.url}` : ""
  } - ${error.config && error.config.method ? ` ${error.config.method}` : ""}`;
  return {
    status: -1,
    error: error.message,
    message: errorMessage,
  };
};

/**
 * gets the correct status from a strapi error object
 * otherwise it will always return status code 400
 *
 * @param {*} error
 * @returns
 */
export const getErrorResponseStatusCode = (error) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.status
  ) {
    return error.response.data.error.status;
  }
  console.log("could not get status returning default code 400");
  return 400;
};

/**
 * creates a custom error object
 *
 * @param {*} status
 * @param {*} errorMsg
 * @param {*} message
 * @returns
 */
export const createErrorResponseObject = (status, errorMsg, message) => {
  return {
    status: status,
    error: errorMsg,
    message: message,
  };
};

/**
 * isNonDesktopDevice
 * Returns true if userAgent matches a non-desktop-Device.
 * Returns false otherwise.
 * @param {*} userAgent
 * @returns
 */
export const isNonDesktopDevice = (userAgent) => {
  if (!userAgent) {
    return false;
  }

  return Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );
};

/**
 * @returns expiration UTC date string
 *
 * NOTE: JWT expiration times by jwt are set in SECONDS not milliseconds
 */
export const buildCookieExpirationDateFromJwtExpiration = (
  expirationTimeFromJWT
) => {
  return new Date(expirationTimeFromJWT * 1000).toUTCString();
};

/**
 * OLD FUNCTION TO SET THE COOKIE EXPIRATION THIS DOES NOT MATCH WITH
 * THE ACTUAL EXPIRATION TIME ON THE GIVEN JWT
 * @param {Number} expirationHours sets the expiration time e.g 3 would mean the expiration date should be at the next 03:00 o'clock
 * @param {Number} maxDaysToExpire how many days before cookies expire, default 1
 * @returns expiration date
 */
export const buildCookieExpirationDate = (
  expirationHours,
  maxDaysToExpire = 1
) => {
  const expirationDate = new Date();
  if (expirationDate.getHours() < expirationHours) {
    expirationDate.setDate(expirationDate.getDate() + maxDaysToExpire - 1);
  } else {
    expirationDate.setDate(expirationDate.getDate() + maxDaysToExpire);
  }
  expirationDate.setUTCHours(expirationHours, 0, 0, 0);
  return expirationDate;
};

export const isURLaPrivatePage = (url, pages) => {
  const requestedPage = pages.find((page) => page.url === url);
  if (requestedPage && requestedPage.isPrivate) {
    return true;
  }
  // All existing public and non-existing pages.
  return false;
};

/**
 * findPrivateDefaultPage
 * @param {*} pages
 * @returns page or null
 */
export const findPrivateDefaultPage = (pages) => {
  const privateDefaultPage = pages.find((page) => page.isPrivateDefault);
  return privateDefaultPage ? privateDefaultPage : null;
};

/**
 * findDefaultPage
 * @param {*} pages
 * @returns page or null
 */
export const findDefaultPage = (pages) => {
  const defaultPage = pages.find(
    (page) => page.url === process.env.NEXT_PUBLIC_ROOT_PAGE_URL
  );
  return defaultPage ? defaultPage : null;
};

/**
 * this function redirects the request to /api/authenticate/refresh
 * to refresh the access token and will redirect back if the accesstoken could
 * successfully refreshed or to the login page if it could not be refreshed
 *
 * @param {*} originalRequestedUrl
 * @returns
 */
export const tryToRefreshUserTokenOrRedirectToUserLogin = (
  res,
  originalRequestedUrl,
  locale
) => {
  res.setHeader(
    "location",
    `/api/authenticate/refreshtoken/refresh?redirect=${originalRequestedUrl}&locale=${locale}`
  );
  res.statusCode = 302;
  res.end();
  // the nextjs way: return { redirect: {...}} does not work since you can be on another locale,
  // then the /api//authenticate... url is prefixed with the locale and the redirect does not work.
  // Also you have to return an object from getServerSideProps so this is why this object is
  // returned, otherwise an error is logged by nextjs
  return { props: {} };
};

/**
 * Used in getServersideProps with serverSideTranslations(...) like this for example:
 *  ...(await serverSideTranslations(
          locale,
          getServerSideTranslationNamespaces(isAuthenticatedCmsUser, query.url[0], [
            "contenttype",
            "userapi"
          ])
        )),

   Adds the "public" namespace by default. 
   The "cms" namespace is added automatically, if the user is an authenticated cms user (see parameter)

   Additional namespaces can be loaded by adding it to the namespacesToInclude parameter.

   This function also checks if the current page is /news,/events or /joboffers, as those pages (with hardcoded urls) are handled
   by the dynamic route ([...url]). If we are on either of those pages, the contenttype namespace will be added automatically.

    
 * @param {*} isAuthenticatedCmsUser return of isUserAccessTokenValid(req);
 * @param {*} url query.url[0] object. Current page URL without leading slash(/)
 * @param {*} namespacesToInclude namespaces to be used for the current page
 * @returns Array of all namespaces to be included for the current page
 */
export const getServerSideTranslationNamespaces = (
  isAuthenticatedCmsUser = false,
  url = "",
  namespacesToInclude = []
) => {
  // Public should always be enabled
  let namespaces = [TRANSLATION_NAMESPACE_PUBLIC];

  // If user is authenticated, add cms
  if (
    isAuthenticatedCmsUser ||
    namespacesToInclude.includes(TRANSLATION_NAMESPACE_CMS)
  ) {
    namespaces.push(TRANSLATION_NAMESPACE_CMS);
  }

  // If included in parameter and feature is enabled
  if (
    namespacesToInclude.includes(TRANSLATION_NAMESPACE_USERAPI) &&
    cmsHasFeatureServerSide(CMS_FEATURE_USERAPI)
  ) {
    namespaces.push(TRANSLATION_NAMESPACE_USERAPI);
  }

  // If included in parameter and at least of the contenttypes is enabled
  if (
    namespacesToInclude.includes(TRANSLATION_NAMESPACE_CONTENTTYPE) &&
    (cmsHasFeatureServerSide(CMS_FEATURE_JOBOFFERS) ||
      cmsHasFeatureServerSide(CMS_FEATURE_NEWS) ||
      cmsHasFeatureServerSide(CMS_FEATURE_EVENTS))
  ) {
    namespaces.push(TRANSLATION_NAMESPACE_CONTENTTYPE);
  }

  // contenttype pages / hardcoded were moved to normal contentpages
  // therefore add namespaces if url is /joboffers /news or /events (hardcoded urls)
  if (
    (cmsHasFeatureServerSide(CMS_FEATURE_NEWS) && `/${url}` === NEWS_URL) ||
    (cmsHasFeatureServerSide(CMS_FEATURE_EVENTS) && `/${url}` === EVENTS_URL) ||
    (cmsHasFeatureServerSide(CMS_FEATURE_JOBOFFERS) &&
      `/${url}` === JOBOFFER_URL)
  ) {
    if (!namespaces.includes(TRANSLATION_NAMESPACE_CONTENTTYPE))
      namespaces.push("contenttype");
  }

  // If user is authenticated, add datagrid
  if (isAuthenticatedCmsUser ||
    namespacesToInclude.includes(TRANSLATION_NAMESPACE_DATAGRID)) {
    namespaces.push(TRANSLATION_NAMESPACE_DATAGRID);
  }

  return namespaces;
};

/**
 * This is needed to use language values in a different locale for just one specific namespace
 * Example: Using english translations for the cms controls, without affecting the locale of the page that is currently being edited
 * Merges another locales cms namespace into the default list of namespaces
 *
 * This is only called in getServersideProps.
 *
 * @param {*} locale Current locale (req.locale)
 * @param {*} namespaces Array of strings (returned by getServerSideTranslationNamespaces)
 * @param {*} serverSideTranslations The serverSideTranslations function itself (see getServerSideProps on any page)
 * @returns JSON object of the same structure as the serverSideTranslation function would return. Can be spreaded into pageProps just like the normal serverSideTranslations function
 */
export const serverSideTranslationsCustomLanguage = async (
  locale,
  namespaces,
  serverSideTranslations
) => {
  let cmsUserLanguage = process.env.NEXT_PUBLIC_CMS_USER_LANGUAGE;

  if (cmsUserLanguage === "placeholder_env_NEXT_PUBLIC_CMS_USER_LANGUAGE") {
    console.log(
      "[serverSideTranslationsCustomLanguage] process.env.NEXT_PUBLIC_CMS_USER_LANGUAGE NOT FOUND. Falling back to default locale."
    );
    cmsUserLanguage = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;
  }

  // Get the normal serverSideTranslations object
  let defaultTranslations = await serverSideTranslations(locale, namespaces);

  // If the namespaces parameter includes the translation, we need to merge the cms namespace of the set locale (see env param)
  // Otherwise we just return the default translations
  if (namespaces.includes(TRANSLATION_NAMESPACE_CMS)) {
    const cmsLanguageTranslations = await serverSideTranslations(
      cmsUserLanguage,
      [TRANSLATION_NAMESPACE_CMS]
    );

    // Merge i18next namespaces
    defaultTranslations._nextI18Next.initialI18nStore[locale].cms =
      cmsLanguageTranslations._nextI18Next.initialI18nStore[
        cmsUserLanguage
      ].cms;
  }

  if (namespaces.includes(TRANSLATION_NAMESPACE_DATAGRID)) {
    const gridLanguageTranslations = await serverSideTranslations(
      cmsUserLanguage,
      [TRANSLATION_NAMESPACE_DATAGRID]
    )

    // Merge i18next namespaces
    defaultTranslations._nextI18Next.initialI18nStore[locale].datagrid =
      gridLanguageTranslations._nextI18Next.initialI18nStore[
        cmsUserLanguage
      ].datagrid;
  }
  return defaultTranslations;
};
