import { startChunkedUpload } from "services/upload/uploadService";
import { triggerReloadOnManagedFileEditorClose } from "store/actions";
import { getNextJsApiURL } from "utils/api";
import { axiosGetRequest, axiosPostRequest } from "utils/clientUtil";
import {
  MANAGED_FILECATEGORIES,
  UPLOAD_TYPE_CONTENT_MANAGER,
} from "utils/constants";
import {
  createToast,
  geti18nLocale,
  handleResponseToastNotifications,
  translate,
} from "utils/util";

/**
 * creates the mfile.localization object if it is missing or empty
 * @param {Object} editManagedFileState
 * @returns
 */
const createLocalizationObject = (editManagedFileState) => {
  let localization = { ...editManagedFileState.localization };

  // create mfile localization object if it is empty
  if (!localization || Object.keys(localization).length === 0) {
    const defaultLocale = geti18nLocale(process.env.NEXT_PUBLIC_DEFAULT_LOCALE);
    const availableNextLocales = process.env.NEXT_PUBLIC_ACTIVE_LOCALES.split(
      ","
    ).map((locale) => {
      return geti18nLocale(locale);
    });

    availableNextLocales.forEach((locale) => {
      if (locale !== defaultLocale) {
        localization[locale] = { altText: "", title: "", description: "" };
      }
    });
  }
  return localization;
};

/**
 * @typedef ManagedFile
 * @type {object}
 * @property {number} id - unique id
 * @property {string} type - "news" | "event"
 * @property {file} file - the fileupload
 */

export const createManagedFile = async (editManagedFileState, dispatch) => {
  let newManagedFileName = editManagedFileState.name;

  // if the name input does not ends with the original file extension
  // add the original fileextension
  if (
    !editManagedFileState.name.endsWith(
      editManagedFileState.file.name.substring(
        editManagedFileState.file.name.lastIndexOf(".")
      )
    )
  ) {
    newManagedFileName =
      editManagedFileState.name +
      editManagedFileState.file.name.substring(
        editManagedFileState.file.name.lastIndexOf(".")
      );
  }
  const chunkedUploadResult = await startChunkedUpload(
    editManagedFileState.file,
    UPLOAD_TYPE_CONTENT_MANAGER,
    dispatch,
    `Lade Datei hoch...`,
    true,
    newManagedFileName,
    editManagedFileState.isPrivate, // isPrivate
    true, // return file instead of id
    {
      altText: editManagedFileState.altText,
      title: editManagedFileState.title,
      description: editManagedFileState.description,
      fileCategory: getFileCategoryFromMimeType(
        editManagedFileState.file.type,
        editManagedFileState.name
      ),
      localization: createLocalizationObject(editManagedFileState),
    }, // fileInfo
    { mFolderPath: editManagedFileState.mFolderPath } // folderInfo
  );

  if (chunkedUploadResult.success) {
    createToast({
      type: "success",
      msg: translate("cms:managedFileCreateSuccess"),
    });
  } else {
    createToast({
      type: "warning",
      msg: translate("cms:managedFileCreateError"),
    });
  }
  return chunkedUploadResult;
};

export const updateManagedFile = async (editManagedFileState, dispatch) => {
  let editedManagedFileName = getNameWithoutRandomSuffix(editManagedFileState);

  // if the name input does not ends with the original file extension
  // add the original fileextension
  if (
    !editManagedFileState.name.endsWith(
      editManagedFileState.file.name.substring(
        editManagedFileState.file.name.lastIndexOf(".")
      )
    )
  ) {
    editedManagedFileName =
      getNameWithoutRandomSuffix(editManagedFileState) +
      editManagedFileState.file.name.substring(
        editManagedFileState.file.name.lastIndexOf(".")
      );
  }

  const currentMimeType =
    typeof window !== "undefined" && editManagedFileState.file instanceof File
      ? editManagedFileState.file.type
      : editManagedFileState.file.mime;

  editManagedFileState.fileCategory = getFileCategoryFromMimeType(
    currentMimeType,
    editManagedFileState.name
  );

  if (
    typeof window !== "undefined" &&
    editManagedFileState.file instanceof File
  ) {
    const chunkedUploadResult = await startChunkedUpload(
      editManagedFileState.file,
      UPLOAD_TYPE_CONTENT_MANAGER,
      dispatch,
      `Lade Datei hoch...`,
      true,
      editedManagedFileName,
      editManagedFileState.isPrivate, // isPrivate
      true, // return file instead of id
      {
        // altText: editManagedFileState.altText,
        // title: editManagedFileState.title,
        // description: editManagedFileState.description,
        updateId: editManagedFileState.id,
        localization: createLocalizationObject(editManagedFileState),
      }, // fileInfo
      {} // folderInfo
    );

    if (chunkedUploadResult.success) {
      console.log("[updateManagedFile] chunkedupload successful");
      editManagedFileState.file = chunkedUploadResult.response.data.file;
      editManagedFileState.name = chunkedUploadResult.response.data.name;
      editManagedFileState.path = chunkedUploadResult.response.data.path;
      editManagedFileState.isPrivate =
        chunkedUploadResult.response.data.isPrivate;

      dispatch(triggerReloadOnManagedFileEditorClose());
    } else {
      console.log("[updateManagedFile] chunkedupload failed");
      editManagedFileState.file = null;
      createToast({
        type: "warning",
        msg: translate("cms:managedFileUpdatedError"),
      });
      return { success: false };
    }
  }

  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/managedfile/update`),
    {
      data: {
        ...editManagedFileState,
        localization: createLocalizationObject(editManagedFileState),
      },
    }
  );
  handleResponseToastNotifications(
    result,
    "managedFileUpdatedSuccess",
    "managedFileUpdatedError"
  );
  return result;
};

export const deleteManagedFile = async (managedFileId) => {
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/managedfile/delete`),
    { id: managedFileId }
  );
  handleResponseToastNotifications(
    result,
    "managedFileDeletedSuccess",
    "managedFileDeletedError"
  );
  return result;
};

/**
 * this will return the managedfilename without the random generated suffix.
 * It removes the lastindex of "_" to the "." of the fileextension
 *
 * NOTE: This function should only be used if you already have a managedfile
 * if you have a not created managedfile (JS File) then you do not have any
 * random generated suffix that can be spliced off
 *
 * example:
 * test_093c859a96.png -> test.png
 *
 * the actual filename on disk and the filename that will be used to
 * fetch it is always with the randomsuffix. This function is only
 * used to display the name in a better way AND handle renaming correctly
 * so the application will not put a random suffix to the name multiple
 * times
 *
 * @param {string} name
 */
export const getNameWithoutRandomSuffix = (managedfileOrFile) => {
  if (managedfileOrFile.file instanceof File) {
    return managedfileOrFile.name;
  }
  const name = managedfileOrFile.name;
  if (name.includes("_") && name.includes(".")) {
    const displayname = name.replace(
      name.substring(name.lastIndexOf("_"), name.lastIndexOf(".")),
      ""
    );
    return displayname;
  }
  return name;
};

/**
 * this will return the managedfilename without the fileextension.
 * It removes the lastindex of "." of the fileextension
 *
 * example:
 * test.png -> test
 *
 * @param {string} name
 */
export const getNameWithoutFileExt = (name) => {
  if (name.includes(".")) {
    const displayname = name.substring(0, name.lastIndexOf("."));
    return displayname;
  }
  return name;
};

export const getExistingRandomSuffix = (managedfileOrFile) => {
  if (managedfileOrFile.file instanceof File) {
    // if it is a new file there is no generated suffix
    return "";
  }
  const name = managedfileOrFile.name;
  if (name.includes("_") && name.includes(".")) {
    const randomSuffix = name.substring(
      name.lastIndexOf("_"),
      name.lastIndexOf(".")
    );
    return randomSuffix;
  }
  return name;
};

/**
 * gets only the fileextension
 *
 * example:
 * test.png -> .png
 *
 * @param {*} name
 * @returns
 */
export const getFileExt = (name) => {
  if (name.includes(".")) {
    const fileExt = name.substring(name.lastIndexOf("."));
    return fileExt;
  }
  return name;
};

export const getManagedFiles = async (type) => {
  // currently the type is by default always "all" this should be
  // extended if there is need to only fetch a specific type from the backend
  // the filter that is implemented filters in frontend only (on the all list)
  const result = await axiosGetRequest(
    getNextJsApiURL(`/cms/manage/managedfile/get-all`)
  );
  if (!result.success) {
    handleResponseToastNotifications(result, "", "managedFileFetchError");
  }
  return result;
};

export const findReferencesToManagedFile = async (id) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/cms/manage/managedfile/find-references"),
    { id: id }
  );
  if (!result.success) {
    handleResponseToastNotifications(
      result,
      "",
      "managedfile-fetch-references-error"
    );
  }
  return result;
};

export const validateManagedFileInputs = (editManagedFile) => {
  let isValid = true;
  if (editManagedFile.file === null) {
    isValid = false;
    createToast({ type: "warning", msg: translate("cms:noFileInput") });
  }

  if (editManagedFile.name === "") {
    isValid = false;
    createToast({ type: "warning", msg: translate("cms:noNameInput") });
  }

  let dotsInFilenameCount = editManagedFile.name.split(".").length - 1;
  // zero dots are handled in the upload
  if (dotsInFilenameCount > 1) {
    isValid = false;
    createToast({ type: "warning", msg: translate("cms:filenameIsWrong") });
  }
  return isValid;
};

/**
 * get the mfile.fileCategory by the given MIME type
 * @param {string} mimeType e.g "audio/mp4"
 * @param {string} fileName e.g "cat_pic.svg"
 * @returns either "image", "video", "document" or "audio"
 */
export const getFileCategoryFromMimeType = (mimeType, fileName) => {
  if (mimeType === "image/svg+xml") {
    return MANAGED_FILECATEGORIES.SVG;
  } else if (mimeType.startsWith("image")) {
    return MANAGED_FILECATEGORIES.IMAGE;
  }

  if (mimeType.startsWith("video")) {
    return MANAGED_FILECATEGORIES.VIDEO;
  }

  if (mimeType.startsWith("text") || mimeType.startsWith("application")) {
    return MANAGED_FILECATEGORIES.DOCUMENT;
  }

  if (mimeType.startsWith("audio")) {
    return MANAGED_FILECATEGORIES.AUDIO;
  }
};

export const convertFileSize = (fileSizeInKb) => {
  if (fileSizeInKb < 1024) {
    return fileSizeInKb.toFixed(1) + " KB";
  } else if (fileSizeInKb < 1024 * 1024) {
    return (fileSizeInKb / 1024).toFixed(1) + " MB";
  } else {
    return (fileSizeInKb / (1024 * 1024)).toFixed(1) + " MB";
  }
};
