const HttpBackend = require("i18next-http-backend/cjs");
const ChainedBackend = require("i18next-chained-backend").default;
const LocalStorageBackend = require("i18next-localstorage-backend").default;

// prettier-ignore
module.exports = {
  backend: {
    backendOptions: [{ expirationTime: 60 * 60 * 1000 }, {}], // 1 hour
    backends:
      typeof window !== "undefined" ? [LocalStorageBackend, HttpBackend] : [],
  },
  i18n: {
    defaultLocale: JSON.parse(process.env.NEXT_PUBLIC_GENERAL_VALUES)
      .NEXT_PUBLIC_DEFAULT_LOCALE,
    locales: JSON.parse(
      process.env.NEXT_PUBLIC_GENERAL_VALUES
    ).NEXT_PUBLIC_ACTIVE_LOCALES.split(","),
    defaultNS: "public",
    ns: ["cms", "public", "contenttype", "userapi", "datagrid"],
  },
  serializeConfig: false,
  use: typeof window !== "undefined" ? [ChainedBackend] : [],
};
