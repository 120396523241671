import * as actionTypes from "store/actionTypes/navigationActionTypes";

export const initNavigationState = {
  navigations: [],
};

const navigationReducer = (state = initNavigationState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_NAVIGATION:
      return {
        ...state,
        navigations: state.navigations.map((navigation, i) =>
          navigation.name === action.payload.updatedNavigation.name
            ? action.payload.updatedNavigation
            : navigation
        ),
      };
    case actionTypes.ADD_CREATED_NAVIGATION:
      return {
        ...state,
        navigations: [...state.navigations, action.payload.newNavigation],
      };
    default:
      return state;
  }
};

export default navigationReducer;
