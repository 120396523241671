export const CMS_INIT_EVENT_CREATE = "CMS_INIT_EVENT_CREATE";
export const CMS_INIT_EVENT_EDIT = "CMS_INIT_EVENT_EDIT";
export const CMS_RESET_EVENT_EDIT = "CMS_RESET_EVENT_EDIT";
export const CMS_DISCARD_EVENT_EDIT_MODAL_CHANGES =
  "CMS_DISCARD_EVENT_EDIT_MODAL_CHANGES";
export const CMS_CREATE_EVENT_SUCCESS = "CMS_CREATE_EVENT_SUCCESS";
export const CMS_CREATE_EVENT_FAILED = "CMS_CREATE_EVENT_FAILED";
export const CMS_EDIT_EVENT_FIELD = "CMS_EDIT_EVENT_FIELD";
export const CMS_UPDATE_EVENT_SUCCESS = "CMS_UPDATE_EVENT_SUCCESS";
export const CMS_UPDATE_EVENT_FAILED = "CMS_UPDATE_EVENT_FAILED";
export const CMS_ADD_EVENT_CONTENT_LIST_ITEM =
  "CMS_ADD_EVENT_CONTENT_LIST_ITEM";
export const CMS_MOVE_EVENT_CONTENT_LIST_ITEM_UP =
  "CMS_MOVE_EVENT_CONTENT_LIST_ITEM_UP";
export const CMS_MOVE_EVENT_CONTENT_LIST_ITEM_DOWN =
  "CMS_MOVE_EVENT_CONTENT_LIST_ITEM_DOWN";
export const CMS_DELETE_EVENT_CONTENT_LIST_ITEM =
  "CMS_DELETE_EVENT_CONTENT_LIST_ITEM";
export const CMS_RELOAD_EVENT_PAGE = "CMS_RELOAD_EVENT_PAGE";
export const CMS_EVENT_VALIDATION_FAILED = "CMS_EVENT_VALIDATION_FAILED";
export const CMS_ADD_NESTED_EVENT_CONTENT_LIST_ITEM =
  "CMS_ADD_NESTED_EVENT_CONTENT_LIST_ITEM";
export const CMS_EDIT_NESTED_EVENT_FIELD = "CMS_EDIT_NESTED_EVENT_FIELD";
export const CMS_DELETE_NESTED_EVENT_FIELD = "CMS_DELETE_NESTED_EVENT_FIELD";
export const CMS_MOVE_EVENT_CONTENT_NESTED_LIST_ITEM_UP =
  "CMS_MOVE_EVENT_CONTENT_NESTED_LIST_ITEM_UP";
export const CMS_MOVE_EVENT_CONTENT_NESTED_LIST_ITEM_DOWN =
  "CMS_MOVE_EVENT_CONTENT_NESTED_LIST_ITEM_DOWN";
export const CMS_EDIT_CONFIG_EVENT_FIELD = "CMS_EDIT_CONFIG_EVENT_FIELD";
export const CMS_CLONE_EVENT_CONTENT_LIST_ITEM =
  "CMS_CLONE_EVENT_CONTENT_LIST_ITEM";
