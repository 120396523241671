import { useRouter } from "next/router";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isSSR, updateLanguageCookie } from "utils/util";

/**
 * updates the language cookie to the visited page language
 */
function useLanguageCookie() {
  const router = useRouter();
  const isPageVisible = useSelector((state) => state.general.isPageVisible);

  useEffect(() => {
    if (!isSSR() && router) {
      updateLanguageCookie(router.locale);
    }
  }, [router]);

  // this useEffects syncs the language cookie if you re visit
  // the browser tab.
  // update the language cookie to the language selected in
  // the current tab/page
  useEffect(() => {
    if (document && isPageVisible) {
      updateLanguageCookie(router.locale);
    }
  }, [isPageVisible, router]);
}

export default useLanguageCookie;
