import {
  cmsForgotPassword,
  cmsLogin,
  cmsLogout,
  cmsSetPassword,
} from "services/authentication/cmsAuthenticationService";
import {
  createEmptyElement,
  createEmptyNestedContentItem,
  getDraftPage,
  isSaveReportModalPresent,
  saveDraftPage,
  showSaveReportModal,
  updateUserSettings,
} from "services/cms/cmsService";
import * as actionTypes from "store/actionTypes/cmsActionTypes";
import {
  CONTENT_TYPE_EVENT,
  CONTENT_TYPE_JOBOFFER,
  CONTENT_TYPE_NEWS,
  PUBLISH_PAGE_STATE,
  SAVE_PAGE_STATE,
} from "utils/constants";
import { translate } from "utils/util";
import { editEventField, editNestedEventField } from "./cmsEventActionCreator";
import {
  editJobofferField,
  editJobofferJobCard,
} from "./cmsJobofferActionCreator";
import { editNestedNewsField, editNewsField } from "./cmsNewsActionCreator";
import {
  disableBeforeunload,
  hideLoadingOverlay,
  redirect,
  showLoadingOverlay,
} from "./generalActionCreator";
import { userLogoutAction } from "./userActionCreator";

export const setEditMode = () => {
  return {
    type: actionTypes.CMS_SET_EDIT_MODE,
  };
};

export const resetEditMode = () => {
  return {
    type: actionTypes.CMS_RESET_EDIT_MODE,
  };
};

export const setCurrentPage = (pageObject, type) => {
  return {
    payload: { pageObject, type },
    type: actionTypes.CMS_SET_CURRENT_PAGE,
  };
};

export const setPublishedIndicator = (published) => {
  return {
    payload: published,
    type: actionTypes.CMS_SET_PUBLISHED_INDICATOR,
  };
};

export const setEditModeContentPage = (editPageUrl, editDraftId) => {
  return {
    payload: { editPageUrl, editDraftId },
    type: actionTypes.CMS_SET_EDIT_MODE_CONTENTPAGE,
  };
};

export const resetEditModeContentPage = () => {
  return {
    type: actionTypes.CMS_RESET_EDIT_MODE_CONTENTPAGE,
  };
};

export const getDraftPageAndInitEdit = (draftPageId) => {
  return async (dispatch) => {
    const result = await getDraftPage(draftPageId);
    if (result.success) {
      dispatch(initEditPage(result.response.data));
    } else {
      console.log("could not initialize draftpage!");
      console.log(result.error);
      dispatch(resetEditModeContentPage());
    }
  };
};

export const initEditPage = (initialEditDraftPage) => {
  return {
    payload: {
      draftPage: initialEditDraftPage.draftPage,
      availableElements: initialEditDraftPage.availableElements,
    },
    type: actionTypes.CMS_INIT_EDITPAGE,
  };
};

export const updateEditPageCEField = (position, field, value) => {
  return {
    payload: { position, field, value },
    type: actionTypes.CMS_UPDATE_EDITPAGE_CE_FIELD,
  };
};

export const updateEditPageCEMoveUp = (originalPosition, newPosition) => {
  return {
    payload: { originalPosition, newPosition },
    type: actionTypes.CMS_UPDATE_EDITPAGE_CE_MOVE_UP,
  };
};

export const updateEditPageCEMoveDown = (originalPosition, newPosition) => {
  return {
    payload: { originalPosition, newPosition },
    type: actionTypes.CMS_UPDATE_EDITPAGE_CE_MOVE_DOWN,
  };
};

export const toggleEditView = () => {
  return {
    type: actionTypes.CMS_TOGGLE_EDIT_VIEW,
  };
};

export const toggleAuthenticatedUserPreview = () => {
  return {
    type: actionTypes.CMS_TOGGLE_AUTHENTICATED_USER_PREVIEW,
  };
};

export const saveEditPage = (pageState, router, callbackFunction) => {
  return async (dispatch, getState) => {
    switch (pageState) {
      case SAVE_PAGE_STATE:
        dispatch(savePageStart(SAVE_PAGE_STATE));
        dispatch(showLoadingOverlay(translate("cms:savingPage")));

        const result = await saveDraftPage(
          getState().cms.editInfo.editDraftId,
          getState().cms.editInfo.editDraftPage.content,
          getState().cms.editInfo.editDraftPage.locale,
          getState().cms.editInfo.newFiles,
          getState().cms.editInfo.deletedFiles,
          SAVE_PAGE_STATE,
          dispatch
        );

        if (result.success) {
          dispatch(savePageSuccess());
          // if the pageState is save
          // sync the redux state with new saved page (fetch it again on success)
          dispatch(
            getDraftPageAndInitEdit(getState().cms.editInfo.editDraftId)
          );
          dispatch(hideLoadingOverlay());
          if (isSaveReportModalPresent(getState().cms.saveReportModal)) {
            const confirmModal = {
              title: translate("cms:hint"),
              content: (
                <>
                  {translate("cms:pageSaved")}
                  <br /> {translate("cms:filesThatCouldNotBeSaved")}:{" "}
                </>
              ),
              acceptBtnText: translate("cms:ok"),
              xIsCancel: true,
              imageType: "warning",
              hideCancelBtn: true,
            };
            showSaveReportModal(
              dispatch,
              getState().cms.saveReportModal,
              confirmModal
            );
            if (callbackFunction) {
              callbackFunction({ status: "saveReportModal" });
            }
          } else {
            if (callbackFunction) {
              callbackFunction({ status: "success" });
            }
          }
        } else {
          dispatch(savePageFailed(SAVE_PAGE_STATE));
          dispatch(hideLoadingOverlay());
          if (callbackFunction) {
            callbackFunction({ status: "error" });
          }
        }

        break;

      case PUBLISH_PAGE_STATE:
        dispatch(savePageStart(SAVE_PAGE_STATE));
        dispatch(showLoadingOverlay(translate("cms:savingPage")));

        const resultSave = await saveDraftPage(
          getState().cms.editInfo.editDraftId,
          getState().cms.editInfo.editDraftPage.content,
          getState().cms.editInfo.editDraftPage.locale,
          getState().cms.editInfo.newFiles,
          getState().cms.editInfo.deletedFiles,
          SAVE_PAGE_STATE,
          dispatch
        );

        if (isSaveReportModalPresent(getState().cms.saveReportModal)) {
          // dont start the auto publish process because there are files that
          // could not be saved show the saveReportModal confirm modal instead

          dispatch(savePageSuccess());
          // if the pageState is save
          // sync the redux state with new saved page (fetch it again on success)
          dispatch(
            getDraftPageAndInitEdit(getState().cms.editInfo.editDraftId)
          );
          dispatch(hideLoadingOverlay());
          const confirmModal = {
            title: translate("cms:hint"),
            content: (
              <>
                {translate("cms:pageWasSavedButNotPublished")}
                <br />
                <br />
                {translate("cms:filesThatCouldNotBeSaved")}:
              </>
            ),
            acceptBtnText: translate("cms:ok"),
            hideCancelBtn: true,
            xIsCancel: true,
            imageType: "warning",
          };
          showSaveReportModal(
            dispatch,
            getState().cms.saveReportModal,
            confirmModal
          );
          if (callbackFunction) {
            callbackFunction({ status: "saveReportModal" });
          }
        } else {
          // no save result is present -> do the auto publish
          if (resultSave.success) {
            dispatch(savePageSuccess());

            const resultAfterSaveRefetch = await getDraftPage(
              getState().cms.editInfo.editDraftId
            );

            if (resultAfterSaveRefetch.success) {
              dispatch(showLoadingOverlay(translate("cms:publishingPage")));

              let resultPublish = await saveDraftPage(
                getState().cms.editInfo.editDraftId,
                // Instead of the line above the following line was used before-localization:
                // resultAfterSaveRefetch.response.data.draftPage.id,
                resultAfterSaveRefetch.response.data.draftPage.content,
                resultAfterSaveRefetch.response.data.draftPage.locale,
                [],
                [],
                PUBLISH_PAGE_STATE,
                dispatch
              );

              if (resultPublish.success) {
                dispatch(disableBeforeunload(router));
                dispatch(savePageSuccess());
                dispatch(redirect(() => router.reload(), "reload"));
                if (callbackFunction) {
                  callbackFunction({ status: "success" });
                }
              } else {
                dispatch(savePageFailed(PUBLISH_PAGE_STATE));
                dispatch(hideLoadingOverlay());
                if (callbackFunction) {
                  callbackFunction({ status: "error" });
                }
              }
            } else {
              dispatch(savePageFailed());
              // TODO publish failed
              console.log("publish failed -> refetch of saved state failed");
              dispatch(hideLoadingOverlay());
              if (callbackFunction) {
                callbackFunction({ status: "error" });
              }
            }
          } else {
            dispatch(savePageFailed(SAVE_PAGE_STATE));
            dispatch(hideLoadingOverlay());
            if (callbackFunction) {
              callbackFunction({ status: "error" });
            }
          }
        }
        break;
    }
  };
};

const savePageStart = (pageState) => {
  return {
    payload: pageState,
    type: actionTypes.CMS_SAVE_EDITPAGE,
  };
};

const savePageSuccess = () => {
  return {
    type: actionTypes.CMS_SAVE_EDITPAGE_SUCCESS,
  };
};

const savePageFailed = () => {
  return {
    type: actionTypes.CMS_SAVE_EDITPAGE_FAILED,
  };
};

export const updateFileEditPage = (position, field, file) => {
  return {
    payload: { position, field, file },
    type: actionTypes.CMS_UPDATE_EDITPAGE_FILE_FIELD,
  };
};

export const deleteFileEditPage = (position, field, file) => {
  return {
    payload: { position, field, file },
    type: actionTypes.CMS_DELETE_EDITPAGE_FILE_FIELD,
  };
};

export const cmsUpdateUserSettings = (settingName, settingValue) => {
  return async (dispatch) => {
    const result = await updateUserSettings(settingName, settingValue);

    if (result.success) {
      dispatch(
        cmsSetUserSetting(
          result.response.data.settingName,
          result.response.data.settingValue
        )
      );
    } else {
      dispatch(cmsUpdateUserSettingsFailed());
    }
  };
};

export const cmsSetUserSetting = (settingName, settingValue) => {
  return {
    payload: { settingName, settingValue },
    type: actionTypes.CMS_UPDATE_USER_SETTINGS_SUCCESS,
  };
};

export const cmsUpdateUserSettingsFailed = () => {
  return {
    type: actionTypes.CMS_UPDATE_USER_SETTINGS_FAILED,
  };
};

export const cmsAuthenticateAction = (
  username,
  password,
  router,
  isAuthenticatedUser
) => {
  return async (dispatch) => {
    const result = await cmsLogin(username, password);
    if (result.success) {
      // TRM-88: Logout user (if logged in).
      if (isAuthenticatedUser) {
        dispatch(userLogoutAction(false, null));
      }
      dispatch(cmsAuthenticateSuccess(result.response.data));
      router.push("/");
    } else {
      dispatch(cmsAuthenticateFailed(result.error));
    }
  };
};

export const cmsAuthenticateSuccess = (response) => {
  return {
    payload: response,
    type: actionTypes.CMS_AUTHENTICATE_SUCCESS,
  };
};

export const cmsAuthenticateFailed = (response) => {
  return {
    payload: response,
    type: actionTypes.CMS_AUTHENTICATE_FAILED,
  };
};

export const cmsLogoutAction = (router) => {
  return async (dispatch) => {
    const result = await cmsLogout();
    if (result.success) {
      dispatch(cmsLogoutSuccess());
      router.push("/");
    } else {
      dispatch(cmsLogoutFailed(result.error));
    }
  };
};

export const cmsLogoutSuccess = (response) => {
  return {
    payload: response,
    type: actionTypes.CMS_LOGOUT_SUCCESS,
  };
};

export const cmsLogoutFailed = (response) => {
  return {
    payload: response,
    type: actionTypes.CMS_LOGOUT_FAILED,
  };
};

export const cmsForgotPasswordAction = (email, captchaResponse) => {
  return async (dispatch) => {
    const result = await cmsForgotPassword(email, captchaResponse);
    if (result.success) {
      dispatch(cmsForgotPasswordSuccess());
    }
  };
};

export const cmsForgotPasswordSuccess = () => {
  return {
    type: actionTypes.CMS_FORGOT_PASSWORD,
  };
};

export const cmsSetPasswordAction = (password1, password2, code) => {
  return async (dispatch) => {
    const result = await cmsSetPassword(password1, password2, code);
    if (result.success) {
      dispatch(cmsSetPasswordSuccess());
    }
  };
};

export const cmsSetPasswordSuccess = () => {
  return {
    type: actionTypes.CMS_FORGOT_PASSWORD,
  };
};

export const toggleConfigModal = (position) => {
  return {
    payload: position,
    type: actionTypes.CMS_TOGGLE_VIEW_CFG_MODAL,
  };
};

export const toggleNestedCEModal = (position) => {
  return {
    payload: position,
    type: actionTypes.CMS_TOGGLE_VIEW_NESTED_CE_MODAL,
  };
};

export const toggleUserConfigModal = (value) => {
  return {
    payload: value,
    type: actionTypes.CMS_TOGGLE_USER_CONFIG_MODAL,
  };
};

export const updateEditPageCE = (position, config) => {
  return {
    payload: { position, config },
    type: actionTypes.CMS_UPDATE_EDITPAGE_CE,
  };
};

export const updateEditPageAddCE = (elementWithAttributes) => {
  return {
    payload: { content: createEmptyElement(elementWithAttributes) },
    type: actionTypes.CMS_UPDATE_EDITPAGE_ADD_CE,
  };
};

export const updateEditPageCloneCE = (position) => {
  return {
    payload: { position },
    type: actionTypes.CMS_UPDATE_EDITPAGE_CLONE_CE,
  };
};

export const updateEditPageAddCEAtPos = (position, elementWithAttributes) => {
  return {
    payload: { position, content: createEmptyElement(elementWithAttributes) },
    type: actionTypes.CMS_UPDATE_EDITPAGE_ADD_CE_AT_POS,
  };
};

export const updateEditPageDeleteCE = (position, name) => {
  return {
    payload: { position, name },
    type: actionTypes.CMS_UPDATE_EDITPAGE_DELETE_CE,
  };
};

export const updateEditPageAddEmptyNestedContentItem = (
  nestedArrayFieldName,
  contentPosition,
  nestedPosition,
  elementWithAttributes
) => {
  return {
    payload: {
      nestedArrayFieldName,
      contentPosition,
      nestedPosition,
      newItem: createEmptyNestedContentItem(elementWithAttributes),
    },
    type: actionTypes.CMS_UPDATE_EDITPAGE_ADD_NESTED_CONTENT_ITEM,
  };
};

export const updateEditPageNestedItemMoveUp = (
  contentPosition,
  originalNestedPosition,
  newNestedPosition,
  nestedArrayFieldName
) => {
  return {
    payload: {
      contentPosition,
      originalNestedPosition,
      newNestedPosition,
      nestedArrayFieldName,
    },
    type: actionTypes.CMS_UPDATE_EDITPAGE_MOVE_NESTED_ITEM_UP,
  };
};

export const updateEditPageNestedItemMoveDown = (
  contentPosition,
  originalNestedPosition,
  newNestedPosition,
  nestedArrayFieldName
) => {
  return {
    payload: {
      contentPosition,
      originalNestedPosition,
      newNestedPosition,
      nestedArrayFieldName,
    },
    type: actionTypes.CMS_UPDATE_EDITPAGE_MOVE_NESTED_ITEM_DOWN,
  };
};

export const updateEditPageDeleteNestedItem = (
  contentPosition,
  nestedPosition,
  nestedArrayFieldName
) => {
  return {
    payload: {
      contentPosition,
      nestedPosition,
      nestedArrayFieldName,
    },
    type: actionTypes.CMS_UPDATE_EDITPAGE_DELETE_NESTED_ITEM,
  };
};

export const updateEditPageDeleteNestedSubItem = (
  contentPosition,
  nestedPosition,
  nestedArrayFieldName,
  nestedArraySubFieldName,
  nestedArraySubFieldPosition
) => {
  return {
    payload: {
      contentPosition,
      nestedPosition,
      nestedArrayFieldName,
      nestedArraySubFieldName,
      nestedArraySubFieldPosition,
    },
    type: actionTypes.CMS_UPDATE_EDITPAGE_DELETE_NESTED_SUB_ITEM,
  };
};

export const handleSaveReportModalHide = (router) => {
  return async (dispatch, getState) => {
    const saveReportModal = getState().cms.saveReportModal;

    if (saveReportModal.showSaveReportModal) {
      switch (saveReportModal.hideAction) {
        case "reload":
          dispatch(showLoadingOverlay());
          dispatch(disableBeforeunload(router));
          dispatch(redirect(() => router.reload(), "reload"));
          break;
        case "redirect":
          dispatch(showLoadingOverlay());
          dispatch(disableBeforeunload(router));
          dispatch(
            redirect(
              () => router.push(saveReportModal.redirectUrl),
              saveReportModal.redirectUrl
            )
          );
          break;
        default:
          break;
      }
    }
    dispatch(resetSaveReportModal());
  };
};

export const resetSaveReportModal = () => {
  return {
    type: actionTypes.RESET_SAVE_REPORT_MODAL,
  };
};

export const addFileToSaveReportModal = (notUploadedFile) => {
  return {
    payload: { notUploadedFile },
    type: actionTypes.ADD_FILE_TO_SAVE_REPORT_MODAL,
  };
};

export const setSaveReportModal = (saveReportModalObject) => {
  return {
    payload: saveReportModalObject,
    type: actionTypes.SET_SAVE_REPORT_MODAL,
  };
};

export const editCollectionTypeField = (
  contentType,
  cmsField,
  value,
  cmsPosition,
  nestedPosition
) => {
  return async (dispatch, getState) => {
    switch (contentType) {
      case CONTENT_TYPE_NEWS:
        if (typeof nestedPosition !== "undefined") {
          dispatch(
            editNestedNewsField(cmsField, value, cmsPosition, nestedPosition)
          );
        } else {
          dispatch(editNewsField(cmsField, value, cmsPosition));
        }

        break;
      case CONTENT_TYPE_EVENT:
        if (typeof nestedPosition !== "undefined") {
          dispatch(
            editNestedEventField(cmsField, value, cmsPosition, nestedPosition)
          );
        } else {
          dispatch(editEventField(cmsField, value, cmsPosition));
        }
        break;

      case CONTENT_TYPE_JOBOFFER:
        if (typeof cmsPosition !== "undefined") {
          dispatch(editJobofferJobCard(cmsField, value, cmsPosition));
        } else {
          dispatch(editJobofferField(cmsField, value));
        }
        break;
      default:
        console.log("unknown contentType");
        break;
    }
  };
};

/**
 * opens the create/edit modal for collection items (News / Event / Joboffer)
 *
 * @param {*} isEdit true if you want to edit false if you want to create a new entity
 * @param {*} contentType content type constants
 * @returns
 */
export const openCreateEditModal = (isEdit, contentType) => {
  return {
    payload: { isEdit, contentType },
    type: actionTypes.SHOW_CREATE_EDIT_COLLECTION_ITEM_MODAL,
  };
};

export const closeCreateEditModal = () => {
  return {
    type: actionTypes.HIDE_CREATE_EDIT_COLLECTION_ITEM_MODAL,
  };
};

/**
 * Use this to show the MediaSelectionModal in CmsContentMedia
 * @param {Number} cmsPosition to know which one is used on the page
 * @returns
 */
export const cmsShowMediaSelectionModal = (cmsPosition) => {
  return {
    type: actionTypes.CMS_SHOW_MEDIA_SELECTION_MODAL,
    payload: { cmsPosition },
  };
};

/**
 * Use this to hide the MediaSelectionModal in CmsContentMedia
 * @param {Number} cmsPosition to know which one is used on the page
 * @returns
 */
export const cmsHideMediaSelectionModal = (cmsPosition) => {
  return {
    type: actionTypes.CMS_HIDE_MEDIA_SELECTION_MODAL,
    payload: { cmsPosition },
  };
};

/**
 * sets the current edited contenttype as currentEditContext
 * the entire application can check what content type is
 * currently edited via this reducer attribute
 *
 * @param {*} contentType
 * @returns
 */
export const setCurrentEditContext = (contentType) => {
  return {
    type: actionTypes.CMS_SET_CURRENT_EDIT_CONTEXT,
    payload: { currentEditContext: contentType },
  };
};

/**
 * Use this to show the Popover from the popover Button
 * @param {String} openerId to identify which element has to open
 * @returns
 */
export const cmsShowPopover = (openerId) => {
  return {
    type: actionTypes.CMS_SHOW_POPOVER,
    payload: { openerId },
  };
};

/**
 * Use this to hide the Popover from the popover Button
 * @returns
 */
export const cmsHidePopover = () => {
  return {
    type: actionTypes.CMS_HIDE_POPOVER,
    payload: null,
  };
};

export const showAddCEModal = (position) => {
  return {
    type: actionTypes.CMS_SHOW_ADD_CE_MODAL,
    payload: { position },
  };
};

export const hideAddCEModal = () => {
  return {
    type: actionTypes.CMS_HIDE_ADD_CE_MODAL,
    payload: {},
  };
};

export const resetAddCeModal = () => {
  return {
    type: actionTypes.CMS_RESET_ADD_CE_MODAL,
    payload: {},
  };
};

export const refreshRenderKey = () => {
  return {
    type: actionTypes.CMS_REFRESH_RENDER_KEY,
    payload: {},
  };
};
