import { getStrapiURL } from "utils/api";
import { CMS_FEATURE_JOBOFFERS } from "utils/constants";
import {
  axiosServerGetRequest,
  cmsHasFeatureServerSide,
  getCmsAccessToken,
  handleServerRequestError,
} from "utils/serverUtil";
// get only published joboffers
export const getJoboffer = async (jobofferUrl, locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/joboffers/get/${jobofferUrl}?_locale=${locale}`)
  );
  if (result.success) {
    console.log(
      `fetched joboffer with url ${jobofferUrl} with locale ${locale}`
    );
  } else {
    console.log(
      `could not fetch joboffer with url ${jobofferUrl} with locale ${locale}`
    );
  }
  return result;
};
// get unpublished & published joboffer
export const getCmsJoboffer = async (jobofferUrl, req, locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/joboffers/cms-get/${jobofferUrl}?_locale=${locale}`),
    { cmsaccesstoken: getCmsAccessToken(req) }
  );
  if (result.success) {
    console.log(
      `fetched joboffer with url ${jobofferUrl} with locale ${locale}`
    );
  } else {
    console.log(
      `could not fetch joboffer with url ${jobofferUrl} with locale ${locale}`
    );
  }
  return result;
};

export const getAllJoboffers = async (req, returnAsResult, locale) => {
  // check the feature here again, to prevent the error log
  // this is only needed for _app.js - other joboffer server service
  // functions shouldnt need this
  if (cmsHasFeatureServerSide(CMS_FEATURE_JOBOFFERS)) {
    if (!locale) {
      locale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;
    }
    const result = await axiosServerGetRequest(
      getStrapiURL(`/joboffers?_locale=${locale}`),
      {
        cmsaccesstoken: getCmsAccessToken(req),
      }
    );
    if (result.success) {
      if (returnAsResult) {
        return result;
      }
      return result.response.data;
    } else {
      handleServerRequestError(
        result.error,
        "could not get joboffers [jobofferServerService getAllJoboffers]"
      );
      if (returnAsResult) {
        return result;
      }
      return [];
    }
  }
  if (returnAsResult) {
    return { success: false, error: null };
  }
  return [];
};

export const getAllPublishedJoboffersServerSide = async (locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/joboffers/published?_locale=${locale}`)
  );
  if (!result.success) {
    handleServerRequestError(
      result.error,
      "could not get published joboffer [jobofferServerService getAllPublishedJoboffer]"
    );
  }
  return result;
};

export const getAllPublishedIncludingPrivateJoboffersServerSide = async (
  locale
) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/joboffers/published?_locale=${locale}&includePrivate=true`)
  );
  if (!result.success) {
    handleServerRequestError(
      result.error,
      "could not get published joboffer [jobofferServerService getAllPublishedIncludingPrivateJoboffersServerSide]"
    );
  }
  return result;
};
