import { getNextJsApiURL } from "utils/api";
import {
  axiosGetRequest,
  axiosPostRequest,
  handleClientRequestError,
} from "utils/clientUtil";
import { createToast, translate } from "utils/util";

export const getDynamicList = async (dynamicListName) => {
  const result = await axiosGetRequest(
    getNextJsApiURL(`/cms/manage/dynamiclist/name/${dynamicListName}`)
  );
  if (result.success) {
    console.log(`fetched dynamiclist: ${dynamicListName}`);
  } else {
    if (result.response?.status && result.response.status !== 404) {
      createToast({
        type: "warning",
        msg: translate("cms:dynamicListFetchError"),
      });
    }
  }
  return result;
};

export const createDynamicList = async (dynamicListName) => {
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/dynamiclist/create`),
    {
      name: dynamicListName,
    }
  );
  if (result.success) {
    console.log(`created dynamiclist with name ${dynamicListName}`);
  } else {
    handleClientRequestError(
      result.error,
      "could not create dynamiclist [createDynamicList]"
    );
    createToast({
      type: "error",
      msg: translate("cms:dynamicListCreateError"),
    });
  }
  return result;
};

export const updateDynamicList = async (editDynamicList) => {
  // MANAGEDFILE REFACTOR: unused since managedfile refactor
  // const newFiles = extractNewFilesAndRemoveFromContentArray(
  //   editDynamicList.content,
  //   "content"
  // );

  // console.log(JSON.stringify(editDynamicList));
  let formData = new FormData();
  formData.append("data", JSON.stringify(editDynamicList));

  // MANAGEDFILE REFACTOR: unused since managedfile refactor
  // newFiles.forEach((element) => {
  //   formData.append(element.propertyPath, element.file);
  // });

  // send the formdata to nextjs api route
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/dynamiclist/update/`),
    formData
  );
  if (result.success) {
    // Generic Default Message
    let updateDynamicListMessage = translate("cms:dynamicListUpdateSuccess");
    if (editDynamicList.name) {
      if (editDynamicList.name === "socialMedia") {
        updateDynamicListMessage = translate(
          "cms:dynamicListSocialMediaUpdateSuccess"
        );
      } else if (editDynamicList.name === "footer") {
        updateDynamicListMessage = translate(
          "cms:dynamicListFooterUpdateSuccess"
        );
      }
    }
    createToast({
      type: "success",
      msg: updateDynamicListMessage,
    });
  } else {
    handleClientRequestError(result.error, "could not update [dynamicList]");
    // Generic Default Error
    let updateDynamicListError = translate("cms:dynamicListUpdateError");
    if (editDynamicList.name) {
      if (editDynamicList.name === "socialMedia") {
        updateDynamicListError = translate(
          "cms:dynamicListSocialMediaUpdateError"
        );
      } else if (editDynamicList.name === "footer") {
        updateDynamicListError = translate("cms:dynamicListFooterUpdateError");
      }
    }
    createToast({ type: "error", msg: updateDynamicListError });
  }
  return result;
};
