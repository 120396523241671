import {
  createTagByName,
  deleteTag,
  updateTag,
} from "services/cms/cmsTagService";
import * as actionTypes from "store/actionTypes/cmsTagActionTypes";

export const resetTagEditor = () => {
  return {
    type: actionTypes.CMS_RESET_TAG_EDITOR,
  };
};

export const editTagField = (fieldName, fieldValue) => {
  return {
    payload: {
      fieldName,
      fieldValue,
    },
    type: actionTypes.CMS_EDIT_TAG_FIELD,
  };
};

export const openTagEditor = (tagId = null, tagName = "") => {
  return {
    payload: {
      tagId,
      tagName,
    },
    type: actionTypes.CMS_OPEN_TAG_EDITOR,
  };
};

export const saveTag = (refreshDataFromServer = null) => {
  return async (dispatch, getState) => {
    if (getState().cmsTag.editTagId === null) {
      dispatch(createTagStart());
      const result = await createTagByName(getState().cmsTag.editTagName);
      if (result.success) {
        dispatch(createTagSuccess(refreshDataFromServer));
      } else {
        dispatch(createTagFailed());
      }
    } else {
      dispatch(updateTagStart);
      const result = await updateTag(
        getState().cmsTag.editTagId,
        getState().cmsTag.editTagName
      );
      if (result.success) {
        dispatch(updateTagSuccess(refreshDataFromServer));
      } else {
        dispatch(createTagFailed());
      }
    }
  };
};

export const createTagStart = () => {
  return {
    type: actionTypes.CMS_CREATE_TAG_START,
  };
};

export const createTagSuccess = (refreshDataFromServer) => {
  if (refreshDataFromServer !== null) {
    refreshDataFromServer();
  }
  return {
    type: actionTypes.CMS_CREATE_TAG_SUCCESS,
  };
};

export const createTagFailed = () => {
  return {
    type: actionTypes.CMS_CREATE_TAG_FAILED,
  };
};

export const updateTagStart = () => {
  return {
    type: actionTypes.CMS_UPDATE_TAG_START,
  };
};

export const updateTagSuccess = (refreshDataFromServer) => {
  if (refreshDataFromServer !== null) {
    refreshDataFromServer();
  }
  return {
    type: actionTypes.CMS_UPDATE_TAG_SUCCESS,
  };
};

export const updateTagFailed = () => {
  return {
    type: actionTypes.CMS_UPDATE_TAG_FAILED,
  };
};

export const sendDeleteTag = (tagId, refreshDataFromServer = null) => {
  return async (dispatch) => {
    dispatch(deleteTagStart());
    const result = await deleteTag(tagId);
    if (result.success) {
      dispatch(resetTagEditor());
      dispatch(deleteTagSuccess(refreshDataFromServer));
    } else {
      dispatch(deleteTagFailed());
    }
  };
};

export const deleteTagStart = () => {
  return {
    type: actionTypes.CMS_DELETE_TAG_START,
  };
};

export const deleteTagSuccess = (refreshDataFromServer) => {
  if (refreshDataFromServer !== null) {
    refreshDataFromServer();
  }
  return {
    type: actionTypes.CMS_DELETE_TAG_SUCCESS,
  };
};

export const deleteTagFailed = () => {
  return {
    type: actionTypes.CMS_DELETE_TAG_FAILED,
  };
};
