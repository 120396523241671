import { useMemo } from "react";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import combinedReducers from "store/combinedReducers";
// Logger with default options
import { createLogger } from "redux-logger";

/**
 * This is the nextjs with redux-thunk example
 * link: https://github.com/vercel/next.js/tree/canary/examples/with-redux-thunk
 */

export let store;

function initStore(initialState) {
  const logger = createLogger({
    collapsed: true,
    diff: true,
  });

  if (process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT === "prod") {
    // remove logger / redux dev tools in production build
    return createStore(
      combinedReducers,
      initialState,
      applyMiddleware(thunkMiddleware)
    );
  } else {
    // on all other environments use redux devtools + logger
    return createStore(
      combinedReducers,
      initialState,
      composeWithDevTools(applyMiddleware(thunkMiddleware, logger))
    );
  }
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
