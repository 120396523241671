import {
  createEvent,
  deleteEvent,
  getEvent,
  getEventCreateValues,
  saveEvent,
  validateEvent,
} from "services/cms/cmsEventService";
import {
  isSaveReportModalPresent,
  showSaveReportModal,
} from "services/cms/cmsService";
import * as actionTypes from "store/actionTypes/cmsEventActionTypes";
import { MODE_CREATE, MODE_EDIT } from "utils/constants";
import { translate, triggerGetServerSideProps } from "utils/util";
import { closeCreateEditModal, setSaveReportModal } from "./cmsActionCreator";
import {
  disableBeforeunload,
  hideLoadingOverlay,
  redirect,
  showLoadingOverlay,
} from "./generalActionCreator";

const initEventCreate = (eventCreateData) => {
  return {
    payload: eventCreateData,
    type: actionTypes.CMS_INIT_EVENT_CREATE,
  };
};

const initEventEditor = (editEvent) => {
  return {
    payload: editEvent,
    type: actionTypes.CMS_INIT_EVENT_EDIT,
  };
};

export const resetEventEdit = () => {
  return {
    type: actionTypes.CMS_RESET_EVENT_EDIT,
  };
};

export const getEventCreateValuesAndInitCreate = () => {
  return async (dispatch) => {
    const result = await getEventCreateValues();
    if (result.success) {
      console.log("fetched create event values", result.response.data);
      dispatch(initEventCreate(result.response.data));
    }
  };
};

export const getEventAndInitEdit = (eventUrl) => {
  return async (dispatch) => {
    const result = await getEvent(eventUrl);
    if (result.success) {
      console.log("fetched event", result.response.data);
      dispatch(initEventEditor(result.response.data));
    }
  };
};

export const saveEventCreate = (router) => {
  return async (dispatch, getState) => {
    if (validateEvent(getState().cmsEvent.editEvent, MODE_CREATE)) {
      dispatch(showLoadingOverlay(translate("cms:creatingEvent")));
      const currentUser = getState().cms.cmsUser.username;
      const editEvent = getState().cmsEvent.editEvent;
      const result = await createEvent(editEvent, currentUser);
      if (result.success) {
        console.log("created event", result.response.data);
        dispatch(createEventSuccess());
        router.push(`/events/${result.response.data.url}?edit=true`, null, {
          locale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
        });
        dispatch(initEventEditor(result.response.data));
        setTimeout(() => {
          dispatch(hideLoadingOverlay());
        }, 1000);
      } else {
        dispatch(createEventFailed());
        console.log("failed to create event", result.error);
        dispatch(hideLoadingOverlay());
      }
    } else {
      dispatch(eventValidationFailed());
      dispatch(hideLoadingOverlay());
    }
  };
};

export const editEventField = (field, value, position, specialType) => {
  return {
    payload: { value, field, position, specialType },
    type: actionTypes.CMS_EDIT_EVENT_FIELD,
  };
};

export const addEventContentListItem = (type, position) => {
  return {
    payload: { type, position },
    type: actionTypes.CMS_ADD_EVENT_CONTENT_LIST_ITEM,
  };
};

export const editNestedEventField = (
  field,
  value,
  position,
  nestedPosition
) => {
  return {
    payload: { value, field, position, nestedPosition },
    type: actionTypes.CMS_EDIT_NESTED_EVENT_FIELD,
  };
};

export const editEventConfigField = (field, value, position) => {
  return {
    payload: { value, field, position },
    type: actionTypes.CMS_EDIT_CONFIG_EVENT_FIELD,
  };
};

export const addEventContentNestedListItem = (position, type) => {
  return {
    payload: { position, type },
    type: actionTypes.CMS_ADD_NESTED_EVENT_CONTENT_LIST_ITEM,
  };
};
export const deleteEventContentNestedListItem = (position, nestedPosition) => {
  return {
    payload: { position, nestedPosition },
    type: actionTypes.CMS_DELETE_NESTED_EVENT_FIELD,
  };
};

export const moveEventContentListItemUp = (position) => {
  return {
    payload: { position, direction: "up" },
    type: actionTypes.CMS_MOVE_EVENT_CONTENT_LIST_ITEM_UP,
  };
};

export const moveEventContentListItemDown = (position) => {
  return {
    payload: { position, direction: "down" },
    type: actionTypes.CMS_MOVE_EVENT_CONTENT_LIST_ITEM_DOWN,
  };
};

export const moveEventContentNestedListItemUp = (position, nestedPosition) => {
  return {
    payload: { position, nestedPosition, direction: "up" },
    type: actionTypes.CMS_MOVE_EVENT_CONTENT_NESTED_LIST_ITEM_UP,
  };
};

export const moveEventContentNestedListItemDown = (
  position,
  nestedPosition
) => {
  return {
    payload: { position, nestedPosition, direction: "down" },
    type: actionTypes.CMS_MOVE_EVENT_CONTENT_NESTED_LIST_ITEM_DOWN,
  };
};

export const deleteEventContentListItem = (position) => {
  return {
    payload: { position },
    type: actionTypes.CMS_DELETE_EVENT_CONTENT_LIST_ITEM,
  };
};

/**
 * updates an event
 *
 * @param {*} router
 * @param {boolean} publish optional only present if you click
 * the publish=true/unpublish=false button defaults to null if its not present
 * @returns
 */
export const saveEventUpdate = (router, publish = null, callbackFunction) => {
  return async (dispatch, getState) => {
    if (validateEvent(getState().cmsEvent.editEvent, MODE_EDIT)) {
      dispatch(showLoadingOverlay(translate("cms:updatingEvent")));
      const oldUrl = getState().cmsEvent.originalEvent.url;

      const result = await saveEvent(
        getState().cmsEvent.editEvent,
        dispatch,
        publish
      );
      if (result.success) {
        console.log("updated event", result.response.data);
        dispatch(getEventAndInitEdit(result.response.data.url));

        let confirmModal = null;
        if (isSaveReportModalPresent(getState().cms.saveReportModal)) {
          confirmModal = {
            title: translate("cms:hint"),
            content: (
              <>
                {translate("cms:eventSaved")}
                <br /> {translate("cms:filesThatCouldNotBeSaved")}:{" "}
              </>
            ),
            acceptBtnText: translate("cms:ok"),
            xIsCancel: true,
            imageType: "warning",
            hideCancelBtn: true,
          };
        }

        // hide create/edit modal
        dispatch(closeCreateEditModal());

        if (oldUrl !== result.response.data.url) {
          if (isSaveReportModalPresent(getState().cms.saveReportModal)) {
            dispatch(
              setSaveReportModal({
                redirectUrl: `/events/${result.response.data.url}`,
                hideAction: "redirect",
              })
            );
            showSaveReportModal(
              dispatch,
              getState().cms.saveReportModal,
              confirmModal
            );
            dispatch(hideLoadingOverlay());
          } else {
            if (callbackFunction) {
              callbackFunction({
                status: "success",
                nextRoute: `/events/${result.response.data.url}`,
              });
            } else {
              dispatch(disableBeforeunload(router));
              dispatch(
                redirect(
                  () => router.push(`/events/${result.response.data.url}`),
                  `/events/${result.response.data.url}`,
                  true
                )
              );
            }
          }
        } else {
          if (isSaveReportModalPresent(getState().cms.saveReportModal)) {
            showSaveReportModal(
              dispatch,
              getState().cms.saveReportModal,
              confirmModal
            );
            dispatch(hideLoadingOverlay());
          } else {
            // if no save report must be shown and the action was publishing
            // reload the event to leave the edit mode
            if (publish) {
              // publish
              dispatch(reloadEvent(router));
            } else {
              // save
              dispatch(hideLoadingOverlay());
              if (callbackFunction) {
                callbackFunction({ status: "success" });
              }
            }
          }
        }
      } else {
        dispatch(hideLoadingOverlay());
        dispatch(updateEventFailed());
        console.log("failed to update event", result.error);
        if (callbackFunction) {
          callbackFunction({ status: "error" });
        }
      }
    } else {
      dispatch(eventValidationFailed());
      dispatch(hideLoadingOverlay());
      if (callbackFunction) {
        callbackFunction({ status: "error" });
      }
    }
  };
};

export const reloadEvent = (router) => {
  return async (dispatch) => {
    dispatch(disableBeforeunload(router));
    dispatch(showLoadingOverlay(translate("cms:stopingEditMode")));
    dispatch(resetEventEdit());
    dispatch(redirect(() => triggerGetServerSideProps(router), `reload`));
  };
};

export const sendDeleteEvent = (id, router) => {
  return async (dispatch) => {
    dispatch(showLoadingOverlay(translate("cms:deletingEvent")));
    const result = await deleteEvent(id);
    if (result.success) {
      dispatch(disableBeforeunload(router));
      dispatch(redirect(() => router.push("/"), "/"));
      console.log("deleted event", result.response.data);
    } else {
      console.log("failed to delete event", result.error);
      dispatch(hideLoadingOverlay());
    }
  };
};

const updateEventSuccess = (updatedEvent) => {
  return {
    payload: updatedEvent,
    type: actionTypes.CMS_UPDATE_EVENT_SUCCESS,
  };
};
const updateEventFailed = () => {
  return {
    type: actionTypes.CMS_UPDATE_EVENT_FAILED,
  };
};

const createEventSuccess = () => {
  return {
    type: actionTypes.CMS_CREATE_EVENT_SUCCESS,
  };
};
const createEventFailed = () => {
  return {
    type: actionTypes.CMS_CREATE_EVENT_FAILED,
  };
};

const eventValidationFailed = () => {
  return {
    type: actionTypes.CMS_EVENT_VALIDATION_FAILED,
  };
};

export const discardEventEditModalChanges = () => {
  return {
    type: actionTypes.CMS_DISCARD_EVENT_EDIT_MODAL_CHANGES,
  };
};

export const cloneEventContentListItem = (position) => {
  return {
    payload: { position },
    type: actionTypes.CMS_CLONE_EVENT_CONTENT_LIST_ITEM,
  };
};
