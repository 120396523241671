import GoogleAnalytics from "components/cookieSettingScripts/googleAnalytics";
import Matomo from "components/cookieSettingScripts/matomo";
import ConfirmModal from "components/modal/confirmModal";
import LoadingSpinnerOverlay from "components/util/loadingSpinnerOverlay/loadingSpinnerOverlay";
import useBeforeunload from "hooks/useBeforeunload";
import useLanguageCookie from "hooks/useLanguageCookie";
import useNavigateBackCallback from "hooks/useNavigateBackCallback";
import { usePageVisibility } from "hooks/usePageVisibility";
import useScrollToTop from "hooks/useScrollToTop";
import useUserAuthentication from "hooks/useUserAuthentication";
import useUserSilentAuthentication from "hooks/useUserSilentAuthentication";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getStrapiURLClientSide } from "utils/api";
import { cmsHasFeature } from "utils/clientUtil";
import {
  CMS_FEATURE_PWA,
  CMS_FEATURE_USERAPI,
  NAVIGATION_MAIN_NAME,
  NAVIGATION_MAIN_PRIVATE_NAME,
} from "utils/constants";
import { createToast, getDocumentTitle } from "utils/util";
import CmsHasFeature from "../cms/cmsUtil/cmsHasFeature.js/cmsHasFeature";

const TwoFactorInitModal = dynamic(
  import("components/twoFA/twoFactorInitModal")
);

const ScrollToTop = dynamic(import("components/scrollToTop/scrollToTop"));

const Navbar = dynamic(() => import("components/navigation/navbar"));

const Footer = dynamic(() => import("components/footer/footer"));

// NOTE: import contentmanager only components dynamic
const CmsTopBar = dynamic(() =>
  import("components/cms/contentManagerControls/cmsTopBar/cmsTopBar")
);

const PwaCheck = dynamic(() => import("../pwa/pwaCheck"));

const AuthenticatedUserComponent = dynamic(() =>
  import("./authenticatedUserComponent")
);

const NotificationModal = dynamic(() =>
  import("components/notificationModal/notificationModal")
);

export default function Layout({ children, globalSettings, notifyMsg }) {
  const isCmsUserAuthenticated = useSelector(
    (state) => state.cms.isCmsUserAuthenticated
  );
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const cmsEditView = useSelector((state) => state.cms.editView);

  useBeforeunload();
  useNavigateBackCallback();
  usePageVisibility();
  useLanguageCookie();
  useScrollToTop();
  useUserSilentAuthentication();

  const isUserAuthenticated = useUserAuthentication();

  // google fonts links replace whitespaces with '+'
  // so we can create our own google fonts links if we know the name
  // const replaceWhitespaceWithPlus = (string) => {
  //   return string.split(" ").join("+");
  // };

  // Default styles for the <main> element
  let mainStyles = {
    backgroundColor:
      globalSettings?.color?.backgroundcolor || "var(--primary-color-1)",
  };

  // sets the z-index if the footerstyle is set to parallax
  if (globalSettings?.footer?.footertype === "parallax") {
    mainStyles = {
      ...mainStyles,
      zIndex: "10",
      position: "relative",
    };
  }

  if (children.props.page && !children.props.page.hasNavigation) {
    mainStyles = {
      ...mainStyles,
      marginTop: 0,
    };
  }

  const favicon32 =
    globalSettings?.faviconandlogo?.favicon32x32 &&
    getStrapiURLClientSide(globalSettings.faviconandlogo.favicon32x32.url);

  const favicon64 =
    globalSettings?.faviconandlogo?.favicon64x64 &&
    getStrapiURLClientSide(globalSettings.faviconandlogo.favicon64x64.url);

  const favicon128 =
    globalSettings?.faviconandlogo?.favicon128x128 &&
    getStrapiURLClientSide(globalSettings.faviconandlogo.favicon128x128.url);

  const favicon192 =
    globalSettings?.faviconandlogo?.favicon192x192 &&
    getStrapiURLClientSide(globalSettings.faviconandlogo.favicon192x192.url);

  const faviconAndroid =
    globalSettings?.faviconandlogo?.androidicon &&
    getStrapiURLClientSide(globalSettings.faviconandlogo.androidicon.url);

  const faviconApple120 =
    globalSettings?.faviconandlogo?.appletouchicon120x120 &&
    getStrapiURLClientSide(
      globalSettings.faviconandlogo.appletouchicon120x120.url
    );

  const faviconApple152 =
    globalSettings?.faviconandlogo?.appletouchicon152x152 &&
    getStrapiURLClientSide(
      globalSettings.faviconandlogo.appletouchicon152x152.url
    );

  const faviconApple167 =
    globalSettings?.faviconandlogo?.appletouchicon167x167 &&
    getStrapiURLClientSide(
      globalSettings.faviconandlogo.appletouchicon167x167.url
    );

  const faviconApple180 =
    globalSettings?.faviconandlogo?.appletouchicon180x180 &&
    getStrapiURLClientSide(
      globalSettings.faviconandlogo.appletouchicon180x180.url
    );

  const preconnectGoogleFonts =
    (globalSettings?.primaryfont &&
      globalSettings?.primaryfont?.primarySelectedType === "googleFont") ||
    (globalSettings?.secondaryfont &&
      globalSettings?.secondaryfont.secondarySelectedType === "googleFont");

  // this useEffect shows the toast notification for a role
  // mismatch in user/jwt accesstoken later this function
  // can display other non role related notifications aswell
  useEffect(() => {
    if (typeof window !== "undefined" && notifyMsg) {
      createToast({
        type: "warning",
        msg: notifyMsg,
      });
    }
  }, [notifyMsg]);

  const getMainNavigationName = () => {
    // TRM-88:
    // if (isUserAuthenticated ||(children.props.page && children.props.page.isPrivate)) {
    if (isUserAuthenticated && cmsHasFeature(CMS_FEATURE_USERAPI)) {
      return NAVIGATION_MAIN_PRIVATE_NAME;
    }
    return NAVIGATION_MAIN_NAME;
  };

  return (
    <div
      id="layout"
      className="layout"
      style={{
        backgroundColor:
          globalSettings?.color?.backgroundcolor || "var(--primary-color-1)",
      }}
    >
      <GoogleAnalytics />
      <Matomo />
      <Head>
        <title>{getDocumentTitle()}</title>
        {favicon32 && <link rel="icon" href={favicon32} sizes="32x32" />}
        {favicon64 && <link rel="icon" href={favicon64} sizes="64x64" />}
        {favicon128 && <link rel="icon" href={favicon128} sizes="128x128" />}
        {favicon192 && <link rel="icon" href={favicon192} sizes="192x192" />}

        {faviconAndroid && (
          <link rel="shortcut icon" href={faviconAndroid} sizes="196x196" />
        )}

        {faviconApple120 && (
          <link rel="apple-touch-icon" href={faviconApple120} sizes="120x120" />
        )}
        {faviconApple152 && (
          <link rel="apple-touch-icon" href={faviconApple152} sizes="152x152" />
        )}
        {faviconApple167 && (
          <link rel="apple-touch-icon" href={faviconApple167} sizes="167x167" />
        )}
        {faviconApple180 && (
          <link rel="apple-touch-icon" href={faviconApple180} sizes="180x180" />
        )}
        {/* preloads the font to prevent a flash of unstyled text */}
        {/* Must be here so that it is only loaded for editors */}
        {isCmsUserAuthenticated ? (
          <link
            href={`${process.env.NEXT_PUBLIC_NEXTJS_URL}/fonts/Roboto/Roboto-Regular.ttf`}
            rel="preload"
            as="font"
            crossOrigin="anonymous"
          />
        ) : null}

        {/* google fonts will have optimized downloads because of the preconnect */}
        {preconnectGoogleFonts ? (
          <>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossOrigin="anonymous"
            />
          </>
        ) : (
          <></>
        )}
      </Head>
      {isUserAuthenticated && <AuthenticatedUserComponent />}
      <div className="combined-sticky-elements">
        {isCmsUserAuthenticated ? (
          <>
            <CmsTopBar />

            {cmsEditView &&
            children.props.page &&
            children.props.page.hasNavigation ? (
              <>
                <Navbar navName={getMainNavigationName()} />
              </>
            ) : (
              <>
                {children.props.page ? (
                  children.props.page.hasNavigation ? (
                    <Navbar navName={getMainNavigationName()} />
                  ) : null
                ) : (
                  <Navbar navName={getMainNavigationName()} />
                )}
              </>
            )}
          </>
        ) : (
          <>
            {children.props.page ? (
              children.props.page.hasNavigation ? (
                <Navbar navName={getMainNavigationName()} />
              ) : null
            ) : (
              <Navbar navName={getMainNavigationName()} />
            )}
          </>
        )}
      </div>

      {/* Used by scrollToTop-Hook. */}
      <div id="scroll-to-top-anchor"></div>

      {/* We might want to add some device-identifier (with useWindowDimensionHook) classNames here.
      Like: mobile/tablet/desktop to improve customCSS-Queries & reduce useWindowDimensionHook-Usage. */}
      <main className="main" style={mainStyles}>
        {children}
      </main>
      {globalSettings.scrolltotop?.enabled && <ScrollToTop />}
      <ConfirmModal />
      <CmsHasFeature feature={CMS_FEATURE_USERAPI} hidePlaceholderText>
        {isUserAuthenticated && <TwoFactorInitModal />}
      </CmsHasFeature>
      <LoadingSpinnerOverlay />
      {children.props.page ? (
        children.props.page.hasFooter ? (
          <Footer />
        ) : null
      ) : (
        <Footer />
      )}
      <ToastContainer />
      <CmsHasFeature feature={CMS_FEATURE_PWA} hidePlaceholderText>
        <PwaCheck appleIcon={faviconApple120} androidIcon={favicon128} />
      </CmsHasFeature>
      <CmsHasFeature feature={CMS_FEATURE_USERAPI} hidePlaceholderText>
        <CmsHasFeature feature={CMS_FEATURE_PWA} hidePlaceholderText>
          {isAuthenticated && !isCmsUserAuthenticated && <NotificationModal />}
        </CmsHasFeature>
      </CmsHasFeature>
    </div>
  );
}
