import * as actionTypes from "store/actionTypes/cmsNavigationActionTypes";

export const initCmsNavigationState = {
  navEditorOpen: false,
  navigationName: "",
  editNavigationId: null,
  editNavigation: null,
  originalNavigation: null,
  navDoesNotExist: false,
};
const cmsNavigationReducer = (state = initCmsNavigationState, action) => {
  switch (action.type) {
    case actionTypes.CMS_RESET_NAV_EDITOR:
      return {
        ...state,
        navEditorOpen: false,
        navigationName: "",
        editNavigationId: null,
        editNavigation: null,
        originalNavigation: null,
        navDoesNotExist: false,
      };
    case actionTypes.CMS_INIT_NAV_EDITOR_CREATE:
      return {
        ...state,
        editNavigation: {
          name: "",
          logoUrl: "/",
          pages: [],
          mlogo: null,
        },
        originalNavigation: {
          name: "",
          logoUrl: "/",
          pages: [],
          mlogo: null,
        },
      };
    case actionTypes.CMS_OPEN_NAV_EDITOR:
      return {
        ...state,
        navigationName: action.payload.navigationName
          ? action.payload.navigationName
          : "",
        navEditorOpen: true,
      };
    case actionTypes.CMS_INIT_NAV_EDITOR:
      if (action.payload.navigation && action.payload.navigation.isPrivate === null) {
        // default the new isPrivate flag to false
        action.payload.navigation.isPrivate = false; 
      } 
      return {
        ...state,
        editNavigationId: action.payload.navigation.id,
        editNavigation: action.payload.navigation,
        originalNavigation: action.payload.navigation,
        navDoesNotExist: false,
      };
    case actionTypes.CMS_NAV_DOES_NOT_EXIST:
      return {
        ...state,
        navDoesNotExist: true,
      };
    case actionTypes.CMS_NAV_EDITOR_RESET_CHANGES:
      if (state.editNavigation && state.originalNavigation) {
        return {
          ...state,
          editNavigation: { ...state.originalNavigation },
        };
      }
      return state;
    case actionTypes.CMS_CREATE_NAV_FAILED:
    case actionTypes.CMS_UPDATE_NAV_FAILED:
    case actionTypes.CMS_DELETE_NAV_FAILED:
      return state;
    case actionTypes.CMS_UPDATE_NAV_SUCCESS:
    case actionTypes.CMS_CREATE_NAV_SUCCESS:
    case actionTypes.CMS_DELETE_NAV_SUCCESS:
      return {
        navEditorOpen: false,
        navigationName: "",
        editNavigationId: null,
        editNavigation: null,
        originalNavigation: null,
        navDoesNotExist: false,
      };

    case actionTypes.CMS_EDIT_NAV_NAME: {
      const { name } = action.payload;
      return {
        ...state,
        editNavigation: {
          ...state.editNavigation,
          name: name,
        },
      };
    }
    case actionTypes.CMS_EDIT_NAV_LOGO_ITEM: {
      const { mlogo, url } = action.payload;
      return {
        ...state,
        editNavigation: {
          ...state.editNavigation,
          mlogo: mlogo,
          logoUrl: url,
        },
      };
    }
    case actionTypes.CMS_EDIT_NAV_FIELD: {
      const { fieldName, value } = action.payload;
      return {
        ...state,
        editNavigation: {
          ...state.editNavigation,
          [fieldName]: value,
        },
      };
    }
    case actionTypes.CMS_ADD_NAV_ITEM: {
      const { layer, layer1Pos, layer2Pos, layer3Pos } = action.payload;

      let newNavItem = {
        subPages: [],
        __editing: true,
        externalUrl: "",
      };

      let updatedPagesArray = null;

      switch (layer) {
        case 1:
          // add __component attribute only if it is on layer 1
          newNavItem.__component = "navigationelements.page";
          return {
            ...state,
            editNavigation: {
              ...state.editNavigation,
              pages: [...state.editNavigation.pages, newNavItem],
            },
          };
        case 2:
          // use JSON parse / JSON stringify since spread operator
          // goes only one level deep
          updatedPagesArray = JSON.parse(
            JSON.stringify([...state.editNavigation.pages])
          );
          if (typeof updatedPagesArray[layer1Pos].subPages === "undefined") {
            updatedPagesArray[layer1Pos].subPages = [];
          }
          updatedPagesArray[layer1Pos].subPages = [
            ...updatedPagesArray[layer1Pos].subPages,
            newNavItem,
          ];
          break;
        case 3:
          updatedPagesArray = JSON.parse(
            JSON.stringify([...state.editNavigation.pages])
          );
          if (
            typeof updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages ===
            "undefined"
          ) {
            updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages = [];
          }
          updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages = [
            ...updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages,
            newNavItem,
          ];
          break;
        default:
          return state;
      }

      return {
        ...state,
        editNavigation: {
          ...state.editNavigation,
          pages: updatedPagesArray,
        },
      };
    }
    case actionTypes.CMS_TOGGLE_NAV_ITEM_EDIT: {
      const { layer, layer1Pos, layer2Pos, layer3Pos } = action.payload;

      let updatedPagesArray = null;
      let updatedPage = null;

      switch (layer) {
        case 1:
          updatedPage = JSON.parse(
            JSON.stringify(state.editNavigation.pages[layer1Pos])
          );

          // Move this to ActionCreator?
          if (updatedPage.__editing) {
            delete updatedPage.__editing;
          } else {
            updatedPage.__editing = true;
          }

          return {
            ...state,
            editNavigation: {
              ...state.editNavigation,
              pages: [
                ...state.editNavigation.pages.map((element, index) =>
                  index !== layer1Pos ? element : updatedPage
                ),
              ],
            },
          };
        case 2:
          updatedPagesArray = JSON.parse(
            JSON.stringify([...state.editNavigation.pages])
          );
          updatedPage = JSON.parse(
            JSON.stringify(
              state.editNavigation.pages[layer1Pos].subPages[layer2Pos]
            )
          );
          if (updatedPage.__editing) {
            delete updatedPage.__editing;
          } else {
            updatedPage.__editing = true;
          }
          updatedPagesArray[layer1Pos].subPages = updatedPagesArray[
            layer1Pos
          ].subPages.map((element, index) =>
            index !== layer2Pos ? element : updatedPage
          );
          break;
        case 3:
          updatedPagesArray = JSON.parse(
            JSON.stringify([...state.editNavigation.pages])
          );
          updatedPage = JSON.parse(
            JSON.stringify(
              state.editNavigation.pages[layer1Pos].subPages[layer2Pos]
                .subPages[layer3Pos]
            )
          );
          if (updatedPage.__editing) {
            delete updatedPage.__editing;
          } else {
            updatedPage.__editing = true;
          }
          updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages =
            updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages.map(
              (element, index) => (index !== layer3Pos ? element : updatedPage)
            );
          break;
      }
      return {
        ...state,
        editNavigation: {
          ...state.editNavigation,
          pages: updatedPagesArray,
        },
      };
    }
    case actionTypes.CMS_DELETE_NAV_ITEM: {
      const { layer, layer1Pos, layer2Pos, layer3Pos } = action.payload;

      let updatedPagesArray = null;

      switch (layer) {
        case 1:
          return {
            ...state,
            editNavigation: {
              ...state.editNavigation,
              pages: [
                ...state.editNavigation.pages.filter(
                  (element, index) => index !== layer1Pos
                ),
              ],
            },
          };
        case 2:
          updatedPagesArray = JSON.parse(
            JSON.stringify([...state.editNavigation.pages])
          );
          updatedPagesArray[layer1Pos].subPages = updatedPagesArray[
            layer1Pos
          ].subPages.filter((element, index) => index !== layer2Pos);
          break;
        case 3:
          updatedPagesArray = JSON.parse(
            JSON.stringify([...state.editNavigation.pages])
          );
          updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages =
            updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages.filter(
              (element, index) => index !== layer3Pos
            );
          break;
        default:
          return state;
      }
      return {
        ...state,
        editNavigation: {
          ...state.editNavigation,
          pages: updatedPagesArray,
        },
      };
    }
    case actionTypes.CMS_EDIT_NAV_ITEM: {
      const { layer, layer1Pos, layer2Pos, layer3Pos, editedPageItem } =
        action.payload;

      let newEditedNavItem = editedPageItem;
      let updatedPagesArray = null;

      switch (layer) {
        case 1:
          newEditedNavItem.__component = "navigationelements.page";
          return {
            ...state,
            editNavigation: {
              ...state.editNavigation,
              pages: [
                ...state.editNavigation.pages.map((element, index) =>
                  index !== layer1Pos ? element : newEditedNavItem
                ),
              ],
            },
          };
        case 2:
          updatedPagesArray = JSON.parse(
            JSON.stringify([...state.editNavigation.pages])
          );
          updatedPagesArray[layer1Pos].subPages = updatedPagesArray[
            layer1Pos
          ].subPages.map((element, index) =>
            index !== layer2Pos ? element : newEditedNavItem
          );
          break;
        case 3:
          updatedPagesArray = JSON.parse(
            JSON.stringify([...state.editNavigation.pages])
          );
          updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages =
            updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages.map(
              (element, index) =>
                index !== layer3Pos ? element : newEditedNavItem
            );
          break;
        default:
          return state;
      }
      return {
        ...state,
        editNavigation: {
          ...state.editNavigation,
          pages: updatedPagesArray,
        },
      };
    }
    case actionTypes.CMS_MOVE_NAV_ITEM_UP:
    case actionTypes.CMS_MOVE_NAV_ITEM_DOWN: {
      const { direction, layer, layer1Pos, layer2Pos, layer3Pos } =
        action.payload;

      let updatedPagesArray = null;
      let newPos = null;
      let movedElement = null;
      switch (layer) {
        case 1:
          newPos = direction === "up" ? layer1Pos - 1 : layer1Pos + 1;

          // check if the new position is in array boundaries
          if (newPos < 0 || newPos > state.editNavigation.pages.length - 1) {
            return state;
          }
          updatedPagesArray = JSON.parse(
            JSON.stringify([...state.editNavigation.pages])
          );
          movedElement = updatedPagesArray[layer1Pos];
          updatedPagesArray[layer1Pos] = updatedPagesArray[newPos];
          updatedPagesArray[newPos] = movedElement;
          break;
        case 2:
          newPos = direction === "up" ? layer2Pos - 1 : layer2Pos + 1;
          // check if the new position is in array boundaries
          if (
            newPos < 0 ||
            newPos > state.editNavigation.pages[layer1Pos].subPages.length - 1
          ) {
            return state;
          }
          updatedPagesArray = JSON.parse(
            JSON.stringify([...state.editNavigation.pages])
          );
          movedElement = updatedPagesArray[layer1Pos].subPages[layer2Pos];
          updatedPagesArray[layer1Pos].subPages[layer2Pos] =
            updatedPagesArray[layer1Pos].subPages[newPos];
          updatedPagesArray[layer1Pos].subPages[newPos] = movedElement;
          break;
        case 3:
          newPos = direction === "up" ? layer3Pos - 1 : layer3Pos + 1;
          // check if the new position is in array boundaries
          if (
            newPos < 0 ||
            newPos >
              state.editNavigation.pages[layer1Pos].subPages[layer2Pos].subPages
                .length -
                1
          ) {
            return state;
          }
          updatedPagesArray = JSON.parse(
            JSON.stringify([...state.editNavigation.pages])
          );
          movedElement =
            updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages[
              layer3Pos
            ];
          updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages[layer3Pos] =
            updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages[newPos];
          updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages[newPos] =
            movedElement;
          break;
        default:
          return state;
      }
      return {
        ...state,
        editNavigation: {
          ...state.editNavigation,
          pages: updatedPagesArray,
        },
      };
    }
    case actionTypes.CMS_MOVE_NAV_ITEM_LAYER_UP:
    case actionTypes.CMS_MOVE_NAV_ITEM_LAYER_DOWN: {
      const { direction, layer, layer1Pos, layer2Pos, layer3Pos } =
        action.payload;

      let updatedPagesArray = null;
      let newLayer = direction === "up" ? layer - 1 : layer + 1;
      let movedElement = null;
      let parentNodePos = null;

      // check if new layer is in boundaries
      if (newLayer < 1 || newLayer > 3) {
        return state;
      }

      switch (layer) {
        case 1:
          parentNodePos = layer1Pos - 1;
          updatedPagesArray = JSON.parse(
            JSON.stringify([...state.editNavigation.pages])
          );
          movedElement = { ...updatedPagesArray[layer1Pos] };
          updatedPagesArray = [...updatedPagesArray].filter(
            (element, index) => index !== layer1Pos
          );
          if (newLayer === 2) {
            // you can only move down to layer 2 from here
            if (
              typeof updatedPagesArray[parentNodePos].subPages === "undefined"
            ) {
              updatedPagesArray[parentNodePos].subPages = [];
            }
            updatedPagesArray[parentNodePos].subPages = [
              ...updatedPagesArray[parentNodePos].subPages,
              movedElement,
            ];
          }
          break;
        case 2:
          parentNodePos = layer2Pos - 1;
          updatedPagesArray = JSON.parse(
            JSON.stringify([...state.editNavigation.pages])
          );
          movedElement = {
            ...updatedPagesArray[layer1Pos].subPages[layer2Pos],
          };
          updatedPagesArray[layer1Pos].subPages = updatedPagesArray[
            layer1Pos
          ].subPages.filter((element, index) => index !== layer2Pos);
          if (newLayer === 3) {
            // move down to layer 3
            if (
              typeof updatedPagesArray[layer1Pos].subPages[parentNodePos]
                .subPages === "undefined"
            ) {
              updatedPagesArray[layer1Pos].subPages[parentNodePos].subPages =
                [];
            }
            updatedPagesArray[layer1Pos].subPages[parentNodePos].subPages = [
              ...updatedPagesArray[layer1Pos].subPages[parentNodePos].subPages,
              movedElement,
            ];
          } else {
            movedElement.__component = "navigationelements.page";
            delete movedElement.id;
            // move up to layer 1
            updatedPagesArray = [
              ...updatedPagesArray.slice(0, layer1Pos + 1),
              movedElement,
              ...updatedPagesArray.slice(layer1Pos + 1),
            ];
          }
          break;
        case 3:
          // parentNodePos = layer3Pos - 1;
          updatedPagesArray = JSON.parse(
            JSON.stringify([...state.editNavigation.pages])
          );
          movedElement = {
            ...updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages[
              layer3Pos
            ],
          };
          updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages =
            updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages.filter(
              (element, index) => index !== layer3Pos
            );

          // you can only move up to layer 2 from here
          updatedPagesArray[layer1Pos].subPages = [
            ...updatedPagesArray[layer1Pos].subPages.slice(0, layer2Pos + 1),
            movedElement,
            ...updatedPagesArray[layer1Pos].subPages.slice(layer2Pos + 1),
          ];
          break;
        default:
          return state;
      }
      return {
        ...state,
        editNavigation: {
          ...state.editNavigation,
          pages: updatedPagesArray,
        },
      };
    }
    default:
      return state;
  }
};

export default cmsNavigationReducer;
