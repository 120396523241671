import { getNextJsApiURL } from "utils/api";
import {
  axiosGetRequest,
  axiosPostRequest,
  handleClientRequestError,
} from "utils/clientUtil";
import {
  createToast,
  handleResponseToastNotifications,
  translate,
} from "utils/util";

export const sendSavePageRequest = async (pageData) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/cms/manage/page/create"),
    pageData
  );
  handleResponseToastNotifications(
    result,
    "pageCreationSuccess",
    "pageCreationError"
  );
  return result;
};

export const getPageByUrlForPageEditor = async (url) => {
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/page/get-page`),
    { url }
  );
  if (result.success) {
    return result;
  } else {
    handleClientRequestError(
      result.error,
      "could not get page by url [pageService getPageByUrlForPageEditor]"
    );
    return result;
  }
};

export const sendUpdateRequest = async (newData) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/cms/manage/page/update-page"),
    { pagedata: newData }
  );
  handleResponseToastNotifications(
    result,
    "pageUpdateSuccess",
    "pageUpdateError"
  );

  return result;
};

export const sendDeleteRequest = async (id) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/cms/manage/page/delete-page"),
    { id }
  );
  handleResponseToastNotifications(
    result,
    "pageDeleteSuccess",
    "pageDeleteError"
  );
  return result;
};

export const sendPublishRequest = async (id) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/cms/manage/page/publish-page"),
    { id }
  );
  if (!result.success) {
    createToast({
      type: "error",
      msg: translate("cms:pagePublishError"),
    });
  } else {
    createToast({
      type: "success",
      msg: translate(
        `cms:${
          result.response.data.published
            ? "pagePublishSuccess"
            : "pageDepublishSuccess"
        }`
      ),
    });
  }
  return result;
};

export const getAllPagesLocalized = async () => {
  const result = await axiosGetRequest(
    getNextJsApiURL(`/cms/manage/page/get-all`)
  );
  if (result.success) {
    console.log(`fetched all pages localized`);
  } else {
    createToast({ type: "error", msg: translate("cms:genericError") });
  }
  return result;
};

export const getPrivateDefaultPage = async () => {
  const result = await axiosPostRequest(
    getNextJsApiURL(`/user/get-private-default-page`)
  );
  if (result.success) {
    return result;
  } else {
    handleClientRequestError(
      result.error,
      "could not get private default page [pageService getPrivateDefaultPage]"
    );
    return result;
  }
};
