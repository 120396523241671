import { getStrapiURL } from "utils/api";
import {
  axiosServerGetRequest,
  getCmsAccessToken,
  handleServerRequestError,
} from "utils/serverUtil";

export const getCmsSettings = async (req, returnAsResult) => {
  const result = await axiosServerGetRequest(getStrapiURL("/cmssetting"), {
    cmsaccesstoken: getCmsAccessToken(req),
  });
  if (result.success) {
    if (returnAsResult) {
      return result;
    }
    return result.response.data;
  } else {
    handleServerRequestError(
      result.error,
      "could not get cmsSettings [cmsSettingsServerService getCmsSettings]"
    );
    if (returnAsResult) {
      return result;
    }
    return null;
  }
};
