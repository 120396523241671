import * as actionTypes from "store/actionTypes/twoFactorActionTypes";

export const initTwoFactorState = {
  twoFactorInitModal: {
    isOpen: false,
    phoneNumber: null,
  },
};

const twoFactorReducer = (state = initTwoFactorState, action) => {
  switch (action.type) {
    case actionTypes.TWO_FACTOR_INIT_MODAL_OPEN:
      return {
        ...state,
        twoFactorInitModal: {
          ...state.twoFactorInitModal,
          isOpen: true,
        },
      };
    case actionTypes.TWO_FACTOR_INIT_MODAL_CLOSE:
      return {
        ...state,
        twoFactorInitModal: {
          ...state.twoFactorInitModal,
          isOpen: false,
        },
      };
    default:
      return state;
  }
};

export default twoFactorReducer;
