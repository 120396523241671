import * as actionTypes from "store/actionTypes/cmsManagedFileActionTypes";

export const initCmsManagedFileState = {
  isManagedFileEditorOpen: false,
  noSelectionConfirmButton: false,
  managedFileCategories: [],
  editManagedFileId: null,
  editManagedFile: null,
  selectedManagedFile: null,
  triggerRefreshOnClose: false,
};

const cmsManagedFileReducer = (state = initCmsManagedFileState, action) => {
  switch (action.type) {
    case actionTypes.CMS_RESET_MANAGEDFILE_EDITOR:
    case actionTypes.CMS_CREATE_MANAGEDFILE_SUCCESS:
    case actionTypes.CMS_UPDATE_MANAGEDFILE_SUCCESS: {
      return initCmsManagedFileState;
    }
    case actionTypes.CMS_OPEN_MANAGEDFILE_EDITOR:
      return {
        ...state,
        isManagedFileEditorOpen: true,
        noSelectionConfirmButton: action.payload.noSelectionConfirmButton,
        managedFileCategories: action.payload.managedFileCategories,
      };
    case actionTypes.CMS_INIT_MANAGEDFILE_CREATE: {
      return {
        ...state,
        editManagedFile: {
          type: state.managedFileTypes[0], // the first available type
          file: null,
          name: "",
        },
      };
    }
    case actionTypes.CMS_EDIT_MANAGEDFILE_FIELD: {
      return {
        ...state,
        editManagedFile: {
          ...state.editManagedFile,
          [action.payload.fieldName]: action.payload.fieldValue,
        },
      };
    }
    case actionTypes.CMS_EDIT_MANAGEDFILE_VALUES: {
      return {
        ...state,
        editManagedFile: {
          ...action.payload.managedFileValues,
        },
      };
    }
    case actionTypes.CMS_EDIT_SELECTED_MANAGEDFILE: {
      return {
        ...state,
        selectedManagedFile: {
          ...action.payload.selectedFileValues,
        },
        editManagedFile: {
          ...action.payload.selectedFileValues,
        },
      };
    }
    case actionTypes.CMS_TRIGGER_RELOAD_ON_CLOSE_MANAGEDFILE_EDITOR: {
      return {
        ...state,
        triggerRefreshOnClose: true,
      };
    }
    default:
      return state;
  }
};

export default cmsManagedFileReducer;
