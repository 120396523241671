import * as actionTypes from "store/actionTypes/navigationActionTypes";

export const updateNavigationState = (updatedNavigation) => {
  return {
    payload: { updatedNavigation },
    type: actionTypes.UPDATE_NAVIGATION,
  };
};

export const addCreatedNavigation = (newNavigation) => {
  return {
    payload: { newNavigation },
    type: actionTypes.ADD_CREATED_NAVIGATION,
  };
};

