import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAuthenticateRefreshAction } from "store/actions";
import { cmsHasFeature } from "utils/clientUtil";
import {
  CMS_FEATURE_USERAPI,
  USER_REFRESH_TOKEN_INTERVAL,
  USER_REFRESH_TOKEN_WHEN_BELOW_REMAINING,
} from "utils/constants";

/**
 * Hook that takes care of the silent login for the authenticated user
 * 
 * This hook does two things:
 * 
 * 1. If the user is not authenticated from _app.js (getInitialProps) 
 * (redux state.user.isAuthenticated = false) it will try to call /refresh 
 * to get a new valid accesstoken. Then router.replace(router.asPath); 
 * is called to go again through _app.js with a valid accessToken and 
 * come back with (redux state.user.isAuthenticated = true).
 * 
 * 2. If the user is authenticated (redux state.user.isAuthenticated = true)
 * this hook will start the frontend interval function that will silently 
 * refresh the accesstoken before it expires.
 * 
 * When state.user.isAuthenticated = false and the /refresh call wont return
 * a new valid accesstoken this hook does not start the silent refresh interval. 
 */
export default function useUserSilentAuthentication() {
  const [triggeredInitialRefresh, setTriggeredInitialRefresh] = useState(false);
  
  const userIsAuthenticatedState = useSelector(
    (state) => state.user.isAuthenticated
  );
  const userAccessExpirationTimeState = useSelector(
    (state) => state.user.expirationDate
  );
  const cmsAuthenticatedUserPreviewState = useSelector(
    (state) => state.cms.authenticatedUserPreview
  );
  const dispatch = useDispatch();
  const router = useRouter();

  // run initial refresh token call if you visit a page without
  // a valid accesstoken but a valid refreshtoken
  useEffect(() => {
    if (!cmsHasFeature(CMS_FEATURE_USERAPI)) {
      // if there is no userapi abort this hook here
      return;
    }

    if (!triggeredInitialRefresh) {
      setTriggeredInitialRefresh(true);
      // if the user authentication was not provided by the server:
      if (!userIsAuthenticatedState) {
        console.log("user is not authenticated try initial refresh");
        // on the initial refresh there should not be a redirect 
        // if you pass here the router the redirect will also redirect
        // the contentmanager
        dispatch(userAuthenticateRefreshAction(true));
      }
    }
  }, [dispatch, triggeredInitialRefresh, userIsAuthenticatedState, router]);

  // start auto silent refresh interval
  // start this only if you are authenticated
  useEffect(() => {
    if (!cmsHasFeature(CMS_FEATURE_USERAPI)) {
      // if there is no userapi abort this hook here
      return;
    }

    const silentRefreshFunction = () => {
      if (
        !cmsAuthenticatedUserPreviewState &&
        userIsAuthenticatedState &&
        userAccessExpirationTimeState &&
        new Date(userAccessExpirationTimeState).getTime() -
          new Date().getTime() <
          USER_REFRESH_TOKEN_WHEN_BELOW_REMAINING
      ) {
        console.log("[refreshtoken] silent refresh token performed!");
        dispatch(userAuthenticateRefreshAction(false, router));
      }
    };

    let silentSubsequentRefreshCallInterval;
    // start this interval only if you are really authenticated not if you are using
    // the cms user authentication preview
    if (!cmsAuthenticatedUserPreviewState && userIsAuthenticatedState) {
      console.log("[refreshtoken] started refreshtoken interval!");
      silentRefreshFunction();
      silentSubsequentRefreshCallInterval = setInterval(
        silentRefreshFunction,
        USER_REFRESH_TOKEN_INTERVAL
      );
    }

    return () => {
      console.log("[refreshtoken] cleared refreshtoken interval!");
      clearInterval(silentSubsequentRefreshCallInterval);
    };
  }, [
    dispatch,
    userAccessExpirationTimeState,
    userIsAuthenticatedState,
    cmsAuthenticatedUserPreviewState,
    router
  ]);
}
