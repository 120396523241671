import {
  createNews,
  deleteNews,
  getNews,
  saveNews,
  validateNews,
} from "services/cms/cmsNewsService";
import {
  isSaveReportModalPresent,
  showSaveReportModal,
} from "services/cms/cmsService";
import * as actionTypes from "store/actionTypes/cmsNewsActionTypes";
import { MODE_CREATE, MODE_EDIT } from "utils/constants";
import { translate, triggerGetServerSideProps } from "utils/util";
import { closeCreateEditModal, setSaveReportModal } from "./cmsActionCreator";
import {
  disableBeforeunload,
  hideLoadingOverlay,
  redirect,
  showLoadingOverlay,
} from "./generalActionCreator";

export const initNewsCreate = () => {
  return {
    type: actionTypes.CMS_INIT_NEWS_CREATE,
  };
};

const initNewsEditor = (editNews) => {
  return {
    payload: editNews,
    type: actionTypes.CMS_INIT_NEWS_EDIT,
  };
};

export const resetNewsEdit = () => {
  return {
    type: actionTypes.CMS_RESET_NEWS_EDIT,
  };
};

export const getNewsAndInitEdit = (newsUrl) => {
  return async (dispatch) => {
    const result = await getNews(newsUrl);
    if (result.success) {
      console.log("fetched news", result.response.data);
      dispatch(initNewsEditor(result.response.data));
    }
  };
};

export const saveNewsCreate = (router) => {
  return async (dispatch, getState) => {
    if (validateNews(getState().cmsNews.editNews, MODE_CREATE)) {
      dispatch(showLoadingOverlay(translate("cms:creatingNews")));
      const editNews = getState().cmsNews.editNews;
      const result = await createNews(editNews);
      if (result.success) {
        console.log("created news", result.response.data);
        dispatch(createNewsSuccess());
        router.push(`/news/${result.response.data.url}?edit=true`, null, {
          locale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
        });
        dispatch(initNewsEditor(result.response.data));
        setTimeout(() => {
          dispatch(hideLoadingOverlay());
        }, 1000);
      } else {
        dispatch(createNewsFailed());
        console.log("failed to create news", result.error);
        dispatch(hideLoadingOverlay());
      }
    } else {
      dispatch(newsValidationFailed());
      dispatch(hideLoadingOverlay());
    }
  };
};

export const editNewsField = (field, value, position) => {
  return {
    payload: { value, field, position },
    type: actionTypes.CMS_EDIT_NEWS_FIELD,
  };
};
export const addNewsContentListItem = (type, position) => {
  return {
    payload: { type, position },
    type: actionTypes.CMS_ADD_NEWS_CONTENT_LIST_ITEM,
  };
};

export const editNestedNewsField = (field, value, position, nestedPosition) => {
  return {
    payload: { value, field, position, nestedPosition },
    type: actionTypes.CMS_EDIT_NESTED_NEWS_FIELD,
  };
};

export const editNewsConfigField = (field, value, position) => {
  return {
    payload: { value, field, position },
    type: actionTypes.CMS_EDIT_CONFIG_NEWS_FIELD,
  };
};

export const addNewsContentNestedListItem = (position, type) => {
  return {
    payload: { position, type },
    type: actionTypes.CMS_ADD_NESTED_NEWS_CONTENT_LIST_ITEM,
  };
};
export const deleteNewsContentNestedListItem = (position, nestedPosition) => {
  return {
    payload: { position, nestedPosition },
    type: actionTypes.CMS_DELETE_NESTED_NEWS_FIELD,
  };
};

export const moveNewsContentListItemUp = (position) => {
  return {
    payload: { position, direction: "up" },
    type: actionTypes.CMS_MOVE_NEWS_CONTENT_LIST_ITEM_UP,
  };
};

export const moveNewsContentListItemDown = (position) => {
  return {
    payload: { position, direction: "down" },
    type: actionTypes.CMS_MOVE_NEWS_CONTENT_LIST_ITEM_DOWN,
  };
};

export const moveNewsContentNestedListItemUp = (position, nestedPosition) => {
  return {
    payload: { position, nestedPosition, direction: "up" },
    type: actionTypes.CMS_MOVE_NEWS_CONTENT_NESTED_LIST_ITEM_UP,
  };
};

export const moveNewsContentNestedListItemDown = (position, nestedPosition) => {
  return {
    payload: { position, nestedPosition, direction: "down" },
    type: actionTypes.CMS_MOVE_NEWS_CONTENT_NESTED_LIST_ITEM_DOWN,
  };
};

export const deleteNewsContentListItem = (position) => {
  return {
    payload: { position },
    type: actionTypes.CMS_DELETE_NEWS_CONTENT_LIST_ITEM,
  };
};

/**
 * updates a news
 *
 * @param {*} router
 * @param {boolean} publish optional only present if you click
 * the publish=true/unpublish=false button defaults to null if its not present
 * @returns
 */
export const saveNewsUpdate = (router, publish = null, callbackFunction) => {
  return async (dispatch, getState) => {
    if (validateNews(getState().cmsNews.editNews, MODE_EDIT)) {
      dispatch(showLoadingOverlay(translate("cms:updatingNews")));
      const oldUrl = getState().cmsNews.originalNews.url;
      const result = await saveNews(
        getState().cmsNews.editNews,
        dispatch,
        publish
      );
      if (result.success) {
        console.log("updated news", result.response.data);
        dispatch(getNewsAndInitEdit(result.response.data.url));

        let confirmModal = null;
        if (isSaveReportModalPresent(getState().cms.saveReportModal)) {
          confirmModal = {
            title: `Hinweis`,
            content: (
              <>
                {translate("cms:newsSaved")}
                <br /> {translate("cms:filesThatCouldNotBeSaved")}:{" "}
              </>
            ),
            acceptBtnText: translate("cms:ok"),
            xIsCancel: true,
            imageType: "warning",
            hideCancelBtn: true,
          };
        }

        // hide create/edit modal
        dispatch(closeCreateEditModal());
        if (oldUrl !== result.response.data.url) {
          if (isSaveReportModalPresent(getState().cms.saveReportModal)) {
            dispatch(
              setSaveReportModal({
                redirectUrl: `/news/${result.response.data.url}`,
                hideAction: "redirect",
              })
            );
            showSaveReportModal(
              dispatch,
              getState().cms.saveReportModal,
              confirmModal
            );
            dispatch(hideLoadingOverlay());
          } else {
            if (callbackFunction) {
              callbackFunction({
                status: "success",
                nextRoute: `/news/${result.response.data.url}`,
              });
            } else {
              dispatch(disableBeforeunload(router));
              dispatch(
                redirect(
                  () => router.push(`/news/${result.response.data.url}`),
                  `/news/${result.response.data.url}`,
                  true
                )
              );
            }
          }
        } else {
          if (isSaveReportModalPresent(getState().cms.saveReportModal)) {
            showSaveReportModal(
              dispatch,
              getState().cms.saveReportModal,
              confirmModal
            );
            dispatch(hideLoadingOverlay());
          } else {
            // if no save report must be shown and the action was publishing
            // reload the news to leave the edit mode
            if (publish) {
              // publish
              dispatch(reloadNews(router));
            } else {
              // save
              dispatch(hideLoadingOverlay());
              if (callbackFunction) {
                callbackFunction({ status: "success" });
              }
            }
          }
        }
      } else {
        dispatch(hideLoadingOverlay());
        dispatch(updateNewsFailed());
        console.log("failed to update news", result.error);
        if (callbackFunction) {
          callbackFunction({ status: "error" });
        }
      }
    } else {
      dispatch(newsValidationFailed());
      dispatch(hideLoadingOverlay());
      if (callbackFunction) {
        callbackFunction({ status: "error" });
      }
    }
  };
};

export const reloadNews = (router) => {
  return async (dispatch) => {
    dispatch(disableBeforeunload(router));
    dispatch(showLoadingOverlay(translate("cms:stopingEditMode")));
    dispatch(resetNewsEdit());
    dispatch(redirect(() => triggerGetServerSideProps(router), `reload`));
  };
};

export const sendDeleteNews = (id, router) => {
  return async (dispatch) => {
    dispatch(showLoadingOverlay(translate("cms:deletingNews")));
    const result = await deleteNews(id);
    if (result.success) {
      dispatch(disableBeforeunload(router));
      dispatch(redirect(() => router.push("/"), "/"));
      console.log("deleted news", result.response.data);
    } else {
      console.log("failed to delete news", result.error);
      dispatch(hideLoadingOverlay());
    }
  };
};

const updateNewsSuccess = (updatedNews) => {
  return {
    payload: updatedNews,
    type: actionTypes.CMS_UPDATE_NEWS_SUCCESS,
  };
};
const updateNewsFailed = () => {
  return {
    type: actionTypes.CMS_UPDATE_NEWS_FAILED,
  };
};

const createNewsSuccess = () => {
  return {
    type: actionTypes.CMS_CREATE_NEWS_SUCCESS,
  };
};
const createNewsFailed = () => {
  return {
    type: actionTypes.CMS_CREATE_NEWS_FAILED,
  };
};

const newsValidationFailed = () => {
  return {
    type: actionTypes.CMS_NEWS_VALIDATION_FAILED,
  };
};

export const discardNewsEditModalChanges = () => {
  return {
    type: actionTypes.CMS_DISCARD_NEWS_EDIT_MODAL_CHANGES,
  };
};

export const cloneNewsContentListItem = (position) => {
  return {
    payload: { position },
    type: actionTypes.CMS_CLONE_NEWS_CONTENT_LIST_ITEM,
  };
};
