/**
 * Returns the current captcha set if it is already set. (by the friendlycaptcha callback function for example)
 * Otherwise try to get the ReCaptcha response.
 * If it fails, the captcha hasn't been solved yet.
 * @param {*} captcha current captcha state
 * @returns friendlycaptcha response | "" -> (captcha not solved) | reCaptcha response
 */
export const checkCaptcha = (captcha) => {
  // Captcha is not set
  if (!captcha) {
    if (process.env.NEXT_PUBLIC_CAPTCHA_TYPE === "recaptcha") {
      let captchaResponse = "";
      try {
        if (typeof window !== "undefined" && window.grecaptcha.getResponse()) {
          captchaResponse = window.grecaptcha.getResponse();
          return captchaResponse;
        }
      } catch (error) {
        return "";
      }
      // Not set and friendlycaptcha -> not solved
    } else if (process.env.NEXT_PUBLIC_CAPTCHA_TYPE === "friendlycaptcha") {
      return "";
    }
  } else if (process.env.NEXT_PUBLIC_CAPTCHA_TYPE === "friendlycaptcha") {
    return captcha;
  }
};

export const isCaptchaActivated = () => {
  if (
    process.env.NEXT_PUBLIC_CAPTCHA_TYPE === "friendlycaptcha" ||
    process.env.NEXT_PUBLIC_CAPTCHA_TYPE === "recaptcha"
  ) {
    return true;
  }
  return false;
};
