import { icon_fa_circle_exclamation } from "assets/svgs/fontawesomeSvgs";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptConfirmModal,
  cancelConfirmModal,
  handleSaveReportModalHide,
  hideConfirmModal,
} from "store/actions";
import ConfirmModalStyle from "./confirmModalStyle";
const CmsContentRichText = dynamic(() =>
  import("components/cms/cmsContent/cmsContentRichText/cmsContentRichText")
);

export default function ConfirmModal() {
  // const style = "";
  const confirmModalConfig = useSelector((state) => state.general.confirmModal);
  const dispatch = useDispatch();
  const router = useRouter();

  // cleanup function that is called if the modal is accepted/closed/hidden
  // in all cases when the confirm modal closes this function is triggered
  const beforeClose = (confirmModalAction) => {
    dispatch(handleSaveReportModalHide(router));
    // add more cases that always needs to be handled before closing confirm modal
  };

  const renderImageRow = (type) => {
    switch (type) {
      case "warning":
        return (
          <Row>
            <Col>
              <div className="text-center">
                {icon_fa_circle_exclamation("", 70, 70, { fill: "#eeb22f" })}
              </div>
            </Col>
          </Row>
        );
      // add more cases for images here
      default:
        return <></>;
    }
  };

  const acceptButtonColor = (color) => {
    switch (color) {
      case "red":
        return "bgcolor-red";
      // add more cases for acceptBtn color here
      default:
        return ""; // default is in globalStyle blue
    }
  };

  return (
    <>
      <div className={`confirm-modal`}>
        <Modal
          className={`cms-modal-style ${
            confirmModalConfig.className ? confirmModalConfig.className : ""
          }`}
          centered
          show={confirmModalConfig.isOpen}
          onHide={() => {
            // only trigger hide functions if the user can close the confirm modal
            // canNotCloseHide is by default false
            if (!confirmModalConfig.canNotCloseHide) {
              if (confirmModalConfig.xIsCancel) {
                beforeClose("cancel");
                dispatch(cancelConfirmModal());
              } else {
                beforeClose("hide");
                dispatch(hideConfirmModal());
              }
            }
          }}
        >
          <Modal.Header closeButton={!confirmModalConfig.canNotCloseHide}>
            <div className="modal-title">
              <div className="modal-title-text-wrapper">
                {confirmModalConfig.title}
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="confirm-modal-body">
            <div className="confirm-modal-content">
              {renderImageRow(confirmModalConfig.imageType)}
              <Row>
                <Col>
                  <div className="confirm-modal-text text-center text-break">
                    {confirmModalConfig.contentIsRT ? (
                      <CmsContentRichText
                        enableHeadlines
                        content={confirmModalConfig.content}
                      />
                    ) : (
                      confirmModalConfig.content
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {confirmModalConfig.showHideBtn ? (
              <Button
                variant="secondary"
                onClick={() => {
                  beforeClose("hide");
                  dispatch(hideConfirmModal());
                }}
              >
                {confirmModalConfig.hideBtnText}
              </Button>
            ) : null}
            {confirmModalConfig.hideCancelBtn ? null : (
              <Button
                variant="secondary"
                onClick={() => {
                  beforeClose("cancel");
                  dispatch(cancelConfirmModal());
                }}
              >
                {confirmModalConfig.cancelBtnText}
              </Button>
            )}
            <Button
              className={`${acceptButtonColor(
                confirmModalConfig.acceptBtnColor
              )}`}
              variant="primary"
              onClick={() => {
                beforeClose("accept");
                dispatch(acceptConfirmModal());
              }}
            >
              {confirmModalConfig.acceptBtnText}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ConfirmModalStyle />
    </>
  );
}
