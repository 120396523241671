import * as actionTypes from "store/actionTypes/cmsDynamicListActionTypes";
import { getRandomSlug } from "utils/util";

export const initCmsDynamicListState = {
  dynamicListDisplayName: "", // display name which is only the contentmanager will be able to see
  dynamicListEditorOpen: false,
  dynamicListName: "",
  editDynamicListId: null,
  editDynamicList: null,
  originalDynamicList: null,
  dynamicListDoesNotExist: false,
  enabledItemInputs: [],
  enabledFormInputs: [],
};

const cmsDynamicListReducer = (state = initCmsDynamicListState, action) => {
  switch (action.type) {
    case actionTypes.CMS_RESET_DLIST_EDITOR:
      return {
        ...state,
        dynamicListDisplayName: "",
        dynamicListEditorOpen: false,
        dynamicListName: "",
        editDynamicListId: null,
        editDynamicList: null,
        originalDynamicList: null,
        dynamicListDoesNotExist: false,
        enabledItemInputs: [],
        enabledFormInputs: [],
      };
    case actionTypes.CMS_OPEN_DLIST_EDITOR:
      return {
        ...state,
        dynamicListDisplayName: action.payload.dynamicListDisplayName
          ? action.payload.dynamicListDisplayName
          : "",
        dynamicListName: action.payload.dynamicListName
          ? action.payload.dynamicListName
          : "",
        dynamicListEditorOpen: true,
        enabledItemInputs: action.payload.enabledItemInputs,
        enabledFormInputs: action.payload.enabledFormInputs,
      };
    case actionTypes.CMS_INIT_DLIST_EDITOR:
      return {
        ...state,
        editDynamicListId: action.payload.dynamicList.id,
        editDynamicList: action.payload.dynamicList,
        originalDynamicList: action.payload.dynamicList,
        dynamicListDoesNotExist: false,
      };
    case actionTypes.CMS_DLIST_DOES_NOT_EXIST:
      return {
        ...state,
        dynamicListDoesNotExist: true,
      };
    case actionTypes.CMS_DLIST_EDITOR_RESET_CHANGES:
      if (state.editDynamicList && state.originalDynamicList) {
        return {
          ...state,
          editDynamicList: { ...state.originalDynamicList },
        };
      }
      return state;
    case actionTypes.CMS_UPDATE_DLIST_FAILED:
      return state;
    case actionTypes.CMS_UPDATE_DLIST_SUCCESS:
      return {
        dynamicListDisplayName: "",
        dynamicListEditorOpen: false,
        dynamicListName: "",
        editDynamicListId: null,
        editDynamicList: null,
        originalDynamicList: null,
        dynamicListDoesNotExist: false,
        enabledItemInputs: [],
        enabledFormInputs: [],
      };
    case actionTypes.CMS_ADD_DLIST_ITEM: {
      let newDynamicListItem = {
        __component: "dynamiclistelements.listitem",
        __new_id: getRandomSlug(),
        title: "",
        richTextContent: "",
        imgAlt: "",
        imgCaption: "",
        linkUrl: "",
        linkText: "",
        img: null,
      };

      return {
        ...state,
        editDynamicList: {
          ...state.editDynamicList,
          content: [...state.editDynamicList.content, newDynamicListItem],
        },
      };
    }
    case actionTypes.CMS_DELETE_DLIST_ITEM: {
      const { pos } = action.payload;

      return {
        ...state,
        editDynamicList: {
          ...state.editDynamicList,
          content: [
            ...state.editDynamicList.content.filter(
              (element, index) => index !== pos
            ),
          ],
        },
      };
    }
    case actionTypes.CMS_EDIT_DLIST_ITEM: {
      const { pos, field, value } = action.payload;

      return {
        ...state,
        editDynamicList: {
          ...state.editDynamicList,
          content: [
            ...state.editDynamicList.content.map((element, index) => {
              if (index !== pos) {
                return element;
              } else {
                return { ...element, [field]: value };
              }
            }),
          ],
        },
      };
    }
    case actionTypes.CMS_MOVE_DLIST_ITEM_UP:
    case actionTypes.CMS_MOVE_DLIST_ITEM_DOWN: {
      const { direction, originalPos } = action.payload;

      let updatedContentArray = null;
      let newPos = null;
      let movedElement = null;

      newPos = direction === "up" ? originalPos - 1 : originalPos + 1;

      // check if the new position is in array boundaries
      if (newPos < 0 || newPos > state.editDynamicList.content.length - 1) {
        return state;
      }

      updatedContentArray = [...state.editDynamicList.content];
      movedElement = updatedContentArray[originalPos];
      updatedContentArray[originalPos] = updatedContentArray[newPos];
      updatedContentArray[newPos] = movedElement;
      return {
        ...state,
        editDynamicList: {
          ...state.editDynamicList,
          content: updatedContentArray,
        },
      };
    }
    case actionTypes.CMS_UPDATE_DLIST_TITLE:
      return {
        ...state,
        editDynamicList: {
          ...state.editDynamicList,
          title: action.payload.title,
        },
      };
    default:
      return state;
  }
};

export default cmsDynamicListReducer;
