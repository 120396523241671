
/**
 * This function provides the internal URL for the Strapi Docker and can solely be accessed from the server side.
 * If you want to access a strapi file from the client use getStrapiURLClientSide or getNextJsApiURL directly
 * 
 * since v4 all controllers are prefixed with /api, /uploads and /assets are not
 *
 * @param {*} path 
 * @returns 
 */
export const getStrapiURL = (path) => {
  if (
    typeof path === "string" &&
    (path.startsWith("/uploads/") || path.startsWith("/assets/"))
  ) {
    return `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${path}`;
  }
  return `${process.env.NEXT_PUBLIC_STRAPI_API_URL}/api${path}`;

};

/**
 * This function returns a the URL to a file that is placed in 
 * strapi /uploads or /assets folder
 * 
 * strapi is only reachable through the API gateway 
 * 
 * @param {*} path 
 * @returns 
 */
export const getStrapiURLClientSide = (path) => {
  if (
    typeof path === "string" &&
    (path.startsWith("/uploads/") || path.startsWith("/assets/"))
  ) {
    return getNextJsApiURL(path);
  }
  console.log("[getStrapiURLClientSide] you are trying to call strapi outside of /uploads or /assets from the client!");
  return "";
};

export const getNextJsApiURL = (path) => {
  return `${process.env.NEXT_PUBLIC_NEXTJS_URL}/api${path}`;
};

// TODO add env vars
export const getUserApiURL = (path) => {
  return `${process.env.NEXT_PUBLIC_USER_API_URL}/api${path}`;
};
