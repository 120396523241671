import { useEffect } from "react";
import { cleanCache } from "utils/util";
import { Workbox } from "workbox-window";

/**
 * useRegisterServiceWorker takes care of the SW registration/unregistration
 * contains a useEffect with empty dependency array
 *
 * All PWA / SW handling begins here.
 * There is next-pwa config in next.config.js and an environment variable (NEXT_PUBLIC_CMS_FEATURES "pwa")
 *
 * by default the PWA is disabled in development (local it is not by default in the NEXT_PUBLIC_CMS_FEATURES array)
 * You can add "pwa" to the NEXT_PUBLIC_CMS_FEATURES to use the PWA in development (local)
 *
 * if "pwa" is not in NEXT_PUBLIC_CMS_FEATURES the Serviceworker will be unregistered and the cache is cleaned.
 * if NEXT_PUBLIC_CMS_FEATURES is present the Serviceworker will be registered/updated and the cache is untouched
 * The service worker source can be found in root service-worker.js
 *
 * If you want to change caching behaviour look into service-worker.js and modify the caching there.
 */
function useRegisterServiceWorker() {
  useEffect(() => {
    // ---- PWA unregister function ----
    function unregister() {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (const registration of registrations) {
              console.log("SW unregister...");
              registration.unregister();
            }
          });
      }
    }

    if (!process.env.NEXT_PUBLIC_CMS_FEATURES.split(",").includes("pwa")) {
      // if the PWA is disabled by environment variables:
      // unregister it if it was registered
      unregister();
      // clean the cache
      cleanCache();
    } else {
      // if the PWA is not disabled by environment variables:

      /**
       * this property registers/unregisters the serviceworker
       * true = serviceWorker will be registered
       * false = serviceWorker will be unregistered
       *
       * if you want to remove a service worker set this to false
       */
      const __PWA_ENABLE_REGISTER__ = true;

      /**
       * true = cleans the entire cache
       * false = do nothing with the existing cache
       *
       * if you want to clean the entire cache for your service worker set this to true
       * You can always call cleanCache from util.js
       */
      const __PWA_CLEAN_CACHE__ = false;

      /**
       * the pwa start URL
       */
      const __PWA_START_URL__ = "/"; // basePath

      /**
       * service worker source file (this will be generated through next-pwa)
       * do not change this const
       */
      const __PWA_SW__ = "/sw.js";

      /**
       * pwa scope for now this is "/" too but this can be /pwa or /app or something else
       * so the service worker / PWA will only work with this path
       */
      const __PWA_SCOPE__ = "/";

      /**
       * caches the pages while navigating in the app without workbox sw
       * (this is from next-pwa I dont think this is mandatory anymore)
       */
      const __PWA_CACHE_ON_FRONT_END_NAV__ = false;

      /**
       * reload the page if your online status changes from offline to online
       */
      const __PWA_RELOAD_ON_ONLINE__ = false;

      // ---- clean entire cache ----
      if (__PWA_CLEAN_CACHE__) {
        cleanCache();
      }

      if (!__PWA_ENABLE_REGISTER__) {
        unregister();
      }

      // ---- SW preparation and registration ----
      if (
        typeof window !== "undefined" &&
        "serviceWorker" in navigator &&
        typeof caches !== "undefined"
      ) {
        // This was done by default in next-pwa register
        // ADD START URL TO CACHE
        // TODO check if this is still needed
        if (__PWA_START_URL__) {
          caches.has("start-url").then(function (has) {
            if (!has) {
              caches
                .open("start-url")
                .then((c) =>
                  c.put(__PWA_START_URL__, new Response("", { status: 200 }))
                );
            }
          });
        }

        window.workbox = new Workbox(window.location.origin + __PWA_SW__, {
          scope: __PWA_SCOPE__,
        });

        window.workbox.addEventListener("installed", async (e) => {
          console.log(`SW installed isUpdate=${e.isUpdate}`);
        });

        // register the service worker
        if (__PWA_ENABLE_REGISTER__) {
          console.log("SW register serviceworker (Workbox)...");
          window.workbox.register();
        }

        window.addEventListener("offline", () => {
          console.log("status: offline");
          // Update your UI to reflect that there's no connection.
        });

        window.addEventListener("online", () => {
          console.log("status: online");
          // Update your UI to reflect that the connection is back.

          if (__PWA_RELOAD_ON_ONLINE__) {
            location.reload();
          }
        });

        // ---- this part is from next-pwa I dont think this is mandatory anymore ----

        // IF PWA START URL is present add installed event and add start url to cache
        //   if (__PWA_START_URL__) {
        //     window.workbox.addEventListener("installed", async ({ isUpdate }) => {
        //       if (!isUpdate) {
        //         const cache = await caches.open("start-url");
        //         const response = await fetch(__PWA_START_URL__);
        //         let _response = response;
        //         if (response.redirected) {
        //           _response = new Response(response.body, {
        //             status: 200,
        //             statusText: "OK",
        //             headers: response.headers,
        //           });
        //         }

        //         await cache.put(__PWA_START_URL__, _response);
        //       }
        //     });
        //   }

        // ADD installed event and add _next/data to cache
        //   window.workbox.addEventListener("installed", async () => {
        //     const data = window.performance
        //       .getEntriesByType("resource")
        //       .map((e) => e.name)
        //       .filter(
        //         (n) =>
        //           n.startsWith(`${window.location.origin}/_next/data/`) &&
        //           n.endsWith(".json")
        //       );
        //     const cache = await caches.open("next-data");
        //     data.forEach((d) => cache.add(d));
        //   });

        // ---- next-pwa part end ----

        // in next-pwa here was the SW registration (below adding start URL cache and _next/data)

        // ---- this part is from next-pwa I dont think this is mandatory anymore ----

        //   if (__PWA_CACHE_ON_FRONT_END_NAV__ || __PWA_START_URL__) {
        //     const cacheOnFrontEndNav = function (url) {
        //       if (!window.navigator.onLine) return;
        //       if (__PWA_CACHE_ON_FRONT_END_NAV__ && url !== __PWA_START_URL__) {
        //         return caches.open("others").then((cache) =>
        //           cache.match(url, { ignoreSearch: true }).then((res) => {
        //             if (!res) return cache.add(url);
        //             return Promise.resolve();
        //           })
        //         );
        //       } else if (__PWA_START_URL__ && url === __PWA_START_URL__) {
        //         return fetch(__PWA_START_URL__).then(function (response) {
        //           if (!response.redirected) {
        //             return caches
        //               .open("start-url")
        //               .then((cache) => cache.put(__PWA_START_URL__, response));
        //           }
        //           return Promise.resolve();
        //         });
        //       }
        //     };

        //     const pushState = history.pushState;
        //     history.pushState = function () {
        //       pushState.apply(history, arguments);
        //       cacheOnFrontEndNav(arguments[2]);
        //     };

        //     const replaceState = history.replaceState;
        //     history.replaceState = function () {
        //       replaceState.apply(history, arguments);
        //       cacheOnFrontEndNav(arguments[2]);
        //     };

        //     window.addEventListener("online", () => {
        //       cacheOnFrontEndNav(window.location.pathname);
        //     });
        //   }

        // next-pwa part end
      }
    }
  }, []);
}

export default useRegisterServiceWorker;
