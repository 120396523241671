import * as actionTypes from "store/actionTypes/cmsOpenAIActionTypes";

export const initCmsOpenAIState = {
  isOpen: false,
  keywords: [],
  keywordOptions: [], // edit session scoped
  rephrasePrompt: "",
  generatedTexts: [],
  pageName: "",
  additionalKeywords: [],
  maxWords: 200,
  withTitle: true,
  isHTML: true,
  withContext: true,
  chipsHistory: [],
  fieldNames: null,
  contentPos: null,
  nestedContentArrayFieldName: null,
  nestedContentPos: null,
};

const cmsOpenAIReducer = (state = initCmsOpenAIState, action) => {
  switch (action.type) {
    case actionTypes.CMS_RESET_OPENAI_EDITOR: {
      return initCmsOpenAIState;
    }
    case actionTypes.CMS_OPEN_OPENAI_EDITOR:
      return {
        ...state,
        isOpen: true,
        contentPos: action.payload.contentPos,
        fieldNames: action.payload.fieldNames
          ? action.payload.fieldNames
          : null,
        nestedContentArrayFieldName: action.payload.nestedContentArrayFieldName
          ? action.payload.nestedContentArrayFieldName
          : null,
        nestedContentPos:
          action.payload.nestedContentPos ||
          action.payload.nestedContentPos === 0
            ? action.payload.nestedContentPos
            : null,
      };
    case actionTypes.CMS_CLOSE_OPENAI_EDITOR:
      return {
        ...state,
        isOpen: false,
        contentPos: null,
        nestedContentPos: null,
        fieldNames: null,
        nestedContentArrayFieldName: null,
        keywords: [],
        withContext: true,
        maxWords: 200,
      };
    case actionTypes.CMS_EDIT_OPENAI_VALUE: {
      return {
        ...state,
        [action.payload.fieldName]: action.payload.fieldValue,
      };
    }
    case actionTypes.CMS_UPDATE_CHIPS_HISTORY:
      const updatedHistory = [...state.chipsHistory];
      const existingElementIndex = updatedHistory.findIndex((item) => {
        return (
          item.id === action.payload.id &&
          item.component === action.payload.component &&
          item.nestedId === action.payload.nestedId
        );
      });
      if (existingElementIndex === -1) {
        updatedHistory.push(action.payload);
      } else {
        updatedHistory[existingElementIndex] = action.payload;
      }
      return {
        ...state,
        chipsHistory: updatedHistory,
      };
    case actionTypes.CMS_DELETE_CHIPS_HISTORY_ITEM:
      let editedHistory = [...state.chipsHistory];
      if (editedHistory.length > 0 && action.payload !== -1) {
        editedHistory.splice(action.payload, 1);
      }
      return {
        ...state,
        chipsHistory: editedHistory,
      };

    // case actionTypes.CMS_INIT_OPENAI_CREATE: {
    //   return {
    //     ...state,
    //     editManagedFile: {
    //       type: state.managedFileTypes[0], // the first available type
    //       file: null,
    //       name: "",
    //     },
    //   };
    // }

    default:
      return state;
  }
};

export default cmsOpenAIReducer;
