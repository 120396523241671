import { getNextJsApiURL } from "utils/api";
import {
  addFilesToContentTypeContentArray,
  axiosGetRequest,
  axiosPostRequest,
  handleClientRequestError,
} from "utils/clientUtil";
import {
  createToast,
  handleResponseToastNotifications,
  translate,
} from "utils/util";
// MANAGEDFILE REFACTOR: unused since managedfile refactor
// import { sendFileAndSave } from "./cmsUploadService";

export const validateEvent = (eventData, isEdit) => {
  let isEventDataValid = true;
  const getIsTimeValid = (value, minDate, maxDate) => {
    let result = true;
    let timeDateValue = value ? new Date(value) : null;
    let minDateTimeValue = minDate ? new Date(minDate) : null;
    let maxDateTimeValue = maxDate ? new Date(maxDate) : null;
    if (timeDateValue) {
      if (minDateTimeValue && minDateTimeValue > timeDateValue) {
        result = false;
      }
      if (maxDateTimeValue && maxDateTimeValue < timeDateValue) {
        result = false;
      }
    } else {
      result = false;
    }
    return result;
  };

  if (
    !eventData.title ||
    (eventData.title && !eventData.title.trim().length > 0)
  ) {
    isEventDataValid = false;
    createToast({ type: "warning", msg: translate("cms:noTitleInput") });
  } else if (!eventData.dateTimeStart) {
    isEventDataValid = false;
    createToast({
      type: "warning",
      msg: translate("cms:eventSaveErrorStartTime"),
    });
  } else if (
    !eventData.dateTimeEnd ||
    !getIsTimeValid(eventData.dateTimeEnd, eventData.dateTimeStart)
  ) {
    isEventDataValid = false;
    createToast({
      type: "warning",
      msg: translate("cms:eventSaveErrorEndTime"),
    });
  } else if (
    eventData.repetition !== "noRepetition" &&
    !getIsTimeValid(eventData.dateTimeRepeatUntil, eventData.dateTimeStart)
  ) {
    isEventDataValid = false;
    createToast({
      type: "warning",
      msg: translate("cms:eventSaveErrorRepeatUntilTime"),
    });
  }

  return isEventDataValid;
};

export const getEvent = async (eventUrl) => {
  console.log("getEvent");
  // TODO eventUrl can be an array if you create event with slash containing url
  const result = await axiosGetRequest(
    getNextJsApiURL(`/cms/manage/event/${eventUrl}`)
  );
  if (result.success) {
    console.log(`fetched event with url ${eventUrl}`);
  } else {
    handleResponseToastNotifications(result, "", "eventFetchError");
  }
  return result;
};

export const getEventCreateValues = async () => {
  console.log("getEventCreateValues");
  const result = await axiosGetRequest(
    getNextJsApiURL(`/cms/manage/event/create-init`)
  );
  if (result.success) {
    console.log(`fetched event create values`);
  } else {
    handleResponseToastNotifications(result, "", "genericError");
  }
  return result;
};

export const createEvent = async (editEvent, creatorName) => {
  console.log("createEvent");
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/event/create`),
    { ...editEvent, creator: creatorName }
  );
  handleResponseToastNotifications(
    result,
    "eventCreateSuccess",
    "eventCreateError"
  );
  if (result.success) {
    console.log(`created event with title ${result.response.title}`);
  }
  return result;
};

export const deleteEvent = async (id) => {
  console.log("deleteEvent");
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/event/delete`),
    { id }
  );
  handleResponseToastNotifications(
    result,
    "eventDeletedSuccess",
    "eventDeletedError"
  );
  if (result.success) {
    console.log(`deleted event with id ${id}`);
  } else {
    console.log(`failed to delete event with id ${id}`);
  }
  return result;
};

export const saveEvent = async (eventData, dispatch, publish) => {
  // MANAGEDFILE REFACTOR: unused since managedfile refactor
  // const newFiles = extractNewFilesAndRemoveFromContentArray(
  //   eventData.content,
  //   "content"
  // );
  // let newImgTitle = null;
  // if (eventData.imgTitle instanceof File) {
  //   newImgTitle = eventData.imgTitle;
  //   eventData.imgTitle = null;
  // }

  const preSavePublishState = eventData.published;
  if (typeof publish === "boolean") {
    eventData.published = publish;
  }

  let formData = new FormData();
  formData.append("data", JSON.stringify(eventData));

  const mainSaveResult = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/event/update`),
    formData,
    null,
    { headers: { "Content-Type": "multipart/form-data;" } }
  );
  if (mainSaveResult.success) {
    // MANAGEDFILE REFACTOR: unused since managedfile refactor
    // const data = mainSaveResult.response.data;
    // formData = new FormData();
    // formData.append("data", JSON.stringify(data));
    // await sendEventFiles(formData, data, newImgTitle, newFiles, dispatch);
    handleResponseToastNotifications(
      mainSaveResult,
      "eventUpdateSuccess",
      "eventUpdateError"
    );
  } else {
    // if the update fails set the old values
    eventData.published = preSavePublishState;
    addFilesToContentTypeContentArray(eventData, newFiles);
    handleClientRequestError(
      mainSaveResult.error,
      "could not update [eventarticle]"
    );
    handleResponseToastNotifications(
      mainSaveResult,
      "eventUpdateSuccess",
      "eventUpdateError"
    );
  }
  return mainSaveResult;
};

// MANAGEDFILE REFACTOR: unused since managedfile refactor
// /**
//  * upload file by file for event save
//  * this code block will add one file to the formData sends the request and
//  * then deletes the formData key from formData. Also the new success response
//  * is set to formData "data" key
//  *
//  * @param {*} formData
//  * @param {*} newImgTitle
//  * @param {*} newFiles
//  * @param {*} dispatch
//  */
// const sendEventFiles = async (
//   formData,
//   data,
//   newImgTitle,
//   newFiles,
//   dispatch
// ) => {
//   // event title image
//   if (newImgTitle) {
//     const dataAndFormData = await sendFileAndSave(
//       CONTENT_TYPE_EVENT,
//       formData,
//       data,
//       newImgTitle,
//       "files.imgTitle",
//       dispatch,
//       `Lade Termin Titelbild hoch...`,
//       `/cms/manage/event/update`
//     );
//     data = dataAndFormData.data;
//     formData = dataAndFormData.formData;
//   }

//   for (let i = 0; i < newFiles.length; i++) {
//     const file = newFiles[i].file;
//     const filesPropertyPath = newFiles[i].propertyPath;

//     const dataAndFormData = await sendFileAndSave(
//       CONTENT_TYPE_EVENT,
//       formData,
//       data,
//       file,
//       filesPropertyPath,
//       dispatch,
//       `Lade Datei ${i + 1} von ${newFiles.length} hoch...`,
//       `/cms/manage/event/update`
//     );
//     data = dataAndFormData.data;
//     formData = dataAndFormData.formData;
//   }
// };
