import * as actionTypes from "../actionTypes/cmsCategoryActionTypes";

export const initCmsCategoryState = {
  categoryEditorOpen: false,
  editCategoryId: null,
  editCategoryName: "",
  editCategoryType: "",
  editCategoryLocalizationJson: "",
};

const cmsCategoryReducer = (state = initCmsCategoryState, action) => {
  switch (action.type) {
    case actionTypes.CMS_RESET_CATEGORY_EDITOR:
    case actionTypes.CMS_CREATE_CATEGORY_SUCCESS:
    case actionTypes.CMS_UPDATE_CATEGORY_SUCCESS: {
      return {
        categoryEditorOpen: false,
        editCategoryId: null,
        editCategoryName: "",
        editCategoryType: "",
        editCategoryLocalizationJson: "{}",
      };
    }
    case actionTypes.CMS_OPEN_CATEGORY_EDITOR:
      return {
        categoryEditorOpen: true,
        editCategoryId: action.payload.categoryId,
        editCategoryName: action.payload.categoryName,
        editCategoryType: action.payload.categoryType,
        editCategoryLocalizationJson: action.payload.categoryLocalizationJson,
      };
    case actionTypes.CMS_EDIT_CATEGORY_FIELD: {
      return {
        ...state,
        [action.payload.fieldName]: action.payload.fieldValue,
      };
    }
    default:
      return state;
  }
};

export default cmsCategoryReducer;
