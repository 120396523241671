import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useSelector } from "react-redux";

/**
 * beforeunload event handling
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
 *
 * example:
 *
 * enable beforeunload event:
 * dispatch(enableBeforeunload());
 *
 * disable beforeunload event:
 * dispatch(disableBeforeunload(router));
 */
function useBeforeunload() {
  const { t: tCms } = useTranslation("cms");
  const router = useRouter();
  const beforeunloadEvent = useSelector((state) => {
    if (state && state.general && state.general.beforeunloadEvent) {
      return state.general.beforeunloadEvent;
    } else {
      return false;
    }
  });

  useEffect(() => {
    const confirmationMessage = tCms("confirmToLeaveThePage");
    const beforeUnloadHandler = (e) => {
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    };
    const beforeRouteHandler = (url) => {
      if (router.pathname !== url && !confirm(confirmationMessage)) {
        router.events.emit("routeChangeError");
        throw `Route change to "${url}" was aborted (this error can be safely ignored).`;
      }
    };
    if (beforeunloadEvent) {
      window.addEventListener("beforeunload", beforeUnloadHandler);
      router.events.on("routeChangeStart", beforeRouteHandler);
    } else {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
      router.events.off("routeChangeStart", beforeRouteHandler);
    }
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
      router.events.off("routeChangeStart", beforeRouteHandler);
    };
  }, [beforeunloadEvent]);
}

export default useBeforeunload;
