import { getStrapiURL } from "utils/api";
import { CMS_FEATURE_NEWS } from "utils/constants";
import {
  axiosServerGetRequest,
  cmsHasFeatureServerSide,
  getCmsAccessToken,
  handleServerRequestError,
} from "utils/serverUtil";

export const getAllTags = async (req, returnAsResult) => {
  if (cmsHasFeatureServerSide(CMS_FEATURE_NEWS)) {
    const result = await axiosServerGetRequest(getStrapiURL("/tags"), {
      cmsaccesstoken: getCmsAccessToken(req),
    });
    if (result.success) {
      if (returnAsResult) {
        return result;
      }
      return result.response.data;
    } else {
      handleServerRequestError(
        result.error,
        "could not get tags [cmsTagService getAllTags]"
      );
      if (returnAsResult) {
        return result;
      }
      return [];
    }
  }
  if (returnAsResult) {
    return { success: false, error: null };
  }
  return [];
};
