import axios from "axios";
import objectPath from "object-path";
import { openCollectionTypeNestedContentModal } from "store/actions";
import { convertPropertyPath, createToast, translate } from "./util";

/**
 * There are some hooks for general axios requests.
 * So I dont know if we need this kind of client Util anymore.
 *
 * mostly this is copied from serverUtil.js the axios config
 * and functions might be different than the ones on the server
 * side
 */

export const handleClientRequestError = (error, customLog) => {
  if (process.env.NEXT_PUBLIC_DEV_MODE === "true") {
    // if (error && process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT === "local") {
    //   createToast({
    //     type: "error",
    //     msg: `DEV MODE MSG:  ${JSON.stringify(error.message)}`,
    //   });
    // }
    if (error) {
      console.log(`DEV MODE MSG:  ${JSON.stringify(error.message)}`);
    }
    if (customLog) {
      console.log(customLog);
    }
  }
  if (error) {
    console.log(error.message);
  }
};

export const globalAxiosConfig = {
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
};

/**
 * general axios get request function
 * TODO this is no final version this might change later
 *
 * @param {} url
 * @param {*} accessToken
 * @param {*} customAxiosConfig
 * @returns
 */
export const axiosGetRequest = async (url, accessToken, customAxiosConfig) => {
  try {
    const response = await axios.get(
      url,
      createAxiosConfig(accessToken, customAxiosConfig)
    );
    return {
      success: true,
      error: null,
      response: response,
    };
  } catch (error) {
    handleClientRequestError(error);
    return {
      success: false,
      error,
      response: error.response,
    };
  }
};

/**
 * general axios post request function
 * TODO this is no final version this might change later
 *
 * @param {*} url
 * @param {*} data
 * @param {*} accessToken
 * @param {*} customAxiosConfig
 * @returns
 */
export const axiosPostRequest = async (
  url,
  data,
  accessToken,
  customAxiosConfig
) => {
  try {
    const response = await axios.post(
      url,
      data,
      createAxiosConfig(accessToken, customAxiosConfig)
    );
    return {
      success: true,
      error: null,
      response: response,
    };
  } catch (error) {
    handleClientRequestError(error);
    return {
      success: false,
      error,
      response: error.response,
    };
  }
};

/**
 * helper function for the general axios get/post functions
 *
 * in this function the access token is added to the request
 * header or the entire (global) axios config will be replaced by a
 * custom config
 *
 * @param {*} accessToken
 * @param {*} customAxiosConfig
 * @returns
 */
const createAxiosConfig = (accessToken, customAxiosConfig) => {
  let currentAxiosConfig = globalAxiosConfig;

  if (customAxiosConfig) {
    currentAxiosConfig = customAxiosConfig;
  }

  // add Authorization header if accessToken param is present
  if (accessToken) {
    currentAxiosConfig = {
      ...currentAxiosConfig,
      headers: {
        ...currentAxiosConfig.headers,
        Authorization: "Bearer " + accessToken,
      },
    };
  }

  return currentAxiosConfig;
};

/**
 * Returns Object with key: value pairs from array that can be safed by strapi.
 * @param {*} array
 * @param {*} positionOfKey
 * @param {*} positionOfValue
 */
export const arrayToKeyValuePairsObject = (array, keyPos, valuePos) => {
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[keyPos]]: item[valuePos],
    };
  }, {});
};

/**
 * nulls all image fields of content array for strapi
 * and collects all inputType === file files in an array and returns it
 * @param {*} content
 * @param {*} fieldName
 */
export const extractNewFilesAndRemoveFromContentArray = (
  content,
  fieldName
) => {
  const newFiles = [];
  const filesToBeNulled = [];
  if (content) {
    content.forEach((element, index) => {
      const elementObjectKeys = Object.keys(element);
      elementObjectKeys.forEach((objKey) => {
        if (element[objKey] instanceof File) {
          newFiles.push({
            propertyPath: `files.${fieldName}[${index}].${objKey}`,
            file: element[objKey],
          });
          filesToBeNulled.push({ index, objKey });
        }
        if (element[objKey] instanceof Array) {
          element[objKey].forEach((nestedElement, nestedIndex) => {
            const nestedElementObjectKeys = Object.keys(nestedElement);
            nestedElementObjectKeys.forEach((nestedObjKey) => {
              if (nestedElement[nestedObjKey] instanceof File) {
                newFiles.push({
                  propertyPath: `files.${fieldName}[${index}].${objKey}[${nestedIndex}].${nestedObjKey}`,
                  file: nestedElement[nestedObjKey],
                });
                filesToBeNulled.push({
                  index,
                  objKey,
                  nestedIndex,
                  nestedObjKey,
                });
              }
            });
          });
        }
      });
    });
  }

  filesToBeNulled.forEach((element) => {
    if (!element.nestedIndex && !element.nestedObjKey) {
      content[element.index][element.objKey] = null;
    } else {
      content[element.index][element.objKey][element.nestedIndex][
        element.nestedObjKey
      ] = null;
    }
  });

  return newFiles;
};

/**
 * add files of the newFiles array back to the data after beeing nulled for the upload. This function should be
 * used for failed uploads of files.
 * @param {*} data
 * @param {*} newFiles
 */
export const addFilesToDraftpagesContentArray = (data, newFiles) => {
  const filesPrefix = "files.";
  newFiles.forEach((element) => {
    const objectKeys = Object.keys(element);
    objectKeys.forEach((key) => {
      // console.log(element[key]);
      if (key.startsWith(filesPrefix)) {
        // part after files.
        let attributeKey = key.substring(filesPrefix.length);
        let escapedAttributeKey = convertPropertyPath(attributeKey);

        objectPath.set(data, escapedAttributeKey, element[key]);
      }
    });
  });
};

/**
 * add files of the newFiles array back to the data after beeing nulled for the upload.
 * This function should be used for failed uploads of files.
 *
 * currently used in news and events
 *
 * @param {*} data
 * @param {*} newFiles
 */
export const addFilesToContentTypeContentArray = (data, newFiles) => {
  const filesPrefix = "files.";
  newFiles.forEach((element) => {
    // part after files.
    let attributeKey = element["propertyPath"].substring(filesPrefix.length);
    let escapedAttributeKey = convertPropertyPath(attributeKey);

    objectPath.set(data, escapedAttributeKey, element["file"]);
  });
};

/**
 * takes a dynamiclist listItem as a property
 * and returns the type associated with the not null fields
 * (used in news)
 * @param {*} listItem
 * @returns listItemType
 */
export const getDynamicListItemType = (listItem) => {
  let listItemType = "";
  if (listItem.richTextContent !== null || listItem.itemType === "richtext") {
    listItemType = "richtext";
  } else if (
    (listItem.linkUrl !== null && listItem.linkText !== null) ||
    listItem.itemType === "link"
  ) {
    listItemType = "link";
  } else if (
    (listItem.imgAlt !== null && listItem.imgCaption !== null) ||
    listItem.itemType === "image"
  ) {
    listItemType = "image";
  } else if (listItem.itemType === "gallery") {
    listItemType = "gallery";
  } else if (listItem.itemType === "multimedia") {
    listItemType = "multimedia";
  }

  // else if (listItem.cfgLinkCanonical !== false && listItem.linkUrl !== null) {
  //   listItemType = "canonicalLink";
  // }

  return listItemType;
};

export const triggerNestedContentModalForCollectionTypeItem = (
  listItem,
  index,
  dispatch
) => {
  const itemType = getDynamicListItemType(listItem);

  switch (itemType) {
    case "gallery":
      return () =>
        dispatch(openCollectionTypeNestedContentModal(index, itemType));

    default:
      return null;
  }
};
export const cmsFeatureCheck = (callFunction, neededFeature) => {
  if (cmsHasFeature(neededFeature)) {
    callFunction();
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const cmsHasFeature = (neededFeature) => {
  if (process.env.NEXT_PUBLIC_CMS_FEATURES.split(",").includes(neededFeature)) {
    return true;
  }
  return false;
};

/**
 *
 * get the strapi error object (clientSide)
 *
 * e.g.:
 *  {
 *   status: 413,
 *   error: 'Request Entity Too Large',
 *   message: 'FileTooBig',
 *  }
 *
 * if the strapi error.response.data is not present this
 * function will create a temporary error object with some
 * request config information
 *
 * @param {*} error
 * @returns
 */
export const getErrorResponseObjectClientSide = (error) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error
  ) {
    return error.response.data.error;
  }
  const errorMessage = `${
    error.config && error.config.url ? ` ${error.config.url}` : ""
  } - ${error.config && error.config.method ? ` ${error.config.method}` : ""}`;
  return {
    status: -1,
    error: errorMessage,
    message: errorMessage,
  };
};

/**
 * gets the correct status from a strapi error object (clientSide)
 * otherwise it will always return status code 400
 *
 * @param {*} error
 * @returns
 */
export const getErrorResponseStatusCodeClientSide = (error) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.status
  ) {
    return error.response.data.error.status;
  }
  console.log("could not get status returning default code 400");
  return 400;
};

export const isNavLinkActive = (router, navPageObject, url) => {
  let routerAsPath = router.asPath.toLowerCase();
  if (routerAsPath.indexOf("#") > -1) {
    routerAsPath = routerAsPath.split("#")[0];
  }

  if (navPageObject && navPageObject.page && navPageObject.page.url) {
    if (navPageObject.page.url === process.env.NEXT_PUBLIC_ROOT_PAGE_URL) {
      return (
        routerAsPath === "/" + navPageObject.page.url || routerAsPath === "/"
      );
    } else {
      return routerAsPath === "/" + navPageObject.page.url;
    }
  }
  if (url) {
    if (url === process.env.NEXT_PUBLIC_ROOT_PAGE_URL) {
      return routerAsPath === "/" + url || routerAsPath === "/";
    } else {
      return routerAsPath === "/" + url;
    }
  }
  return false;
};

/**
 * Gets the route from an url
 * 127.0.0.1:3000/events -> events
 * @param {*} url
 * @returns
 */
export const getRouteFromUrl = (url) => {
  return url.slice(url.lastIndexOf("/") + 1);
};

/**
 * CopyToClipboard
 * Working: Opera, Safari, Chrome, Edge, Firefox
 * https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
 * @param {String} text
 * @returns
 */
export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(
    function () {
      // Success-Case:
      createToast({
        type: "success",
        msg: translate("cms:copyToClipboardSuccess"),
      });
    },
    function () {
      // Error-Case:
      createToast({
        type: "error",
        msg: `${text} ${translate("cms:copyToClipboardError")}`,
      });
    }
  );
};

// const cfgIsAnchorCopyToClipboard = (anchorLink) => {
//   // Copy to Clipboard
//   // https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
//   // Working: Opera, Safari, Chrome, Edge, Firefox
//   navigator.clipboard.writeText(anchorLink).then(
//     function () {
//       // Success-Case:
//       createToast({
//         type: "success",
//         msg: toastMessages().copyToClipboardSuccess,
//       });
//     },
//     function () {
//       // Error-Case:
//       createToast({
//         type: "error",
//         msg: `${toastMessages().copyToClipboardError1} ${anchorLink} ${
//           toastMessages().copyToClipboardError2
//         }`,
//       });
//     }
//   );
// };
