import * as actionTypes from "store/actionTypes/cmsPageActionTypes";

export const initCmsPageState = {
  pageEditorOpen: false,
  isEdit: false,
  existingPage: false,
  redirect: null,
  page: {
    name: "",
    url: "",
    id: null,
    isDefault: false,
    hasNavigation: true,
    hasBreadcrumbs: false,
    hasFooter: true,
    isPrivate: false,
    isPrivateDefault: false,
    seoSettings: "",
    draftId: null,
  },
  originalPage: {
    name: "",
    url: "",
    id: null,
    isDefault: false,
    hasNavigation: true,
    hasBreadcrumbs: false,
    hasFooter: true,
    isPrivate: false,
    isPrivateDefault: false,
    seoSettings: "",
    draftId: null,
  },
};

const cmsPageReducer = (state = initCmsPageState, action) => {
  switch (action.type) {
    case actionTypes.CMS_DELETE_PAGE:
      return {
        ...state,
      };
    case actionTypes.CMS_PAGE_REDIRECT:
      return {
        ...state,
        redirect: action.payload.url,
        pageEditorOpen: false,
      };
    case actionTypes.CMS_RESET_PAGE_EDITOR:
      return {
        ...initCmsPageState,
      };
    case actionTypes.CMS_LOAD_ORIGINAL_PAGE:
      return {
        ...state,
        page: state.originalPage,
      };
    case actionTypes.CMS_CLOSE_PAGE_EDITOR:
      return {
        pageEditorOpen: false,
        ...initCmsPageState,
      };
    case actionTypes.CMS_OPEN_PAGE_EDITOR:
      return {
        ...initCmsPageState,
        pageEditorOpen: true,
        isEdit: action.payload.isEdit,
      };
    case actionTypes.CMS_SAVE_PAGE:
      return {
        ...state,
      };
    case actionTypes.CMS_INIT_PAGE_EDITOR:
      // set states from existing values
      return {
        ...state,
        existingPage: true,
        isEdit: action.payload.isEdit,
        page: {
          ...action.payload.existingPage,
        },
        originalPage: {
          ...action.payload.existingPage,
        },
      };
    case actionTypes.CMS_UPDATE_PAGE:
      return {
        ...state,
        page: {
          ...state.page,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default cmsPageReducer;
