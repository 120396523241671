import React from "react";
import { useDispatch } from "react-redux";
import { setPageVisibility } from "store/actions";
import { isSSR } from "utils/util";

function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
}

function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
}

function getIsDocumentHidden() {
  if (isSSR()) {
    return true;
  } else {
    return !document[getBrowserDocumentHiddenProp()];
  }
}
/**
 * this hook sets a flag into the general redux state
 * state.general.isPageVisible
 *
 * if you want to use this:
 *
 * const isPageVisible = useSelector((state) => state.general.isPageVisible);
 */
export function usePageVisibility() {
  const dispatch = useDispatch();
  const onVisibilityChange = () =>
    dispatch(setPageVisibility(getIsDocumentHidden()));

  React.useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    document.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  });
}
