import * as actionTypes from "store/actionTypes/generalActionTypes";

/**
 * dispatch this action to show the general confirm modal
 *
 * @param {String} title modal title
 * @param {String} content modal main content
 * @param {String} acceptBtnText
 * @param {String} cancelBtnText
 * @param {Boolean} xIsCancel if
 * @returns
 */
export const showConfirmModal = ({
  title,
  content,
  contentIsRT,
  acceptBtnText,
  cancelBtnText,
  xIsCancel,
  imageType,
  className,
  acceptBtnColor,
  hideCancelBtn,
  showHideBtn,
  hideBtnText,
  canNotCloseHide,
}) => {
  return {
    type: actionTypes.SHOW_CONFIRM_MODAL,
    payload: {
      xIsCancel: xIsCancel ? xIsCancel : false,
      title: title,
      content: content,
      contentIsRT: contentIsRT,
      acceptBtnText: acceptBtnText,
      cancelBtnText: cancelBtnText,
      imageType: imageType,
      className: className,
      acceptBtnColor: acceptBtnColor,
      hideCancelBtn: hideCancelBtn ? hideCancelBtn : false,
      showHideBtn: showHideBtn ? showHideBtn : false,
      hideBtnText: hideBtnText,
      canNotCloseHide: canNotCloseHide ? canNotCloseHide : false,
    },
  };
};

export const acceptConfirmModal = () => {
  return {
    type: actionTypes.ACCEPT_CONFIRM_MODAL,
    payload: {
      action: {
        userActionPerformed: true,
        isConfirmed: true,
        isCanceled: false,
        isHidden: false,
      },
    },
  };
};

export const cancelConfirmModal = () => {
  return {
    type: actionTypes.CANCEL_CONFIRM_MODAL,
    payload: {
      action: {
        userActionPerformed: true,
        isConfirmed: false,
        isCanceled: true,
        isHidden: false,
      },
    },
  };
};

export const hideConfirmModal = () => {
  return {
    type: actionTypes.CANCEL_CONFIRM_MODAL,
    payload: {
      action: {
        userActionPerformed: true,
        isConfirmed: false,
        isCanceled: false,
        isHidden: true,
      },
    },
  };
};

export const resetConfirmModal = () => {
  return {
    type: actionTypes.RESET_CONFIRM_MODAL,
    payload: {
      action: {
        userActionPerformed: false,
        isConfirmed: false,
        isCanceled: false,
        isHidden: false,
      },
    },
  };
};

export const updateDynamicListState = (updatedDynamicList) => {
  return {
    payload: { updatedDynamicList },
    type: actionTypes.UPDATE_DLIST,
  };
};

export const addCreatedDynamicList = (newDynamicList) => {
  return {
    payload: { newDynamicList },
    type: actionTypes.ADD_CREATED_DLIST,
  };
};

export const showLoadingOverlay = (overlayMessage) => {
  return {
    payload: {
      overlayMessage,
    },
    type: actionTypes.SHOW_LOADING_OVERLAY,
  };
};

export const hideLoadingOverlay = () => {
  return {
    type: actionTypes.HIDE_LOADING_OVERLAY,
  };
};

export const setLoadingOverlayMessage = (overlayMessage) => {
  return {
    payload: {
      overlayMessage,
    },
    type: actionTypes.SET_LOADING_OVERLAY_MESSAGE,
  };
};

export const enableBeforeunload = () => {
  return {
    payload: {},
    type: actionTypes.ENABLE_BEFOREUNLOAD_EVENT,
  };
};

export const disableBeforeunload = (router) => {
  if (typeof window !== "undefined") {
    const beforeUnloadHandler = (e) => {
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    };
    window.removeEventListener("beforeunload", beforeUnloadHandler);
  }
  if (router) {
    const beforeRouteHandler = (url) => {
      if (router.pathname !== url && !confirm(confirmationMessage)) {
        router.events.emit("routeChangeError");
        throw `Route change to "${url}" was aborted (this error can be safely ignored).`;
      }
    };
    router.events.off("routeChangeStart", beforeRouteHandler);
  }
  return {
    payload: {},
    type: actionTypes.DISABLE_BEFOREUNLOAD_EVENT,
  };
};

/**
 * redirect function that can be dispatched from everywhere
 *
 * @param {*} redirectFunction mandatory e.g.: () => router.push("/")
 * @param {*} redirectUrl the redirect url is just for logging purpose
 *      in redux devtools you it is easier to see where this redirect action
 *      redirects to
 * @param {*} hideOverlay optional
 * @param {*} deferTime optional default is 500ms
 * @returns
 */
export const redirect = (
  redirectFunction,
  redirectUrl,
  hideOverlay,
  deferTime
) => {
  return async (dispatch, getState) => {
    dispatch(redirectAction(redirectUrl));
    setTimeout(
      () => {
        redirectFunction(redirectFunction());
        if (hideOverlay) {
          dispatch(hideLoadingOverlay());
        }
      },
      deferTime ? deferTime : 500
    );
  };
};

const redirectAction = (redirectUrl) => {
  return {
    payload: { redirectUrl },
    type: actionTypes.REDIRECT,
  };
};

export const setPageVisibility = (visibility) => {
  return {
    payload: visibility,
    type: actionTypes.SET_PAGE_VISIBILITY,
  };
};

export const setClipboard = (data) => {
  return {
    type: actionTypes.SET_CLIPBOARD_DATA,
    payload: data,
  };
};

export const cancelClipboard = () => {
  return {
    type: actionTypes.SET_CLIPBOARD_CANCELED,
  };
};

export const resetClipBoard = () => {
  return {
    type: actionTypes.RESET_CLIPBOARD,
  };
};

export const setShowPwaPrompt = (showPwaPrompt) => {
  return {
    type: actionTypes.SET_SHOW_PWA_PROMPT,
    payload: showPwaPrompt,
  };
};
