export const CMS_SET_EDIT_MODE = "CMS_SET_EDIT_MODE";
export const CMS_RESET_EDIT_MODE = "CMS_RESET_EDIT_MODE";
export const CMS_SET_CURRENT_PAGE = "CMS_SET_CURRENT_PAGE";
export const CMS_SET_PUBLISHED_INDICATOR = "CMS_SET_PUBLISHED_INDICATOR";
export const CMS_SET_EDIT_MODE_CONTENTPAGE = "CMS_SET_EDIT_MODE_CONTENTPAGE";
export const CMS_RESET_EDIT_MODE_CONTENTPAGE =
  "CMS_RESET_EDIT_MODE_CONTENTPAGE";
export const CMS_INIT_EDITPAGE = "CMS_INIT_EDITPAGE";
export const CMS_UPDATE_EDITPAGE_CE_FIELD = "CMS_UPDATE_EDITPAGE_CE_FIELD";
export const CMS_UPDATE_EDITPAGE_CE_MOVE_UP = "CMS_UPDATE_EDITPAGE_CE_MOVE_UP";
export const CMS_UPDATE_EDITPAGE_CE_MOVE_DOWN =
  "CMS_UPDATE_EDITPAGE_CE_MOVE_DOWN";
export const CMS_SAVE_EDITPAGE = "CMS_SAVE_EDITPAGE";
export const CMS_SAVE_EDITPAGE_SUCCESS = "CMS_SAVE_EDITPAGE_SUCCESS";
export const CMS_SAVE_EDITPAGE_FAILED = "CMS_SAVE_EDITPAGE_FAILED";
export const CMS_TOGGLE_EDIT_VIEW = "CMS_TOGGLE_EDIT_VIEW";
export const CMS_TOGGLE_AUTHENTICATED_USER_PREVIEW =
  "CMS_TOGGLE_AUTHENTICATED_USER_PREVIEW";
export const CMS_AUTHENTICATE_SUCCESS = "CMS_AUTHENTICATE_SUCCESS";
export const CMS_AUTHENTICATE_FAILED = "CMS_AUTHENTICATE_FAILED";
export const CMS_LOGOUT_SUCCESS = "CMS_LOGOUT_SUCCESS";
export const CMS_LOGOUT_FAILED = "CMS_LOGOUT_FAILED";
export const CMS_FORGOT_PASSWORD = "CMS_FORGOT_PASSWORD";
export const CMS_RESET_PASSWORD = "CMS_RESET_PASSWORD";
export const CMS_TOGGLE_VIEW_CFG_MODAL = "CMS_TOGGLE_VIEW_CFG_MODAL";
export const CMS_TOGGLE_VIEW_NESTED_CE_MODAL =
  "CMS_TOGGLE_VIEW_NESTED_CE_MODAL";
export const CMS_UPDATE_USER_SETTINGS = "CMS_UPDATE_USER_SETTINGS";
export const CMS_UPDATE_EDITPAGE_FILE_FIELD = "CMS_UPDATE_EDITPAGE_FILE_FIELD";
export const CMS_DELETE_EDITPAGE_FILE_FIELD = "CMS_DELETE_EDITPAGE_FILE_FIELD";
export const CMS_UPDATE_EDITPAGE_CE = "CMS_UPDATE_EDITPAGE_CE";
export const CMS_UPDATE_EDITPAGE_ADD_CE = "CMS_UPDATE_EDITPAGE_ADD_CE";
export const CMS_UPDATE_EDITPAGE_ADD_CE_AT_POS =
  "CMS_UPDATE_EDITPAGE_ADD_CE_AT_POS";
export const CMS_UPDATE_EDITPAGE_CLONE_CE = "CMS_UPDATE_EDITPAGE_CLONE_CE";
export const CMS_UPDATE_EDITPAGE_DELETE_CE = "CMS_UPDATE_EDITPAGE_DELETE_CE";
export const CMS_UPDATE_EDITPAGE_ADD_NESTED_CONTENT_ITEM =
  "CMS_UPDATE_EDITPAGE_ADD_NESTED_CONTENT_ITEM";
export const CMS_UPDATE_EDITPAGE_MOVE_NESTED_ITEM_UP =
  "CMS_UPDATE_EDITPAGE_MOVE_NESTED_ITEM_UP";
export const CMS_UPDATE_EDITPAGE_MOVE_NESTED_ITEM_DOWN =
  "CMS_UPDATE_EDITPAGE_MOVE_NESTED_ITEM_DOWN";
export const CMS_UPDATE_EDITPAGE_DELETE_NESTED_ITEM =
  "CMS_UPDATE_EDITPAGE_DELETE_NESTED_ITEM";
export const CMS_UPDATE_EDITPAGE_DELETE_NESTED_SUB_ITEM =
  "CMS_UPDATE_EDITPAGE_DELETE_NESTED_SUB_ITEM";
export const RESET_SAVE_REPORT_MODAL = "RESET_SAVE_REPORT_MODAL";
export const ADD_FILE_TO_SAVE_REPORT_MODAL = "ADD_FILE_TO_SAVE_REPORT_MODAL";
export const SET_SAVE_REPORT_MODAL = "SET_SAVE_REPORT_MODAL";
export const SHOW_CREATE_EDIT_COLLECTION_ITEM_MODAL =
  "SHOW_CREATE_EDIT_COLLECTION_ITEM_MODAL";
export const HIDE_CREATE_EDIT_COLLECTION_ITEM_MODAL =
  "HIDE_CREATE_EDIT_COLLECTION_ITEM_MODAL";
export const CMS_SHOW_MEDIA_SELECTION_MODAL = "CMS_SHOW_MEDIA_SELECTION_MODAL";
export const CMS_HIDE_MEDIA_SELECTION_MODAL = "CMS_HIDE_MEDIA_SELECTION_MODAL";
export const CMS_SET_CURRENT_EDIT_CONTEXT = "CMS_SET_CURRENT_EDIT_CONTEXT";
export const CMS_TOGGLE_USER_CONFIG_MODAL = "CMS_TOGGLE_USER_CONFIG_MODAL";
export const CMS_UPDATE_USER_SETTINGS_SUCCESS = "CMS_USER_SETTINGS_SUCCESS";
export const CMS_UPDATE_USER_SETTINGS_FAILED = "CMS_USER_SETTINGS_FAILED";
export const CMS_SHOW_POPOVER = "CMS_SHOW_POPOVER";
export const CMS_HIDE_POPOVER = "CMS_HIDE_POPOVER";
export const CMS_SHOW_ADD_CE_MODAL = "SHOW_ADD_CE_MODAL";
export const CMS_HIDE_ADD_CE_MODAL = "HIDE_ADD_CE_MODAL";
export const CMS_RESET_ADD_CE_MODAL = "RESET_ADD_CE_MODAL";
export const CMS_REFRESH_RENDER_KEY = "CMS_REFRESH_RENDER_KEY";
