export let globalSettings = null;

export const setGlobalSettings = (gSettings) => {
  if (gSettings) {
    globalSettings = gSettings;
    // console.log("global settings set.");
  } else {
    console.log(
      "using template/theme default values! - fetched template is null or empty."
    );
  }
};
