import { getStrapiURL } from "utils/api";
import { PAGE_TYPE_EVENT } from "utils/constants";

import {
  axiosServerGetRequest,
  getCmsAccessToken,
  handleServerRequestError,
} from "utils/serverUtil";

/**
 * gets alls Categories unless filtered with the type param
 * @param {request} req
 * @param {string} type filters the categories to contain all categories with the specified type
 * @param {boolean} returnAsResult
 * @param {string} locale
 * @returns
 */
export const getAllCategories = async (req, returnAsResult, locale) => {
  if (!locale) {
    locale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;
  }
  const result = await axiosServerGetRequest(getStrapiURL("/categories"), {
    cmsaccesstoken: getCmsAccessToken(req),
  });
  if (result.success) {
    if (returnAsResult) {
      return result;
    }
    return result.response.data;
  } else {
    handleServerRequestError(
      result.error,
      "could not get categories [categoriesServerService getAllCategories]"
    );
    if (returnAsResult) {
      return result;
    }
    return [];
  }
};

export const getAllEventCategories = async (req, returnAsResult) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/categories?type=${PAGE_TYPE_EVENT}`),
    {
      cmsaccesstoken: getCmsAccessToken(req),
    }
  );
  if (result.success) {
    if (returnAsResult) {
      return result;
    }
    return result.response.data;
  } else {
    handleServerRequestError(
      result.error,
      "could not get categories [categoriesServerService getAllCategories]"
    );
    if (returnAsResult) {
      return result;
    }
    return [];
  }
};
