export const CMS_CREATE_TAG = "CMS_CREATE_TAG";
export const CMS_UPDATE_TAG = "CMS_UPDATE_TAG";
export const CMS_CREATE_TAG_START = "CMS_CREATE_TAG_START";
export const CMS_CREATE_TAG_SUCCESS = "CMS_CREATE_TAG_SUCCESS";
export const CMS_CREATE_TAG_FAILED = "CMS_CREATE_TAG_FAILED";
export const CMS_UPDATE_TAG_START = "CMS_UPDATE_TAG_START";
export const CMS_UPDATE_TAG_SUCCESS = "CMS_UPDATE_TAG_SUCCESS";
export const CMS_UPDATE_TAG_FAILED = "CMS_UPDATE_TAG_FAILED";
export const CMS_OPEN_TAG_EDITOR = "CMS_OPEN_TAG_EDITOR";
export const CMS_RESET_TAG_EDITOR = "CMS_RESET_TAG_EDITOR";
export const CMS_EDIT_TAG_FIELD = "CMS_EDIT_TAG_FIELD";
export const CMS_SEND_DELETE_TAG = "CMS_SEND_DELETE_TAG";
export const CMS_DELETE_TAG_START = "CMS_DELETE_TAG_START";
export const CMS_DELETE_TAG_SUCCESS = "CMS_DELETE_TAG_SUCCESS";
export const CMS_DELETE_TAG_FAILED = "CMS_DELETE_TAG_FAILED";
