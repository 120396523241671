import { getStrapiURL } from "utils/api";
import { CMS_FEATURE_EVENTS } from "utils/constants";
import {
  axiosServerGetRequest,
  cmsHasFeatureServerSide,
  getCmsAccessToken,
  handleServerRequestError,
} from "utils/serverUtil";
// get only published events
export const getEvent = async (eventUrl, locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/events/get/${eventUrl}?_locale=${locale}`)
  );
  if (result.success) {
    console.log(`fetched event with url ${eventUrl} with locale ${locale}`);
  } else {
    console.log(
      `could not fetch event with url ${eventUrl} with locale ${locale}`
    );
  }
  return result;
};

// get unpublished & published event
export const getCmsEvent = async (eventUrl, req, locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/events/cms-get/${eventUrl}?_locale=${locale}`),
    { cmsaccesstoken: getCmsAccessToken(req) }
  );
  if (result.success) {
    console.log(`fetched event with url ${eventUrl} with locale ${locale}`);
  } else {
    console.log(
      `could not fetch event with url ${eventUrl} with locale ${locale}`
    );
  }
  return result;
};

export const getAllEvents = async (req, returnAsResult, locale) => {
  // check the feature here again, to prevent the error log
  // this is only needed for _app.js - other event server service
  // functions shouldnt need this
  if (cmsHasFeatureServerSide(CMS_FEATURE_EVENTS)) {
    if (!locale) {
      locale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;
    }
    const result = await axiosServerGetRequest(
      getStrapiURL(`/events?_locale=${locale}`),
      {
        cmsaccesstoken: getCmsAccessToken(req),
      }
    );
    if (result.success) {
      if (returnAsResult) {
        return result;
      }
      return result.response.data;
    } else {
      handleServerRequestError(
        result.error,
        "could not get events [eventServerService getAllEvents]"
      );
      if (returnAsResult) {
        return result;
      }
      return [];
    }
  }
  if (returnAsResult) {
    return { success: false, error: null };
  }
  return [];
};

/**
 *
 * @param {string} locale
 * @param {string} sort e.g. "dateTimeStart:ASC" using this overrides the default "updateDate:DESC"
 * @returns
 */
export const getAllPublishedEventsServerSide = async (locale, sort) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(
      `/events/published?_locale=${locale}${sort ? `&_sort=${sort}` : ""}`
    )
  );
  if (!result.success) {
    handleServerRequestError(
      result.error,
      "could not get published events [eventServerService getAllPublishedEvents]"
    );
  }
  return result;
};

/**
 *
 * @param {string} locale
 * @param {string} sort e.g. "dateTimeStart:ASC" using this overrides the default "updateDate:DESC"
 * @returns
 */
export const getAllPublishedIncludingPrivateEventsServerSide = async (
  locale,
  sort
) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(
      `/events/published?_locale=${locale}${
        sort ? `&_sort=${sort}` : ""
      }&includePrivate=true`
    )
  );
  if (!result.success) {
    handleServerRequestError(
      result.error,
      "could not get published events [eventServerService getAllPublishedEvents]"
    );
  }
  return result;
};
