import * as actionTypes from "store/actionTypes/cmsUserManagementActionTypes";

export const initCmsUserManagementState = {
  editUser: null,
};

const cmsUserManagementReducer = (
  state = initCmsUserManagementState,
  action
) => {
  switch (action.type) {
    case actionTypes.CMS_RESET_USER_EDITOR:
      return {
        ...initCmsUserManagementState,
      };
    case actionTypes.CMS_INIT_USER_EDITOR_CREATE:
      return {
        ...state,
        editUser: {
          email: null,
          firstName: null,
          lastName: null,
          password: null,
          isEnabled: true,
          isEmailVerified: false, // set to false because of TRM-116
          isFirstLogin: true,
          // gender: null,
          // birthday: null,
          // roles: null,
          // image: {
          //   imageBase64: null,
          // },
          // data: { data1: null, data2: null, data3: null },
        },
      };
    case actionTypes.CMS_EDIT_USER_FIELD:
      return {
        ...state,
        editUser: {
          ...state.editUser,
          [action.payload.field]: action.payload.value,
        },
      };

    case actionTypes.CMS_EDIT_USER_IMAGE_FIELD:
      return {
        ...state,
        editUser: {
          ...state.editUser,
          image: {
            ...state.editUser.image,
            [action.payload.field]: action.payload.value,
          },
        },
      };

    case actionTypes.CMS_EDIT_USER_DATA_FIELD:
      return {
        ...state,
        editUser: {
          ...state.editUser,
          data: {
            ...state.editUser.data,
            [action.payload.field]: action.payload.value,
          },
        },
      };

    case actionTypes.CMS_INIT_USER_EDITOR:
      return {
        ...state,
        editUser: action.payload.user,
      };

    case actionTypes.CMS_CREATE_USER_FAILED:
    case actionTypes.CMS_UPDATE_USER_FAILED:
    case actionTypes.CMS_DELETE_USER_FAILED:
      return state;
    case actionTypes.CMS_CREATE_USER_SUCCESS:
    case actionTypes.CMS_UPDATE_USER_SUCCESS:
    case actionTypes.CMS_DELETE_USER_SUCCESS:
      return {
        ...initCmsUserManagementState,
      };
    default:
      return state;
  }
};

export default cmsUserManagementReducer;
