export const CMS_RESET_NAV_EDITOR = "CMS_RESET_NAV_EDITOR";
export const CMS_OPEN_NAV_EDITOR = "CMS_OPEN_NAV_EDITOR";
export const CMS_INIT_NAV_EDITOR_CREATE = "CMS_INIT_NAV_EDITOR_CREATE";
export const CMS_INIT_NAV_EDITOR = "CMS_INIT_NAV_EDITOR";
export const CMS_NAV_DOES_NOT_EXIST = "CMS_NAV_DOES_NOT_EXIST";
export const CMS_ADD_NAV_ITEM = "CMS_ADD_NAV_ITEM";
export const CMS_EDIT_NAV_NAME = "CMS_EDIT_NAV_NAME";
export const CMS_EDIT_NAV_ITEM = "CMS_EDIT_NAV_ITEM";
export const CMS_DELETE_NAV_ITEM = "CMS_DELETE_NAV_ITEM";
export const CMS_EDIT_NAV_LOGO_ITEM = "CMS_EDIT_NAV_LOGO_ITEM";
export const CMS_EDIT_NAV_FIELD = "CMS_EDIT_NAV_FIELD";
export const CMS_MOVE_NAV_ITEM_UP = "CMS_MOVE_NAV_ITEM_UP";
export const CMS_MOVE_NAV_ITEM_DOWN = "CMS_MOVE_NAV_ITEM_DOWN";
export const CMS_MOVE_NAV_ITEM_LAYER_UP = "CMS_MOVE_NAV_ITEM_LAYER_UP";
export const CMS_MOVE_NAV_ITEM_LAYER_DOWN = "CMS_MOVE_NAV_ITEM_LAYER_DOWN";
export const CMS_UPDATE_NAV = "CMS_UPDATE_NAV";
export const CMS_CREATE_NAV_FAILED = "CMS_CREATE_NAV_FAILED";
export const CMS_CREATE_NAV_SUCCESS = "CMS_CREATE_NAV_SUCCESS";
export const CMS_DELETE_NAV_FAILED = "CMS_DELETE_NAV_FAILED";
export const CMS_DELETE_NAV_SUCCESS = "CMS_DELETE_NAV_SUCCESS";
export const CMS_UPDATE_NAV_FAILED = "CMS_UPDATE_NAV_FAILED";
export const CMS_UPDATE_NAV_SUCCESS = "CMS_UPDATE_NAV_SUCCESS";
export const CMS_NAV_EDITOR_RESET_CHANGES = "CMS_NAV_EDITOR_RESET_CHANGES";
export const CMS_TOGGLE_NAV_ITEM_EDIT = "CMS_TOGGLE_NAV_ITEM_EDIT";
