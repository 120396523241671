import {
  createEmptyFormField,
  createForm,
  deleteForm,
  getForm,
  getFormCreateValues,
  updateForm,
} from "services/cms/cmsFormService";
import * as actionTypes from "store/actionTypes/cmsFromActionTypes";
import { translate } from "utils/util";
import {
  disableBeforeunload,
  hideLoadingOverlay,
  redirect,
  showLoadingOverlay,
} from "./generalActionCreator";

export const resetFormEditor = () => {
  return {
    type: actionTypes.CMS_RESET_FORM_EDITOR,
  };
};

export const getFormCreateValuesAndInitFormEditorCreate = () => {
  return async (dispatch) => {
    const result = await getFormCreateValues();
    if (result.success) {
      dispatch(initFormEditorCreate(result.response.data));
    } else {
      console.log("could get form editor options!");
      console.log(result.error);
    }
  };
};

export const initFormEditorCreate = (formCreateValues) => {
  return {
    payload: formCreateValues,
    type: actionTypes.CMS_INIT_FORM_EDITOR_CREATE,
  };
};

// export const initFormEditorEdit = (navigationId) => {
//   return async (dispatch) => {
//     const result = await getNavigationById(navigationId);
//     if (result.success) {
//       dispatch(initNavEditor(result.response.data));
//     } else {
//       if (result.response?.status && result.response.status === 404) {
//         dispatch(navigationDoesNotExist());
//       } else {
//         console.log("could not initialize navigation editor!");
//         console.log(result.error);
//       }
//     }
//   };
// };

export const openFormEditor = (formId, formName) => {
  return {
    payload: {
      editFormId: formId,
      formName: formName,
    },
    type: actionTypes.CMS_OPEN_FORM_EDITOR,
  };
};

/**
 *
 * @param {*} router optional - in the currently not used modal this parameter is not needed
 * @returns
 */
export const sendCreateForm = (router) => {
  return async (dispatch, getState) => {
    dispatch(showLoadingOverlay(translate("cms:creatingForm")));
    const editForm = getState().cmsForm.editForm;
    const currentUser = getState().cms.cmsUser.username;
    const result = await createForm(editForm, currentUser);
    if (result.success) {
      if (router) {
        dispatch(disableBeforeunload());
        dispatch(redirect(() => router.push("/cms/forms"), "/cms/forms"));
      }
    } else {
      dispatch(hideLoadingOverlay());
      console.log("could not initialize form editor!");
      console.log(result.error);
    }
  };
};

/**
 *
 * @param {*} formId
 * @param {*} router optional - in the currently not used modal this parameter is not needed
 * @returns
 */
export const getFormAndInitEdit = (formId, router) => {
  return async (dispatch) => {
    const result = await getForm(formId);
    if (result.success) {
      dispatch(initFormEditor(result.response.data));
    } else {
      if (router) {
        dispatch(showLoadingOverlay());
        dispatch(disableBeforeunload());
        dispatch(redirect(() => router.push("/cms/forms"), "/cms/forms"));
      }
      console.log("could not initialize form editor!");
      console.log(result.error);
    }
  };
};

const initFormEditor = (responseData) => {
  return {
    payload: {
      form: responseData.form,
      availableFormFields: responseData.availableFormFields,
      availableFormEmailTemplates: responseData.availableFormEmailTemplates,
    },
    type: actionTypes.CMS_INIT_FORM_EDITOR,
  };
};

export const editFormField = (field, value, position) => {
  return {
    payload: { field, value, position },
    type: actionTypes.CMS_EDIT_FORM_FIELD,
  };
};

export const sendDeleteForm = (formId) => {
  return async (dispatch) => {
    dispatch(beginDeleteFormAction());
    const result = await deleteForm(formId);
    if (result.success) {
      dispatch(deleteFormSuccess());
    } else {
      dispatch(deleteFormFailed());
      console.log(`could not delete form with id ${formId}`);
      console.log(result.error);
    }
  };
};

export const beginDeleteFormAction = () => {
  return {
    payload: {},
    type: actionTypes.CMS_DELETE_FORM,
  };
};

export const deleteFormSuccess = () => {
  return {
    payload: {},
    type: actionTypes.CMS_DELETE_FORM_SUCCESS,
  };
};

export const deleteFormFailed = () => {
  return {
    payload: {},
    type: actionTypes.CMS_DELETE_FORM_FAILED,
  };
};

/**
 *
 * @param {*} router optional - in the currently not used modal this parameter is not needed
 * @returns
 */
export const saveForm = (router, callbackFunction) => {
  return async (dispatch, getState) => {
    dispatch(showLoadingOverlay(translate("cms:updatingForm")));
    const result = await updateForm(getState().cmsForm.editForm);
    if (result.success) {
      console.log("updated form");
      dispatch(saveFormSuccess());
      if (callbackFunction) {
        dispatch(disableBeforeunload());
        callbackFunction({ status: "success" });
      } else if (router) {
        dispatch(disableBeforeunload());
        dispatch(redirect(() => router.push("/cms/forms"), "cms/forms"));
      } else {
        dispatch(hideLoadingOverlay());
      }
    } else {
      dispatch(hideLoadingOverlay());
      dispatch(saveFormFailed());
      console.log("failed to update form", result.error);
      if (callbackFunction) {
        callbackFunction({ status: "error" });
      }
    }
  };
};

export const saveFormSuccess = () => {
  return {
    payload: {},
    type: actionTypes.CMS_SAVE_FORM_SUCCESS,
  };
};

export const saveFormFailed = () => {
  return {
    payload: {},
    type: actionTypes.CMS_SAVE_FORM_FAILED,
  };
};

export const addFormField = (formFieldAttributes) => {
  return {
    payload: { newFormField: createEmptyFormField(formFieldAttributes) },
    type: actionTypes.CMS_ADD_FORMFIELD,
  };
};

export const deleteFormField = (position) => {
  return {
    payload: { position },
    type: actionTypes.CMS_DELETE_FORMFIELD,
  };
};

export const moveFormFieldUp = (position) => {
  return {
    payload: { direction: "up", position },
    type: actionTypes.CMS_FORMFIELD_MOVE_UP,
  };
};

export const moveFormFieldDown = (position) => {
  return {
    payload: { direction: "down", position },
    type: actionTypes.CMS_FORMFIELD_MOVE_DOWN,
  };
};

export const addFormFieldOption = (
  newFormFieldOption,
  fieldName,
  formFieldPos
) => {
  return {
    payload: { newFormFieldOption, fieldName, formFieldPos },
    type: actionTypes.CMS_ADD_FORM_FIELD_OPTION,
  };
};

export const deleteFormFieldOption = (position, fieldName, formFieldPos) => {
  return {
    payload: { position, fieldName, formFieldPos },
    type: actionTypes.CMS_DELETE_FORM_FIELD_OPTION,
  };
};

export const moveFormFieldOptionUp = (position, fieldName, formFieldPos) => {
  return {
    payload: { direction: "up", position, fieldName, formFieldPos },
    type: actionTypes.CMS_DELETE_FORM_FIELD_OPTION_MOVE_UP,
  };
};

export const moveFormFieldOptionDown = (position, fieldName, formFieldPos) => {
  return {
    payload: { direction: "down", position, fieldName, formFieldPos },
    type: actionTypes.CMS_DELETE_FORM_FIELD_OPTION_MOVE_DOWN,
  };
};

export const editFormFieldOption = (
  optionsFieldName,
  value,
  position,
  fieldName,
  formFieldPos
) => {
  return {
    payload: {
      optionsFieldName,
      value,
      position,
      fieldName,
      formFieldPos,
    },
    type: actionTypes.CMS_EDIT_FORM_FIELD_OPTION,
  };
};
