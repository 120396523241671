import { useTranslation } from "next-i18next";
import { loadingSpinnerStyle } from "./loadingSpinnerStyle";

const LoadingSpinner = (props) => {
  const style = loadingSpinnerStyle();
  const { t: tCms } = useTranslation("cms");
  return (
    <>
      <div className="loading-spinner">{tCms("loading")}</div>
      {/* fixed style */}
      <style jsx>{style}</style>
      {/* dynamic style */}
      <style jsx>{``}</style>
    </>
  );
};

export default LoadingSpinner;
