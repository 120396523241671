export let globalCss = "";

export const setGlobalCss = (globalMinCss) => {
  if (globalMinCss) {
    globalCss = globalMinCss;
  } else {
    console.log("no global css fetched - global css is empty.");
  }
};

/**
 * global css fix look into csrStyleFix.js
 */
export let globalCssFallback = false;

export const setGlobalCssFallback = (value) => {
  globalCssFallback = value;
};