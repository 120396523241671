import * as actionTypes from "store/actionTypes/cmsJobofferActionTypes";
import { getRandomSlug } from "utils/util";

export const initCmsJobofferState = {
  editJoboffer: null,
  editJobofferId: null,
  originalJoboffer: null,
  jobofferUrl: null,
};

const cmsJobofferReducer = (state = initCmsJobofferState, action) => {
  switch (action.type) {
    case actionTypes.CMS_INIT_JOBOFFER_CREATE: {
      return {
        ...state,
        editJoboffer: {
          title: "",
          canonicalLink: false,
          canonicalLinkUrl: null,
          seoSettings: "",
          isPrivate: false,
          structuredData: null,
        },
      };
    }
    case actionTypes.CMS_INIT_JOBOFFER_EDIT: {
      return {
        ...state,
        editJoboffer: action.payload,
        editJobofferId: action.payload.id,
        originalJoboffer: action.payload,
        jobofferUrl: action.payload.url,
      };
    }
    case actionTypes.CMS_RESET_JOBOFFER_EDIT: {
      return {
        ...state,
        editJoboffer: null,
        editJobofferId: null,
        originalJoboffer: null,
        jobofferUrl: null,
      };
    }
    case actionTypes.CMS_DISCARD_JOBOFFER_EDIT_MODAL_CHANGES: {
      return {
        ...state,
        editJoboffer: {
          ...state.editJoboffer,
          canonicalLink: state.originalJoboffer.canonicalLink,
          canonicalLinkUrl: state.originalJoboffer.canonicalLinkUrl,
          seoSettings: state.originalJoboffer.seoSettings,
          url: state.originalJoboffer.url,
          urlXing: state.originalJoboffer.urlXing,
          urlLinkedIn: state.originalJoboffer.urlLinkedIn,
          imgTitle: state.originalJoboffer.imgTitle,
          richTextPreview: state.originalJoboffer.richTextPreview,
        },
      };
    }
    case actionTypes.CMS_EDIT_JOBOFFER_FIELD: {
      const { value, field, position } = action.payload;
      // if position is undefined the field is direct property
      // and not a nested array
      if (typeof position === "undefined") {
        return {
          ...state,
          editJoboffer: {
            ...state.editJoboffer,
            [field]: value,
          },
        };
      } else {
        return {
          ...state,
          editJoboffer: {
            ...state.editJoboffer,
            jobCards: state.editJoboffer.jobCards.map((element, index) =>
              position === index ? { ...element, [field]: value } : element
            ),
          },
        };
      }
    }
    case actionTypes.CMS_ADD_JOBOFFER_JOBCARD: {
      const { type } = action.payload;

      let newJobCard = {
        __component: "jobCard",
        __new_id: getRandomSlug(),
        managedFile: null,
        imgAltText: null,
        text: null,
        cfgSettings: {
          backgroundColor: {
            backgroundColorValue: "default",
            backgroundColorOptions: ["default", "primary", "secondary"],
          },
        },
      };

      return {
        ...state,
        editJoboffer: {
          ...state.editJoboffer,
          jobCards: [...state.editJoboffer.jobCards, newJobCard],
        },
      };
    }

    case actionTypes.CMS_EDIT_JOBOFFER_JOBCARD: {
      const { position, field, value } = action.payload;

      return {
        ...state,
        editJoboffer: {
          ...state.editJoboffer,
          jobCards: state.editJoboffer.jobCards.map((jobCard, index) =>
            position === index
              ? {
                  ...jobCard,
                  [field]: value,
                }
              : jobCard
          ),
        },
      };
    }

    case actionTypes.CMS_DELETE_JOBOFFER_JOBCARD: {
      const { position } = action.payload;

      return {
        ...state,
        editJoboffer: {
          ...state.editJoboffer,
          jobCards: state.editJoboffer.jobCards.filter(
            (element, index) => position !== index
          ),
        },
      };
    }

    case actionTypes.CMS_MOVE_JOBOFFER_CONTENT_LIST_ITEM_UP:
    case actionTypes.CMS_MOVE_JOBOFFER_CONTENT_LIST_ITEM_DOWN: {
      const { direction, position } = action.payload;

      let updatedContentArray = null;
      let newPos = null;
      let movedElement = null;

      newPos = direction === "up" ? position - 1 : position + 1;

      // check if the new position is in array boundaries
      if (newPos < 0 || newPos > state.editJoboffer.jobCards.length - 1) {
        return state;
      }

      updatedContentArray = [...state.editJoboffer.jobCards];
      movedElement = updatedContentArray[position];
      updatedContentArray[position] = updatedContentArray[newPos];
      updatedContentArray[newPos] = movedElement;
      return {
        ...state,
        editJoboffer: {
          ...state.editJoboffer,
          jobCards: updatedContentArray,
        },
      };
    }

    case actionTypes.CMS_DELETE_JOBOFFER_FORM: {
      const { formId } = action.payload;

      return {
        ...state,
        editJoboffer: {
          ...state.editJoboffer,
          form: null,
        },
      };
    }
    case actionTypes.CMS_CREATE_JOBOFFER_SUCCESS: {
      return state;
    }
    case actionTypes.CMS_CREATE_JOBOFFER_FAILED: {
      return state;
    }
    case actionTypes.CMS_UPDATE_JOBOFFER_SUCCESS: {
      return {
        ...state,
        originalJoboffer: action.payload,
        editJoboffer: action.payload,
      };
    }
    case actionTypes.CMS_UPDATE_JOBOFFER_FAILED: {
      return state;
    }
    case actionTypes.CMS_JOBOFFER_VALIDATION_FAILED: {
      return state;
    }

    default:
      return state;
  }
};

export default cmsJobofferReducer;
