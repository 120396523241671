import {
  userForgotPassword,
  userLogin,
  userLogout,
  userRefreshToken,
  userSetPassword,
} from "services/authentication/userClientAuthenticationService";
import { getPrivateDefaultPage } from "services/page/pageService";
import * as actionTypes from "store/actionTypes/userActionTypes";
import { redirect } from "store/actions";
import { USER_LOGIN_URL } from "utils/constants";
import { triggerGetServerSideProps } from "utils/util";

export const userAuthenticateAction = (
  username,
  password,
  router,
  routeAfterSuccesfulLogin,
  twoFACode = null
) => {
  return async (dispatch) => {
    const result = await userLogin(username, password, twoFACode);
    if (result.success) {
      if (result.response.data.waitingForTwoFactor) {
        dispatch(userAuthenticateWaitingForTwoFactor());
      } else {
        dispatch(userAuthenticateSuccess(result.response.data));
        //TODO: check if twofa enabled
        if (routeAfterSuccesfulLogin && router) {
          router.push(routeAfterSuccesfulLogin);
        } else {
          const privateDefaultPage = await getPrivateDefaultPage();
          if (privateDefaultPage.success && router) {
            router.push(`/${privateDefaultPage.response.data.url}`);
          } else {
            if (router) {
              router.push("/");
            }
          }
        }
      }
    } else {
      dispatch(userAuthenticateFailed(result.error));
    }
  };
};

export const userAuthenticateWaitingForTwoFactor = () => {
  return {
    type: actionTypes.USER_AUTHENTICATE_WAITING_FOR_2FA,
  };
};

export const userAuthenticateSuccess = (responseData) => {
  return {
    payload: {
      user: responseData.user,
      expirationDate: responseData.expirationDate,
    },
    type: actionTypes.USER_AUTHENTICATE_SUCCESS,
  };
};

export const userAuthenticateFailed = (response) => {
  return {
    payload: response,
    type: actionTypes.USER_AUTHENTICATE_FAILED,
  };
};

export const userAuthenticateRefreshAction = (
  isInitial = false,
  router = null
) => {
  return async (dispatch) => {
    const result = await userRefreshToken();
    if (result.response.status === 200) {
      if (router && isInitial) {
        console.log("is initial token refresh");
        dispatch(userRefreshTokenSuccess(result.response.data, isInitial));
        triggerGetServerSideProps(router);
      } else {
        console.log("subsequent token refresh");
        dispatch(userRefreshTokenSuccess(result.response.data));
      }
    } else {
      dispatch(userRefreshTokenFailed(result.error));
      if (router) {
        router.push(USER_LOGIN_URL);
      }
    }
  };
};

export const userRefreshTokenSuccess = (responseData, isInitial) => {
  return {
    payload: {
      isInitial: isInitial,
      user: responseData.user,
      expirationDate: responseData.expirationDate,
    },
    type: actionTypes.USER_REFRESH_TOKEN_SUCCESS,
  };
};

export const userRefreshTokenFailed = (response) => {
  return {
    type: actionTypes.USER_REFRESH_TOKEN_FAILED,
  };
};

export const userLogoutAction = (deleteAllTokens, router) => {
  return async (dispatch) => {
    const userLogoutReponse = await userLogout({
      deleteAllTokens,
    });
    if (userLogoutReponse.success) {
      dispatch(userLogoutSuccess());
      if (router) {
        dispatch(redirect(() => router.push("/"), "/"));
      }
    } else {
      dispatch(userLogoutFailed());
    }
  };
};

export const userLogoutSuccess = (response) => {
  return {
    payload: response,
    type: actionTypes.USER_LOGOUT_SUCCESS,
  };
};

export const userLogoutFailed = (response) => {
  return {
    payload: response,
    type: actionTypes.USER_LOGOUT_FAILED,
  };
};

export const userForgotPasswordAction = (email, captchaResponse) => {
  return async (dispatch) => {
    const result = await userForgotPassword(email, captchaResponse);
    if (result.success) {
      dispatch(userForgotPasswordSuccess());
    } else {
      dispatch(userForgotPasswordFailed());
    }
  };
};

export const userForgotPasswordSuccess = () => {
  return {
    type: actionTypes.USER_FORGOT_PASSWORD_SUCCESS,
  };
};

export const userForgotPasswordFailed = () => {
  return {
    type: actionTypes.USER_FORGOT_PASSWORD_FAILED,
  };
};

export const userSetPasswordAction = (password1, password2, router, token) => {
  return async (dispatch) => {
    const result = await userSetPassword(password1, password2, token);
    if (result.success) {
      dispatch(userSetPasswordSuccess());
      // We can't securely verify a 2FA Code after setting a password from the set-password page.
      // Therefore, disable silent login after setPassword if 2FA is enabled and redirect to login page instead.
      // Additionally, if it's been the initial password-set (from welcome mail), log in silently anyways. The 2FA will be verified by the twoFactorInitModal.
      if (
        result.response.data.isWelcome ||
        !result.response.data.twoFactorEnabled
      ) {
        dispatch(
          userAuthenticateAction(result.response.data.email, password1, router)
        );
      } else {
        router.push(USER_LOGIN_URL);
      }
    } else {
      dispatch(userSetPasswordFailed());
    }
  };
};

export const userSetPasswordSuccess = () => {
  return {
    type: actionTypes.USER_SET_PASSWORD_SUCCESS,
  };
};

export const userSetPasswordFailed = () => {
  return {
    type: actionTypes.USER_SET_PASSWORD_FAILED,
  };
};