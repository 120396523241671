import * as actionTypes from "store/actionTypes/cmsFromActionTypes";
import { translate } from "utils/util";

export const initCmsFormState = {
  formEditorOpen: false,
  formName: "",
  editFormId: null,
  editForm: null,
  originalForm: null,
  availableFormFields: [],
  availableFormEmailTemplates: [],
  refetchFormContentElements: null,
};

const cmsFormReducer = (state = initCmsFormState, action) => {
  switch (action.type) {
    case actionTypes.CMS_RESET_FORM_EDITOR:
      return {
        ...state,
        formEditorOpen: false,
        formName: "",
        editFormId: null,
        editForm: null,
        originalForm: null,
        availableFormFields: [],
        availableFormEmailTemplates: [],
      };
    case actionTypes.CMS_INIT_FORM_EDITOR_CREATE:
      return {
        ...state,
        editForm: {
          ...state.editForm,
          name: "",
          title: "",
          mailRecipients: "",
          mailSubject: "",
          cfgFormSubmitButtonText: translate("public:sendRequest"),
          cfgWithCaptcha: true,
          cfgMailTemplate: "unstyled",
          fields: [],
        },
        originalForm: {
          ...state.editForm,
          name: "",
          title: "",
          mailRecipients: "",
          mailSubject: "",
          cfgFormSubmitButtonText: translate("public:sendRequest"),
          cfgWithCaptcha: true,
          cfgMailTemplate: "unstyled",
          fields: [],
        },
        availableFormFields: action.payload.availableFormFields,
        availableFormEmailTemplates: action.payload.availableFormEmailTemplates,
      };
    case actionTypes.CMS_OPEN_FORM_EDITOR:
      return {
        ...state,
        formEditorOpen: true,
        editFormId: action.payload.editFormId,
        formName: action.payload.formName,
      };
    case actionTypes.CMS_INIT_FORM_EDITOR:
      return {
        ...state,
        formName: action.payload.form.title,
        editForm: action.payload.form,
        originalForm: action.payload.form,
        availableFormFields: action.payload.availableFormFields,
        availableFormEmailTemplates: action.payload.availableFormEmailTemplates,
      };
    case actionTypes.CMS_DELETE_FORM:
      return state;
    case actionTypes.CMS_DELETE_FORM_SUCCESS:
      return {
        ...state,
        formEditorOpen: false,
        formName: "",
        editFormId: null,
        editForm: null,
        originalForm: null,
        availableFormFields: [],
        availableFormEmailTemplates: [],
        refetchFormContentElements: new Date().getTime(),
      };
    case actionTypes.CMS_DELETE_FORM_FAILED:
      return state;
    case actionTypes.CMS_SAVE_FORM_SUCCESS:
      return {
        ...state,
        formEditorOpen: false,
        formName: "",
        editFormId: null,
        editForm: null,
        originalForm: null,
        availableFormFields: [],
        availableFormEmailTemplates: [],
        refetchFormContentElements: new Date().getTime(),
      };
    case actionTypes.CMS_SAVE_FORM_FAILED:
      return state;
    case actionTypes.CMS_ADD_FORMFIELD:
      const { newFormField } = action.payload;

      return {
        ...state,
        editForm: {
          ...state.editForm,
          fields: [...state.editForm.fields, newFormField],
        },
      };
    case actionTypes.CMS_DELETE_FORMFIELD: {
      const { position } = action.payload;
      return {
        ...state,
        editForm: {
          ...state.editForm,
          fields: state.editForm.fields.filter(
            (element, index) => index !== position
          ),
        },
      };
    }
    case actionTypes.CMS_EDIT_FORM_FIELD: {
      const { value, field, position } = action.payload;
      // if position is undefined the field is direct property
      // and not a nested array
      if (typeof position === "undefined") {
        return {
          ...state,
          editForm: {
            ...state.editForm,
            [field]: value,
          },
        };
      } else {
        return {
          ...state,
          editForm: {
            ...state.editForm,
            fields: state.editForm.fields.map((element, index) =>
              position === index ? { ...element, [field]: value } : element
            ),
          },
        };
      }
    }
    case actionTypes.CMS_FORMFIELD_MOVE_UP:
    case actionTypes.CMS_FORMFIELD_MOVE_DOWN: {
      const { direction, position } = action.payload;

      let updatedFieldsArray = null;
      let newPos = null;
      let movedElement = null;

      newPos = direction === "up" ? position - 1 : position + 1;

      // check if the new position is in array boundaries
      if (newPos < 0 || newPos > state.editForm.fields.length - 1) {
        return state;
      }

      updatedFieldsArray = [...state.editForm.fields];
      movedElement = updatedFieldsArray[position];
      updatedFieldsArray[position] = updatedFieldsArray[newPos];
      updatedFieldsArray[newPos] = movedElement;
      return {
        ...state,
        editForm: {
          ...state.editForm,
          fields: updatedFieldsArray,
        },
      };
    }
    case actionTypes.CMS_ADD_FORM_FIELD_OPTION:
      const { newFormFieldOption, fieldName, formFieldPos } = action.payload;

      return {
        ...state,
        editForm: {
          ...state.editForm,
          fields: state.editForm.fields.map((element, index) => {
            if (formFieldPos === index) {
              return {
                ...element,
                [fieldName]: [...element[fieldName], newFormFieldOption],
              };
            } else {
              return element;
            }
          }),
        },
      };
    case actionTypes.CMS_DELETE_FORM_FIELD_OPTION: {
      const { position, fieldName, formFieldPos } = action.payload;

      return {
        ...state,
        editForm: {
          ...state.editForm,
          fields: state.editForm.fields.map((element, index) => {
            if (formFieldPos === index) {
              return {
                ...element,
                [fieldName]: element[fieldName].filter(
                  (element, index) => index !== position
                ),
              };
            } else {
              return element;
            }
          }),
        },
      };
    }
    case actionTypes.CMS_EDIT_FORM_FIELD_OPTION: {
      const { optionsFieldName, value, position, fieldName, formFieldPos } =
        action.payload;

      // console.log(state.editForm.fields[formFieldPos][fieldName][position]);

      return {
        ...state,
        editForm: {
          ...state.editForm,
          fields: state.editForm.fields.map((element, index) => {
            if (formFieldPos === index) {
              return {
                ...element,
                [fieldName]: element[fieldName].map((element, index) => {
                  if (index === position) {
                    return { ...element, [optionsFieldName]: value };
                  } else {
                    return element;
                  }
                }),
              };
            } else {
              return element;
            }
          }),
        },
      };
    }
    case actionTypes.CMS_DELETE_FORM_FIELD_OPTION_MOVE_UP:
    case actionTypes.CMS_DELETE_FORM_FIELD_OPTION_MOVE_DOWN: {
      const { direction, position, fieldName, formFieldPos } = action.payload;

      let updatedFieldsArray = null;
      let newPos = null;
      let movedElement = null;

      newPos = direction === "up" ? position - 1 : position + 1;

      // check if the new position is in array boundaries
      if (
        newPos < 0 ||
        newPos > state.editForm.fields[formFieldPos][fieldName].length - 1
      ) {
        return state;
      }

      updatedFieldsArray = [...state.editForm.fields];
      movedElement = updatedFieldsArray[formFieldPos][fieldName][position];
      updatedFieldsArray[formFieldPos][fieldName][position] =
        updatedFieldsArray[formFieldPos][fieldName][newPos];
      updatedFieldsArray[formFieldPos][fieldName][newPos] = movedElement;
      return {
        ...state,
        editForm: {
          ...state.editForm,
          fields: updatedFieldsArray,
        },
      };
    }

    default:
      return state;
  }
};

export default cmsFormReducer;
