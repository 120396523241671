import { getNextJsApiURL } from "utils/api";
import {
  addFilesToContentTypeContentArray,
  axiosGetRequest,
  axiosPostRequest,
  handleClientRequestError,
} from "utils/clientUtil";
import {
  createToast,
  handleResponseToastNotifications,
  translate,
} from "utils/util";
// MANAGEDFILE REFACTOR: unused since managedfile refactor
// import { sendFileAndSave } from "./cmsUploadService";

export const validateJoboffer = (jobofferData, isEdit) => {
  let isJobofferDataValid = true;

  if (
    !jobofferData.title ||
    (jobofferData.title && !jobofferData.title.trim().length > 0)
  ) {
    isJobofferDataValid = false;
    createToast({ type: "warning", msg: translate("cms:noTitleInput") });
  }

  return isJobofferDataValid;
};

export const getJoboffer = async (jobofferUrl) => {
  // TODO jobofferUrl can be an array if you create joboffer with slash containing url
  const result = await axiosGetRequest(
    getNextJsApiURL(`/cms/manage/joboffer/${jobofferUrl}`)
  );
  if (result.success) {
    console.log(`fetched joboffer with url ${jobofferUrl}`);
  } else {
    handleResponseToastNotifications(result, "", "jobofferFetchError");
  }
  return result;
};

export const createJoboffer = async (editJoboffer) => {
  console.log("createJoboffer");
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/joboffer/create`),
    { ...editJoboffer }
  );
  handleResponseToastNotifications(
    result,
    "jobofferCreateSuccess",
    "jobofferCreateError"
  );
  if (result.success) {
    console.log(`created joboffer with title ${result.response.data.title}`);
  }
  return result;
};

export const deleteJoboffer = async (id) => {
  console.log("deleteJoboffer");
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/joboffer/delete`),
    { id }
  );
  handleResponseToastNotifications(
    result,
    "jobofferDeletedSuccess",
    "jobofferDeletedError"
  );
  if (result.success) {
    console.log(`deleted joboffer with id ${id}`);
  } else {
    console.log(`failed to delete joboffer with id ${id}`);
  }
  return result;
};

export const saveJoboffer = async (jobofferData, dispatch, publish) => {
  // MANAGEDFILE REFACTOR: unused since managedfile refactor
  // const newFiles = extractNewFilesAndRemoveFromContentArray(
  //   jobofferData.content,
  //   "content"
  // );

  // let newImgTitle = null;

  // if (jobofferData.imgTitle instanceof File) {
  //   newImgTitle = jobofferData.imgTitle;
  //   jobofferData.imgTitle = null;
  // }

  const preSavePublishState = jobofferData.published;
  if (typeof publish === "boolean") {
    jobofferData.published = publish;
  }

  let formData = new FormData();
  formData.append("data", JSON.stringify(jobofferData));

  const mainSaveResult = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/joboffer/update`),
    formData,
    null,
    { headers: { "Content-Type": "multipart/form-data;" } }
  );
  if (mainSaveResult.success) {
    // MANAGEDFILE REFACTOR: unused since managedfile refactor
    // const data = mainSaveResult.response.data;
    // formData.append("data", JSON.stringify(data));
    // await sendJobofferFiles(formData, data, newImgTitle, newFiles, dispatch);
    handleResponseToastNotifications(
      mainSaveResult,
      "jobofferUpdateSuccess",
      "jobofferUpdateError"
    );
  } else {
    // if the update fails set the old values
    jobofferData.published = preSavePublishState;
    addFilesToContentTypeContentArray(jobofferData, newFiles);
    handleClientRequestError(
      mainSaveResult.error,
      "could not update [jobofferarticle]"
    );
    handleResponseToastNotifications(
      mainSaveResult,
      "jobofferUpdateSuccess",
      "jobofferUpdateError"
    );
  }
  return mainSaveResult;
};

// MANAGEDFILE REFACTOR: unused since managedfile refactor
// /**
//  * upload file by file for joboffer save
//  * this code block will add one file to the formData sends the request and
//  * then deletes the formData key from formData. Also the new success response
//  * is set to formData "data" key
//  *
//  * @param {*} formData
//  * @param {*} newImgTitle
//  * @param {*} newFiles
//  * @param {*} dispatch
//  */
// const sendJobofferFiles = async (
//   formData,
//   data,
//   newImgTitle,
//   newFiles,
//   dispatch
// ) => {
//   // joboffer title image
//   if (newImgTitle) {
//     const dataAndFormData = await sendFileAndSave(
//       CONTENT_TYPE_JOBOFFER,
//       formData,
//       data,
//       newImgTitle,
//       "files.imgTitle",
//       dispatch,
//       translate("joboffer-uploadingTitleImage"),
//       `/cms/manage/joboffer/update`
//     );
//     data = dataAndFormData.data;
//     formData = dataAndFormData.formData;
//   }

//   for (let i = 0; i < newFiles.length; i++) {
//     const file = newFiles[i].file;
//     const filesPropertyPath = newFiles[i].propertyPath;

//     const dataAndFormData = await sendFileAndSave(
//       CONTENT_TYPE_JOBOFFER,
//       formData,
//       data,
//       file,
//       filesPropertyPath,
//       dispatch,
//       translate("uploadingFileXofY", { index: i + 1, length: newFiles.length }),
//       `/cms/manage/joboffer/update`
//     );
//     data = dataAndFormData.data;
//     formData = dataAndFormData.formData;
//   }
// };
