import { getStrapiURL } from "utils/api";
import {
  axiosServerGetRequest,
  handleServerRequestError,
} from "utils/serverUtil";

export const getGlobalSettings = async () => {
  const result = await axiosServerGetRequest(
    getStrapiURL("/assets/config-globalsettings.json")
  );

  if (result.success) {
    return result.response.data;
  } else {
    handleServerRequestError(
      result.error,
      "could not fetch getGlobalSettings config-globalsettings.json [getGlobalSettings]"
    );
    return {}; // TODO return some default global settings
  }
};
