import { hideLoadingOverlay, showLoadingOverlay } from "store/actions";
import { createToast, sleep, translate } from "utils/util";

import { getNextJsApiURL } from "utils/api";
import { axiosPostRequest } from "utils/clientUtil";
import {
  CHUNK_UPLOAD_DEFAULT_SLICE_SIZE,
  MAX_MB_FORM_DATA_FILE_SIZE,
  UPLOAD_STATE,
  UPLOAD_TYPE_CONTENT_MANAGER,
  UPLOAD_TYPE_GUEST,
  UPLOAD_TYPE_USER,
} from "utils/constants";

/**
 * uploads a file chunked in base64 format with the help of a FileReader.
 * returns the new uploaded file id from backend
 *
 * this function can be used separately to the other functions if needed.
 * This function will only upload to uploads and adds it to the DB.
 * The new fileId is returned then
 *
 * @param {File} file JS file blob
 * @param {String} uploadType constants.js UPLOAD_TYPE_CONTENT_MANAGER | UPLOAD_TYPE_GUEST | UPLOAD_TYPE_USER
 *  determines the request route
 * @param {Object} dispatch optional - the redux dispatch object to show the loadingoverlay
 * @param {boolean} hideLoadingOverlayOnFinish optional - hides the loadingoverlay on finished upload
 * @param {String} fileNamePrefix optional - prefix for the saved file in backend
 * @param {boolean} isPrivate optional - defaults to false
 * @param {boolean} returnFileInsteadOfId optional - defaults to false. when set to true the file object is returned instead of just the file ID
 * @param {Object} fileInformation optional - object that contains custom file information
 * @param {Object} folderInformation optional - object that contains custom folder information
 * @returns {*} result.success true/false and result.response.data = the new upload id or the file object
 */
export const startChunkedUpload = async (
  file,
  uploadType,
  dispatch,
  overlayMessage,
  hideLoadingOverlayOnFinish,
  optionalFileNameOverride,
  isPrivate = false,
  returnFileInsteadOfId = true,
  fileInformation = {},
  folderInformation = {}
) => {
  const fileReader = new FileReader();

  let chunkedUpload = {
    status: UPLOAD_STATE.started,
    sliceStart: 0,
    newFileName: null,
  };

  while (
    chunkedUpload.status !== UPLOAD_STATE.failed &&
    chunkedUpload.status !== UPLOAD_STATE.finished
  ) {
    chunkedUpload = await uploadChunk(
      file,
      uploadType,
      dispatch,
      overlayMessage,
      fileReader,
      chunkedUpload.sliceStart,
      chunkedUpload.newFileName,
      optionalFileNameOverride,
      isPrivate,
      fileInformation,
      folderInformation
    );
    // throttle the request loop because of server side request limiting
    await sleep(100);
  }

  console.log("uploadChunk finished...");
  if (dispatch && hideLoadingOverlayOnFinish) {
    dispatch(hideLoadingOverlay());
  }

  // create a result object like axiosRequest results
  if (chunkedUpload.status === UPLOAD_STATE.finished) {
    console.log(`uploadId: ${chunkedUpload.uploadId}`);
    return {
      success: true,
      error: null,
      response: {
        data: returnFileInsteadOfId
          ? chunkedUpload.file
          : chunkedUpload.uploadId,
      },
    };
  } else {
    return {
      success: false,
      error: null,
      response: null,
    };
  }
};

/**
 * uploads only a chunk from a file this function gets called from
 * startChunkedUpload
 *
 * @param {*} file
 * @param {*} dispatch
 * @param {*} overlayMessage
 * @param {*} fileReader
 * @param {*} sliceStart
 * @param {*} newFileName
 * @returns
 */
const uploadChunk = async (
  file,
  uploadType,
  dispatch,
  overlayMessage,
  fileReader,
  sliceStart,
  newFileName,
  optionalFileNameOverride,
  isPrivate = false,
  fileInformation,
  folderInformation
) => {
  return new Promise((resolve, reject) => {
    let nextSlice = sliceStart + getSliceSize(file) + 1;
    let blob = file.slice(sliceStart, nextSlice);

    fileReader.onloadend = async function (event) {
      if (event.target.readyState !== FileReader.DONE) {
        return;
      }

      const uploadUrl = getUploadUrlByUploadType(uploadType);

      const result = await axiosPostRequest(uploadUrl, {
        fileChunk: event.target.result,
        fileName: optionalFileNameOverride
          ? optionalFileNameOverride
          : file.name,
        fileType: file.type,
        fileBegin: sliceStart === 0 ? true : false,
        fileSize: file.size,
        newFileName: newFileName,
        isPrivate: isPrivate,
        fileInformation,
        folderInformation,
      });

      if (result.success && result.response.data.ok) {
        const uploadedSize = sliceStart + getSliceSize(file);
        let progressInPercent = Math.floor((uploadedSize / file.size) * 100);
        if (progressInPercent > 100) {
          progressInPercent = 100;
        }
        if (dispatch) {
          dispatch(
            showLoadingOverlay(
              `${
                overlayMessage
                  ? overlayMessage
                  : translate("public:uploadingFiles")
              } ${progressInPercent}%`
            )
          );
        }

        if (nextSlice < file.size) {
          // the chunked upload isnt finished set the next sliceStart to nextSlice
          // and upload the next chunk
          resolve({
            status: UPLOAD_STATE.progress,
            sliceStart: nextSlice,
            newFileName: result.response.data.newFileName,
          });
          return;
        } else {
          // if the chunked upload is finished add the new inserted upload ID
          resolve({
            status: UPLOAD_STATE.finished,
            sliceStart: nextSlice,
            newFileName: result.response.data.newFileName,
            uploadId: result.response.data.uploadId,
            file: result.response.data.file,
          });
          return;
        }
      } else {
        // the backend had an error abort the fileupload.
        console.log("chunked upload failed");

        if (result?.response?.data?.errorMsg) {
          console.log(result?.response?.data);
          if (result?.response?.data?.info) {
            createToast(
              {
                type: "warning",
                msg: translate("cms:fileTypeIncomingVsExpected", {
                  actualFileExt: result.response.data.info.actualFileExt,
                  incomingFileExt: result.response.data.info.incomingFileExt,
                }),
              },
              10000
            );
          }
          createToast(
            {
              type: "warning",
              msg: translate(`cms:${result.response.data.errorMsg}`),
            },
            10000
          );
        }

        resolve({ status: UPLOAD_STATE.failed, sliceStart: sliceStart });
        return;
      }
    };

    fileReader.readAsDataURL(blob);
  });
};

const getSliceSize = (file) => {
  const fileSizeInMB = file.size / (1024 * 1024);
  if (fileSizeInMB > MAX_MB_FORM_DATA_FILE_SIZE) {
    return CHUNK_UPLOAD_DEFAULT_SLICE_SIZE;
  } else {
    return file.size / 3;
  }
};

const getUploadUrlByUploadType = (uploadType) => {
  switch (uploadType) {
    case UPLOAD_TYPE_CONTENT_MANAGER:
      return getNextJsApiURL("/cms/manage/upload/chunk");
    case UPLOAD_TYPE_USER:
    // currently there are no users
    case UPLOAD_TYPE_GUEST:
    // currently there is no guest upload
    default:
      console.log("unknown upload type... using cms upload");
      return getNextJsApiURL("/cms/manage/upload/chunk");
  }
};
