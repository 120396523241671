import { getNextJsApiURL } from "utils/api";
import { axiosPostRequest, cmsHasFeature } from "utils/clientUtil";
import { CMS_FEATURE_USERAPI } from "utils/constants";
import { handleResponseToastNotifications } from "utils/util";

export let userapiSettings = null;

export const setUserapiSettings = (_userapiSettings) => {
  if (_userapiSettings) {
    userapiSettings = _userapiSettings;
  } else if (cmsHasFeature(CMS_FEATURE_USERAPI) && !_userapiSettings) {
    console.log("Feature UserAPI is enabled. Fetched userapiSettings are null or empty.");
  }
};

export const updateUserPolicies = async (userPolicies) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/cms/manage/userapisettings/update-userpolicies"),
    userPolicies
  );
  if (result.success) {
    setUserapiSettings(result.response.data);
  }
  handleResponseToastNotifications(
    result,
    "userapi-userPoliciesUpdateSuccess",
    "userapi-userPoliciesUpdateError"
  );
  return result;
};
