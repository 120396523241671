import { globalSettings } from "services/globalSettings/globalSettingsService";

export const ConfirmModalStyle = () => {
  return (
    <style jsx global>{`
      /* Confirm-Modal Used as User-Policies-Modal */
      /* !important makes sure that Bootstrap-Styles are always overriden. */
      .user-policies-modal {
        .modal-dialog {
          @media (min-width: 576px) {
            max-width: max(550px, 90vw) !important;
          }
        }

        .modal-header {
          padding: 1.25rem !important;
          @media (min-width: 576px) {
            padding: 1.5rem !important;
          }

          @media (min-width: 806px) {
            padding: 2rem !important;
          }

          .modal-title {
            font-size: 3rem !important;
            font-weight: bold;
          }
        }
        .confirm-modal-body {
          /* Overwrites additional styling from globalStyle */
          justify-content: left !important;

          .confirm-modal-content {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }

          /* Fonts */
          /* Font-Styling for p and h-Tags without their font-color! */
          p {
            font-size: ${globalSettings.paragraph.paragraphfontsizemobile};
            font-weight: ${globalSettings.paragraph.paragraphfontweight};
            @media (min-width: ${globalSettings.responsive
                .breakpointdesktop}px) {
              font-size: ${globalSettings.paragraph.paragraphfontsizedesktop};
            }
          }

          h1 {
            font-size: ${globalSettings.h1.h1fontsizemobile};
            font-weight: ${globalSettings.h1.h1fontweight};
            margin-top: ${globalSettings.h1.h1richtextmargin || 0}px;
            margin-bottom: ${globalSettings.h1.h1richtextmargin || 0}px;
            @media (min-width: ${globalSettings.responsive
                .breakpointdesktop}px) {
              font-size: ${globalSettings.h1.h1fontsizedesktop};
            }
          }
          h2 {
            font-size: ${globalSettings.h2.h2fontsizemobile};
            font-weight: ${globalSettings.h2.h2fontweight};
            margin-top: ${globalSettings.h2.h2richtextmargin || 0}px;
            margin-bottom: ${globalSettings.h2.h2richtextmargin || 0}px;
            @media (min-width: ${globalSettings.responsive
                .breakpointdesktop}px) {
              font-size: ${globalSettings.h2.h2fontsizedesktop};
            }
          }
          h3 {
            font-size: ${globalSettings.h3.h3fontsizemobile};
            font-weight: ${globalSettings.h3.h3fontweight};
            margin-top: ${globalSettings.h3.h3richtextmargin || 0}px;
            margin-bottom: ${globalSettings.h3.h3richtextmargin || 0}px;
            @media (min-width: ${globalSettings.responsive
                .breakpointdesktop}px) {
              font-size: ${globalSettings.h3.h3fontsizedesktop};
            }
          }
          h4 {
            font-size: ${globalSettings.h4.h4fontsizemobile};
            font-weight: ${globalSettings.h4.h4fontweight};
            margin-top: ${globalSettings.h4.h4richtextmargin || 0}px;
            margin-bottom: ${globalSettings.h4.h4richtextmargin || 0}px;
            @media (min-width: ${globalSettings.responsive
                .breakpointdesktop}px) {
              font-size: ${globalSettings.h4.h4fontsizedesktop};
            }
          }
          h5 {
            font-size: ${globalSettings.h5.h5fontsizemobile};
            font-weight: ${globalSettings.h5.h5fontweight};
            margin-top: ${globalSettings.h5.h5richtextmargin || 0}px;
            margin-bottom: ${globalSettings.h5.h5richtextmargin || 0}px;
            @media (min-width: ${globalSettings.responsive
                .breakpointdesktop}px) {
              font-size: ${globalSettings.h5.h5fontsizedesktop};
            }
          }
          h6 {
            font-size: ${globalSettings.h6.h6fontsizemobile};
            font-weight: ${globalSettings.h6.h6fontweight};
            margin-top: ${globalSettings.h6.h6richtextmargin || 0}px;
            margin-bottom: ${globalSettings.h6.h6richtextmargin || 0}px;
            @media (min-width: ${globalSettings.responsive
                .breakpointdesktop}px) {
              font-size: ${globalSettings.h6.h6fontsizedesktop};
            }
          }

          /* Font-Color is always black. */
          p,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: black !important;
          }

          /* Mobile, Tablet, Portrait & Landscape */
          min-height: 40vh !important;
          max-height: 48vh !important;
          overflow-y: auto !important;
          overflow-x: hidden !important;

          @media (min-width: 1024px) {
            /* Desktop */
            min-height: 60vh !important;
            max-height: 65vh !important;
          }

          .confirm-modal-content {
            padding-top: 1rem !important;

            .confirm-modal-text {
              padding: 0 !important;
              padding-left: 1.25rem !important;
              padding-right: 1.25rem !important;
              @media (min-width: 576px) {
                padding-left: 1.5rem !important;
                padding-right: 1.5rem !important;
              }

              @media (min-width: 806px) {
                padding-left: 2rem !important;
                padding-right: 2rem !important;
              }

              &.text-center {
                text-align: left !important;
              }
            }
          }
        }
      }
    `}</style>
  );
};

export default ConfirmModalStyle;
