import { useRouter } from "next/router";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isSSR } from "utils/util";

export default function useNavigateBackCallback() {
  const router = useRouter();

  const beforeunloadEvent = useSelector((state) =>
    state && state.general && state.general.beforeunloadEvent
      ? state.general.beforeunloadEvent
      : false
  );

  useEffect(() => {
    const reload = () => {
      if (
        router &&
        window &&
        window.location &&
        window.location.pathname &&
        !beforeunloadEvent // fixed a bug if you navigate back the beforunloadEvent triggers twice
      ) {
        router.replace(
          window.location.pathname +
            window.location.hash +
            window.location.search
        );
      } else {
        // if you stay on the same page keep the correct path in browser url bar
        window.history.replaceState(null, "", router.asPath);
      }
    };

    if (!isSSR()) {
      window.addEventListener("popstate", reload, false);
    }

    return () => {
      window.removeEventListener("popstate", reload);
    };
    // eslint-disable-next-line
  }, [beforeunloadEvent]);
}
