import * as actionTypes from "store/actionTypes/cmsNewsActionTypes";
import { getRandomSlug } from "utils/util";

export const initCmsNewsState = {
  editNews: null,
  editNewsId: null,
  originalNews: null,
  newsUrl: null,
};

const cmsNewsReducer = (state = initCmsNewsState, action) => {
  switch (action.type) {
    case actionTypes.CMS_INIT_NEWS_CREATE: {
      return {
        ...state,
        editNews: {
          title: "",
          categories: [],
          tags: [],
          canonicalLinkUrl: null,
          seoSettings: "",
          isPrivate: false,
        },
      };
    }
    case actionTypes.CMS_INIT_NEWS_EDIT: {
      return {
        ...state,
        editNews: action.payload,
        editNewsId: action.payload.id,
        originalNews: action.payload,
        newsUrl: action.payload.url,
      };
    }
    case actionTypes.CMS_RESET_NEWS_EDIT: {
      return {
        ...state,
        editNews: null,
        editNewsId: null,
        originalNews: null,
        newsUrl: null,
      };
    }
    case actionTypes.CMS_DISCARD_NEWS_EDIT_MODAL_CHANGES: {
      return {
        ...state,
        editNews: {
          ...state.editNews,
          categories: [...state.originalNews.categories],
          canonicalLinkUrl: state.originalNews.canonicalLinkUrl,
          seoSettings: state.originalNews.seoSettings,
        },
      };
    }
    case actionTypes.CMS_EDIT_NEWS_FIELD: {
      const { value, field, position } = action.payload;
      // if position is undefined the field is direct property
      // and not a nested array
      if (typeof position === "undefined") {
        return {
          ...state,
          editNews: {
            ...state.editNews,
            [field]: value,
          },
        };
      } else {
        return {
          ...state,
          editNews: {
            ...state.editNews,
            content: state.editNews.content.map((element, index) =>
              position === index ? { ...element, [field]: value } : element
            ),
          },
        };
      }
    }

    case actionTypes.CMS_EDIT_NESTED_NEWS_FIELD: {
      const { value, field, position, nestedPosition } = action.payload;

      return {
        ...state,
        editNews: {
          ...state.editNews,
          content: state.editNews.content.map((element, index) =>
            position === index
              ? {
                  ...element,
                  nestedlistitems: state.editNews.content[
                    index
                  ].nestedlistitems.map((nestedElement, nestedIndex) =>
                    nestedPosition === nestedIndex
                      ? { ...nestedElement, [field]: value }
                      : nestedElement
                  ),
                }
              : element
          ),
        },
      };
    }

    case actionTypes.CMS_EDIT_CONFIG_NEWS_FIELD: {
      const { value, field, position } = action.payload;
      return {
        ...state,
        editNews: {
          ...state.editNews,
          content: state.editNews.content.map((element, index) =>
            position === index
              ? {
                  ...element,
                  cfgSettings: {
                    ...element.cfgSettings,
                    [field]: {
                      ...element.cfgSettings[field],
                      [`${field}Value`]: value,
                    },
                  },
                }
              : element
          ),
        },
      };
    }

    case actionTypes.CMS_DELETE_NESTED_NEWS_FIELD: {
      const { position, nestedPosition } = action.payload;

      return {
        ...state,
        editNews: {
          ...state.editNews,
          content: state.editNews.content.map((element, index) =>
            position === index
              ? {
                  ...element,
                  nestedlistitems: state.editNews.content[
                    index
                  ].nestedlistitems.filter(
                    (nestedElement, nestedIndex) =>
                      nestedPosition !== nestedIndex
                  ),
                }
              : element
          ),
        },
      };
    }

    case actionTypes.CMS_ADD_NESTED_NEWS_CONTENT_LIST_ITEM: {
      const { position, type } = action.payload;

      let newNestedListItem = {
        __component: "dynamiclistelements.nestedlistitem",
        __new_id: getRandomSlug(),
        cfgLinkCanonical: false,
        img: null,
        imgAlt: null,
        imgCaption: null,
        linkCanonical: null,
        linkText: null,
        linkUrl: null,
        richTextContent: null,
        title: null,
        videoUrl: null,
        video: null,
        videoThumbnail: null,
        videoFileName: null,
        audio: null,
        audioFileName: null,
        file: null,
        itemType: null,
      };

      switch (type) {
        case "gallery":
          newNestedListItem = {
            ...newNestedListItem,
            img: null,
            imgAlt: "",
            imgCaption: "",
            itemType: "image",
          };
          break;
        default:
          break;
      }

      return {
        ...state,
        editNews: {
          ...state.editNews,
          content: state.editNews.content.map((element, index) =>
            position === index
              ? {
                  ...element,
                  nestedlistitems: [
                    ...state.editNews.content[index].nestedlistitems,
                    newNestedListItem,
                  ],
                }
              : element
          ),
        },
      };
    }

    case actionTypes.CMS_ADD_NEWS_CONTENT_LIST_ITEM: {
      const { type, position } = action.payload;

      let newListItem = {
        __component: "dynamiclistelements.listitem",
        __new_id: getRandomSlug(),
        cfgLinkCanonical: false,
        img: null,
        imgAlt: null,
        imgCaption: null,
        linkCanonical: null,
        linkText: null,
        linkUrl: null,
        richTextContent: null,
        title: null,
        videoUrl: null,
        video: null,
        videoThumbnail: null,
        videoFileName: null,
        audio: null,
        audioFileName: null,
        file: null,
        nestedlistitems: [],
        itemType: null,
        cfgSettings: {
          backgroundColor: {
            backgroundColorValue: "default",
            backgroundColorOptions: ["default", "primary", "secondary"],
          },
        },
      };

      switch (type) {
        case "image":
          newListItem = {
            ...newListItem,
            img: null,
            imgAlt: "",
            imgCaption: "",
            itemType: "image",
          };
          break;
        case "link":
          newListItem = {
            ...newListItem,
            linkText: "",
            linkUrl: "",
            itemType: "link",
          };
          break;
        // case "canonicalLink":
        //   newListItem = {
        //     ...newListItem,
        //     linkUrl: "",
        //     cfgLinkCanonical: true,
        //   };
        //   break;
        case "multimedia":
          newListItem = {
            ...newListItem,
            itemType: "multimedia",
          };
          break;
        case "richtext":
          newListItem = {
            ...newListItem,
            richTextContent: "",
            itemType: "richtext",
          };
          break;
        case "gallery":
          newListItem = {
            ...newListItem,
            itemType: "gallery",
            cfgSettings: {
              ...newListItem.cfgSettings,
              galleryMode: {
                galleryModeValue: "default",
                galleryModeOptions: ["default", "masonry", "grid"],
              },
            },
          };
          break;

        default:
          break;
      }

      let updatedContentArray = [...state.editNews.content];

      // If position is set, insert the element in the given position
      if (typeof position !== "undefined" && position !== null) {
        const currentCePos = position;
        const newCePos =
          currentCePos === state.editNews.content.length
            ? currentCePos
            : currentCePos + 1;

        updatedContentArray.splice(newCePos, 0, newListItem);
        // Position is not set, add the element to the end of the content array
      } else {
        updatedContentArray.push(newListItem);
      }

      return {
        ...state,
        editNews: {
          ...state.editNews,
          content: updatedContentArray,
        },
      };
    }

    case actionTypes.CMS_MOVE_NEWS_CONTENT_LIST_ITEM_UP:
    case actionTypes.CMS_MOVE_NEWS_CONTENT_LIST_ITEM_DOWN: {
      const { direction, position } = action.payload;

      let updatedContentArray = null;
      let newPos = null;
      let movedElement = null;

      newPos = direction === "up" ? position - 1 : position + 1;

      // check if the new position is in array boundaries
      if (newPos < 0 || newPos > state.editNews.content.length - 1) {
        return state;
      }

      updatedContentArray = [...state.editNews.content];
      movedElement = updatedContentArray[position];
      updatedContentArray[position] = updatedContentArray[newPos];
      updatedContentArray[newPos] = movedElement;
      return {
        ...state,
        editNews: {
          ...state.editNews,
          content: updatedContentArray,
        },
      };
    }

    case actionTypes.CMS_MOVE_NEWS_CONTENT_NESTED_LIST_ITEM_UP:
    case actionTypes.CMS_MOVE_NEWS_CONTENT_NESTED_LIST_ITEM_DOWN: {
      const { direction, position, nestedPosition } = action.payload;

      let updatedContentArray = null;
      let updatedNestedContentArray = null;
      let newPos = null;
      let movedElement = null;

      newPos = direction === "up" ? nestedPosition - 1 : nestedPosition + 1;

      // check if the new nestedPosition is in array boundaries
      if (
        newPos < 0 ||
        newPos > state.editNews.content[position].nestedlistitems.length - 1
      ) {
        return state;
      }

      updatedNestedContentArray = [
        ...state.editNews.content[position].nestedlistitems,
      ];
      movedElement = updatedNestedContentArray[nestedPosition];
      updatedNestedContentArray[nestedPosition] =
        updatedNestedContentArray[newPos];
      updatedNestedContentArray[newPos] = movedElement;

      updatedContentArray = [...state.editNews.content];
      updatedContentArray[position].nestedlistitems = updatedNestedContentArray;

      return {
        ...state,
        editNews: {
          ...state.editNews,
          content: updatedContentArray,
        },
      };
    }

    case actionTypes.CMS_DELETE_NEWS_CONTENT_LIST_ITEM: {
      const { position } = action.payload;

      return {
        ...state,
        editNews: {
          ...state.editNews,
          content: state.editNews.content.filter(
            (element, index) => position !== index
          ),
        },
      };
    }

    case actionTypes.CMS_CREATE_NEWS_SUCCESS: {
      return state;
    }
    case actionTypes.CMS_CREATE_NEWS_FAILED: {
      return state;
    }
    case actionTypes.CMS_UPDATE_NEWS_SUCCESS: {
      return {
        ...state,
        originalNews: action.payload,
        editNews: action.payload,
      };
    }
    case actionTypes.CMS_UPDATE_NEWS_FAILED: {
      return state;
    }
    case actionTypes.CMS_NEWS_VALIDATION_FAILED: {
      return state;
    }
    case actionTypes.CMS_CLONE_NEWS_CONTENT_LIST_ITEM: {
      const { position } = action.payload;

      let updatedContentArray = [...state.editNews.content];

      const currentCePos = position;
      const newCePos = currentCePos + 1;

      let clonedElement = {
        ...state.editNews.content[currentCePos],
      };
      delete clonedElement.id;
      clonedElement.__new_id = `${getRandomSlug()}`;
      clonedElement.isCopy = true;

      updatedContentArray.splice(newCePos, 0, clonedElement);

      return {
        ...state,
        editNews: {
          ...state.editNews,
          content: updatedContentArray,
        },
      };
    }

    default:
      return state;
  }
};

export default cmsNewsReducer;
