import { getNextJsApiURL } from "utils/api";
import { axiosPostRequest } from "utils/clientUtil";
import {
  handleResponseToastNotifications,
  isLocaleDefaultLocale,
} from "utils/util";

/**
 * @typedef Category
 * @type {object}
 * @property {number} id - unique id
 * @property {string} name - unique name
 * @property {string} type - "news" | "event"
 * @property {string} categoryLocalizationJson - JSON-string e.g. "{"en":"test-englisch","cn":"test-chinesisch"}"
 */

export const createCategoryByNameAndType = async (
  categoryName,
  categoryType,
  categoryLocalizationJson
) => {
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/categories/create`),
    { name: categoryName, type: categoryType, categoryLocalizationJson }
  );
  handleResponseToastNotifications(
    result,
    "categoryCreateSuccess",
    "categoryCreateError"
  );
  return result;
};

export const deleteCategory = async (categoryId) => {
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/categories/delete`),
    { categoryId }
  );
  handleResponseToastNotifications(
    result,
    "categoryDeletedSuccess",
    "categoryDeletedError"
  );
  return result;
};

export const updateCategory = async (
  categoryId,
  categoryName,
  categoryType,
  categoryLocalizationJson
) => {
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/categories/update`),
    { categoryId, categoryName, categoryType, categoryLocalizationJson }
  );
  handleResponseToastNotifications(
    result,
    "categoryUpdateSuccess",
    "categoryUpdateError"
  );
  return result;
};

/**
 * localizes each category.name
 * @param {Array<Category>} categories
 * @param {string} locale
 * @returns localized categories
 */
export const localizeCategoryNames = (categories, locale) => {
  categories.forEach((category) => {
    if (!isLocaleDefaultLocale(locale)) {
      if (category.categoryLocalizationJson) {
        if (typeof category.categoryLocalizationJson === "object") {
          console.log("categoryLocalizationJson is object. should be string");
        } else {
          const localizedName = JSON.parse(category.categoryLocalizationJson)[
            locale
          ];
          category.name = localizedName || category.name;
        }
      }
    }
  });
  return categories;
};

/**
 *
 * @param {Array<Category>} categories
 * @returns array of categories in the current locale
 */
export const getLocalizedCategories = (categories, locale) => {
  let categoryArr = [];
  if (!isLocaleDefaultLocale(locale)) {
    categories.forEach((category) => {
      let localizationJson = category.categoryLocalizationJson;
      if (localizationJson && localizationJson.includes(locale)) {
        categoryArr.push(category);
      }
    });
    localizeCategoryNames(categoryArr, locale);
    return categoryArr;
  } else {
    return categories;
  }
};
