import {
  createDynamicList,
  getDynamicList,
  updateDynamicList,
} from "services/cms/cmsDynamicListService";
import * as actionTypes from "store/actionTypes/cmsDynamicListActionTypes";
import {
  addCreatedDynamicList,
  updateDynamicListState,
} from "./generalActionCreator";

export const resetDynamicListEditor = () => {
  return {
    type: actionTypes.CMS_RESET_DLIST_EDITOR,
  };
};

/**
 * @param {String} dynamicListDisplayName only the contentmanager will be able to see this
 * @param {String} dynamicListName
 * @param {Boolean} enabledFormInputs e.g. ["title"], [] means all inputs are disabled
 * @param {Array<String>} enabledItemInputs e.g. ["richTextContent", "img", "imgAlt"].
 * You can ignore it or given an empty array to enable all inputs.
 * Look in CmsDynamicListItem for what you can enable.
 * @returns
 */
export const openDynamicListEditor = (
  dynamicListDisplayName,
  dynamicListName,
  enabledFormInputs,
  enabledItemInputs = []
) => {
  return {
    payload: {
      dynamicListDisplayName,
      dynamicListName,
      enabledFormInputs,
      enabledItemInputs,
    },
    type: actionTypes.CMS_OPEN_DLIST_EDITOR,
  };
};

export const getDynamicListAndInitEdit = (dynamicListName) => {
  return async (dispatch) => {
    const result = await getDynamicList(dynamicListName);
    if (result.success) {
      dispatch(initDynamicListEditor(result.response.data));
    } else {
      if (result.response?.status && result.response.status === 404) {
        dispatch(dynamicListDoesNotExist());
      } else {
        console.log("could not initialize dynamicList editor!");
        console.log(result.error);
      }
    }
  };
};

const initDynamicListEditor = (dynamicList) => {
  return {
    payload: {
      dynamicList: dynamicList,
    },
    type: actionTypes.CMS_INIT_DLIST_EDITOR,
  };
};

export const resetDynamicListEditorChanges = () => {
  return {
    type: actionTypes.CMS_DLIST_EDITOR_RESET_CHANGES,
  };
};

const dynamicListDoesNotExist = () => {
  return {
    type: actionTypes.CMS_DLIST_DOES_NOT_EXIST,
  };
};

export const createDynamicListAndInitEditor = (dynamicListName) => {
  return async (dispatch) => {
    const result = await createDynamicList(dynamicListName);
    if (result.success) {
      dispatch(addCreatedDynamicList(result.response.data));
      dispatch(initDynamicListEditor(result.response.data));
    } else {
      console.log("could not create dynamicList!");
      console.log(result.error);
    }
  };
};

export const sendUpdateDynamicList = () => {
  return async (dispatch, getState) => {
    dispatch(saveDynamicList());
    const result = await updateDynamicList(
      getState().cmsDynamicList.editDynamicList
    );
    if (result.success) {
      const newDynamicListResult = await getDynamicList(
        getState().cmsDynamicList.dynamicListName
      );
      if (newDynamicListResult.success) {
        dispatch(saveDynamicListSuccess());
        console.log("update the global dynamic list state");
        dispatch(updateDynamicListState(result.response.data));
      } else {
        console.log("could not fetch updated dynamic list!");
      }
    } else {
      dispatch(saveDynamicListFailed());
      console.log("could not update dynamic list!");
      console.log(result.error);
    }
  };
};

const saveDynamicList = () => {
  return {
    type: actionTypes.CMS_UPDATE_DLIST,
  };
};

const saveDynamicListFailed = () => {
  return {
    type: actionTypes.CMS_UPDATE_DLIST_FAILED,
  };
};

const saveDynamicListSuccess = () => {
  return {
    type: actionTypes.CMS_UPDATE_DLIST_SUCCESS,
  };
};

export const createDynamicListItem = () => {
  return {
    type: actionTypes.CMS_ADD_DLIST_ITEM,
  };
};

export const deleteDynamicListItem = (pos) => {
  return {
    payload: {
      pos,
    },
    type: actionTypes.CMS_DELETE_DLIST_ITEM,
  };
};

export const editDynamicListItem = (pos, field, value) => {
  return {
    payload: {
      pos,
      field,
      value,
    },
    type: actionTypes.CMS_EDIT_DLIST_ITEM,
  };
};

export const moveDynamicListItemUp = (originalPos) => {
  return {
    payload: { direction: "up", originalPos },
    type: actionTypes.CMS_MOVE_DLIST_ITEM_UP,
  };
};

export const moveDynamicListItemDown = (originalPos) => {
  return {
    payload: { direction: "down", originalPos },
    type: actionTypes.CMS_MOVE_DLIST_ITEM_DOWN,
  };
};

export const updateTitle = (title) => {
  return {
    payload: { title },
    type: actionTypes.CMS_UPDATE_DLIST_TITLE,
  };
};
