import { showConfirmModal } from "store/actions";
import { getNextJsApiURL } from "utils/api";
import {
  axiosGetRequest,
  axiosPostRequest,
  handleClientRequestError,
} from "utils/clientUtil";
import { PUBLISH_PAGE_STATE, SAVE_PAGE_STATE } from "utils/constants";
import {
  createToast,
  getFileUploadStatusCodeMessage,
  getRandomSlug,
  translate,
} from "utils/util";
// MANAGEDFILE REFACTOR: unused since managedfile refactor
// import { sendFileAndSave } from "./cmsUploadService";

export const saveDraftPage = async (
  id,
  content,
  locale,
  newFiles,
  deletedFiles,
  pageState,
  dispatch
) => {
  let data = {
    id: id,
    state: pageState ? pageState : SAVE_PAGE_STATE,
    content: content,
    locale: locale,
    deletedFiles: deletedFiles,
  };

  // MANAGEDFILE REFACTOR: unused since managedfile refactor
  // removeAllJsFilesFromContentArray(data, newFiles);

  let formData = new FormData();
  formData.append("data", JSON.stringify(data));

  // send save request without files all new files are filled with null values
  const mainSaveResult = await sendEditedDraftPage(formData);

  if (mainSaveResult.success) {
    // formData = new FormData();
    // formData.append("data", JSON.stringify(mainSaveResult.response.data));
    // await sendEditPageFiles(formData, data, newFiles, dispatch);
  } else {
    // on mainSave fail map the newFiles back to the state
    // addFilesToDraftpagesContentArray(data, newFiles);
  }

  if (mainSaveResult.success) {
    switch (pageState) {
      case PUBLISH_PAGE_STATE:
        createToast({
          type: "success",
          msg: translate("cms:pagePublishSuccess"),
        });
        break;
      case SAVE_PAGE_STATE:
      default:
        createToast({
          type: "success",
          msg: translate("cms:pageSaveSuccess"),
        });
        break;
    }
  } else {
    createToast({
      type: "error",
      msg: translate("cms:pageSaveError"),
    });
  }

  return mainSaveResult;
};

// MANAGEDFILE REFACTOR: unused since managedfile refactor
// /**
//  * upload file by file
//  * this code block will add one file to the formData sends the request and
//  * then deletes the formData key from formData. Also the new success response
//  * is set to formData "data" key
//  *
//  * @param {*} formData
//  * @param {*} data
//  * @param {*} newFiles
//  */
// const sendEditPageFiles = async (formData, data, newFiles, dispatch) => {
//   // loop through all newFiles
//   for (let i = 0; i < newFiles.length; i++) {
//     const element = newFiles[i];
//     const objectKeys = Object.keys(newFiles[i]);
//     // loop through all object keys in newFiles elements and find
//     // the "files." key
//     for (let j = 0; j < objectKeys.length; j++) {
//       const key = objectKeys[j];
//       const filesPrefix = "files.";
//       if (key.startsWith(filesPrefix)) {
//         const file = element[key];

//         const dataAndFormData = await sendFileAndSave(
//           CONTENT_TYPE_CONTENTPAGE,
//           formData,
//           data,
//           file,
//           key,
//           dispatch,
//           `Lade Datei ${i + 1} von ${newFiles.length} hoch...`,
//           `/cms/manage/draftpage/save-page`
//         );

//         // set new data and formData for the next request so the
//         // response from the previous request is taken into account
//         data = dataAndFormData.data;
//         formData = dataAndFormData.formData;
//       }
//     }
//   }
// };

const sendEditedDraftPage = async (formData) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/cms/manage/draftpage/save-page"),
    formData,
    null,
    { headers: { "Content-Type": "multipart/form-data;" } }
  );
  if (!result.success) {
    handleClientRequestError(
      result.error,
      "could not send edited draftpage [sendEditedDraftPage]"
    );
  }
  return result;
};

// MANAGEDFILE REFACTOR: unused since managedfile refactor
// /**
//  * removes all instances of type file from content array based on
//  * newFiles array.
//  *
//  * @param {*} data
//  * @param {*} newFiles
//  */
// const removeAllJsFilesFromContentArray = (data, newFiles) => {
//   const filesPrefix = "files.";
//   newFiles.forEach((element) => {
//     const objectKeys = Object.keys(element);
//     objectKeys.forEach((key) => {
//       // console.log(key);
//       if (key.startsWith(filesPrefix)) {
//         // part after files.
//         let attributeKey = key.substring(filesPrefix.length);
//         let escapedAttributeKey = convertPropertyPath(attributeKey);
//         // console.log(escapedAttributeKey);

//         let fileAttribute = objectPath.get(data, escapedAttributeKey);
//         if (fileAttribute instanceof File) {
//           objectPath.set(data, escapedAttributeKey, null);
//           console.log(`removing attribute type File from ${attributeKey}`);
//         }
//       }
//     });
//   });
// };

export const getDraftPage = async (draftPageId) => {
  const result = await axiosGetRequest(
    getNextJsApiURL(`/cms/manage/draftpage/${draftPageId}`)
  );
  if (result.success) {
    console.log(`fetched draftpage with id ${draftPageId}`);
  } else {
    createToast({ type: "error", msg: translate("cms:draftpageFetchError") });
  }
  return result;
};

export const getForms = async () => {
  const result = await axiosGetRequest(
    getNextJsApiURL(`/cms/manage/forms/get-forms`)
  );
  if (result.success) {
  } else {
    createToast({ type: "error", msg: translate("cms:formsFetchError") });
  }
  return result;
};

export const getCategoriesByType = async (categoryType) => {
  const result = await axiosGetRequest(
    getNextJsApiURL(`/cms/manage/categories/${categoryType}`)
  );
  if (result.success) {
  } else {
    createToast({ type: "error", msg: translate("cms:categoriesFetchError") });
  }
  return result;
};

/**
 *
 * @param {*} element the attributes element
 * @param {boolean} integerDefaultNull optional - (was needed for forms builder)
 *    if an integer field has no default value it defaults to 0, if this parameter
 *    is present it defaults to null instead of 0
 * @returns
 */
export const createEmptyElement = (element, integerDefaultNull) => {
  const emptyElement = {
    ...Object.entries(element.attributes).reduce((obj, item) => {
      let defaultValue = null;
      if (item[1]) {
        switch (item[1].type) {
          case "string":
          case "text":
          case "richtext":
            defaultValue = item[1].default ? item[1].default : "";
            break;
          case "json":
            defaultValue = item[1].default ? JSON.parse(item[1].default) : null;
            break;
          case "boolean":
            defaultValue = item[1].default ? item[1].default : false;
            break;
          case "integer":
            if (integerDefaultNull) {
              // this is needed for formsBuilder in forms builder
              // fields like cfgFieldCharactersMin/Max would be 0 otherwise
              defaultValue = item[1].default ? item[1].default : null;
            } else {
              defaultValue = item[1].default ? item[1].default : 0;
            }
            break;
          case "enumeration":
            const firstEnumVal =
              item[1].enum && item[1].enum.length > 0 ? item[1].enum[0] : null;
            defaultValue = item[1].default ? item[1].default : firstEnumVal;
            break;
          /* Nested Element */
          case "component":
            defaultValue = [];
          /* Add potential further types here or (if they got no '.type') in default! */
          default:
            // Type: Image
            // Type: ...
            break;
        }
        return {
          ...obj,
          [item[0]]: defaultValue,
        };
      } else {
        console.log(`There was no matching attributes-Value for ${item[0]}!`);
        return {
          ...obj,
          [item[0]]: defaultValue,
        };
      }
    }, {}),
    __new_id: `new_${getRandomSlug()}`,
    attributes: element.attributes,
    __component: element.name,
  };
  return emptyElement;
};

export const createEmptyNestedContentItem = (elementWithAttributes) => {
  let emptyElement = createEmptyElement(elementWithAttributes);
  delete emptyElement.__component;
  return emptyElement;
};

export const isSaveReportModalPresent = (saveReportModal) => {
  if (saveReportModal && saveReportModal.showSaveReportModal) {
    return true;
  }
  return false;
};

export const showSaveReportModal = (
  dispatch,
  saveReportModal,
  passedConfirmModal
) => {
  if (dispatch && saveReportModal && saveReportModal.showSaveReportModal) {
    const notUploadedFilesList = saveReportModal.notUploadedFiles.map(
      (element, index) => {
        console.log(element.status);
        return (
          <li key={`${element.file.name}-${index}`}>
            {element.file.name} (
            {getFileUploadStatusCodeMessage(element.status)})
            <br />
          </li>
        );
      }
    );

    let content = (
      <>
        {passedConfirmModal.content}
        <br />
        <br />
        <div className="save-report-files">
          <ul>{notUploadedFilesList}</ul>
        </div>
      </>
    );

    const confirmModal = { ...passedConfirmModal, content: content };
    dispatch(showConfirmModal(confirmModal));
  }
};

export const updateUserSettings = async (settingName, settingValue) => {
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/user/userSettings`),
    {
      settingName,
      settingValue,
    }
  );
  if (result.success) {
    createToast({
      type: "success",
      msg: translate("cms:settingSaved"),
    });
  } else {
    createToast({
      type: "error",
      msg: translate("cms:settingSaveError"),
    });
  }
  return result;
};
