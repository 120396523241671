import { getNextJsApiURL } from "utils/api";
import { axiosPostRequest } from "utils/clientUtil";
import { createToast, translate } from "utils/util";

/**
 * cms user login
 * @param {*} username
 * @param {*} password
 * @returns
 */
export const cmsLogin = async (username, password) => {
  const result = await axiosPostRequest(getNextJsApiURL("/cms/auth/login"), {
    username,
    password,
  });
  if (result.success) {
    createToast({
      type: "success",
      msg: translate("cms:loginSuccess"),
    });
  } else {
    createToast({
      type: "error",
      msg: translate("cms:loginError"),
    });
  }
  return result;
};

/**
 * cms user
 * service for sending password forgotten email
 * @param {*} email
 * @param {*} captchaResponse
 * @returns
 */
export const cmsForgotPassword = async (email, captchaResponse) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/cms/auth/forgot-password"),
    {
      email,
      captchaResponse,
    }
  );

  createToast({
    type: "success",
    msg: translate("cms:emailWasSent"),
  });

  return result;
};

/**
 * cms user
 * service for setting the password of the cms user
 * @param {*} password1
 * @param {*} password2
 * @param {*} code
 * @returns
 */
export const cmsSetPassword = async (password1, password2, code) => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/cms/auth/set-password"),
    {
      password1,
      password2,
      code,
    }
  );

  if (result.success) {
    createToast({
      type: "success",
      msg: translate("public:setPasswordSuccess"),
    });
  } else {
    createToast({
      type: "error",
      msg: translate("public:setPasswordError"),
    });
  }

  return result;
};

/**
 * cms user
 * service to logout cms user
 * @returns
 */
export const cmsLogout = async () => {
  const result = await axiosPostRequest(
    getNextJsApiURL("/cms/auth/logout"),
    {}
  );

  if (result.success) {
    createToast({
      type: "success",
      msg: translate("cms:logoutSuccess"),
    });
  } else {
    createToast({
      type: "error",
      msg: translate("cms:logoutError"),
    });
  }

  return result;
};
