function ToastNotification(props) {
  return (
    <div className="row ml-0 mr-0">
      <div className="col-2 col-md-1 my-auto ml-0 p-0">{props.toastIcon}</div>
      <div className="col-9 col-md-10 offset-1 my-auto">
        <div className="cms-notification-text ml-2">{props.toastMessage}</div>
      </div>
    </div>
  );
}

export default ToastNotification;
