import { combineReducers } from "redux";
import cmsCategory from "store/reducers/cmsCategoryReducer";
import cmsCollectionType from "store/reducers/cmsCollectionTypeReducer";
import cmsDynamicList from "store/reducers/cmsDynamicListReducer";
import cmsEvent from "store/reducers/cmsEventReducer";
import cmsForm from "store/reducers/cmsFormReducer";
import cmsJoboffer from "store/reducers/cmsJobofferReducer";
import cmsManagedFile from "store/reducers/cmsManagedFileReducer";
import cmsNavigation from "store/reducers/cmsNavigationReducer";
import cmsNews from "store/reducers/cmsNewsReducer";
import cmsOpenAI from "store/reducers/cmsOpenAIReducer";
import cmsPage from "store/reducers/cmsPageReducer";
import cms from "store/reducers/cmsReducer";
import cmsTag from "store/reducers/cmsTagReducer";
import cmsUserManagement from "store/reducers/cmsUserManagementReducer";
import general from "store/reducers/generalReducer";
import navigation from "store/reducers/navigationReducer";
import twoFactor from "store/reducers/twoFactorReducer";
import user from "store/reducers/userReducer";

//Combine all the sub reducers
const combinedReducers = combineReducers({
  general,
  user,
  twoFactor,
  cms,
  cmsNavigation,
  cmsPage,
  navigation,
  cmsDynamicList,
  cmsEvent,
  cmsNews,
  cmsJoboffer,
  cmsForm,
  cmsCategory,
  cmsTag,
  cmsCollectionType,
  cmsManagedFile,
  cmsUserManagement,
  cmsOpenAI,
});

export default combinedReducers;
