export const CMS_OPEN_MANAGEDFILE_EDITOR = "CMS_OPEN_MANAGEDFILE_EDITOR";
export const CMS_RESET_MANAGEDFILE_EDITOR = "CMS_RESET_MANAGEDFILE_EDITOR";
export const CMS_INIT_MANAGEDFILE_CREATE = "CMS_INIT_MANAGEDFILE_CREATE";
export const CMS_CREATE_MANAGEDFILE = "CMS_CREATE_MANAGEDFILE";
export const CMS_CREATE_MANAGEDFILE_START = "CMS_CREATE_MANAGEDFILE_START";
export const CMS_CREATE_MANAGEDFILE_SUCCESS = "CMS_CREATE_MANAGEDFILE_SUCCESS";
export const CMS_CREATE_MANAGEDFILE_FAILED = "CMS_CREATE_MANAGEDFILE_FAILED";
export const CMS_UPDATE_MANAGEDFILE_START = "CMS_UPDATE_MANAGEDFILE_START";
export const CMS_UPDATE_MANAGEDFILE_SUCCESS = "CMS_UPDATE_MANAGEDFILE_SUCCESS";
export const CMS_UPDATE_MANAGEDFILE_FAILED = "CMS_UPDATE_MANAGEDFILE_FAILED";
export const CMS_EDIT_MANAGEDFILE_FIELD = "CMS_EDIT_MANAGEDFILE_FIELD";
export const CMS_EDIT_MANAGEDFILE_VALUES = "CMS_EDIT_MANAGEDFILE_VALUES";
export const CMS_EDIT_SELECTED_MANAGEDFILE = "CMS_EDIT_SELECTED_MANAGEDFILE";
export const CMS_DELETE_MANAGEDFILE = "CMS_DELETE_MANAGEDFILE";
export const CMS_DELETE_MANAGEDFILE_START = "CMS_DELETE_MANAGEDFILE_START";
export const CMS_DELETE_MANAGEDFILE_SUCCESS = "CMS_DELETE_MANAGEDFILE_SUCCESS";
export const CMS_DELETE_MANAGEDFILE_FAILED = "CMS_DELETE_MANAGEDFILE_FAILED";
export const CMS_TRIGGER_RELOAD_ON_CLOSE_MANAGEDFILE_EDITOR =
  "CMS_TRIGGER_RELOAD_ON_CLOSE_MANAGEDFILE_EDITOR";
