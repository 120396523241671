import dynamic from "next/dynamic";
import { Col, Row } from "react-bootstrap";
import { getNextJsApiURL } from "utils/api";
import { axiosGetRequest, axiosPostRequest } from "utils/clientUtil";
import {
  CONTENT_TYPE_FORM,
  MAX_FORM_FIELD_FILE_SIZE_IN_MB,
} from "utils/constants";
import { getRandomSlug, handleResponseToastNotifications } from "utils/util";
import { getDefaultLocaleId } from "./cmsCustomService";
import { createEmptyElement } from "./cmsService";
const CmsContentRichText = dynamic(() =>
  import("components/cms/cmsContent/cmsContentRichText/cmsContentRichText")
);
const CmsFixedImage = dynamic(() =>
  import("components/cms/cmsFixedImage/cmsFixedImage")
);
const CmsCheckboxFormField = dynamic(() =>
  import(
    "components/cms/cmsForm/cmsFormFields/cmsCheckboxFormField/cmsCheckboxFormField"
  )
);
const CmsDateFormField = dynamic(() =>
  import(
    "components/cms/cmsForm/cmsFormFields/cmsDateFormField/cmsDateFormField"
  )
);
const CmsDropdownFormField = dynamic(() =>
  import(
    "components/cms/cmsForm/cmsFormFields/cmsDropdownFormField/cmsDropdownFormField"
  )
);
const CmsEmailFormField = dynamic(() =>
  import(
    "components/cms/cmsForm/cmsFormFields/cmsEmailFormField/cmsEmailFormField"
  )
);
const CmsFileFormField = dynamic(() =>
  import(
    "components/cms/cmsForm/cmsFormFields/cmsFileFormField/cmsFileFormField"
  )
);
const CmsStringFormField = dynamic(() =>
  import(
    "components/cms/cmsForm/cmsFormFields/cmsStringFormField/cmsStringFormField"
  )
);
const CmsTextFormField = dynamic(() =>
  import(
    "components/cms/cmsForm/cmsFormFields/cmsTextFormField/cmsTextFormField"
  )
);
const CmsInputLabel = dynamic(() =>
  import("components/cms/cmsInputLabel/cmsInputLabel")
);

export const getForm = async (formId) => {
  const result = await axiosGetRequest(
    getNextJsApiURL(`/cms/manage/forms/${formId}`)
  );
  if (result.success) {
    console.log(result.response.data);
    // add unique ids to dropdown options etc. so you
    // can use them as render keys
    addUniqueIdsToOptions(result.response.data.form.fields);
  } else {
    console.log(`could not fetch form with id ${formId}`);
    if (result.response.status === 404) {
      handleResponseToastNotifications(result, "", "formDoesNotExist");
    } else {
      handleResponseToastNotifications(result, "", "formsGetError");
    }
  }

  return result;
};

const addUniqueIdsToOptions = (fields) => {
  if (fields) {
    fields.forEach((element, index) => {
      if (element.__component === "formfields.dropdown") {
        if (!element) {
          fields[index].fieldDropdownValues = [];
        } else {
          fields[index].fieldDropdownValues.forEach((option, optionIndex) => {
            fields[index].fieldDropdownValues[optionIndex] = {
              ...option,
              __new_id: getRandomSlug(),
            };
          });
        }
      }
    });
  }
};

export const getFormCreateValues = async () => {
  const result = await axiosGetRequest(
    getNextJsApiURL(`/cms/manage/forms/create-init`)
  );
  return result;
};

export const createForm = async (editForm, creatorName) => {
  console.log("createForm");
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/forms/create`),
    { ...editForm, creator: creatorName }
  );
  handleResponseToastNotifications(
    result,
    "formsCreateSuccess",
    "formsCreateError"
  );
  if (result.success) {
    console.log(`created form: ${editForm.name}`);
  }
  return result;
};

export const updateForm = async (editForm) => {
  console.log("updateForm");
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/forms/update`),
    editForm
  );
  handleResponseToastNotifications(
    result,
    "formsUpdateSuccess",
    "formsUpdateError"
  );
  if (result.success) {
    console.log(`updated form: ${editForm.id}`);
  }
  return result;
};

export const deleteForm = async (formId) => {
  console.log("deleteForm");
  const result = await axiosPostRequest(
    getNextJsApiURL(`/cms/manage/forms/delete`),
    { formId: formId }
  );
  handleResponseToastNotifications(
    result,
    "formsDeleteSuccess",
    "formsDeleteError"
  );
  if (result.success) {
    console.log(`deleted form with id: ${formId}`);
  }
  return result;
};

export const createEmptyFormField = (formFieldAttributes) => {
  const newElement = createEmptyElement(formFieldAttributes, true);
  // remove attributes
  delete newElement.attributes;
  if (newElement.hasOwnProperty("fieldDropdownValues")) {
    newElement.fieldDropdownValues = [];
  }
  console.log(newElement);
  return newElement;
};

export const renderFormField = (
  formField,
  index,
  isEditor,
  updateFormData,
  isFormsBuilder,
  editFormField,
) => {
  switch (formField.__component) {
    case "formfields.string":
      return (
        <Row className="s-py-1">
          <Col xs={12}>
            {/* StringField */}
            <CmsInputLabel
              label={formField.fieldLabel}
              mandatory={formField.cfgFieldIsMandatory}
            />
            <CmsStringFormField
              formField={formField}
              isEditor={isEditor}
              formPos={index}
              handleValidatedInput={(validatedFieldData) =>
                updateFormData(validatedFieldData)
              }
            />
          </Col>
        </Row>
      );
    case "formfields.email":
      return (
        <Row className="s-py-1">
          <Col xs={12}>
            {/* EmailField */}
            <CmsInputLabel
              label={formField.fieldLabel}
              mandatory={formField.cfgFieldIsMandatory}
            />
            <CmsEmailFormField
              formField={formField}
              isEditor={isEditor}
              formPos={index}
              handleValidatedInput={(validatedFieldData) =>
                updateFormData(validatedFieldData)
              }
            />
          </Col>
        </Row>
      );
    case "formfields.dropdown":
      return (
        <Row className="s-py-1">
          <Col xs={6}>
            {/* DropdownField (Autocomplete) */}
            <CmsInputLabel
              label={formField.fieldLabel}
              mandatory={formField.cfgFieldIsMandatory}
            />
            <CmsDropdownFormField
              fullWidth
              formField={formField}
              isEditor={isEditor}
              formPos={index}
              handleValidatedInput={(validatedFieldData) =>
                updateFormData(validatedFieldData)
              }
            />
          </Col>
        </Row>
      );
    case "formfields.text":
      return (
        <Row className="s-py-1">
          <Col xs={12}>
            {/* TextField multiline*/}
            <CmsInputLabel
              label={formField.fieldLabel}
              mandatory={formField.cfgFieldIsMandatory}
            />
            <CmsTextFormField
              formField={formField}
              isEditor={isEditor}
              formPos={index}
              handleValidatedInput={(validatedFieldData) =>
                updateFormData(validatedFieldData)
              }
            />
          </Col>
        </Row>
      );
    case "formfields.checkbox":
      return (
        <Row className="s-py-1">
          <Col xs={12}>
            <div className="checkbox-container d-flex justify-content-left">
              {/* Checkbox */}
              <CmsCheckboxFormField
                formField={formField}
                isRequired={formField.cfgFieldIsMandatory}
                isEditor={isEditor}
                formPos={index}
                handleValidatedInput={(validatedFieldData) =>
                  updateFormData(validatedFieldData)
                }
                editFormField={editFormField}
              />
              <div
                className={`${
                  formField.fieldLabel
                    ? "text-with-label"
                    : "text-without-label"
                }`}
              >
                <CmsContentRichText
                  enableHeadlines
                  disableEditView={true}
                  content={formField.fieldRichTextDescription}
                />
              </div>
            </div>
          </Col>
        </Row>
      );
    case "formfields.file":
      return (
        <Row className="s-py-1">
          <Col xs={12}>
            {/* FileField */}
            <CmsInputLabel
              label={formField.fieldLabel}
              mandatory={formField.cfgFieldIsMandatory}
            />
            <CmsFileFormField
              formField={formField}
              isRequired={formField.cfgFieldIsMandatory}
              isEditor={isEditor}
              formPos={index}
              handleValidatedInput={(validatedFieldData) =>
                updateFormData(validatedFieldData)
              }
            />
          </Col>
        </Row>
      );
    case "formfields.date":
      return (
        <Row className="s-py-1">
          <Col xs={12} md={6}>
            {/* DateField */}
            <CmsInputLabel
              label={formField.fieldLabel}
              mandatory={formField.cfgFieldIsMandatory}
            />

            <CmsDateFormField
              formField={formField}
              isRequired={formField.cfgFieldIsMandatory}
              isEditor={isEditor}
              formPos={index}
              handleValidatedInput={(validatedFieldData) =>
                updateFormData(validatedFieldData)
              }
            />
          </Col>
        </Row>
      );
    default:
      return (
        <Row>
          <Col xs={12}>
            <pre>{JSON.stringify(formField)}</pre>
          </Col>
        </Row>
      );
  }
};

/**
 * checks if cfgFieldCharactersMin is greater 0 and if cfgFieldCharactersMax is greater than cfgFieldCharactersMin
 * @param {Object} formField
 * @returns ture or false
 */
const checkMinConfigHasError = (formField) => {
  let minInputValue = formField["cfgFieldCharactersMin"];
  let maxInputValue = formField["cfgFieldCharactersMax"];

  if (minInputValue !== null && minInputValue < 0) {
    return true;
  }

  return checkMaxMinConfigHasError(minInputValue, maxInputValue);
};

/**
 * checks if cfgFieldCharactersMax is greater 0 and if cfgFieldCharactersMax is greater than cfgFieldCharactersMin
 * @param {Object} formField
 * @returns ture or false
 */
const checkMaxConfigHasError = (formField) => {
  let minInputValue = formField["cfgFieldCharactersMin"];
  let maxInputValue = formField["cfgFieldCharactersMax"];

  if (maxInputValue !== null && maxInputValue < 0) {
    return true;
  }

  return checkMaxMinConfigHasError(minInputValue, maxInputValue);
};

/**
 * checks if maxInputValue ist greater than minInputValue
 * @param {Number} minInputValue
 * @param {Number} maxInputValue
 * @returns
 */
const checkMaxMinConfigHasError = (minInputValue, maxInputValue) => {
  return (
    maxInputValue !== null &&
    minInputValue !== null &&
    minInputValue > maxInputValue
  );
};

/**
 * checks if the fileSize set by the CM is lower than the max allowed file size and a number and the value is positiv
 * @param {Object} formField
 * @returns
 */
const checkMaxFileSizeConfigHasError = (formField) => {
  const maxFileSizeValue = formField["cfgFieldMaxFileSizeInMB"];

  if (maxFileSizeValue !== null && !isNaN(maxFileSizeValue)) {
    if (
      maxFileSizeValue < 0 ||
      maxFileSizeValue > MAX_FORM_FIELD_FILE_SIZE_IN_MB
    ) {
      return true;
    }

    if (!Number.isInteger(maxFileSizeValue)) {
      return true;
    }
  }
  return false;
};

/**
 * check if a formField is a negativ value, that should not be negativ
 * @param {Object} formField
 * @param {String} label
 * @returns true if negativ else false
 */
const checkNegativNumber = (formField, label) => {
  return formField[label] < 0;
};

/**
 * calls the correct validation method depending on what integer config form field needs validation
 * @param {Object} formField
 * @param {String} label
 * @returns true if the field has an error, else false
 */
export const checkConfigIntegerEditFieldHasError = (formField, label) => {
  switch (label) {
    case "cfgFieldCharactersMin":
      return checkMinConfigHasError(formField);
    case "cfgFieldCharactersMax":
      return checkMaxConfigHasError(formField);
    case "cfgFieldMaxFileSizeInMB":
      return checkMaxFileSizeConfigHasError(formField);
    case "cfgMaxDaysPast":
    case "cfgMaxDaysFuture":
      return checkNegativNumber(formField, label);
    default:
      return false;
  }
};

/**
 * Checks if the form field config has errors
 * @param {Object} formField
 * @returns true or false
 */
export const checkFormFieldConfigHasError = (formField) => {
  let hasError = false;
  Object.keys(formField).forEach((key) => {
    if (key === "cfgFieldCharactersMin" || "cfgFieldCharactersMax") {
      if (checkConfigIntegerEditFieldHasError(formField, key)) {
        hasError = true;
      }
    }
  });
  return hasError;
};

/**
 * checks if a checkbox should be disabled
 * @param {Object} formField
 * @param {String} label
 * @returns true or false to disable the checkbox
 */
export const disableCheckboxConfigField = (formField, label) => {
  switch (label) {
    case "cfgFieldNoNumbers":
      return (
        formField["cfgFieldNoLetters"] &&
        formField["cfgFieldNoSpecialCharacters"]
      );
    case "cfgFieldNoLetters":
      return (
        formField["cfgFieldNoNumbers"] &&
        formField["cfgFieldNoSpecialCharacters"]
      );
    case "cfgFieldNoSpecialCharacters":
      return formField["cfgFieldNoNumbers"] && formField["cfgFieldNoLetters"];
    default:
      return false;
  }
};

// Array to order the validation input fields in the forms config modal for the text form field
const configValidateTextFieldOrder = [
  "cfgFieldIsMandatory",
  "cfgFieldCharactersMin",
  "cfgFieldCharactersMax",
  "cfgFieldNoLetters",
  "cfgFieldNoNumbers",
  "cfgFieldNoSpecialCharacters",
];

// Array to order the validation input fields in the forms config modal for the string form field
const configValidateStringFieldOrder = [
  "cfgFieldIsMandatory",
  "cfgFieldCharactersMin",
  "cfgFieldCharactersMax",
  "cfgFieldNoLetters",
  "cfgFieldNoNumbers",
  "cfgFieldNoSpecialCharacters",
  "cfgFieldNoWhitespaceCharacters",
  "cfgFieldCustomRegex",
];

// Array to order the validation input fields in the forms config modal for the file form field
const configValidateFileFieldOrder = [
  "cfgFieldIsMandatory",
  "cfgFieldAllowedFileEndings",
  "cfgFieldMaxFileSizeInMB",
];

// Array to order the validation input fields in the forms config modal for the date form field
const configValidateDateFieldOrder = [
  "cfgFieldIsMandatory",
  "cfgMaxDaysPast",
  "cfgMaxDaysFuture",
  "cfgCalendarStart",
  "cfgNotAllowedDatesStart",
  "cfgNotAllowedDatesEnd",
];

// Array to order the validation input fields in the forms config modal for the a generic form field
const configValidateDefaultFieldOrder = ["cfgFieldIsMandatory"];

// Array to order the general information input fields in the forms config modal for all form fields
const generalConfiglFieldOrder = [
  "fieldLabel",
  "cfgFieldPlaceholder",
  "cfgFieldCustomErrorMessage",
  "fieldRichTextDescription",
];

/**
 * returns the order of the gernal forms config fields depending on what config fields the form field component has
 * @param {Object} fieldAttributes
 * @returns array of ordered general forms config fields
 */
export const getGeneralConfigFieldOrder = (fieldAttributes) => {
  return generalConfiglFieldOrder.filter((orderedConfigField) => {
    return checkOrderedFieldIsContained(fieldAttributes, orderedConfigField);
  });
};

/**
 * get the correct ordered config field order depending on the form field component
 * @param {String} formfieldComponent
 * @returns array
 */
export const getValidationConfigFieldOrderArray = (formfieldComponent) => {
  switch (formfieldComponent) {
    case "formfields.file":
      return configValidateFileFieldOrder;
    case "formfields.string":
      return configValidateStringFieldOrder;
    case "formfields.text":
      return configValidateTextFieldOrder;
    case "formfields.date":
      return configValidateDateFieldOrder;
    default:
      return configValidateDefaultFieldOrder;
  }
};

/**
 * checks if an config field is contained in the ordered config field array
 * @param {Object} fieldAttributes
 * @param {Array} orderedConfigField
 * @returns true or false
 */
const checkOrderedFieldIsContained = (fieldAttributes, orderedConfigField) => {
  let isContained = false;
  Object.keys(fieldAttributes).forEach((key) => {
    if (orderedConfigField === key) {
      isContained = true;
    }
  });

  return isContained;
};

export const openFormsEditInNewTab = async (formsId, locale) => {
  const result = await getDefaultLocaleId(formsId, CONTENT_TYPE_FORM);
  if (result.success && result.response.data) {
    if (formsId === result.response.data) {
      // if the response.data is the same as the formId there is no need for
      // the locale in the url because it is the default locale
      window.open(
        `${process.env.NEXT_PUBLIC_NEXTJS_URL}/cms/forms/edit/${result.response.data}`
      );
    } else {
      window.open(
        `${process.env.NEXT_PUBLIC_NEXTJS_URL}/${locale}/cms/forms/edit/${result.response.data}`
      );
    }
  }
};

export const renderCaptchaPlaceholder = () => {
  switch (process.env.NEXT_PUBLIC_CAPTCHA_TYPE) {
    case "recaptcha":
      return (
        <Row>
          <Col xs={12}>
            <div className="py-2 captcha-placeholder">
              <CmsFixedImage
                img="/recaptcha_placeholder.png"
                alt="reCaptcha Placeholder"
                width={305}
                height={79}
              />
            </div>
          </Col>
        </Row>
      );
    case "friendlycaptcha":
      return (
        <Row>
          <Col xs={12}>
            <div className="py-2 captcha-placeholder">
              <CmsFixedImage
                img="/friendlycaptcha_placeholder.png"
                alt="Friendlycaptcha Placeholder"
                width={305}
                height={79}
              />
            </div>
          </Col>
        </Row>
      );
    default:
      return null;
  }
};
