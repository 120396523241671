import { getStrapiURL } from "utils/api";
import {
  axiosServerGetRequest,
  handleServerRequestError,
} from "utils/serverUtil";

export const getDynamicLists = async (locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/assets/${locale}_content-dynamiclists.json`)
  );
  if (result.success) {
    return result.response.data;
  } else {
    handleServerRequestError(
      result.error,
      "could not fetch content-dynamiclists.json [getDynamicLists]"
    );
    return []; // return an empty list
  }
};
