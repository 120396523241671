import axios from "axios";
import ToastNotification from "components/toastNotification/ToastNotification";
import { marked } from "marked";
import { i18n } from "next-i18next";
import { toast } from "react-toastify";
import { globalSettings } from "services/globalSettings/globalSettingsService";
import { store } from "store/store";
import { getNextJsApiURL, getStrapiURLClientSide } from "utils/api";
import { cmsHasFeature } from "./clientUtil";
import {
  CMS_FEATURE_EVENTS,
  CMS_FEATURE_JOBOFFERS,
  CMS_FEATURE_NEWS,
  CMS_FEATURE_USERAPI,
  CMS_ROLE_ADMIN,
  IMAGE_PLACEHOLDER,
  PAGE_TYPE_CONTENTPAGE,
  PAGE_TYPE_EVENT,
  PAGE_TYPE_JOBOFFER,
  PAGE_TYPE_NEWS,
  USER_LOGIN_URL,
} from "./constants";

/**
 * translate function
 *
 * More docs:
 * We are using next-i18next: https://github.com/isaachinman/next-i18next
 * next-i18next uses: https://www.i18next.com/ and https://github.com/i18next/react-i18next
 *
 * translate function works the same as const { t: tCms } = useTranslation("cms"); * this function is needed for non react components -> every js file that is
 * not in react component scope and should not use react hooks.
 * (services/actioncreator/util... etc.)
 *
 * in react components use:
 * import { useTranslation } from "next-i18next";
 * const { t: tCms } = useTranslation("cms"); *
 * NOTE: All translation files can be found in nextjs /public/locales/[locale] folder.
 * getServerSideProps will inject the chosen files into the current context through:
 * ...(await serverSideTranslations(locale, ["common"])) -> this example would load
 * common.json then you can use const { t } = useTranslation("common")
 *
 * Multiple namespaces:
 * ...(await serverSideTranslations(locale, ["common", "test"])), // loads common.json AND test.json
 * const { t } = useTranslation(["common", "test"]);
 * console.log(t("change-locale")); // from common.json
 * console.log(t("test:test123")); // from test.json
 * console.log(translate("change-locale")); // util function common.json
 * console.log(translate("test:test123")); // util function test.json
 *
 * note the colon in the example above
 * for non common.json translations you need a colon in the translation call
 * t("test:test123") // translation from test.json
 *
 * translation text interpolation: https://www.i18next.com/translation-function/interpolation
 *
 * In tranlation json files you can define variables in the text like this:
 * "to-second-page": "Zur zweiten Seite {{test}}!",
 * then you can call t("to-second-page", {test: "foo bar"}) or
 * translate("to-second-page", {test: "foo bar"})
 * then youll get "Zur zweiten Seite foo bar!"
 *
 * @param {string} messageKey the message key from the localized *.json file (e.g.: common.json)
 * @param {object} values for more information look into https://www.i18next.com/translation-function/interpolation
 * @returns
 */
export const translate = (messageKey, values) => {
  // on startup the i18n object might not be initialized
  if (i18n && i18n.t) {
    return i18n.t(messageKey, values);
  }
  return messageKey;
};

export const axiosFetcher = (url) => axios.get(url).then((res) => res.data);

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const isSSR = () => typeof window === "undefined";

/**
 * language selector change function
 *
 * @param {*} nextLocale
 * @param {*} router
 */
export const changeLanguage = (
  nextLocale,
  router,
  nextRoute,
  deferTime,
  startEditMode
) => {
  let newRoute = router.asPath;
  if (nextRoute) {
    newRoute = nextRoute;
  } else if (router.asPath.indexOf("?edit=true") > -1) {
    // if the current route contains "edit=true" remove it
    newRoute = router.asPath.replace("?edit=true", "");
  }

  if (startEditMode) {
    newRoute = newRoute + "?edit=true";
  }

  updateLanguageCookie(nextLocale);

  let isNextLocaleDefaultLocale = false;
  if (nextLocale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE) {
    isNextLocaleDefaultLocale = true;
  }

  setTimeout(
    () => {
      if (router && !isSSR()) {
        router.replace(newRoute, null, { locale: nextLocale });
      }
    },
    deferTime ? deferTime : 0
  );
};

/**
 * updates the NEXT_LOCALE cookie to the visited/set locale
 * @param {*} locale
 */
export const updateLanguageCookie = (locale) => {
  if (!isSSR()) {
    // get the current language from cookie;
    const currentLanguage = ("; " + document.cookie)
      .split(`; NEXT_LOCALE=`)
      .pop()
      .split(";")[0];
    if (!currentLanguage || (currentLanguage && currentLanguage !== locale)) {
      document.cookie = `NEXT_LOCALE=${locale}; path=/; expires=${new Date(
        Date.now() + 86400 * 1000 * 365
      ).toUTCString()}`;
    }
  }
};

/**
 * returns true if the locale is the default locale
 *
 * @param {string} locale (router.locale) or "de"/"en"...
 * @returns true=default locale / false=non default locale
 */
export const isLocaleDefaultLocale = (locale) => {
  if (locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE) {
    return true;
  }
  return false;
};

/**
 * returns true if the i18n locale is the default nextjs locale
 *
 * @param {*} i18nLocale "de-DE" / "en-US"
 * @returns
 */
export const isI18nLocaleDefaultLocale = (i18nLocale) => {
  if (
    matchWithNexti18NextLocale(i18nLocale) ===
    process.env.NEXT_PUBLIC_DEFAULT_LOCALE
  ) {
    return true;
  }
  return false;
};

export const convertPropertyPath = (attributePath) => {
  return attributePath.replaceAll("[", ".").replaceAll("]", "");
};

export const getRandomSlug = () => {
  return Math.random().toString(36).substring(2, 5);
};

export const removeUrlQueryParams = () => {
  if (typeof window !== "undefined") {
    window.history.replaceState(
      null,
      "",
      window.location.pathname + window.location.hash
    );
  }
};

export const getIdOrNewId = (element, index) => {
  if (element.id) {
    return element.id;
  } else if (element.__new_id) {
    return element.__new_id;
  }
  return index;
};

export const getDocumentTitle = () => {
  // remove this statement if this is making any trouble with nextjs/SSR!
  // this is only for local developer document title
  if (process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT === "local") {
    return `${process.env.NEXT_PUBLIC_NEXTJS_DOCUMENT_TITLE_BASE} (local)`;
  } else {
    return `${process.env.NEXT_PUBLIC_NEXTJS_DOCUMENT_TITLE_BASE}`;
  }
};

/**
 * Takes Markdown or HTML as param.
 * Adds line-breaks before each p and h tag.
 * @param {String} text
 * @returns formatted String
 */
export const formatMarkdownOrHTMLToString = (text) => {
  let onlyText = "";
  if (text !== undefined && text !== null && text !== "") {
    // Render HTML first, to not destroy Markdown-Elements.
    let validHTML = marked(text);

    // get all positions in string before a <p> or <h>
    let positionsOfPTags = [];
    for (let i = 0; i < validHTML.length; i++) {
      if (
        validHTML[i] === "<" &&
        (validHTML[i + 1] === "p" || validHTML[i + 1] === "h")
      ) {
        if (i !== 0) {
          positionsOfPTags.push(i);
        }
      }
    }

    // adds line-breaks before each <p> and <h>
    positionsOfPTags.forEach((position, index) => {
      if (index === 0) {
        validHTML = [
          validHTML.slice(0, position),
          " \n",
          validHTML.slice(position),
        ].join("");
      } else {
        validHTML = [
          validHTML.slice(0, position + index * 2),
          " \n",
          validHTML.slice(position + index * 2),
        ].join("");
      }
    });

    // Use HTML-Element to make use of HTML-Functionality
    const tempDIV = document.createElement("div");
    tempDIV.innerHTML = validHTML;
    onlyText = tempDIV.textContent;
  }
  return onlyText;
};

/**
 * This function returns the correct url from a page.
 * The root page "/" has as page.url process.env.NEXT_PUBLIC_ROOT_PAGE_URL.
 * So you need to check if your page is the root page.
 *
 * @param {string} url the page url
 * @returns
 */
export const getPageUrlOrRootUrl = (url) => {
  if (url === process.env.NEXT_PUBLIC_ROOT_PAGE_URL) {
    return "/";
  }
  return `/${url}`;
};

/**
 * This function returns the name for of the page and adds
 * an "(Startseite)" string to the display name for the root page
 *
 * @param {string} url page.url
 * @param {string} pageName page.name
 * @returns
 */
export const getPageNameOrRootPageName = (url, pageName) => {
  if (url === process.env.NEXT_PUBLIC_ROOT_PAGE_URL) {
    return `${pageName} (${translate("homepage")})`;
  }
  return pageName;
};

/**
 * Make sure you really need to use this function.
 * Most Strapi attributes should be of type integer and not a string.
 *
 * Takes e.g. "100px" and returns {value: 100, type: "px"} or {value: null, type: null} if param was invalid
 * @param {String} cssValueString can be px, rem, %, em
 * @returns {{value: Number, type: String}}
 */
export const getCSSStringValueAndType = (cssValueString) => {
  let value = null;
  let type = null;

  if (cssValueString && typeof cssValueString === "string") {
    const pxPos = cssValueString.indexOf("px");
    if (pxPos > 0) {
      value = parseInt(cssValueString.substring(0, pxPos), 10);
      type = "px";
    }

    const emPos = cssValueString.indexOf("em");
    if (emPos > 0) {
      value = parseInt(cssValueString.substring(0, emPos), 10);
      type = "em";
    }

    const remPos = cssValueString.indexOf("rem");
    if (remPos > 0) {
      value = parseInt(cssValueString.substring(0, remPos), 10);
      type = "rem";
    }

    const percentPos = cssValueString.indexOf("%");
    if (percentPos > 0) {
      value = parseInt(cssValueString.substring(0, percentPos), 10);
      type = "%";
    }

    // add more cases here
  }

  return { value, type };
};

/**
 * This function returns the Bootstrap row classname for the given text-alignment
 * e.g. alignment: "left" -> returns "justify-content-start"
 *
 * @param {String} alignment "left", "center", "right"
 * @returns the Bootstrap classname corresponding the text-alignment, defaults to empty String
 */
export const getBootstrapAlignmentRowClass = (alignment) => {
  switch (alignment) {
    case "left":
      return "justify-content-start";
    case "center":
      return "justify-content-center";
    case "right":
      return "justify-content-end";
    default:
      return "";
  }
};

/**
 * DEPRECATED
 * use getGlobalSettingsColor instead
 * @param {String} colorString
 * @returns
 */
export const getColorVariableName = (colorString) => {
  let correctColorVar = "";
  if (colorString && colorString !== "black" && colorString !== "white") {
    correctColorVar = `--${colorString.substring(
      0,
      colorString.length - 6
    )}-${colorString.substring(
      colorString.length - 6,
      colorString.length - 1
    )}-${colorString.substring(colorString.length - 1)}`;
  } else if (colorString === "black") {
    correctColorVar = "--black";
  } else if (colorString === "white") {
    correctColorVar = "--white";
  }
  return correctColorVar;
};

/**
 * creates a toast notification using react-toastify
 *
 * example:
 * createToast({type: 'success', msg: 'this is my test notification'});
 *
 * @param {Object} notification object {type: 'success|error|warning|default', msg: 'the notification message'}
 * @param {number} duration optional - defaults to 5000
 **/
export const createToast = (notification, duration = 5000) => {
  let toastClass;
  let toastIcon;
  let toastIconClass;

  switch (notification.type) {
    case "success":
      toastClass = "success-toast";
      toastIcon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 150 150"
        >
          <path
            fill="#4ca22f"
            fillRule="evenodd"
            d="M63.438 101c-1.15 0-2.301-.438-3.18-1.316L40.32 79.776c-1.76-1.756-1.761-4.605-.005-6.364 1.756-1.758 4.606-1.76 6.364-.004L63.438 90.14l39.883-39.824c1.758-1.758 4.607-1.755 6.364.004 1.756 1.759 1.754 4.608-.005 6.364L66.618 99.684c-.88.878-2.03 1.316-3.18 1.316"
          />
        </svg>
      );
      toastIconClass = "success-toast-icon";
      break;
    case "error":
      toastClass = "error-toast";
      toastIcon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 150 150"
        >
          <path
            fill="#d51317"
            fillRule="evenodd"
            d="M99.682 93.318c1.757 1.757 1.757 4.607 0 6.364-.88.879-2.03 1.318-3.182 1.318-1.152 0-2.303-.44-3.182-1.318L75 81.364 56.682 99.682c-.88.879-2.03 1.318-3.182 1.318-1.152 0-2.303-.44-3.182-1.318-1.757-1.757-1.757-4.607 0-6.364L68.636 75 50.318 56.682c-1.757-1.757-1.757-4.607 0-6.364 1.758-1.757 4.606-1.757 6.364 0L75 68.636l18.318-18.318c1.758-1.757 4.606-1.757 6.364 0 1.757 1.757 1.757 4.607 0 6.364L81.364 75l18.318 18.318z"
          />
        </svg>
      );
      toastIconClass = "error-toast-icon";
      break;
    case "warning":
      toastClass = "warning-toast";
      toastIcon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 150 150"
        >
          <path
            fill="#f39200"
            fillRule="evenodd"
            d="M78.54 100.7c.93-.94 1.46-2.22 1.46-3.54 0-1.32-.53-2.61-1.46-3.54-.94-.93-2.22-1.46-3.54-1.46-1.32 0-2.61.53-3.54 1.46-.93.93-1.46 2.22-1.46 3.54 0 1.32.53 2.6 1.46 3.54.93.93 2.22 1.46 3.54 1.46 1.32 0 2.61-.53 3.54-1.46zM79 84.091V65.486c0-2.209-1.79-4-4-4-2.209 0-4 1.791-4 4v18.605c0 2.209 1.791 4 4 4 2.21 0 4-1.791 4-4zM39.87 108h70.26L75 46.104 39.87 108zm77.13 8H33c-1.424 0-2.74-.757-3.457-1.987-.716-1.23-.725-2.749-.02-3.987l41.998-74c.711-1.252 2.04-2.026 3.48-2.026 1.438 0 2.767.774 3.477 2.026l42 74c.704 1.238.695 2.757-.022 3.987-.716 1.23-2.032 1.987-3.456 1.987z"
          />
        </svg>
      );
      toastIconClass = "warning-toast-icon";
      break;

    default:
      toastClass = "default-toast";
      toastIcon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 150 150"
        >
          <path
            fill="#00b1eb"
            fillRule="evenodd"
            d="M113 75c0 20.953-17.047 38-38 38S37 95.953 37 75s17.047-38 38-38 38 17.047 38 38m8 0c0-25.364-20.636-46-46-46S29 49.636 29 75s20.636 46 46 46 46-20.636 46-46M82.24 55.05c.93-.93 1.46-2.22 1.46-3.53 0-1.32-.53-2.61-1.46-3.54-.93-.93-2.22-1.46-3.54-1.46-1.31 0-2.6.53-3.53 1.46-.94.93-1.47 2.22-1.47 3.54 0 1.31.53 2.6 1.47 3.53.93.93 2.21 1.47 3.53 1.47 1.32 0 2.61-.54 3.54-1.47m-9.934 47.76c-.784 0-1.56-.264-2.194-.773-1.014-.815-1.492-2.127-1.24-3.404l4.926-25.009-2.713 1.528c-1.686.949-3.82.352-4.767-1.332-.949-1.684-.353-3.818 1.331-4.767l9.334-5.258c1.191-.671 2.663-.587 3.769.214 1.107.8 1.647 2.172 1.383 3.512L77.03 93.434l4.186-1.824c1.774-.77 3.835.04 4.607 1.811.772 1.772-.039 3.834-1.811 4.607l-10.308 4.491c-.449.195-.925.291-1.398.291"
          />
        </svg>
      );
      toastIconClass = "default-toast-icon";
  }

  toast(
    <ToastNotification
      toastClass={toastClass}
      toastIcon={toastIcon}
      toastIconClass={toastIconClass}
      toastMessage={notification.msg}
    />,
    {
      position: "bottom-right",
      autoClose: duration,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      className: toastClass,
    }
  );
};

/**
 * renderImage
 * @param {Object} imgOrMimg managedImage with .file (the actual image)
 * @param {boolean} isThumbnail if true strapi thumbnail path is returned else strapi url
 * @returns {string} Path of Image
 */
export const renderImage = (imgOrMimg, isThumbnail) => {
  if (!imgOrMimg) {
    // Not existing.
    return "";
  }

  if (typeof imgOrMimg === "string") {
    // Specific URL:
    return imgOrMimg;
  }

  // this is needed to have a actual javascript file fallback (used in media manager and file input)
  if (typeof window !== "undefined" && imgOrMimg.file instanceof File) {
    // File is not defined on serverside so you have to check
    // if this is running in browser first nodejs will throw a
    // ReferenceError: File is not defined
    return URL.createObjectURL(imgOrMimg.file);
  }

  if (imgOrMimg.file && imgOrMimg.file.url) {
    // Managed-Image:
    if (isThumbnail && imgOrMimg.file.formats?.thumbnail?.url) {
      // Note: file.formats only exist for public files
      return getStrapiURLClientSide(imgOrMimg.file.formats.thumbnail.url);
    } else if (imgOrMimg.isPrivate) {
      // Private:
      return getNextJsApiURL(imgOrMimg.file.url);
    } else {
      // Public OR missing file.formats:
      return getStrapiURLClientSide(imgOrMimg.file.url);
    }
  }

  // Note: Use this only for public files. (file.formats only exist for public files)
  if (imgOrMimg.url) {
    // Case for using a specific strapi-image-object directly.
    // For example: small, like in gallery.
    return getStrapiURLClientSide(imgOrMimg.url);
  }

  // Unhandled: General Placeholder
  return IMAGE_PLACEHOLDER;
};

export const getPublicOrPrivateUrlFromManagedFile = (managedFile) => {
  if (!managedFile || !managedFile.file) {
    return "";
  }

  if (managedFile.isPrivate) {
    return getNextJsApiURL(managedFile.file.url);
  }
  return getStrapiURLClientSide(managedFile.file.url);
};

/**
 * @param {Object} numberObj the value you want to convert to rem - e.g. 15px or 15
 * @returns {Number} the rem-value or 0, if the given numberObj was 0, undefined or null
 */
export const getRemValue = (numberObj) => {
  if (!numberObj || numberObj === undefined || numberObj === 0) {
    return 0;
  }
  if (!Number.isInteger(numberObj) && numberObj.endsWith("px")) {
    numberObj = numberObj.substring(0, numberObj.length - 2);
  }

  return numberObj / 10;
};

/**
 * checks a role and executes the given function after that role check.
 * If the rolecheck is successful the function is executed if not there is a toast message shown
 *
 * () => functionCall(a, b)
 *
 * @param {function} callFunction the function that should be called after successful role check
 * @param {Array string} roles the roles needed to run that function
 * @param {string} showNotification optional - defaults to true so the default permission denied notification will be displayed
 * @param {string} customNotificationText  optional - custom permission denied msg
 */
export const roleCheck = async (
  callFunction,
  roles,
  showNotification,
  customNotificationText
) => {
  if (typeof showNotification === "undefined") {
    showNotification = true;
  }

  if (cmsUserHasRole(roles, showNotification, customNotificationText)) {
    return callFunction();
  }
  return null;
};

/**
 * checks if a CMS user has a specific role that is needed
 * Attention: this is only a frontend check!! Always check permissions/roles in backend
 *
 * @param {Array string} roles the rolenames that will be checked as an array example: [CMS_ROLE_AUTHOR, CMS_ROLE_EDITOR]
 * @param {boolean} showNotification optional - enabled/disables default permission denied
 *  toast notification if this function runs calls showPermissionDeniedNotification
 * @param {string} customNotificationText optional - custom permission denied notification text
 * @returns {boolean} true if the user does have the role / false if the user does not have the role
 */
export const cmsUserHasRole = (
  roles,
  showNotification,
  customNotificationText
) => {
  const userRoles = store.getState().cms.cmsUser.roles;
  if (
    userRoles !== null &&
    userRoles.length > 0 &&
    (userRoles.includes(CMS_ROLE_ADMIN) ||
      userRoles.some((userRole) => roles.includes(userRole)))
  ) {
    return true;
  } else {
    if (showNotification) {
      showPermissionDeniedNotification(customNotificationText);
    }

    if (process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT !== "prod") {
      console.log(`role needed: ${roles}`);
    }

    return false;
  }
};

/**
 * shows a simple permission denied toast msg text is optional
 *
 * @param {string} msg optional - for custom permission denied texts
 */
export const showPermissionDeniedNotification = (msg) => {
  const notifyMsg = msg ? msg : translate("permissionDenied");
  createToast({ type: "warning", msg: notifyMsg });
};

/**
 * get the URL to a managed file
 * @param {Object} file
 * @returns url or ""
 */
export const getFileURL = (mfile) => {
  if (mfile) {
    return getNextJsApiURL(`/download/${mfile.id}`);
  } else {
    return "";
  }
};

export const getJustifyContentClassName = (alignment) => {
  switch (alignment) {
    case "left":
      return "justify-content-start";
    case "middle":
      return "justify-content-center";
    case "right":
      return "justify-content-end";
    default:
      return "justify-content-start";
  }
};

export const getTextAlignmentClassName = (alignment) => {
  switch (alignment) {
    case "left":
      return "text-left";
    case "middle":
      return "text-center";
    case "right":
      return "text-right";
    default:
      return "text-left";
  }
};

/**
 * Get the MaxWidth for the "span" of a next.js-Image
 *
 * All params are optional
 * @param {number} imgWidth in px
 * @param {number} imgHeight in px
 * @param {number | string} maxWidth in px | in %
 * @param {number} maxHeight in px
 *
 * @returns {number | string} maxWidth in px | original maxWidth in %
 **/
export const calculateMaxWidth = (imgWidth, imgHeight, maxWidth, maxHeight) => {
  const scaledMaxWidth = (maxHeight / imgHeight) * imgWidth;

  if (!scaledMaxWidth) {
    if (maxWidth) {
      // only maxWidth (no MaxHeight) is set
      return maxWidth;
    }
    // no maxWidth or maxHeight set
    return imgWidth;
  }

  if (!imgWidth && !imgHeight) {
    // no img set -> placeholder image rendered
    // need to keep dimension (512:512)
    if (maxHeight) {
      // placeholder will be rendered with maxHeight so the maxWidth needs to be the same
      return maxHeight;
    } else {
      // no maxHeight set -> original sized placeholder image will be rendered
      return 512;
    }
  }

  if (maxWidth && scaledMaxWidth > maxWidth) {
    // maxWidth and maxHeight set, img bigger than maxWidth
    return maxWidth;
  }
  // maxWidth and maxHeight set, img smaller than maxWidth
  return scaledMaxWidth;
};

/**
 * combines pages and News to one list with identifier
 *
 * @param {Array} pages
 * @param {Array} news
 *
 * @returns Array with pages and news
 */
export const combineAllPageTypesContents = (pages, news, events, joboffers) => {
  let pagesWithIdentifier = [];
  let newsWithIdentifier = [];
  let eventsWithIdentifier = [];
  let jobofferWithIdentifier = [];

  if (pages && pages.length > 0) {
    pagesWithIdentifier = pages.map((page) => {
      return {
        ...page,
        pageType: PAGE_TYPE_CONTENTPAGE,
        groupByLabel: "pages",
      };
    });
  }
  if (news && news.length > 0) {
    newsWithIdentifier = news.map((singleNews) => {
      return {
        ...singleNews,
        pageType: PAGE_TYPE_NEWS,
        name: singleNews.title,
        groupByLabel: "news",
      };
    });
  }
  if (events && events.length > 0) {
    eventsWithIdentifier = events.map((singleEvent) => {
      return {
        ...singleEvent,
        pageType: PAGE_TYPE_EVENT,
        name: singleEvent.title,
        groupByLabel: "events",
      };
    });
  }
  if (joboffers && joboffers.length > 0) {
    jobofferWithIdentifier = joboffers.map((singleJoboffer) => {
      return {
        ...singleJoboffer,
        pageType: PAGE_TYPE_JOBOFFER,
        name: singleJoboffer.title,
        groupByLabel: "joboffers",
      };
    });
  }

  return [
    ...pagesWithIdentifier,
    ...newsWithIdentifier,
    ...eventsWithIdentifier,
    ...jobofferWithIdentifier,
  ];
};

/**
 * Checks if external links should be opened in an external tab.
 * Internal links are always "_self".
 * @param {String} url internal or external URL
 */
export const getTargetValue = (url) => {
  const isBlank = globalSettings.link.openExternalLinksInNewTab;
  try {
    new URL(url);
  } catch (error) {
    return "_self";
  }
  return isBlank ? "_blank" : "_self";
};

/**
 * Creates an ID that is used to jump to the element on the page.
 * The same pattern is used in the search.
 *  * @param {Obj} content strapi-component
 */
export const searchableID = (content) => {
  if (content && content.__component && content.id) {
    return `${content.__component.replace("contentelements.", "")}-${
      content.id
    }`;
  } else {
    return "not-searchable";
  }
};

/**
 * Returns a ***more*** DSGVO conform youtube url
 * @param {String} url e.g. "https://www.youtube.com/watch?v=jNQXAC9IVRw"
 * @returns youtube url or empty string if given url is invalid
 */
export const createDSGVOYoutubeUrl = (url) => {
  let sanitizedUrl = "";
  if (url) {
    const regEx =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    const match = url.match(regEx);
    // ignore invalid youtube url
    if (match && match[2].length == 11) {
      sanitizedUrl =
        "https://www.youtube-nocookie.com/embed/" + match[2] + "?controls=0";
    }
  }
  return sanitizedUrl;
};

export const removeSpecialCharacters = (value, keepHyphen = false) => {
  if (keepHyphen) {
    return value.replace(/[^\w\s-]/gi, "");
  }
  return value.replace(/[^\w\s]/gi, "");
};

export const translateUmlaute = (value) => {
  value = value.replace(/ä/g, "ae");
  value = value.replace(/ö/g, "oe");
  value = value.replace(/ü/g, "ue");
  value = value.replace(/ß/g, "ss");
  return value;
};

/**
 * Returns the youtube preview image
 * @param {String} url e.g. "https://www.youtube.com/watch?v=jNQXAC9IVRw"
 * @returns url or empty string if given url is invalid
 */
export const getYoutubePreviewImage = (url) => {
  let sanitizedUrl = "";
  if (url) {
    const regEx =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    const match = url.match(regEx);
    // ignore invalid youtube url
    if (match && match[2].length == 11) {
      sanitizedUrl = "https://i3.ytimg.com/vi/" + match[2] + "/0.jpg";
    }
  }
  return sanitizedUrl;
};

/**
 * Creates an URL-safe anchorString from given String.
 *  * @param {String} originalString
 */
export const createAnchorString = (originalString) => {
  return removeSpecialCharacters(
    translateUmlaute(originalString.slice(0, 40).toLowerCase()),
    true
  ).replaceAll(" ", "-");
};

/**
 * Creates BreadCrumbs {url: "", name: ""} from url.
 * -> this/is/a/test -> [{url: "/this", name: "this"}, {url: "/this/is", name: "is"}, {url: "/this/is/a", name: "a"}, {url: "/this/is/a/test", name: "test"}]
 * If currentPageTitle is provided the last breadCrumb is replaced by currentPageTitle
 * @param {String} url
 * @param {String} currentPageTitle optional
 * @param {String} localizedRootPageName rootPageName
 * @returns {Array} BreadCrumbsArray
 */

export const createBreadCrumbs = (
  url,
  currentPageTitle = "",
  localizedRootPageName = "Startseite"
) => {
  // default Value
  let breadCrumbs = [{ url: "", name: "" }];
  if (typeof url === "string") {
    url = process.env.NEXT_PUBLIC_ROOT_PAGE_URL + "/" + url;
    breadCrumbs = url.split("/");
    breadCrumbs = breadCrumbs.map((breadCrumbName, index) => {
      let breadCrumbURL = "";
      if (
        breadCrumbName === process.env.NEXT_PUBLIC_ROOT_PAGE_URL &&
        index === 0
      ) {
        breadCrumbURL = "/";
        breadCrumbName = localizedRootPageName;
      } else {
        // Create URLs for each URL-Part.
        for (let i = 0; i < index; i++) {
          breadCrumbURL += `/${breadCrumbs[i + 1]}`;
        }
      }
      return {
        url: breadCrumbURL,
        name: breadCrumbName,
      };
    });

    if (currentPageTitle) {
      breadCrumbs[breadCrumbs.length - 1].name = currentPageTitle;
    }
  }

  return breadCrumbs;
};

/**
 * takes the height and width settings of a content element and returns an object.
 * That object is needed, because there are content elements with different height and width definitions for different view ports.
 * Also converts a number to a string ending with px to use in the style
 * @param {*} cssDimension
 * @returns {
 * mobileDimension
 * tabletDimension
 * desktopDimension
 * wqhdDimension
 * }
 */
export const createImageDimensionObject = (cssDimension) => {
  if (typeof cssDimension !== "object") {
    let tempDimension = convertImageDimensionObject(cssDimension);
    return {
      mobile: tempDimension,
      tablet: tempDimension,
      desktop: tempDimension,
      wqhd: tempDimension,
    };
  } else {
    return {
      mobile: convertImageDimensionObject(cssDimension.mobile),
      tablet: convertImageDimensionObject(cssDimension.tablet),
      desktop: convertImageDimensionObject(cssDimension.desktop),
      wqhd:
        convertImageDimensionObject(cssDimension.wqhd) === "auto"
          ? convertImageDimensionObject(cssDimension.desktop)
          : convertImageDimensionObject(cssDimension.wqhd),
    };
  }
};

const convertImageDimensionObject = (cssDimension) => {
  let returnCssDimension;
  if (
    typeof cssDimension === "string" &&
    cssDimension.match(/^\d+$/) === null
  ) {
    returnCssDimension = cssDimension;
  } else if (
    typeof cssDimension === "number" ||
    (cssDimension && cssDimension.match(/^\d+$/) !== null)
  ) {
    returnCssDimension = `${cssDimension}px`;
  } else {
    returnCssDimension = "auto";
  }

  return returnCssDimension;
};

// /**
//  * converts the maxDimension to a string as to not have undefined in the css stylesheet
//  * @param {*} maxDimension
//  * @returns
//  */
// export const convertImageMaxDimensionOld = (maxDimension, windowWidth) => {
//   if (
//     typeof maxDimension === "string" &&
//     maxDimension.match(/^\d+$/) === null
//   ) {
//     return maxDimension;
//   } else if (typeof maxDimension === "object") {
//     // const windowWidth = isSSR() ? 1200 : window.innerWidth;
//     const breakpoints = {
//       mobile: globalSettings.responsive.breakpointmobile,
//       desktop: globalSettings.responsive.breakpointdesktop,
//     };
//     const mobileMaxDimension = maxDimension.mobile;
//     const tabletMaxDimension = maxDimension.tablet;
//     const desktopMaxDimension = maxDimension.desktop;

//     if (windowWidth <= breakpoints.mobile) {
//       return `${mobileMaxDimension}px`;
//     } else if (
//       windowWidth > breakpoints.mobile &&
//       windowWidth < breakpoints.desktop
//     ) {
//       return `${tabletMaxDimension}px`;
//     } else if (windowWidth >= breakpoints.desktop) {
//       return `${desktopMaxDimension}px`;
//     } else {
//       return "100px";
//     }
//   } else if (
//     typeof maxDimension === "number" ||
//     (maxDimension && maxDimension.match(/^\d+$/) !== null)
//   ) {
//     return `${maxDimension}px`;
//   } else {
//     return "100%";
//   }
// };

const convertMaxImageSizeToString = (cssDimension) => {
  let cssString;
  if (
    typeof cssDimension === "string" &&
    cssDimension.match(/^\d+$/) === null
  ) {
    cssString = cssDimension;
  } else if (
    typeof cssDimension === "number" ||
    (cssDimension && cssDimension.match(/^\d+$/) !== null)
  ) {
    cssString = `${cssDimension}px`;
  } else {
    cssString = "100%";
  }
  return cssString;
};

/**
 * TODO: if this works fine combine both dimension functions to one (controlled by parameter)
 * converts the maxDimension to a string as to not have undefined in the css stylesheet
 * @param {*} maxDimension
 * @returns
 */
export const convertImageMaxDimension = (maxDimension) => {
  if (typeof maxDimension !== "object") {
    let tempDimension = convertMaxImageSizeToString(maxDimension);
    return {
      mobile: tempDimension,
      tablet: tempDimension,
      desktop: tempDimension,
      wqhd: tempDimension,
    };
  } else {
    return {
      mobile: convertMaxImageSizeToString(maxDimension.mobile),
      tablet: convertMaxImageSizeToString(maxDimension.tablet),
      desktop: convertMaxImageSizeToString(maxDimension.desktop),
      wqhd:
        convertMaxImageSizeToString(maxDimension.wqhd) === "100%"
          ? convertMaxImageSizeToString(maxDimension.desktop)
          : convertMaxImageSizeToString(maxDimension.wqhd),
    };
  }
};

/**
 * checks if the entered Date is the default Date the of the database or null and returns true if it's the case or false otherwise.
 * @param {Date} updateDate
 */
export const isDefaultDateOrNull = (updateDate) => {
  if (
    updateDate == null ||
    updateDate === "1970-01-01T00:00:00.000Z" ||
    updateDate === "1969-12-31T23:00:00.000Z"
  ) {
    return true;
  } else {
    return false;
  }
};

export const numericDateStringFormat = {
  day: "numeric",
  month: "numeric",
  year: "numeric",
};

export const numericTimeStringFormat = {
  hour: "numeric",
  minute: "numeric",
};

export const newDateWihtoutTime = (date = new Date()) => {
  return new Date(new Date(date).setHours(0, 0, 0, 0));
};

export const dateToLocaleDDMMYYYDateString = (date, locale) => {
  return new Date(date).toLocaleDateString(locale, numericDateStringFormat);
};

export const dateToLocaleHHMMTimeString = (date, locale) => {
  return new Date(date).toLocaleTimeString(locale, numericTimeStringFormat);
};

export const dateToLocaleMMMMYYYYDateString = (date, locale) => {
  return new Date(date).toLocaleDateString(locale, {
    month: "long",
    year: "numeric",
  });
};

export const dateToLocaleLongWeekDayString = (date, locale) => {
  return new Date(date).toLocaleDateString(locale, {
    weekday: "long",
  });
};

export const dateToLocaleShortWeekdayString = (date, locale) => {
  return new Date(date).toLocaleDateString(locale, { weekday: "short" });
};

/**
 * checks if a link is external or internal.
 * If it is internal then the "/" is added to it
 * If the link is external (ie. starts with www.) then the "https://" is added to the link
 *
 * @param {string} linkToSanitize
 */
export const sanitizeLink = (linkToSanitize) => {
  let sanitizedLink = null;
  const link = linkToSanitize || "";

  if (link.startsWith("www.")) {
    sanitizedLink = `https://${link}`;
  } else if (
    !link.startsWith("http") &&
    !link.startsWith("/") &&
    !link.startsWith("mailto:") &&
    !link.startsWith("tel:")
  ) {
    sanitizedLink = `/${link}`;
  } else {
    sanitizedLink = link;
  }

  // convert /NEXT_PUBLIC_ROOT_PAGE_URL to "/"
  if (sanitizedLink === "/" + process.env.NEXT_PUBLIC_ROOT_PAGE_URL) {
    sanitizedLink = "/";
  }

  return sanitizedLink;
};

/**
 * Checks if the YoutubeCookieConsent component is currently shown.
 * @returns
 */
export const isYoutubeCookieConsentVisible = () => {
  if (!isSSR()) {
    return window.Cookiebot?.consent?.marketing === false;
  }
  return false;
};

/*
 * takes hex value and transforms returns an array with the rgb values
 * @param {*} hex
 * @returns RGB values array
 */
export const hexToRgb = (hex) => {
  if (typeof hex === "string") {
    const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    if (normal) return normal.slice(1).map((e) => parseInt(e, 16));

    const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
    if (shorthand) return shorthand.slice(1).map((e) => 0x11 * parseInt(e, 16));

    return [0, 0, 0];
  }
  return [0, 0, 0];
};

/**
 * E.g.: "formfields.checkbox" to "checkbox"
 * @param {String} formFieldName
 */
export const getFormfieldComponentName = (formFieldName) => {
  // the component name is translated and text has a different translation so message is used
  if (formFieldName.includes("text")) {
    return "message";
  }
  return formFieldName.replace("formfields.", "");
};

/**
 * E.g.: "contentelements.headline" to headline
 * @param {String} contentElementString
 */
export const getContentElementName = (contentElementString) => {
  return contentElementString.replace("contentelements.", "");
};

/**
 * E.g.: "repeatablecontent.headline" to headline
 * @param {String} repeatableContentElementString
 */
export const getRepeatableContentElementName = (
  repeatableContentElementString
) => {
  return repeatableContentElementString.replace("repeatablecontent.", "");
};
// returns the corresponding i18n version of the router.locale
export const geti18nLocale = (nextJsLocale) => {
  const strapiLocales = {
    de: "de-DE",
    "de-DE": "de-DE",
    en: "en-US",
    "en-US": "en-US",
    fr: "fr",
    cn: "zh-Hans",
    "zh-Hans": "zh-Hans",
  };
  return strapiLocales[nextJsLocale];
};

/**
 * Returns the corresponding frontend/nextjs-locale (from nexti18next).
 * Returns nextjs locale
 * @param {String} strapiLocale
 * @returns
 */
export const matchWithNexti18NextLocale = (i18nLocale) => {
  const nextjsLocales = {
    ["de-DE"]: "de",
    ["en-US"]: "en",
    fr: "fr",
    ["zh-Hans"]: "cn",
  };
  return nextjsLocales[i18nLocale];
};

export const getFileUploadStatusCodeMessage = (status) => {
  switch (status) {
    case 413:
      return translate("fileTooBig");
    case -1:
    default:
      return translate("unexpectedError");
  }
};

/**
 * finds the default language id from the given object
 * this function looks into the localizedEntity.locale attribute if this was not
 * the default locale the function will search the localizedEntity.localizations
 * array for the default locale id
 *
 * @param {object} localizedEntity localized object with localizations array in it
 * @returns the object id from the default locale
 */
export const findDefaultLocaleIdInLocalizationsArray = (localizedEntity) => {
  if (
    localizedEntity.locale ===
    geti18nLocale(process.env.NEXT_PUBLIC_DEFAULT_LOCALE)
  ) {
    return localizedEntity.id;
  } else {
    if (localizedEntity.localizations) {
      const defaultLocaleObject = localizedEntity.localizations.find(
        (localeObject) =>
          localeObject.locale ===
          geti18nLocale(process.env.NEXT_PUBLIC_DEFAULT_LOCALE)
      );
      if (defaultLocaleObject) {
        return defaultLocaleObject.id;
      }
    }
  }
  console.log(
    `could not find default locale id returning id from parameter 
      locale=${localizedEntity.locale}`
  );
  return localizedEntity.id;
};

/**
 * cleans the browser cache, mostly for the SW (Serviceworker)
 */
export const cleanCache = () => {
  const cacheName = "old_cache";
  (async () => {
    try {
      const keys = await caches.keys();
      return keys.map(async (cache) => {
        if (cache !== cacheName) {
          console.log("Service Worker: Removing old cache: " + cache);
          return await caches.delete(cache);
        }
      });
    } catch (error) {
      console.log("Could not clean cache. (Private Tab?)");
      console.log(error);
    }
  })();
};

/**
 * Use this in a content-element to set the background-color of the container
 * @param {Object} cfgBackgroundColor props.content.cfgBackgroundColor from CE
 * @param {Object} settings props.settings from CE
 * @returns
 */
export const contentElementBGColor = (cfgBackgroundColor, settings) => {
  if (cfgBackgroundColor === "primary") {
    return settings.backgroundColorPrimary;
  } else if (cfgBackgroundColor === "secondary") {
    return settings.backgroundColorSecondary;
  }
  return "unset";
};

/**
 * spaceInPercent
 * Note: If you are in need for a significant larger spaceX in WQHD and above: scalingfactoSpaceX2kPlus: 5+ it is.
 * @param {Number} spaceXpx spaceX: 100px
 * @param {Number} breakpoint breakpoint: 999px
 * @param {Number} factor scalingFactor: 0.75
 * @returns {String} with %!
 */
export const spaceInPercent = (spaceXpx, breakpoint, factor = 1) => {
  // console.log(
  //   spaceXpx,
  //   breakpoint,
  //   factor,
  //   `${Math.floor((spaceXpx / breakpoint) * 100 * factor)}%`
  // );
  return `${Math.floor((spaceXpx / breakpoint) * 100 * factor)}%`;
};

/**
 * optimizedImage
 * Returns the image with width (!) dimension closest to given widthLimitSettings.
 * If no widthLimitSettings are provided it will default to mobile 700 and desktop 1400.
 * widthLimitSettings can also be an Object {desktop: 1200, mobile: 600}
 * Note: mobile is mobile AND tablet - so always pass tabletLimit as widthLimitSettings.mobile!
 * @param {Object} mimg managedImage (with .file as the actual image)
 * @param {Boolean} isMobile
 * @param {Number} widthLimitSettings optional: Number: 615 or Null or Object: {desktop: 999, mobile: 555}
 * @param {Boolean} skipOptimization optional: (default: false)
 * @returns {Object} image
 */
export const optimizedImage = (
  mimg,
  isMobile,
  widthLimitSettings = null,
  skipOptimization = false
) => {
  const optimizationDisabled = globalSettings.seo.disableImageOptimization;

  // Hackathon
  // Instead of the file managedImage-Object will be passed to optimizeImage
  // TODO: This needs to be adjusted to the managedFile-Object.
  if (
    !mimg ||
    mimg.isPrivate ||
    !mimg.file ||
    !mimg.file.formats ||
    isGIF(mimg.file) ||
    optimizationDisabled ||
    skipOptimization
  ) {
    return mimg;
  }

  const MOBILE_MAX_IMG_WIDTH = 700;
  const DESKTOP_MAX_IMG_WIDTH = 1400;

  // Dimensions: Sorted from smallest to largest.
  const dimensions = new Map();
  Object.entries(mimg.file.formats)
    .sort((a, b) => {
      return a[1].width - b[1].width;
    })
    .forEach((sortedFormat) =>
      dimensions.set(sortedFormat[1].width, sortedFormat[0])
    );
  dimensions.set(mimg.file.width, "normal");

  let widthLimit;
  if (isMobile) {
    if (!widthLimitSettings) {
      widthLimit = MOBILE_MAX_IMG_WIDTH;
    } else {
      const widthLimitSetting = widthLimitSettings.mobile
        ? widthLimitSettings.mobile
        : widthLimitSettings;
      widthLimit =
        widthLimitSetting < MOBILE_MAX_IMG_WIDTH
          ? widthLimitSetting
          : MOBILE_MAX_IMG_WIDTH;
    }
  } else {
    if (!widthLimitSettings) {
      widthLimit = DESKTOP_MAX_IMG_WIDTH;
    } else {
      const widthLimitSetting = widthLimitSettings.desktop
        ? widthLimitSettings.desktop
        : widthLimitSettings;
      widthLimit =
        widthLimitSetting < DESKTOP_MAX_IMG_WIDTH
          ? widthLimitSetting
          : DESKTOP_MAX_IMG_WIDTH;
    }
  }

  // Finding the dimension that is closest above 'widthLimit'
  for (const [width, format] of dimensions) {
    if (width > widthLimit) {
      if (format !== "normal") {
        // TBD: Maybe keep this console.log for non-Developers? [without very hard to detect optimizations]
        // console.log(
        //   `optimizedImage: ${mimg.file.width} to ${width} (${format}) [Limit: ${widthLimit}].`
        // );

        // Set url of image to the url of optimized version.
        mimg.file.url = mimg.file.formats[format].url || mimg.file.url;
        mimg.file.width = mimg.file.formats[format].width || mimg.file.width;
        mimg.file.height = mimg.file.formats[format].height || mimg.file.height;
      }
      // Skip other formats
      break;
    }
  }

  return mimg;
};

/**
 * Returns true if URL ends with .gif.
 * @param file
 * @returns
 */
export const isGIF = (file) => {
  return file && file.url && file.url.endsWith(".gif");
};

/**
 * Returns the corresponding CSS object-fit value
 *
 * @param {String} cfgValue the cfg value from cfgImageObjectFit
 * @returns {String} css attribute value
 */
export const imageObjectFitValue = (cfgValue) => {
  switch (cfgValue) {
    case "cover":
    case "coverTop":
    case "coverBottom":
      return "cover";
    case "contain":
    default:
      return "contain";
  }
};

/**
 * getCssMaxWidthValue
 * Returns 100% or the px-Value.
 * @param {Number} cfgMaxWidthValue
 * @param {Boolean} cfgIgnoreMaxWidthValue
 * @returns {String} CSS Attribute Value (Value + Unit)
 */
export const getCssMaxWidthValue = (
  cfgMaxWidthValue,
  cfgIgnoreMaxWidthValue
) => {
  // TBD: We could also transform very high cfgMaxWidthValues (2000+) to 100% here.
  // For now: These values are wanted/configuration errors.
  if (cfgIgnoreMaxWidthValue || !cfgMaxWidthValue) {
    return "100%";
  }
  return `${cfgMaxWidthValue}px`;
};

/**
 * returns the corresponding CSS object-position value
 *
 * @param {String} cfgValue the cfg value from cfgImageObjectFit
 * @returns {String} css attribute value
 */
export const imageObjectPositionValue = (cfgValue) => {
  switch (cfgValue) {
    case "coverTop":
      return "top";
    case "coverBottom":
      return "bottom";
    case "contain":
    case "cover":
    default:
      return "center";
  }
};

export const removeWrappingSingleQuotes = (text) => {
  if (text.charAt(0) === "'" && text.charAt(text.length - 1) === "'") {
    return text.substring(1, text.length - 1);
  }
  return text;
};

export const handleResponseToastNotifications = (
  result,
  generalSuccess,
  generalError,
  namespace = "cms"
) => {
  if (!result.success) {
    /** @type {Array<{ error: string }>} */
    const errorArray = result.error.response?.data?.message;
    if (errorArray instanceof Array && errorArray.length > 0) {
      for (const errorObj of errorArray) {
        createToast({
          type: "warning",
          msg: translate(errorObj.error),
        });
      }
    }
    createToast({
      type: "error",
      msg: translate(`${namespace}:${generalError}`),
    });
  } else {
    createToast({
      type: "success",
      msg: translate(`${namespace}:${generalSuccess}`),
    });
  }
};

/**
 * Checks if the meta title should be used as page title
 * Finds the meta tag with name="title" and returns its contents or null
 * @param {*} pageData page instance
 * @returns meta title or null
 */
export const getMetaTitleOrNull = (pageData) => {
  if (pageData.isSeoTitlePageTitle) {
    const match = pageData.seoSettings.match(
      /<meta name="title" content="(.+)" \/>/
    );
    if (match && match[1]) {
      return match[1];
    }
  }
  return null;
};

/**
 * Returns the corresponding CSS object-fit value
 * @param {*} cfgValue the cfg value from cfgImageObjectFit
 * @returns "cover"/"none"/"contain" (default)
 */
export const getObjectFitValue = (cfgValue) => {
  switch (cfgValue) {
    case "objectfit-cover":
      return "cover";
    case "objectfit-none":
      return "none";
    case "objectfit-contain":
    default:
      return "contain";
  }
};

// NOTE: Unused. Don't use OR be sure about the difference between server-rendered and client-side-rendered.
// /**
//  * getSizeForCurrentBreakpoint
//  * Note: You should always consider using css-only to apply a given height/width to different breakpoints.
//  * However atm some components require a width/height-Prop.
//  *
//  * @param {object} sizes {mobile: 200, tablet: 300, desktop: 400, wqhd: 400 }
//  * @param {object} windowWidth windowSize.width from useWindowSize
//  * @returns  sizes[mobile/tablet/desktop/wqhd] / sizes
//  */
// export const getSizeForCurrentBreakpoint = (sizes, windowWidth) => {
//   if (windowWidth && sizes && typeof sizes === "object") {
//     if (windowWidth < globalSettings.responsive.breakpointdesktop) {
//       // Below Desktop:
//       if (windowWidth < globalSettings.responsive.breakpointmobile) {
//         // Mobile:
//         return sizes.mobile;
//       } else {
//         // Tablet:
//         return sizes.tablet;
//       }
//     } else {
//       // Above Desktop:
//       if (windowWidth < globalSettings.responsive.breakpoint2k) {
//         // Desktop:
//         return sizes.desktop;
//       } else {
//         // WQHD:
//         return sizes.wqhd;
//       }
//     }
//   } else {
//     return sizes;
//   }
// };

/**
 * Returns Array of URLs that belong to inactive Features.
 * Used to filter URLs from pageLists.
 * @returns []
 */
export const getInactiveFeatureURLs = () => {
  let inactiveFeatureURLs = [];
  if (!cmsHasFeature(CMS_FEATURE_NEWS)) {
    inactiveFeatureURLs.push(CMS_FEATURE_NEWS);
  }

  if (!cmsHasFeature(CMS_FEATURE_EVENTS)) {
    inactiveFeatureURLs.push(CMS_FEATURE_EVENTS);
  }

  if (!cmsHasFeature(CMS_FEATURE_JOBOFFERS)) {
    inactiveFeatureURLs.push(CMS_FEATURE_JOBOFFERS);
  }

  if (!cmsHasFeature(CMS_FEATURE_USERAPI)) {
    // user/login
    inactiveFeatureURLs.push(USER_LOGIN_URL.replace("/", ""));
  }

  return inactiveFeatureURLs;
};

/**
 * Returns true/false if URL belongs to an inactive feature.
 * @param {String} url height or width
 * @returns []
 */
export const isURLofInactiveFeature = (url) => {
  return getInactiveFeatureURLs().includes(url);
};

/**
 * Checks if the given file or managedfile is an image or
 * SVG that can be displayed in a <img> component
 * @param {*} fileOrManagedFile
 * @returns
 */
export const isImage = (fileOrManagedFile) => {
  if (!fileOrManagedFile) {
    return false;
  }
  if (fileOrManagedFile instanceof File) {
    // check type attribute (javascript file)
    if (fileOrManagedFile.type.includes("image")) {
      return true;
    }
    return false;
  } else if (fileOrManagedFile.file && fileOrManagedFile.file.mime) {
    // check mime attribute (managedfile)
    if (fileOrManagedFile.file.mime.includes("image")) {
      return true;
    }
  }
  return false;
};

/**
 * This function is mainly used for strapi enum fields
 * where you can choose from a dropdown (enum)
 * to select a number. In strapi you can not assign
 * only numbers to the dropdown enum values.
 *
 * currently this is used to select the bootstrap col
 * count.
 *
 * @param {String} numberString
 * @param {number} defaultValue optional the default value that gets returned (default is 3)
 * @returns
 */
export const getIntegersFromEnum = (
  numberString, defaultValue = 3 
)=> {
  const enumMappings = {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
  };

  if (numberString && enumMappings.hasOwnProperty(numberString)) {
    return enumMappings[numberString];
  }
  return defaultValue;
};

/**
 * returns the globalsettings color
 * 
 * for example "primarycolor1" could be returned as #000000
 * @param {string} type
 * @returns
 */
export const getGlobalSettingsColor = (type) => {
  return globalSettings.color[type] || "unset";
};

/**
 * Reloads the page and always triggers getServerSideProps to re-fetch page data.
 * router.replace("/foo#bar") does not trigger getServerSideProps.
 * With this workaround it does.
 * @param {Object} router the useRouter from nextJS
 * @example 
 * const router = useRouter();
 * await triggerGetServerSideProps(router);
 */
export const triggerGetServerSideProps = async (router) => {
  if (router.asPath.includes("#")) {
    const firstReplaceFinished = await router.replace(window.location.pathname);
    if (firstReplaceFinished) {
      await router.replace(window.location.pathname);
    }
  } else {
    await router.replace(router.asPath);
  }
};

/**
 * Gets localized pagename if exists
 * Used for cmsLinkchooser and cmsNavItem
 * @param {*} router useRouter instance
 * @param {*} option autocomplete getOptionLabel / renderOption Parameter
 * @returns 
 */
export const getLocalizedPagename = (router, option) => {
  let pageName = option.name;

  if (router.locale !== process.env.NEXT_PUBLIC_DEFAULT_LOCALE) {
    // change pagename to different locale if exists
    const localizedPage = option.localizations.find(
      (localization) => localization.locale === geti18nLocale(router.locale)
    );
    if (localizedPage) {
      pageName = localizedPage.name;
    }
  }
  return pageName;
}

/** 
 * Returns the localized alt/title text of an mfile
 * @param {string} type e.g. "title" or "altText" 
 * @param {Object} mfile 
 * @param {string} locale e.g. router.locale
 * @returns 
 */
export const getMfileAltTextOrTitle = (type, mfile, locale) => {
  if (!mfile || !type || !locale) {
    return "";
  }

  if (isLocaleDefaultLocale(locale)) {
    return mfile[type] || "";
  }

  if (mfile.localization) {
    return mfile.localization[geti18nLocale(locale)][type];
  }
  return "";
};

/**
 * Builds metatags for pages. Used on every page ([...url], [...newsUrl], ...)
 * NOTE: Currently only works with name, property and content attributes on meta tags
 * build as a replacement for html-react-parser
 * @param {*} seoSettings props.page.seoSettings object
 * @returns <meta name="xx" content="xx"></meta>
 *          <meta name="yy" content="yy"></meta>
 */
export const buildMetaTags = (seoSettings) => {
  const regex = /<meta\s+((?:name|property))="([^"]+)"\s+content="([^"]+)"\s*\/>/g;
  let match;
  const metaTags = [];

  while ((match = regex.exec(seoSettings)) !== null) {
    const myAttribute = match[1]
    const attributeName = match[2].toLowerCase(); 
    const content = match[3]; 
    const metaTagElement = <meta {...(myAttribute === 'name' ? { name: attributeName } : { property: attributeName })} content={content} key={attributeName} />;
    
    metaTags.push(metaTagElement);
  }
  return metaTags;
}

/**
 * returns the to currentLocal matching localized version of a contentpage if it exists
 * 
 * else returns the default contentpage
 * @param {string} currentLocale 
 * @param {object} currentContentPage 
 * @returns 
 */
export const getCurrentLocalizedContentpage = (
  currentLocale,
  currentContentPage
) => {
  if (isLocaleDefaultLocale(currentLocale)) {
    return currentContentPage;
  }

  const localizedContentPage = currentContentPage.localizations.find(
    (localizedContentPage) =>
      localizedContentPage.locale === geti18nLocale(currentLocale)
  );

  if (localizedContentPage) {
    return localizedContentPage;
  }

  return currentContentPage;
};