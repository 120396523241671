export const CMS_RESET_PAGE_EDITOR = "CMS_RESET_PAGE_EDITOR";
export const CMS_OPEN_PAGE_EDITOR = "CMS_OPEN_PAGE_EDITOR";
export const CMS_INIT_PAGE_EDITOR = "CMS_INIT_PAGE_EDITOR";
export const CMS_PAGE_DOES_NOT_EXIST = "CMS_PAGE_DOES_NOT_EXIST";
export const CMS_SAVE_PAGE = "CMS_SAVE_NAV";
export const CMS_CREATE_PAGE = "CMS_CREATE_PAGE";
export const CMS_UPDATE_PAGE = "CMS_UPDATE_PAGE";
export const CMS_DELETE_PAGE = "CMS_DELETE_PAGE";
export const CMS_PAGE_REDIRECT = "CMS_PAGE_REDIRECT";
export const CMS_CLOSE_PAGE_EDITOR = "CMS_CLOSE_PAGE_EDITOR";
export const CMS_LOAD_ORIGINAL_PAGE = "CMS_LOAD_ORIGINAL_PAGE";
