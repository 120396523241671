import {
  getAllPublishedEventsServerSide,
  getAllPublishedIncludingPrivateEventsServerSide,
} from "services/event/eventServerService";
import {
  getAllPublishedIncludingPrivateJoboffersServerSide,
  getAllPublishedJoboffersServerSide,
} from "services/joboffer/jobofferServerService";
import {
  getAllPublishedIncludingPrivateNewsServerSide,
  getAllPublishedNewsServerSide,
} from "services/news/newsServerService";
import { getStrapiURL } from "utils/api";
import {
  CMS_FEATURE_EVENTS,
  CMS_FEATURE_JOBOFFERS,
  CMS_FEATURE_NEWS,
} from "utils/constants";
import {
  axiosServerGetRequest,
  cmsHasFeatureServerSide,
  getCmsAccessToken,
  handleServerRequestError,
} from "utils/serverUtil";

export const getPages = async (req, returnAsResult, locale) => {
  if (!locale) {
    locale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;
  }
  const result = await axiosServerGetRequest(
    getStrapiURL(`/pages${locale ? `?_locale=${locale}` : ""}`),
    {
      cmsaccesstoken: getCmsAccessToken(req),
    }
  );
  if (result.success) {
    if (returnAsResult) {
      return result;
    }
    return result.response.data;
  } else {
    handleServerRequestError(
      result.error,
      "could not get pages [pageService getPages]"
    );
    if (returnAsResult) {
      return result;
    }
    return [];
  }
};

export const getPage = async (url, locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/pages/url/${url}?_locale=${locale}`)
  );
  if (result.success) {
    return result;
  } else {
    handleServerRequestError(
      result.error,
      "could not get page [pageService getPage] with locale " + locale
    );
    return result;
  }
};

export const getCmsPage = async (url, req, locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/pages/cms/url/${url}?_locale=${locale}`),
    { cmsaccesstoken: getCmsAccessToken(req) }
  );
  if (result.success) {
    return result;
  } else {
    handleServerRequestError(
      result.error,
      "could not get page [pageService getCmsPage] with locale " + locale
    );
    return result;
  }
};

export const getDefaultPage = async (locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/custom/default?_locale=${locale}`)
  );
  if (!result.success) {
    handleServerRequestError(
      result.error,
      "could not get index page [getDefaultPage] with locale " + locale
    );
  }
  return result;
};

export const getCmsDefaultPage = async (req, locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/custom/cms/default?_locale=${locale}`),
    { cmsaccesstoken: getCmsAccessToken(req) }
  );
  if (!result.success) {
    handleServerRequestError(
      result.error,
      "could not get index page [getDefaultPage] with locale " + locale
    );
  }
  return result;
};

/**
 * This function check if the requested URL leads to a hardcoded page.
 * If so, it fetches the needed data to render the hardcoded page and returns it.
 * @param {*} url
 * @param {*} locale
 * @param {*} authenticatedUser CMS User or authenticated userapi user
 * @returns hardcodedcontent | true (hardcodedpage without content) | null (no hardcoded page)
 */
export const getHardcodedContent = async (url, locale, authenticatedUser) => {
  switch (url) {
    case "news":
      if (!cmsHasFeatureServerSide(CMS_FEATURE_NEWS)) {
        return {
          notFound: true,
        };
      }
      let hardcodedContent;
      if (!authenticatedUser) {
        hardcodedContent = await getAllPublishedNewsServerSide(locale);
      } else {
        hardcodedContent = await getAllPublishedIncludingPrivateNewsServerSide(
          locale
        );
      }

      return hardcodedContent.response.data;
    case "events":
      if (!cmsHasFeatureServerSide(CMS_FEATURE_EVENTS)) {
        return {
          notFound: true,
        };
      }
      // Shows only events which are published, wether you`re logged in or not
      let resultEvents;
      if (!authenticatedUser) {
        resultEvents = await getAllPublishedEventsServerSide(
          locale,
          "dateTimeStart=asc"
        );
      } else {
        resultEvents = await getAllPublishedIncludingPrivateEventsServerSide(
          locale,
          "dateTimeStart=asc"
        );
      }
      return resultEvents.response.data;
    case "joboffers":
      if (!cmsHasFeatureServerSide(CMS_FEATURE_JOBOFFERS)) {
        return {
          notFound: true,
        };
      }
      let resultJoboffers;
      if (!authenticatedUser) {
        resultJoboffers = await getAllPublishedJoboffersServerSide(locale);
      } else {
        resultJoboffers =
          await getAllPublishedIncludingPrivateJoboffersServerSide(locale);
      }
      return resultJoboffers.response.data;
    default:
      return null;
  }
};
