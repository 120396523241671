import * as actionTypes from "store/actionTypes/userActionTypes";

export const initUserState = {
  isAuthenticated: false,
  expirationDate: null,
  user: null,
};

const userReducer = (state = initUserState, action) => {
  switch (action.type) {
    case actionTypes.USER_AUTHENTICATE_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        expirationDate: action.payload.expirationDate,
        user: action.payload.user,
      };
    case actionTypes.USER_REFRESH_TOKEN_SUCCESS:
      // do nothing on initial client side token refresh
      // instead in the userActionCreator the
      // current route is replaced to retrigger
      // getserversideprops again with the new
      // accesstoken
      if (action.payload.isInitial) {
        return state;
      }
      // if it is a subsequent refresh token action
      // put the new user data and expirydate to the state
      return {
        ...state,
        isAuthenticated: true,
        expirationDate: action.payload.expirationDate,
        user: action.payload.user,
      };
    case actionTypes.USER_AUTHENTICATE_WAITING_FOR_2FA:
      return {
        ...state,
        waitingForTwoFactor: true,
      };
    case actionTypes.USER_LOGOUT_SUCCESS:
      return initUserState;
    case actionTypes.USER_AUTHENTICATE_FAILED:
      return initUserState;
    case actionTypes.USER_REFRESH_TOKEN_FAILED:
      return initUserState;
    case actionTypes.USER_FORGOT_PASSWORD_SUCCESS:
      // TODO add state handling here for after successful pw set
      return state;
    case actionTypes.USER_LOGOUT_FAILED:
      return initUserState;
    case actionTypes.USER_FORGOT_PASSWORD_FAILED:
    case actionTypes.USER_SET_PASSWORD_SUCCESS:
    case actionTypes.USER_SET_PASSWORD_FAILED:
    case actionTypes.USER_LOGOUT_FAILED:
    default:
      return state;
  }
};

export default userReducer;
