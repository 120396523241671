import { useSelector } from "react-redux";

/**
 * Hook for checking the isAuthenticated flag from the redux store.
 * You could use (useSelector state.user.isAuthenticated) directly but
 * since the content manager can simulate an authenticated user this hook
 * should be used where you want to render authenticated content. 
 * (e.g. navigation)
 * 
 * @returns 
 */
export default function useUserAuthentication() {
  const isAuthenticatedState = useSelector(
    (state) => state.user.isAuthenticated
  );

  const isCmsUserAuthenticated = useSelector(
    (state) => state.cms.isCmsUserAuthenticated
  );

  // TRM-88: A previewMode for authenticatedUsers is not wanted anymore.
  // -> Replaced with isCmsUserAuthenticated.
  // However this might change in the future and will be useful again.
  // const authenticatedUserPreviewState = useSelector(
  //   (state) => state.cms.authenticatedUserPreview
  // );
  // return isAuthenticatedState || authenticatedUserPreviewState;
  return isAuthenticatedState || isCmsUserAuthenticated;
}
