import { getStrapiURL } from "utils/api";
import { CMS_FEATURE_NEWS } from "utils/constants";
import {
  axiosServerGetRequest,
  cmsHasFeatureServerSide,
  getCmsAccessToken,
  handleServerRequestError,
} from "utils/serverUtil";
// get only published news
export const getNews = async (newsUrl, locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/newsarticles/get/${newsUrl}?_locale=${locale}`)
  );
  if (result.success) {
    console.log(`fetched news with url ${newsUrl} with locale ${locale}`);
  } else {
    console.log(
      `could not fetch news with url ${newsUrl} with locale ${locale}`
    );
  }
  return result;
};
// get unpublished & published news
export const getCmsNews = async (newsUrl, req, locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/newsarticles/cms-get/${newsUrl}?_locale=${locale}`),
    { cmsaccesstoken: getCmsAccessToken(req) }
  );
  if (result.success) {
    console.log(`fetched news with url ${newsUrl} with locale ${locale}`);
  } else {
    console.log(
      `could not fetch news with url ${newsUrl} with locale ${locale}`
    );
  }
  return result;
};

export const getAllNews = async (req, returnAsResult, locale) => {
  // check the feature here again, to prevent the error log
  // this is only needed for _app.js - other news server service
  // functions shouldnt need this
  if (cmsHasFeatureServerSide(CMS_FEATURE_NEWS)) {
    if (!locale) {
      locale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;
    }
    const result = await axiosServerGetRequest(
      getStrapiURL(`/newsarticles?_locale=${locale}`),
      {
        cmsaccesstoken: getCmsAccessToken(req),
      }
    );
    if (result.success) {
      if (returnAsResult) {
        return result;
      }
      return result.response.data;
    } else {
      handleServerRequestError(
        result.error,
        "could not get news [newsServerService getAllNews]"
      );
      if (returnAsResult) {
        return result;
      }
      return [];
    }
  }
  if (returnAsResult) {
    return { success: false, error: null };
  }
  return [];
};

export const getAllPublishedNewsServerSide = async (locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(`/newsarticles/published?_locale=${locale}`)
  );
  if (!result.success) {
    handleServerRequestError(
      result.error,
      "could not get published news [newsServerService getAllPublishedNews]"
    );
  }
  return result;
};

export const getAllPublishedIncludingPrivateNewsServerSide = async (locale) => {
  const result = await axiosServerGetRequest(
    getStrapiURL(
      `/newsarticles/published?_locale=${locale}&includePrivate=true`
    )
  );
  if (!result.success) {
    handleServerRequestError(
      result.error,
      "could not get published news [newsServerService getAllPublishedIncludingPrivateNewsServerSide]"
    );
  }
  return result;
};
